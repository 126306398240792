import React, { useEffect } from "react";
import Historial from "../components/Historial/Historial";

const HistorialPage = () => {
  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - Historial`;
  }, []);

  return <Historial />;
};

export default HistorialPage;
