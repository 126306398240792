import React from "react";
import { shallow } from "zustand/shallow";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import TiposPeriodos from "./TiposPeriodos";
import TiposNovedades from "./TiposNovedades";
import TiposIdentificacion from "./TiposIdentificacion";

import Layout from "../Layout/Layout";

import { useParametrosStore } from "../../store/parametrosStore";

type TabLabelType = {
  id: number;
  label: string;
};

const tabsLabels: TabLabelType[] = [
  { id: 1, label: "Tipos de Periodos" },
  { id: 2, label: "Tipos de Novedad" },
  { id: 3, label: "Tipos de Identificación" },
];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`parametro-tabpanel-${index}`}
      aria-labelledby={`parametro-tab-${index}`}
      style={{ height: "100%" }}
      {...other}
    >
      {/* {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )} */}
      {value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `parametro-tab-${index}`,
    "aria-controls": `parametro-tabpanel-${index}`,
    sx: { color: "black" },
  };
}

const CompaniesParametros = () => {
  const empresaTabValue = useParametrosStore(
    (state) => state.empresaTabValue,
    shallow
  );

  const setEmpresaTabValue = useParametrosStore(
    (state) => state.setEmpresaTabValue
  );

  const handleChangeTab = (newEmpresaTabValue: number) => {
    setEmpresaTabValue(newEmpresaTabValue);
  };

  const renderTabs = () => {
    return tabsLabels.map((tabLabel, index) => {
      return <Tab key={index} label={tabLabel.label} {...a11yProps(index)} />;
    });
  };

  return (
    <Layout icon="SettingsIcon" title="Parametros" noIcon>
      <Box
        sx={{
          height: "100%",
          "& .theme--header": {
            backgroundColor: "#EEF1FC",
          },
        }}
      >
        <Box>
          <Tabs
            value={empresaTabValue}
            onChange={(_, value: number) => handleChangeTab(value)}
            aria-label="parametros tabs"
            TabIndicatorProps={{ sx: { backgroundColor: "black" } }}
            sx={{ "& button.Mui-selected": { color: "black" } }}
          >
            {renderTabs()}
          </Tabs>
        </Box>
        <TabPanel value={empresaTabValue} index={0}>
          <TiposPeriodos />
        </TabPanel>
        <TabPanel value={empresaTabValue} index={1}>
          <TiposNovedades />
        </TabPanel>
        <TabPanel value={empresaTabValue} index={2}>
          <TiposIdentificacion />
        </TabPanel>
      </Box>
    </Layout>
  );
};

export default CompaniesParametros;
