import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { ReactComponent as BDOIcon } from "../icons/svg/bdo-icon.svg";

const NewNewLogin = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/gestiondecliente",
      },
    });
  };

  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - Iniciar sesión`;
  }, []);

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ backgroundColor: "#EEF1FC", minHeight: "100vh" }}
      >
        <Grid item xs={9} sx={{ maxHeight: "634px" }}>
          <Box
            component="main"
            sx={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              minHeight: "100%",
              backgroundColor: "white",
              width: "486px",
            }}
          >
            <Container sx={{ height: "634px", width: "486px" }}>
              <Box sx={{ paddingBottom: "72px", paddingTop: "148px" }}>
                <BDOIcon
                  width={320}
                  height={100}
                  style={{ paddingLeft: "50px" }}
                />
              </Box>
              <Box sx={{ pb: 9 }} justifyContent="center" display="flex">
                <Box sx={{ display: "flex", placeContent: "center" }}>
                  <Typography
                    sx={{
                      height: "48px",
                      fontWeight: 600,
                      fontSize: "32px",
                      lineHeight: "48px",
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",

                      color: "#000000",
                    }}
                  >
                    Bienvenido a BDO Payroll
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ pb: 18 }} justifyContent="center" display="flex">
                <Box width="50%">
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={() => handleLogin()}
                  >
                    Iniciar sesión
                  </Button>
                </Box>
              </Box>
            </Container>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default NewNewLogin;
