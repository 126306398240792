import React, { useContext, useEffect, useState } from "react";
import { isEqual } from "lodash";
import { shallow } from "zustand/shallow";

import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";

import CustomBreadcrumbs from "../../CustomBreadcrumbs/CustomBreadcrumbs";
import Layout from "../../Layout/Layout";
import InformationModal from "../../Modals/ConfirmationModal/InformationModal";
import { MaterialIcons } from "../../../icons/Material/MaterialIcons";

import { UserContext } from "../../../context/user/UserContext";
import { DrawerContext } from "../../../context/drawer/DrawerContext";

import {
  deleteNoveltyType,
  downloadNoveltyFile,
  editNoveltyType,
  getNoveltyTypeById,
} from "../../../services/ParametersService";

import { getCompanyNoveltyTypeById } from "../../../services/CompaniesService";

import { useEmpresaStore } from "../../../store/empresaStore";
import { useParametrosStore } from "../../../store/parametrosStore";

type ParametroNovedadDetailsType = {
  params: any;
};

const ParametroNovedadDetails: React.FC<ParametroNovedadDetailsType> = ({
  params,
}) => {
  const [previusState, setPreviusState] = useState({
    name: "",
    code: "",
    file: "",
    fileName: "",
  });
  const [openInformationModal, setOpenInformationModal] =
    useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [clickCancelar, setClickCancelar] = useState<boolean>(false);
  const [clickGuardar, setClickGuardar] = useState<boolean>(false);
  const [clickEliminar, setClickEliminar] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [modalMessage, setModalMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [fetchError, setFetchError] = useState<boolean>(false);
  const [fetchErrorMessage, setFetchErrorMessage] = useState<string>("");

  const links: any[] = [
    { id: "1", value: "Empresas", isLink: true, to: "/empresas" },
    {
      id: "2",
      value: "Empresa",
      isLink: true,
      to: `/empresas/empresa/${params.empresaId}`,
    },
    {
      id: "3",
      value: "Parametros",
      isLink: true,
      to: `/empresas/empresa/${params.empresaId}/parametros`,
    },
    { id: "4", value: "Tipo de Novedad", isLink: false },
  ];

  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  const { user } = useContext(UserContext);

  const { changeLink } = useContext(DrawerContext);

  const setTabValue = useParametrosStore((state) => state.setTabValue);

  const empresaId = useEmpresaStore((state) => state.empresaId, shallow);

  const { novedadId } = params;

  const formik = useFormik({
    initialValues: {
      name: "",
      code: "",
      fileName: "",
      file: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().max(55).required("El nombre es requerido"),
      code: Yup.string().max(255).required("El código es requerido"),
    }),
    onSubmit: async (data) => {
      const token = await getAccessTokenSilently();
      const finalData = {
        name: data.name,
        code: data.code,
        file: data.file,
      };
      const result = await editNoveltyType(token, novedadId, finalData);

      if (result.status === 201 || result.status === 200) {
        handleCloseModal();
        formik.resetForm();
        setTabValue(1);
        navigate(-1);
      }
    },
  });

  const handleUploadFile = (file: any) => {
    formik.setFieldValue("file", file);
    formik.setFieldValue("fileName", file.name);
  };

  const handleOpenModal = () => {
    setOpenInformationModal(true);
  };

  const handleCloseModal = () => {
    setOpenInformationModal(false);
  };

  const handleSaveButton = () => {
    formik.submitForm();
  };

  const handleDeshacerCancelar = () => {
    formik.resetForm();
    navigate(-1);
  };

  const handleClickDirtyCancelar = () => {
    setIsDirty(true);
    setClickCancelar(true);
    setClickGuardar(false);
    setClickEliminar(false);
    setModalTitle("Cancelar cambios");
    setModalMessage(
      "¿Querés cancelar los cambios realizados a éste tipo de novedad?"
    );
    handleOpenModal();
  };

  const handleClickGuardar = () => {
    setClickGuardar(true);
    setIsDirty(false);
    setClickCancelar(false);
    setClickEliminar(false);
    setModalTitle("Editar cambios");
    setModalMessage(
      "¿Querés guardar los cambios realizados a éste tipo de novedad?"
    );
    handleOpenModal();
  };

  const handleRemove = async () => {
    const token = await getAccessTokenSilently();

    const result = await deleteNoveltyType(token, novedadId);
    if (result.status === 200 || 201) {
      handleCloseModal();
      navigate(-1);
    }
  };

  const handleClickEliminar = () => {
    setClickGuardar(false);
    setClickCancelar(false);
    setClickEliminar(true);
    setModalTitle("Eliminar tipo de novedad");
    setModalMessage("¿Querés eliminar éste tipo de novedad?");
    handleOpenModal();
  };

  const downloadArch = async (file: any) => {
    const token = await getAccessTokenSilently();
    const result = await downloadNoveltyFile(token, file.ref);

    // Create blob link to download
    const link = document.createElement("a");
    const url = window.URL.createObjectURL(new Blob([result.data]));
    link.href = url;
    link.setAttribute("download", file.name);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.dispatchEvent(new MouseEvent("click"));

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
  };

  const handleDownloadFile = (file: any) => {
    downloadArch(file);
  };

  useEffect(() => {
    const getNovelty = async () => {
      setIsLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const result =
          changeLink === 1
            ? await getNoveltyTypeById(token, novedadId)
            : await getCompanyNoveltyTypeById(token, empresaId, novedadId);

        const commonValues = {
          name:
            changeLink === 2 ? result.data.novelty_type.name : result.data.name,
          code:
            changeLink === 2 ? result.data.novelty_type.code : result.data.code,
          file: changeLink === 2 ? result.data.file : "",
          fileName: changeLink === 2 ? result.data.file.fileName : "",
        };
        setFetchError(false);

        formik.setValues(commonValues);
        setPreviusState(commonValues);
      } catch (error: any) {
        setFetchError(true);
        setFetchErrorMessage(
          error?.response?.data?.error || "Ha ocurrido un error"
        );
        console.error(error.response.data.error);
      } finally {
        setIsLoading(false);
      }
    };

    getNovelty();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [novedadId]);

  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - Tipo de novedad`;
  }, []);

  return (
    <Layout
      title="Tipo de Novedad"
      noIcon={params.empresaId && params.novedadId}
      breadcrumb={!params.empresaId && params.novedadId}
    >
      {params.empresaId && params.novedadId && (
        <CustomBreadcrumbs icon="PollIcon" links={links} />
      )}
      <Grid container marginTop="12px" borderRadius="6px">
        <Grid bgcolor="white" item xs={6} borderRadius="6px">
          <Box
            sx={{
              borderRadius: "6px",
              display: "flex",
              backgroundColor: "white",
              height: "100%",
              width: "100%",
              paddingRight: fetchError ? "0px" : "18px",
            }}
          >
            {isLoading ? (
              <Box
                sx={{
                  borderRadius: "6px",
                  display: "flex",
                  backgroundColor: "white",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Box
                    id="create-novelty-form"
                    borderRadius="6px"
                    sx={{
                      backgroundColor: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "208px",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                </Box>
              </Box>
            ) : fetchError ? (
              <Box
                sx={{
                  borderRadius: "6px",
                  display: "flex",
                  backgroundColor: "white",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Box
                    id="create-novelty-form"
                    borderRadius="6px"
                    sx={{
                      backgroundColor: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "208px",
                    }}
                  >
                    <h1>{fetchErrorMessage}</h1>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box sx={{ flexGrow: 1 }}>
                <Box
                  component="form"
                  autoComplete="off"
                  borderRadius="6px"
                  sx={{ backgroundColor: "white", height: "100%" }}
                  onSubmit={formik.handleSubmit}
                >
                  <Grid container justifyContent="space-between">
                    <Grid item xs={12}>
                      <Grid container alignItems="center">
                        <Grid item xs={8} sx={{ pt: "18px", pl: "18px" }}>
                          <Box
                            display="flex"
                            justifyContent="flex-start"
                            marginBottom="4px"
                          >
                            <InputLabel
                              sx={{ color: "black" }}
                              htmlFor="nombre-input"
                            >
                              Nombre
                            </InputLabel>
                          </Box>
                          <TextField
                            id="nombre-input"
                            error={Boolean(
                              formik.touched.name && formik.errors.name
                            )}
                            fullWidth
                            helperText={
                              formik.touched.name && formik.errors.name
                            }
                            name="name"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            type="text"
                            value={formik.values.name}
                            variant="filled"
                            placeholder="Nombre de la novedad"
                            InputProps={{
                              disableUnderline: true,
                              readOnly: user?.type === 0 || changeLink === 2,
                              sx: {
                                borderRadius: "6px",
                                ".MuiInputBase-input": {
                                  paddingBottom: "16px",
                                  paddingTop: "16px",
                                },
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={4} sx={{ pt: "48px", pl: "18px" }}>
                          <Box
                            display="flex"
                            justifyContent="flex-start"
                            marginBottom="4px"
                          >
                            <Button
                              variant="contained"
                              component="span"
                              startIcon={<MaterialIcons.FileDownloadIcon />}
                              onClick={() =>
                                handleDownloadFile(formik.values.file)
                              }
                              sx={{
                                height: "100%",
                                backgroundColor: "white",
                                color: "black",
                                "&:hover": { color: "white" },
                              }}
                            >
                              Descargar
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Grid
                      item
                      xs={8}
                      {...(changeLink === 1
                        ? { sx: { pt: "18px", pl: "18px" } }
                        : { sx: { pt: "10px", pb: "16px", pl: "18px" } })}
                    >
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="code-input"
                        >
                          Codigo
                        </InputLabel>
                      </Box>
                      <TextField
                        id="code-input"
                        error={Boolean(
                          formik.touched.code && formik.errors.code
                        )}
                        fullWidth
                        helperText={formik.touched.code && formik.errors.code}
                        name="code"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.code}
                        variant="filled"
                        placeholder="Codigo"
                        type="number"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: user?.type === 0 || changeLink === 2,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  {changeLink === 1 && (
                    <Grid container justifyContent="space-between">
                      <Grid item xs={8} sx={{ py: "18px", pl: "18px" }}>
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          marginBottom="4px"
                        >
                          <InputLabel
                            sx={{ color: "black" }}
                            htmlFor="model-template-input"
                          >
                            Plantilla Modelo
                          </InputLabel>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          marginBottom="4px"
                        >
                          <TextField
                            id="model-template-input"
                            error={Boolean(
                              formik.touched.fileName && formik.errors.fileName
                            )}
                            fullWidth
                            helperText={
                              formik.touched.fileName && formik.errors.fileName
                            }
                            value={formik.values.fileName}
                            variant="filled"
                            placeholder="Subir archivo"
                            sx={{
                              width: changeLink === 1 ? "70%" : "100%",
                            }}
                            InputProps={{
                              readOnly: true,
                              disableUnderline: true,
                              sx: {
                                borderRadius: "6px",
                                ".MuiInputBase-input": {
                                  paddingBottom: "16px",
                                  paddingTop: "16px",
                                },
                              },
                            }}
                          />
                          <>
                            <input
                              hidden
                              id="importar-plantilla-button"
                              type="file"
                              name="file"
                              onChange={(e: any) =>
                                handleUploadFile(e.currentTarget.files[0])
                              }
                              accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            />
                            <label htmlFor="importar-plantilla-button">
                              <Button
                                variant="contained"
                                component="span"
                                startIcon={<MaterialIcons.ArrowUpwardIcon />}
                                sx={{
                                  height: "100%",
                                  backgroundColor: "white",
                                  color: "black",
                                  "&:hover": { color: "white" },
                                }}
                              >
                                Subir archivo
                              </Button>
                            </label>
                          </>
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                  {changeLink === 1 && (
                    <Grid container maxWidth="sm" paddingBottom="32px">
                      <Box sx={{ display: "flex" }}>
                        <Button
                          sx={{ marginLeft: "18px" }}
                          variant="contained"
                          color="primary"
                          onClick={handleClickGuardar}
                          disabled={
                            !formik.dirty ||
                            !formik.values.name ||
                            !formik.values.code ||
                            !formik.values.file ||
                            isEqual(previusState, formik.values)
                          }
                        >
                          Guardar
                        </Button>

                        {formik.dirty &&
                        !isEqual(previusState, formik.values) ? (
                          <Button
                            sx={{ marginLeft: "18px" }}
                            variant="outlined"
                            color="primary"
                            onClick={handleClickDirtyCancelar}
                          >
                            Cancelar
                          </Button>
                        ) : (
                          <Button
                            sx={{ marginLeft: "18px" }}
                            variant="outlined"
                            color="primary"
                            onClick={() => navigate(-1)}
                          >
                            Cancelar
                          </Button>
                        )}
                        <Button
                          sx={{ marginLeft: "18px" }}
                          variant="contained"
                          color="error"
                          onClick={handleClickEliminar}
                        >
                          Eliminar
                        </Button>
                      </Box>
                    </Grid>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      {openInformationModal && (
        <InformationModal
          openModal={openInformationModal}
          clickCancelar={clickCancelar}
          clickEliminar={clickEliminar}
          guardar={clickGuardar}
          title={modalTitle}
          message={modalMessage}
          isDirty={isDirty}
          onClose={() => handleCloseModal()}
          onEliminar={() => handleRemove()}
          handleDeshacerCancelar={handleDeshacerCancelar}
          saveForm={handleSaveButton}
        />
      )}
    </Layout>
  );
};

export default ParametroNovedadDetails;
