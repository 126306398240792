import React from "react";
import { useParams } from "react-router-dom";

import CompanyParametroNovedadDetails from "../../../components/Details/Parametros/CompaniesParametroNovedadDetails";

const CompaniesParametroNovedadDetailsPage = () => {
  const params = useParams();
  return <CompanyParametroNovedadDetails params={params} />;
};

export default CompaniesParametroNovedadDetailsPage;
