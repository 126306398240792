import * as React from "react";
import { shallow } from "zustand/shallow";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";

import * as Yup from "yup";
import { useFormik } from "formik";
import { useAuth0 } from "@auth0/auth0-react";

import EmpresasCheckboxList from "./EmpresasCheckboxList";

import { sendInviteToUser } from "../../../services/UsersService";

import { useDrawerStore } from "../../../store/drawerStore";
import { useEmpresaStore } from "../../../store/empresaStore";
import { CircularProgress, Fade } from "@mui/material";

type NewUserModalType = {
  openModal: boolean;
  handleRefreshTable: React.Dispatch<React.SetStateAction<boolean>>;
  changeOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  changeOpenInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMessage?: React.Dispatch<React.SetStateAction<string>>;
};

const NewUserModal: React.FC<NewUserModalType> = ({
  changeOpenModal,
  handleRefreshTable,
  openModal,
  changeOpenInfoModal,
  setErrorMessage,
}) => {
  const [disabledOnSubmit, setDisabledOnSubmit] =
    React.useState<boolean>(false);
  const { getAccessTokenSilently } = useAuth0();

  const selectedEmpresa = useEmpresaStore(
    (state) => state.selectedEmpresa,
    shallow
  );

  const changeLink = useDrawerStore((state) => state.changeLink, shallow);

  const formik = useFormik({
    initialValues: {
      name: "",
      last_name: "",
      email: "",
      companies: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().max(55).required("El nombre es requerido"),
      last_name: Yup.string().max(55).required("El apellido es requerido"),
      email: Yup.string().max(255).required("El email es requerido"),
      companies: Yup.array()
        .min(1, "Debe seleccionar al menos una (1) empresa")
        .required("Debe seleccionar al menos una (1) empresa"),
    }),
    onSubmit: async (data) => {
      setDisabledOnSubmit(true);
      const token = await getAccessTokenSilently();

      sendInviteToUser(token, data)
        .then((result) => {
          formik.resetForm();
          if (result.status === 201) {
            setDisabledOnSubmit(false);
            handleRefreshTable(true);
            changeOpenModal(false);
          }
        })
        .catch((e) => {
          changeOpenModal(false);
          changeOpenInfoModal(true);
          setErrorMessage &&
            setErrorMessage("El usuario que intenta registrar ya existe");
          setDisabledOnSubmit(false);
        });
    },
  });

  const handleClose = () => {
    changeOpenModal(false);
  };

  React.useLayoutEffect(() => {
    changeLink === 2 &&
      formik.setFieldValue("companies", [
        { company_id: selectedEmpresa?.id, role: 1 },
      ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeLink, selectedEmpresa?.id]);

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: "40%",
          },
        }}
      >
        {disabledOnSubmit ? (
          <Box
            sx={{
              display: "flex",
              height: "348px",
              placeContent: "center",
              placeItems: "center",
            }}
          >
            <Fade in={disabledOnSubmit}>
              <div>
                <CircularProgress />
              </div>
            </Fade>
          </Box>
        ) : (
          <>
            <DialogTitle>Nuevo Usuario</DialogTitle>
            <DialogContent>
              <Grid container borderRadius="6px">
                <Grid bgcolor="white" item xs borderRadius="6px">
                  <Box
                    sx={{
                      borderRadius: "6px",
                      display: "flex",
                      backgroundColor: "white",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Box
                        component="form"
                        id="crear-usuario-form"
                        autoComplete="off"
                        borderRadius="6px"
                        sx={{ backgroundColor: "white", height: "100%" }}
                        onSubmit={formik.handleSubmit}
                      >
                        <Grid container>
                          <Grid bgcolor="white" item xs>
                            <Grid container>
                              <Grid item xs sx={{ py: "10px" }}>
                                <Box
                                  display="flex"
                                  justifyContent="flex-start"
                                  marginBottom="4px"
                                >
                                  <InputLabel
                                    sx={{ color: "black" }}
                                    htmlFor="nombre-input"
                                  >
                                    Nombre
                                  </InputLabel>
                                </Box>
                                <TextField
                                  id="nombre-input"
                                  error={Boolean(
                                    formik.touched.name && formik.errors.name
                                  )}
                                  fullWidth
                                  helperText={
                                    formik.touched.name && formik.errors.name
                                  }
                                  name="name"
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  type="text"
                                  value={formik.values.name}
                                  variant="filled"
                                  placeholder="Nombre"
                                  InputProps={{
                                    disableUnderline: true,
                                    sx: {
                                      borderRadius: "6px",
                                      ".MuiInputBase-input": {
                                        paddingBottom: "16px",
                                        paddingTop: "16px",
                                      },
                                    },
                                  }}
                                />
                              </Grid>
                              <Grid item xs sx={{ py: "10px", pl: "18px" }}>
                                <Box
                                  display="flex"
                                  justifyContent="flex-start"
                                  marginBottom="4px"
                                >
                                  <InputLabel
                                    sx={{ color: "black" }}
                                    htmlFor="apellido-input"
                                  >
                                    Apellido
                                  </InputLabel>
                                </Box>
                                <TextField
                                  id="apellido-input"
                                  error={Boolean(
                                    formik.touched.last_name &&
                                      formik.errors.last_name
                                  )}
                                  fullWidth
                                  helperText={
                                    formik.touched.last_name &&
                                    formik.errors.last_name
                                  }
                                  name="last_name"
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  type="text"
                                  value={formik.values.last_name}
                                  variant="filled"
                                  placeholder="Apellido"
                                  InputProps={{
                                    disableUnderline: true,
                                    sx: {
                                      borderRadius: "6px",
                                      ".MuiInputBase-input": {
                                        paddingBottom: "16px",
                                        paddingTop: "16px",
                                      },
                                    },
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid bgcolor="white" item xs>
                            <Grid container>
                              <Grid item xs={6} sx={{ py: "10px", pr: "8px" }}>
                                <Box
                                  display="flex"
                                  justifyContent="flex-start"
                                  marginBottom="4px"
                                >
                                  <InputLabel
                                    sx={{ color: "black" }}
                                    htmlFor="email-input"
                                  >
                                    Email
                                  </InputLabel>
                                </Box>
                                <TextField
                                  id="email-input"
                                  error={Boolean(
                                    formik.touched.email && formik.errors.email
                                  )}
                                  fullWidth
                                  helperText={
                                    formik.touched.email && formik.errors.email
                                  }
                                  name="email"
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  type="email"
                                  value={formik.values.email}
                                  variant="filled"
                                  placeholder="info@bglobalsolutions.com"
                                  InputProps={{
                                    disableUnderline: true,
                                    sx: {
                                      borderRadius: "6px",
                                      ".MuiInputBase-input": {
                                        paddingBottom: "16px",
                                        paddingTop: "16px",
                                      },
                                    },
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        {changeLink === 1 && (
                          <Grid container>
                            <Grid bgcolor="white" item xs>
                              <Grid container>
                                <Grid item xs={12} sx={{ py: "10px" }}>
                                  <Box
                                    display="flex"
                                    justifyContent="flex-start"
                                    marginBottom="4px"
                                  >
                                    <InputLabel
                                      sx={{ color: "black" }}
                                      htmlFor="status-select"
                                    >
                                      Asignar a empresa
                                    </InputLabel>
                                  </Box>
                                  <EmpresasCheckboxList
                                    actualState={formik.values}
                                    onChange={formik.setValues}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ paddingBottom: "16px", paddingRight: "24px" }}>
              <Button color="primary" variant="outlined" onClick={handleClose}>
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                form="crear-usuario-form"
                type="submit"
                disabled={
                  !formik.dirty ||
                  !formik.values.email ||
                  !formik.values.name ||
                  !formik.values.last_name ||
                  formik.values.companies.length <= 0 ||
                  disabledOnSubmit
                }
              >
                Crear Usuario
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default NewUserModal;
