import * as React from "react";
import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import InputLabel from "@mui/material/InputLabel";

import SearchBar from "material-ui-search-bar";

import { UserType } from "../../../types";

const BackGround = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

type UsersListType = {
  handleSelectUserId: (fieldName: string, userId: string) => void;
  isLoading: boolean;
  userList: any[];
};

const UsersList: React.FC<UsersListType> = ({
  handleSelectUserId,
  isLoading,
  userList,
}) => {
  const [usersList, setUsersList] = React.useState<any[]>([]);
  const [originalUsersList, setOriginalUsersList] = React.useState<any[]>([]);
  const [searched, setSearched] = React.useState<string>("");
  const [selectedId, setSelectedId] = React.useState<string>("");
  const [isSearching, setIsSearching] = React.useState<boolean>(false);

  const requestSearch = (searchedVal: string) => {
    const newUsersList = [...originalUsersList];
    if (searchedVal.length > 2) {
      setIsSearching(true);
      const filteredUsersByNameOrEmail = newUsersList.filter((user) => {
        return (
          user?.name?.toLowerCase().includes(searchedVal.toLowerCase()) ||
          user?.email?.toLowerCase().includes(searchedVal.toLowerCase())
        );
      });

      if (filteredUsersByNameOrEmail.length > 0) {
        setUsersList(filteredUsersByNameOrEmail);
      } else {
        setUsersList([]);
      }
    } else {
      setIsSearching(false);
      setUsersList(originalUsersList);
    }
  };

  const handleListItemClick = (user: UserType) => {
    setSelectedId(user.id);
    handleSelectUserId("user_id", user.id);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  React.useEffect(() => {
    if (!isLoading) {
      if (userList.length > 0) {
        userList.sort((a, b) => {
          if (a.email.toLowerCase() < b.email.toLowerCase()) {
            return -1;
          }
          if (a.email.toLowerCase() > b.email.toLowerCase()) {
            return 1;
          }
          return 0;
        });
        setUsersList(userList);
        setOriginalUsersList(userList);
      }
    }
  }, [userList, isLoading]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        sx={{ placeContent: "center", placeItems: "center", padding: "200px" }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box display="flex" justifyContent="flex-start" marginBottom="4px">
        <InputLabel sx={{ color: "black" }} htmlFor="status-select">
          Buscar usuario
        </InputLabel>
      </Box>
      <Box sx={{ paddingBottom: "24px" }}>
        <SearchBar
          value={searched}
          onChange={(searchVal: string) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
          placeholder="Buscar usuario"
          style={{
            boxShadow: "none",
            borderRadius: "6px",
            backgroundColor: "rgba(0, 0, 0, 0.06)",
            height: "54px",
          }}
        />
      </Box>
      <Box sx={{ flexGrow: 1, maxWidth: 752 }}>
        <BackGround
        //   sx={{
        //     "& .MuiList-root>li:nth-of-type(odd)": {
        //       backgroundColor: "#EEF1FC",
        //     },
        //   }}
        >
          <Box display="flex" justifyContent="flex-start" marginBottom="4px">
            <InputLabel sx={{ color: "black" }} htmlFor="status-input">
              Usuarios
            </InputLabel>
          </Box>
          {usersList.length > 0 ? (
            <List>
              {usersList.map((user) => {
                return (
                  <Box key={user.id}>
                    <ListItem disablePadding>
                      <ListItemButton
                        selected={selectedId === user.id}
                        onClick={() => handleListItemClick(user)}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: "#AAC1E6 !important",
                          },
                        }}
                      >
                        <ListItemText primary={user.email} />
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                  </Box>
                );
              })}
            </List>
          ) : (
            <List>
              <ListItem>
                <ListItemText
                  primary={
                    isSearching
                      ? "No se encuentran usuarios con ese nombre o email"
                      : "No hay usuarios"
                  }
                />
              </ListItem>
            </List>
          )}
        </BackGround>
      </Box>
    </>
  );
};

export default UsersList;
