import React, { useCallback, useContext, useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import { isEqual } from "lodash";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useHasPermissions } from "react-simple-rbac";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import Layout from "../../Layout/Layout";
import InformationModal from "../../Modals/ConfirmationModal/InformationModal";
import HtmlTooltip from "../../HtmlTooltip/HtmlTooltip";

import { MaterialIcons } from "../../../icons/Material/MaterialIcons";

import {
  cancelNoveltyProcessing,
  editCompanyNovelty,
  getCompanyNoveltiesTypes,
  getCompanyNoveltyById,
  getNoveltyLogs,
  processNovelty,
  unblockNovelty,
} from "../../../services/CompaniesService";

import { downloadNoveltyFile } from "../../../services/ParametersService";

import { UserContext } from "../../../context/user/UserContext";

import { useEmpresaStore } from "../../../store/empresaStore";
import FetchErrorMessage from "../../FetchErrorMessage/FetchErrorMessage";
import { useNoveltiesStore } from "../../../store/noveltiesStore";

const statuses = [
  { id: 1, value: 0, label: "Pendiente de Procesar" },
  { id: 2, value: 1, label: "Inactivo" },
  { id: 3, value: 2, label: "Cancelado" },
  { id: 4, value: 3, label: "Bloqueado" },
  { id: 5, value: 4, label: "Error en Proceso" },
  { id: 6, value: 5, label: "Procesado Exitosamente" },
];

type PreviusStateType = {
  file: string;
  fileName: string;
  name: string;
  novelty_type_id: string;
  period_id: string;
  priority: number;
  urgent: boolean;
  status: number;
  created_by: string;
  error: string;
  result: string;
  process_date: string;
};

type NovedadDetailsType = {
  params: any;
};

const NovedadDetails: React.FC<NovedadDetailsType> = ({ params }) => {
  const [clickCancelar, setClickCancelar] = useState<boolean>(false);
  const [clickGuardar, setClickGuardar] = useState<boolean>(false);
  const [clickCancelarProcesamiento, setClickCancelarProcesamiento] =
    useState<boolean>(false);
  const [clickProcesar, setClickProcesar] = useState<boolean>(false);
  const [clickDesbloquear, setClickDesbloquear] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [openInformationModal, setOpenInformationModal] =
    useState<boolean>(false);
  const [openProcessingModal, setOpenProcessingModal] =
    useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [openUnblockModal, setOpenUnblockModal] = useState<boolean>(false);
  const [disabledDownload, setDisabledDownload] = useState<boolean>(false);
  const [disabledDownloadLogs, setDisabledDownloadLogs] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [previusState, setPreviusState] = useState<PreviusStateType>({
    file: "",
    fileName: "",
    name: "",
    novelty_type_id: "",
    period_id: "",
    priority: 0,
    urgent: false,
    status: 1,
    created_by: "",
    error: "",
    result: "",
    process_date: "",
  });
  const [isErrorProcessedOrCancelled, setIsErrorProcessedOrCancelled] =
    useState<boolean>(true);
  const [disabledOnSubmit, setDisabledOnSubmit] = useState<boolean>(false);
  const [showIcon, setShowIcon] = useState<boolean>(false);
  const [successIcon, setSuccessIcon] = useState<boolean>(false);
  const [refreshNovelty, setRefreshNovelty] = useState<boolean>(false);
  const [fetchError, setFetchError] = useState<boolean>(false);
  const [fetchErrorMessage, setFetchErrorMessage] = useState<string>("");
  const [actualEmpresaNovedades, setActualEmpresaNovedades] = useState<any[]>(
    []
  );
  const [loadNoveltyError, setLoadNoveltyError] = useState<boolean>(false);
  const [loadNoveltyErrorMessage, setLoadNoveltyErrorMessage] =
    useState<string>("");
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isNoveltyProcessing, setIsNoveltyProcessing] =
    useState<boolean>(false);
  const [isProcessingMessage, setIsProcessingMessage] = useState<string>("");

  const userCanUnblock = useHasPermissions(["unblock:novelty"]);

  const timer = React.useRef<number>();

  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  const { user } = useContext(UserContext);

  const { allEmpresaPeriodos, empresaId } = useEmpresaStore(
    ({ allEmpresaPeriodos, empresaId }) => ({
      allEmpresaPeriodos,
      empresaId,
    }),
    shallow
  );

  const noveltiesStore = useNoveltiesStore((state) => state.novedades, shallow);

  const { eliminarNovedad, setNovedadProcesada } = useNoveltiesStore(
    ({ eliminarNovedad, setNovedadProcesada }) => ({
      eliminarNovedad,
      setNovedadProcesada,
    })
  );

  const formik = useFormik({
    initialValues: {
      name: "",
      period_id: "",
      novelty_type_id: "",
      fileName: "",
      file: "",
      urgent: false,
      status: 0,
      priority: 0,
      created_by: "",
      error: "",
      result: "",
      process_date: "",
    },
    onSubmit: async (data) => {
      setDisabledOnSubmit(true);
      let finalData = {};
      if (data.file !== previusState.file) {
        finalData = {
          file: data.file,
          name: data.name,
          period_id: data.period_id,
          company_id: empresaId,
          novelty_type_id: data.novelty_type_id,
          urgent: data.urgent,
          priority: data.priority,
          status: data.status,
        };
      } else {
        finalData = {
          name: data.name,
          period_id: data.period_id,
          company_id: empresaId,
          novelty_type_id: data.novelty_type_id,
          urgent: data.urgent,
          priority: data.priority,
          status: data.status,
        };
      }

      const token = await getAccessTokenSilently();

      editCompanyNovelty(token, empresaId, params.novedadId, finalData)
        .then((result) => {
          if (result.status === 201 || result.status === 200) {
            handleCloseModal();
            formik.resetForm();
            navigate(-1);
          }
        })
        .catch((e) => {
          console.error(e.response.data.error);
          setErrorMessage(e.response.data.error);
          setDisabledOnSubmit(false);
          setOpenErrorModal(true);
        });
    },
  });

  const handleCloseModal = () => {
    setClickCancelarProcesamiento(false);
    setClickProcesar(false);
    setOpenInformationModal(false);
    openErrorModal && setOpenErrorModal(false);
    openProcessingModal && setOpenProcessingModal(false);
    openUnblockModal && setOpenUnblockModal(false);
  };

  const handleIsProcessingCloseModal = () => {
    if (!isProcessing) {
      setOpenProcessingModal(false);
      setIsProcessing(false);
      setClickCancelarProcesamiento(false);
      setShowIcon(false);
      setSuccessIcon(false);
      setErrorMessage("");
      setDisabledOnSubmit(false);
      setRefreshNovelty(true);
    }
  };

  const handleDeshacerCancelar = () => {
    setClickCancelar(true);
    setClickGuardar(false);
    setClickCancelarProcesamiento(false);
    setClickProcesar(false);
    setOpenUnblockModal(false);
    formik.resetForm();
    navigate(-1);
  };

  const handleClickDirtyCancelar = () => {
    setClickGuardar(false);
    setClickCancelarProcesamiento(false);
    setClickProcesar(false);
    setOpenInformationModal(true);
    setIsDirty(true);
    setClickCancelar(true);
  };

  const handleClickGuardar = () => {
    setIsDirty(false);
    setClickCancelar(false);
    setClickGuardar(true);
    setOpenInformationModal(true);
  };

  const handleClickCancelNoveltyProccessing = () => {
    setClickProcesar(false);
    setIsDirty(false);
    setClickCancelar(false);
    setClickGuardar(false);
    setClickCancelarProcesamiento(true);
    setOpenProcessingModal(true);
  };

  const handleClickUnblockNovelty = () => {
    setClickProcesar(false);
    setIsDirty(false);
    setClickCancelar(false);
    setClickGuardar(false);
    setClickCancelarProcesamiento(false);
    setOpenProcessingModal(false);
    setClickDesbloquear(true);
    setOpenUnblockModal(true);
  };

  const handleUploadFile = (file: any) => {
    formik.setFieldValue("file", file);
    formik.setFieldValue("fileName", file.name);
  };

  const handleSaveButton = () => {
    formik.submitForm();
  };

  const getNovedadLogs = async () => {
    setDisabledDownloadLogs(true);
    const token = await getAccessTokenSilently();
    getNoveltyLogs(token, empresaId, params.novedadId)
      .then((result) => {
        // Create blob link to download
        const link = document.createElement("a");
        const url = window.URL.createObjectURL(new Blob([result.data, formik.values.error]));
        link.href = url;
        link.setAttribute("download", `${previusState.name} - logs`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.dispatchEvent(new MouseEvent("click"));

        // Clean up and remove the link
        link.parentNode?.removeChild(link);
        setDisabledDownloadLogs(false);
      })
      .catch((e) => {
        console.error("error", e.response.data.error);
        //  Download the error as log 
        if (formik.values.error) {
          // Create blob link to download
          const link = document.createElement("a");
          const url = window.URL.createObjectURL(new Blob([formik.values.error]));
          link.href = url;
          link.setAttribute("download", `${previusState.name} - logs`);

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.dispatchEvent(new MouseEvent("click"));

          // Clean up and remove the link
          link.parentNode?.removeChild(link);
          setDisabledDownloadLogs(false);
        } else {

          setErrorMessage(e.response.data.error);
          setDisabledDownloadLogs(false);
          setOpenErrorModal(true);
        }


      });
  };
  console.log(formik.values.error)
  const downloadArch = async () => {
    setDisabledDownload(true);
    const token = await getAccessTokenSilently();
    const result = await downloadNoveltyFile(token, previusState.file);

    // Create blob link to download
    const link = document.createElement("a");
    const url = window.URL.createObjectURL(new Blob([result.data]));
    link.href = url;
    link.setAttribute("download", previusState.fileName);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.dispatchEvent(new MouseEvent("click"));

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
    setDisabledDownload(false);
  };

  const handleChangePriority = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(e.target.value) >= 0) {
      formik.setFieldValue("priority", Number(e.target.value));
    }
  };

  const handleClickProcesar = () => {
    setIsDirty(false);
    setClickCancelar(false);
    setClickGuardar(false);
    setClickCancelarProcesamiento(false);
    setClickProcesar(true);
    setOpenProcessingModal(true);
  };

  const handleSaveForm = () => {
    clickCancelarProcesamiento && handleCancelNoveltyProccessing();
    clickProcesar && handleProcessNovelty();
    clickDesbloquear && handleUnblockNovelty();
  };

  const handleUnblockNovelty = async () => {
    setDisabledOnSubmit(true);
    setShowIcon(false);
    const token = await getAccessTokenSilently();
    unblockNovelty(token, empresaId, params.novedadId)
      .then((res) => {
        console.table(res);
        setClickDesbloquear(false);
        setDisabledOnSubmit(false);
        navigate(-1);
      })
      .catch((e) => {
        console.error(e.response.data.error);
        setErrorMessage(
          "Solo podés desbloquear una novedad en estado Bloqueado"
        );
        setDisabledOnSubmit(false);
        setOpenErrorModal(true);
      });
  };

  const handleCancelNoveltyProccessing = async () => {
    setDisabledOnSubmit(true);
    setShowIcon(false);
    const token = await getAccessTokenSilently();
    cancelNoveltyProcessing(token, empresaId, params.novedadId, formik.values)
      .then((res) => {
        console.table(res);
        setClickCancelarProcesamiento(false);
        setDisabledOnSubmit(false);
        navigate(-1);
      })
      .catch((e) => {
        console.error(e.response.data.error);
        setErrorMessage(
          "Solo podés cancelar una novedad Pendiente de procesar"
        );
        setDisabledOnSubmit(false);
        setOpenErrorModal(true);
      });
  };

  const getActualEmpresaNovedades = useCallback(
    async (actEmpresaId: string) => {
      setIsLoading(true);
      const token = await getAccessTokenSilently();
      getCompanyNoveltiesTypes(token, actEmpresaId)
        .then((novedades) => {
          if (novedades.data) {
            if (novedades.data.length > 0) {
              setActualEmpresaNovedades(novedades.data);
            } else {
              setActualEmpresaNovedades([]);
            }
          }
          setIsLoading(false);
        })
        .catch((e) => {
          console.error(e.response.data.error);
          setFetchError(true);
          setFetchErrorMessage(e.response.data.error);
          setActualEmpresaNovedades([]);
          setIsLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleProcessNovelty = async () => {
    setDisabledOnSubmit(true);
    setIsProcessing(true);
    const token = await getAccessTokenSilently();
    processNovelty(token, empresaId, { id: params.novedadId })
      .then((res) => {
        setNovedadProcesada(params.novedadId, true);
        setIsProcessingMessage(res.data.message);
        setIsProcessing(false);
        setClickCancelarProcesamiento(false);
        setShowIcon(true);
        setSuccessIcon(true);
        timer.current = window.setTimeout(() => {
          setShowIcon(false);
          setSuccessIcon(false);
          handleCloseModal();
          setDisabledOnSubmit(false);
          setRefreshNovelty(true);
        }, 1000);
        // navigate(-1);
      })
      .catch((e) => {
        console.error("ERROR", e.request.status);
        setIsProcessing(false);
        setNovedadProcesada(params.novedadId, false);
        if (e.request.status === 0) {
          setErrorMessage("Ha ocurrido un error de CORS");
        } else {
          setErrorMessage("Error al procesar la novedad");
        }
        setShowIcon(true);
        setSuccessIcon(false);
      });
  };

  const getEmpresaNovedadById = useCallback(async () => {
    setIsLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const result = await getCompanyNoveltyById(
        token,
        empresaId,
        params.novedadId
      );

      setPreviusState({
        file: result.data.file.ref,
        fileName: result.data.file.name,
        name: result.data.name,
        novelty_type_id: result.data.novelty_type_id,
        period_id: result.data.period_id,
        priority: result.data.priority,
        urgent: result.data.urgent,
        status: result.data.status,
        created_by: result.data.created_by,
        error: result.data.error ? result.data.error : "",
        result: result.data.result ? result.data.result : "",
        process_date: result.data.process_date ? result.data.process_date : "",
      });

      formik.setValues({
        file: result.data.file.ref,
        fileName: result.data.file.name,
        name: result.data.name,
        novelty_type_id: result.data.novelty_type_id,
        period_id: result.data.period_id,
        priority: result.data.priority,
        urgent: result.data.urgent,
        status: result.data.status,
        created_by: result.data.created_by,
        error: result.data.error ? result.data.error : "",
        result: result.data.result ? result.data.result : "",
        process_date: result.data.process_date ? result.data.process_date : "",
      });

      setIsErrorProcessedOrCancelled(
        result.data.status === 2 ||
        result.data.status === 4 ||
        result.data.status === 5
      );

      if (
        result.data.status === 2 ||
        result.data.status === 4 ||
        result.data.status === 5
      ) {
        eliminarNovedad(params.novedadId);
      }

      setIsLoading(false);
      setRefreshNovelty(false);
    } catch (error: any) {
      console.error(
        "Error al realizar la petición:",
        error?.response?.data?.error
      );
      setLoadNoveltyErrorMessage(
        error?.response?.data?.error ?? "Ha ocurrido un error"
      );
      setFetchError(true);
      setLoadNoveltyError(true);
      setIsLoading(false);
      setRefreshNovelty(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresaId, params.novedadId]);

  useEffect(() => {
    if (noveltiesStore[params.novedadId]) {
      setIsNoveltyProcessing(true);
    }
  }, [noveltiesStore, params.novedadId]);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  useEffect(() => {
    if (refreshNovelty) {
      getEmpresaNovedadById();
      getActualEmpresaNovedades(empresaId);
    }
  }, [
    empresaId,
    getActualEmpresaNovedades,
    getEmpresaNovedadById,
    refreshNovelty,
  ]);

  useEffect(() => {
    getEmpresaNovedadById();
    getActualEmpresaNovedades(empresaId);
  }, [empresaId, getActualEmpresaNovedades, getEmpresaNovedadById]);

  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - Novedad`;
  }, []);

  return (
    <Layout icon="PollIcon" title="Novedad" breadcrumb>
      <Grid container marginTop="12px" borderRadius="6px">
        <Grid bgcolor="white" item xs={8} borderRadius="6px">
          {isLoading ? (
            <Box
              sx={{
                borderRadius: "6px",
                display: "flex",
                backgroundColor: "white",
                height: "100%",
                width: "100%",
                paddingRight: "18px",
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Box
                  id="create-novelty-form"
                  borderRadius="6px"
                  sx={{
                    backgroundColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "461px",
                  }}
                >
                  <CircularProgress />
                </Box>
              </Box>
            </Box>
          ) : fetchError || loadNoveltyError ? (
            <FetchErrorMessage
              errorMessage={fetchErrorMessage || loadNoveltyErrorMessage}
            />
          ) : (
            <>
              <Box
                sx={{
                  borderRadius: "6px",
                  display: "flex",
                  backgroundColor: "white",
                  height: "100%",
                  width: "100%",
                  paddingRight: "18px",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Box
                    id="create-novelty-form"
                    component="form"
                    autoComplete="off"
                    borderRadius="6px"
                    sx={{ backgroundColor: "white", height: "100%" }}
                    onSubmit={formik.handleSubmit}
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={6}
                        sx={{ pt: "18px", pb: "10px", pl: "18px" }}
                      >
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          marginBottom="4px"
                        >
                          <InputLabel
                            sx={{ color: "black" }}
                            htmlFor="nombre-input"
                          >
                            Nombre
                          </InputLabel>
                        </Box>
                        <TextField
                          id="nombre-input"
                          error={Boolean(
                            formik.touched.name && formik.errors.name
                          )}
                          fullWidth
                          helperText={formik.touched.name && formik.errors.name}
                          name="name"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          type="text"
                          value={formik.values.name}
                          variant="filled"
                          placeholder="Nombre de la novedad"
                          InputProps={{
                            disableUnderline: true,
                            readOnly:
                              isErrorProcessedOrCancelled ||
                              isNoveltyProcessing,
                            sx: {
                              borderRadius: "6px",
                              ".MuiInputBase-input": {
                                paddingBottom: "16px",
                                paddingTop: "16px",
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={{ pt: "18px", pb: "10px", pl: "18px" }}
                      >
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          marginBottom="4px"
                        >
                          <InputLabel
                            sx={{ color: "black" }}
                            htmlFor="type-select"
                          >
                            Tipo
                          </InputLabel>
                        </Box>
                        <TextField
                          id="type-select"
                          select
                          error={Boolean(
                            formik.touched.novelty_type_id &&
                            formik.errors.novelty_type_id
                          )}
                          fullWidth
                          helperText={
                            formik.touched.novelty_type_id &&
                            formik.errors.novelty_type_id
                          }
                          name="novelty_type_id"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.novelty_type_id}
                          variant="filled"
                          InputProps={{
                            disableUnderline: true,
                            readOnly:
                              isErrorProcessedOrCancelled ||
                              isNoveltyProcessing,
                            sx: {
                              borderRadius: "6px",
                              ".MuiInputBase-input": {
                                paddingBottom: "16px",
                                paddingTop: "16px",
                                display: "flex",
                                justifyContent: "flex-start",
                              },
                            },
                          }}
                        >
                          {actualEmpresaNovedades.map(
                            (actualEmpresaNovedad) => (
                              <MenuItem
                                key={actualEmpresaNovedad.id}
                                value={actualEmpresaNovedad.id}
                              >
                                {`${actualEmpresaNovedad.novelty_type.code} - ${actualEmpresaNovedad.novelty_type.name}`}
                              </MenuItem>
                            )
                          )}
                        </TextField>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={6} sx={{ py: "10px", pl: "18px" }}>
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          marginBottom="4px"
                        >
                          <InputLabel
                            sx={{ color: "black" }}
                            htmlFor="periodo-select"
                          >
                            Periodo
                          </InputLabel>
                        </Box>
                        <TextField
                          id="periodo-select"
                          select
                          error={Boolean(
                            formik.touched.period_id && formik.errors.period_id
                          )}
                          fullWidth
                          helperText={
                            formik.touched.period_id && formik.errors.period_id
                          }
                          name="period_id"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.period_id}
                          variant="filled"
                          InputProps={{
                            disableUnderline: true,
                            readOnly:
                              isErrorProcessedOrCancelled ||
                              isNoveltyProcessing,
                            sx: {
                              borderRadius: "6px",
                              ".MuiInputBase-input": {
                                paddingBottom: "16px",
                                paddingTop: "16px",
                                display: "flex",
                                justifyContent: "flex-start",
                              },
                            },
                          }}
                        >
                          {allEmpresaPeriodos.map((periodo) => (
                            <MenuItem key={periodo.id} value={periodo.id}>
                              {periodo.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={6} sx={{ py: "10px", pl: "18px" }}>
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          marginBottom="4px"
                        >
                          <InputLabel
                            sx={{ color: "black" }}
                            htmlFor="status-select"
                          >
                            Estado
                          </InputLabel>
                        </Box>
                        <TextField
                          id="status-select"
                          select
                          error={Boolean(
                            formik.touched.status && formik.errors.status
                          )}
                          fullWidth
                          helperText={
                            formik.touched.status && formik.errors.status
                          }
                          name="status"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.status}
                          variant="filled"
                          InputProps={{
                            disableUnderline: true,
                            readOnly:
                              isErrorProcessedOrCancelled ||
                              isNoveltyProcessing,
                            sx: {
                              borderRadius: "6px",
                              ".MuiInputBase-input": {
                                paddingBottom: "16px",
                                paddingTop: "16px",
                                display: "flex",
                                justifyContent: "flex-start",
                              },
                            },
                          }}
                        >
                          {statuses.map((status) => (
                            <MenuItem key={status.id} value={status.value}>
                              {status.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={6} sx={{ py: "10px", pl: "18px" }}>
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          marginBottom="4px"
                        >
                          <InputLabel
                            sx={{ color: "black" }}
                            htmlFor="prioridad-input"
                          >
                            Prioridad
                          </InputLabel>
                        </Box>
                        <TextField
                          id="prioridad-input"
                          error={Boolean(
                            formik.touched.priority && formik.errors.priority
                          )}
                          fullWidth
                          helperText={
                            formik.touched.priority && formik.errors.priority
                          }
                          name="priority"
                          onBlur={formik.handleBlur}
                          onChange={handleChangePriority}
                          type="number"
                          value={formik.values.priority}
                          variant="filled"
                          placeholder="Prioridad"
                          inputProps={{
                            min: 0,
                          }}
                          InputProps={{
                            disableUnderline: true,
                            readOnly:
                              isErrorProcessedOrCancelled ||
                              isNoveltyProcessing,
                            sx: {
                              borderRadius: "6px",
                              ".MuiInputBase-input": {
                                paddingBottom: "16px",
                                paddingTop: "16px",
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} sx={{ py: "10px", pl: "18px" }}>
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          marginBottom="4px"
                        >
                          <InputLabel
                            sx={{ color: "black" }}
                            htmlFor="novedad-input"
                          >
                            Novedad
                          </InputLabel>
                        </Box>
                        <TextField
                          id="novedad-input"
                          error={Boolean(
                            formik.touched.fileName && formik.errors.fileName
                          )}
                          fullWidth
                          helperText={
                            formik.touched.fileName && formik.errors.fileName
                          }
                          name="fileName"
                          value={formik.values.fileName}
                          variant="filled"
                          placeholder="Subir archivo"
                          InputProps={{
                            endAdornment: previusState.file && (
                              <>
                                <InputAdornment position="end">
                                  {!isErrorProcessedOrCancelled && (
                                    <HtmlTooltip title={<b>Subir archivo</b>}>
                                      <IconButton
                                        aria-label="subir archivo"
                                        component="label"
                                      >
                                        <input
                                          hidden
                                          id="importar-plantilla-icon-button"
                                          type="file"
                                          name="file"
                                          onChange={(e: any) =>
                                            handleUploadFile(
                                              e.currentTarget.files[0]
                                            )
                                          }
                                          accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        />
                                        <MaterialIcons.FileUploadIcon color="primary" />
                                      </IconButton>
                                    </HtmlTooltip>
                                  )}
                                  {isEqual(
                                    formik.values.file,
                                    previusState.file
                                  ) && (
                                      <HtmlTooltip title={<b>Descargar</b>}>
                                        <span>
                                          <IconButton
                                            aria-label="descargar plantillo modelo"
                                            onClick={() => downloadArch()}
                                            disabled={disabledDownload}
                                          >
                                            <MaterialIcons.FileDownloadIcon
                                              color={
                                                disabledDownload
                                                  ? "disabled"
                                                  : "primary"
                                              }
                                            />
                                          </IconButton>
                                        </span>
                                      </HtmlTooltip>
                                    )}
                                </InputAdornment>
                              </>
                            ),
                            readOnly: true,
                            disableUnderline: true,
                            sx: {
                              borderRadius: "6px",
                              ".MuiInputBase-input": {
                                paddingBottom: "16px",
                                paddingTop: "16px",
                              },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid
                        item
                        xs={2}
                        sx={{ pb: "24px", pt: "10px", pl: "18px" }}
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="urgent"
                                checked={formik.values.urgent}
                                onChange={formik.handleChange}
                                inputProps={{
                                  "aria-label": "urgente-checkbox",
                                }}
                                disabled={isErrorProcessedOrCancelled}
                              />
                            }
                            sx={{
                              ".Mui-disabled.MuiTypography-root": {
                                color: "black",
                              },
                              ".Mui-disabled.MuiCheckbox-root": {
                                color: (theme) => theme.palette.primary.main,
                              },
                            }}
                            label="Urgente"
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                    {!isErrorProcessedOrCancelled || isNoveltyProcessing ? (
                      <Grid container maxWidth="md" paddingBottom="32px">
                        <Box sx={{ display: "flex" }}>
                          <Button
                            sx={{ marginLeft: "18px" }}
                            variant="contained"
                            color="primary"
                            onClick={handleClickGuardar}
                            id="guardar-button"
                            disabled={
                              !formik.dirty ||
                              isEqual(formik.values, previusState)
                            }
                          >
                            Guardar
                          </Button>

                          {formik.dirty &&
                            !isEqual(formik.values, previusState) ? (
                            <Button
                              sx={{ marginLeft: "18px" }}
                              variant="outlined"
                              color="primary"
                              onClick={handleClickDirtyCancelar}
                              id="cancelar-edicion-modal-button"
                            >
                              Cancelar Edición
                            </Button>
                          ) : (
                            <Button
                              sx={{ marginLeft: "18px" }}
                              variant="outlined"
                              color="primary"
                              onClick={() => navigate(-1)}
                              id="cancelar-edicion-button"
                            >
                              Cancelar Edición
                            </Button>
                          )}
                          {!isErrorProcessedOrCancelled &&
                            previusState.status === 0 &&
                            user?.type === 1 &&
                            !isNoveltyProcessing && (
                              <Button
                                sx={{ marginLeft: "18px" }}
                                variant="contained"
                                color="primary"
                                onClick={handleClickProcesar}
                                id="procesar-button"
                              >
                                Procesar ahora
                              </Button>
                            )}
                          {previusState.status === 0 &&
                            !isNoveltyProcessing && (
                              <Button
                                sx={{ marginLeft: "18px" }}
                                variant="contained"
                                color="error"
                                onClick={handleClickCancelNoveltyProccessing}
                                id="cancelar-procesar-button"
                              >
                                Cancelar Procesamiento
                              </Button>
                            )}
                          {previusState.status === 3 && userCanUnblock && (
                            <Button
                              sx={{ marginLeft: "18px" }}
                              variant="contained"
                              color="secondary"
                              onClick={handleClickUnblockNovelty}
                              id="desbloquear-button"
                            >
                              Desbloquear
                            </Button>
                          )}
                        </Box>
                      </Grid>
                    ) : (
                      <Grid container maxWidth="md" paddingBottom="32px">
                        <Button
                          sx={{ marginLeft: "18px" }}
                          variant="outlined"
                          color="primary"
                          onClick={() => navigate(-1)}
                          id="cancelar-edicion-button"
                        >
                          Volver
                        </Button>
                      </Grid>
                    )}
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Grid>
      </Grid>

      {!isLoading && !fetchError && !loadNoveltyError && (
        <>
          <Grid bgcolor="#EEF1FC" container>
            <Box
              sx={{
                display: "flex",
                placeItems: "center",
                justifyContent: "flex-start",
                py: "30px",
              }}
            >
              <Typography
                sx={{ fontSize: "24px", fontWeight: 500, lineHeight: "24px" }}
              >
                Resultado
              </Typography>
            </Box>
          </Grid>

          <Grid container borderRadius="6px">
            <Grid bgcolor="white" item xs={8} borderRadius="6px">
              <Box
                sx={{
                  borderRadius: "6px",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "white",
                  height: "100%",
                  width: "74%",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "6px",
                    flexGrow: 1,
                  }}
                >
                  <Grid container>
                    <Stack spacing={1} width="100%">
                      <Grid container paddingLeft="18px" paddingTop="18px">
                        <Grid item xs={6}>
                          <Typography textAlign="start">
                            Fecha de procesamiento:
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography textAlign="start">
                            {formik.values.process_date
                              ? new Date(
                                formik.values.process_date
                              ).toLocaleString()
                              : "No procesado"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container paddingLeft="18px" paddingTop="10px">
                        <Grid item xs={6}>
                          <Typography textAlign="start">Resultado:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          {formik.values.error && (
                            <Box height="auto">
                              <Typography
                                overflow="hidden"
                                whiteSpace="nowrap"
                                textAlign="start"
                                textOverflow="ellipsis"
                              >
                                {formik.values.error}
                              </Typography>
                            </Box>
                          )}
                          {formik.values.result && (
                            <Box>
                              <Typography textAlign="start">
                                {formik.values.result}
                              </Typography>
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                      {(formik.values.error || formik.values.result) && (
                        <Grid
                          container
                          sx={{
                            paddingLeft: "18px",
                            paddingTop: "10px",
                            paddingBottom: "32px",
                          }}
                        >
                          <Button
                            color="primary"
                            variant="contained"
                            startIcon={<MaterialIcons.DescriptionIcon />}
                            onClick={() => getNovedadLogs()}
                            disabled={disabledDownloadLogs}
                          >
                            Descargar logs
                          </Button>

                        </Grid>
                      )}
                    </Stack>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      )}

      {openUnblockModal && (
        <InformationModal
          title="Desbloquear novedad"
          message="¿Querés desbloquear ésta novedad?"
          openModal={openUnblockModal}
          onClose={handleCloseModal}
          saveForm={handleSaveForm}
          isDirty={false}
          clickCancelar={clickCancelar}
          guardar={clickDesbloquear}
          guardarMessage="¿Querés guardar ésta novedad?"
          clickEliminar={false}
          disabledOnSubmit={disabledOnSubmit}
        />
      )}

      {openInformationModal && (
        <InformationModal
          title={isDirty ? "Cancelar cambios" : "Editar empresa"}
          message={
            isDirty
              ? "¿Querés cancelar los cambios realizados?"
              : "¿Querés guardar los cambios realizados a ésta empresa?"
          }
          openModal={openInformationModal}
          onClose={handleCloseModal}
          saveForm={handleSaveButton}
          isDirty={isDirty}
          clickCancelar={clickCancelar}
          handleDeshacerCancelar={handleDeshacerCancelar}
          guardar={clickGuardar}
          guardarMessage="¿Querés guardar ésta novedad?"
          clickEliminar={false}
          disabledOnSubmit={disabledOnSubmit}
        />
      )}

      {openProcessingModal && (
        <InformationModal
          title={
            clickCancelarProcesamiento
              ? "Cancelar procesamiento"
              : "Procesar novedad"
          }
          message={
            clickCancelarProcesamiento
              ? "¿Querés cancelar el procesamiento de ésta novedad?"
              : "¿Querés procesar ésta novedad?"
          }
          openModal={openProcessingModal}
          onClose={handleIsProcessingCloseModal}
          saveForm={handleSaveForm}
          isDirty={clickCancelarProcesamiento}
          clickCancelar={false}
          handleDeshacerCancelar={handleDeshacerCancelar}
          guardar={clickProcesar || clickCancelarProcesamiento}
          guardarMessage="¿Querés procesar ésta novedad?"
          clickEliminar={false}
          disabledOnSubmit={disabledOnSubmit}
          showIcon={showIcon}
          successIcon={successIcon}
          processingErrorMessage={errorMessage}
          isProcessingMessage={isProcessingMessage}
        />
      )}

      {openErrorModal && (
        <InformationModal
          title="Ha ocurrido un error"
          message={errorMessage}
          onClose={handleCloseModal}
          openModal={openErrorModal}
          clickCancelar={false}
          clickEliminar={false}
          guardar={false}
          isDirty={false}
          errorMessage
        />
      )}
    </Layout>
  );
};

export default NovedadDetails;
