import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import Typography from "@mui/material/Typography";

import { MaterialIcons } from "../../icons/Material/MaterialIcons";

type CustomCircularProgressType = {
  processingErrorMessage: string;
  isProcessingMessage: string;
  successIcon: boolean;
  handleCloseButton: () => void;
};

const CustomCircularProgress: React.FC<CustomCircularProgressType> = ({
  processingErrorMessage = "Ha ocurrido un error",
  isProcessingMessage,
  successIcon,

  handleCloseButton,
}) => {
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <Box sx={{ m: 1, position: "relative" }}>
        <Fab aria-label="save" color={successIcon ? "success" : "error"}>
          {successIcon ? (
            <MaterialIcons.CheckIcon sx={{ fontSize: "42px" }} />
          ) : (
            <MaterialIcons.ErrorIcon sx={{ fontSize: "42px" }} />
          )}
        </Fab>
      </Box>
      <Typography>
        {successIcon ? isProcessingMessage : processingErrorMessage}
      </Typography>

      {processingErrorMessage && (
        <Box sx={{ pt: 2 }}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleCloseButton}
          >
            Cerrar
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default CustomCircularProgress;
