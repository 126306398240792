import React, { useEffect } from "react";

import Novedades from "../components/Novedades/Novedades";

const NovedadesPage = () => {
  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - Novedades`;
  }, []);

  return <Novedades />;
};

export default NovedadesPage;
