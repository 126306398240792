import * as React from "react";
import { shallow } from "zustand/shallow";

import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import InputAdornment from "@mui/material/InputAdornment";
// import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";

import { useEmpresaStore } from "../../store/empresaStore";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type SelectUserCompanyType = {
  actualState: any;
  onChange: any;
};

const SelectUserCompany: React.FC<SelectUserCompanyType> = ({
  actualState,
  onChange,
}) => {
  const empresas = useEmpresaStore((state) => state.empresas, shallow);

  return (
    <Autocomplete
      disableCloseOnSelect
      fullWidth
      multiple
      onChange={(_, value: any) =>
        onChange({ ...actualState, companies: value })
      }
      id="select-user-company-autocomplete"
      options={empresas || []}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      sx={{
        backgroundColor: "rgba(0, 0, 0, 0.06)",
        borderRadius: "6px",
        border: "none",
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            sx: {
              border: "none",
            },
          }}
          placeholder="Buscar empresa"
        />
      )}
    />
  );
};

export default SelectUserCompany;
