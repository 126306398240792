import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { Link as RouterLink } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import { MaterialIcons } from "../../icons/Material/MaterialIcons";

import Layout from "../Layout/Layout";
import InformationModal from "../Modals/ConfirmationModal/InformationModal";
import HtmlTooltip from "../HtmlTooltip/HtmlTooltip";

import { addCompany, getCompanyTenants } from "../../services/CompaniesService";
import { getIdentificationsTypes } from "../../services/ParametersService";

import { IdentificationType } from "../../types";

const statuses = [
  {
    id: 1,
    value: 0,
    label: "Activo",
  },
  {
    id: 2,
    value: 1,
    label: "Inactivo",
  },
];

const CrearEmpresa = () => {
  const [openInformationModal, setOpenInformationModal] =
    useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [clickCancelar, setClickCancelar] = useState<boolean>(false);
  const [clickGuardar, setClickGuardar] = useState<boolean>(false);
  const [disabledOnSubmit, setDisabledOnsubmit] = useState<boolean>(false);
  const [isIdsLoading, setIsIdsLoading] = useState<boolean>(true);
  const [isTenantsLoading, setIsTenantsLoading] = useState<boolean>(true);
  const [allTenants, setAllTenants] = useState<any[]>([]);
  const [identifications, setIdentifications] = useState<IdentificationType[]>(
    []
  );
  const [errorMessage, setErrorMessage] = useState<string>("");

  const tenants = [...allTenants];

  const handleOpenModal = () => {
    setOpenInformationModal(true);
  };

  const handleCloseModal = useCallback(() => {
    setOpenInformationModal(false);
    if (openErrorModal) {
      setErrorMessage("");
      setOpenErrorModal(false);
    }
  }, [openErrorModal]);

  const handleClickDirtyCancelar = () => {
    setIsDirty(true);
    setClickGuardar(false);
    setClickCancelar(true);
    handleOpenModal();
  };

  const handleClickGuardar = () => {
    setIsDirty(false);
    setClickCancelar(false);
    setClickGuardar(true);
    handleOpenModal();
  };

  const handleDeshacerCancelar = () => {
    formik.resetForm();
    navigate(-1);
  };

  const handleSaveButton = () => {
    formik.submitForm();
  };

  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      tenant: "",
      phone: "",
      address: "",
      status: 1,
      idType: "",
      idNumber: "",
      file: null,
      fileName: "",
      sftp_user: "",
      sftp_password: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("El nombre es requerido"),
      file: Yup.mixed().required("Ingrese la company key"),
      idNumber: Yup.string()
        .max(55)
        .required("El nro de identificación es requerido"),
      idType: Yup.string()
        .max(55)
        .required("El tipo de identificación es requerido"),
    }),
    onSubmit: async (data) => {
      setDisabledOnsubmit(true);
      const finalTenant = tenants.find((tenant) => tenant.Id === data.tenant);

      const finalData = {
        name: data.name,
        email: data.email,
        phone: data.phone,
        status: Number(data.status),
        address: data.address,
        identification_id: data.idNumber,
        identification_type: data.idType,
        tenant: JSON.stringify(finalTenant),
        sftp_user: data.sftp_user,
        sftp_password: data.sftp_password,
        file: data.file,
      };

      const token = await getAccessTokenSilently();
      addCompany(token, finalData)
        .then((result) => {
          formik.resetForm();
          setDisabledOnsubmit(false);
          handleCloseModal();
          navigate(-1);
        })
        .catch((e) => {
          console.error(e.response.data.error);
          setErrorMessage(e.response.data.error);
          setDisabledOnsubmit(false);
          setOpenErrorModal(true);
        });
    },
  });

  const handleUploadFile = (file: any) => {
    formik.setFieldValue("file", file);
    formik.setFieldValue("fileName", file.name);
  };

  const getIdsTypes = useCallback(async () => {
    setIsIdsLoading(true);
    const token = await getAccessTokenSilently();
    getIdentificationsTypes(token)
      .then((result) => {
        setIdentifications(result.data);
        setIsIdsLoading(false);
      })
      .catch((e) => {
        console.error("idsType", e.response.data.error);
        setIdentifications([]);
        setIsIdsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllTenants = useCallback(async () => {
    const token = await getAccessTokenSilently();
    getCompanyTenants(token)
      .then((tenantsRes) => {
        const tenants = tenantsRes.data.sort(function (
          a: { TenantName: string },
          b: { TenantName: string }
        ) {
          if (a.TenantName < b.TenantName) {
            return -1;
          }
          if (a.TenantName > b.TenantName) {
            return 1;
          }
          return 0;
        });
        setIsTenantsLoading(false);
        setAllTenants(tenants);
      })
      .catch((e) => {
        console.error("tenants", e.response.data.error);
        setAllTenants([]);
        setIsTenantsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    getAllTenants();
    getIdsTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (allTenants.length > 0) {
      formik.setFieldValue("tenant", allTenants[0].Id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTenants]);

  useLayoutEffect(() => {
    if (identifications.length > 0) {
      formik.setFieldValue("idType", identifications[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identifications]);

  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - Crear empresa`;
  }, []);

  useEffect(() => {
    if (formik.isSubmitting && Object.entries(formik.errors).length > 0) {
      handleCloseModal();
      setErrorMessage(
        "Hay campos requeridos sin completar. Por favor, llene los campos vacíos."
      );
      setOpenErrorModal(true);
    }
  }, [formik.errors, formik.isSubmitting, handleCloseModal]);

  return (
    <Layout icon="PollIcon" title="Empresas" breadcrumb>
      <Grid container>
        <Grid bgcolor="white" item xs={8} borderRadius="6px">
          {isIdsLoading || isTenantsLoading ? (
            <Box
              sx={{
                borderRadius: "6px",
                display: "flex",
                backgroundColor: "white",
                height: "100%",
                width: "100%",
                paddingRight: "18px",
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Box
                  id="create-novelty-form"
                  borderRadius="6px"
                  sx={{
                    backgroundColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "494px",
                  }}
                >
                  <CircularProgress />
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                borderRadius: "6px",
                display: "flex",
                backgroundColor: "white",
                height: "100%",
                width: "100%",
                paddingRight: "18px",
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Box
                  component="form"
                  autoComplete="off"
                  borderRadius="6px"
                  sx={{ backgroundColor: "white", height: "100%" }}
                  onSubmit={formik.handleSubmit}
                >
                  <Grid container justifyContent="space-between">
                    <Grid item xs={4} sx={{ pt: "18px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="nombre-input"
                        >
                          Nombre
                        </InputLabel>
                      </Box>
                      <TextField
                        id="nombre-input"
                        error={Boolean(
                          formik.touched.name && formik.errors.name
                        )}
                        fullWidth
                        helperText={formik.touched.name && formik.errors.name}
                        name="name"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.name}
                        variant="filled"
                        placeholder="Nombre de la empresa"
                        InputProps={{
                          disableUnderline: true,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} sx={{ pt: "18px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="email-input"
                        >
                          Email
                        </InputLabel>
                      </Box>
                      <TextField
                        id="email-input"
                        error={Boolean(
                          formik.touched.email && formik.errors.email
                        )}
                        fullWidth
                        helperText={formik.touched.email && formik.errors.email}
                        name="email"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="email"
                        value={formik.values.email}
                        variant="filled"
                        placeholder="info@bglobalsolutions.com"
                        InputProps={{
                          disableUnderline: true,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} sx={{ pt: "18px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="tenant-input"
                        >
                          Tenant
                        </InputLabel>
                      </Box>
                      <TextField
                        id="tenant-input"
                        error={Boolean(
                          formik.touched.tenant && formik.errors.tenant
                        )}
                        select
                        fullWidth
                        helperText={
                          formik.touched.tenant && formik.errors.tenant
                        }
                        name="tenant"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.tenant}
                        variant="filled"
                        InputProps={{
                          disableUnderline: true,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                              display: "flex",
                              justifyContent: "flex-start",
                            },
                          },
                        }}
                      >
                        {tenants.map((tenant) => (
                          <MenuItem key={tenant.Id} value={tenant.Id}>
                            {tenant.TenantName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Grid item xs={4} sx={{ py: "10px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="phone-input"
                        >
                          Teléfono
                        </InputLabel>
                      </Box>
                      <TextField
                        id="phone-input"
                        error={Boolean(
                          formik.touched.phone && formik.errors.phone
                        )}
                        fullWidth
                        helperText={formik.touched.phone && formik.errors.phone}
                        name="phone"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.phone}
                        variant="filled"
                        placeholder="+542351235454"
                        InputProps={{
                          disableUnderline: true,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} sx={{ py: "10px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="address-input"
                        >
                          Dirección
                        </InputLabel>
                      </Box>
                      <TextField
                        id="address-input"
                        error={Boolean(
                          formik.touched.address && formik.errors.address
                        )}
                        fullWidth
                        helperText={
                          formik.touched.address && formik.errors.address
                        }
                        name="address"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.address}
                        variant="filled"
                        placeholder="Av. Santander"
                        InputProps={{
                          disableUnderline: true,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} sx={{ py: "10px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="status-select"
                        >
                          Estado
                        </InputLabel>
                      </Box>
                      <TextField
                        id="status-select"
                        select
                        error={Boolean(
                          formik.touched.status && formik.errors.status
                        )}
                        fullWidth
                        helperText={
                          formik.touched.status && formik.errors.status
                        }
                        name="status"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.status}
                        variant="filled"
                        InputProps={{
                          disableUnderline: true,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                              display: "flex",
                              justifyContent: "flex-start",
                            },
                          },
                        }}
                      >
                        {statuses.map((status) => (
                          <MenuItem key={status.id} value={status.value}>
                            {status.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4} sx={{ py: "10px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="id-number-input"
                        >
                          Nro. de Identificación
                        </InputLabel>
                      </Box>
                      <TextField
                        id="id-number-input"
                        error={Boolean(
                          formik.touched.idNumber && formik.errors.idNumber
                        )}
                        fullWidth
                        helperText={
                          formik.touched.idNumber && formik.errors.idNumber
                        }
                        name="idNumber"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.idNumber}
                        variant="filled"
                        placeholder="Nro. de Identificación"
                        InputProps={{
                          disableUnderline: true,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} sx={{ py: "10px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="id-tipo-input"
                        >
                          Tipo de identificación
                        </InputLabel>
                      </Box>
                      <TextField
                        id="id-tipo-input"
                        select
                        error={Boolean(
                          formik.touched.idType && formik.errors.idType
                        )}
                        fullWidth
                        helperText={
                          formik.touched.idType && formik.errors.idType
                        }
                        name="idType"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.idType}
                        variant="filled"
                        InputProps={{
                          disableUnderline: true,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                              display: "flex",
                              justifyContent: "flex-start",
                            },
                          },
                        }}
                      >
                        {identifications.map((idType) => (
                          <MenuItem key={idType.id} value={idType.id}>
                            {idType.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={4} sx={{ py: "10px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="company-key-select"
                        >
                          Company Key
                        </InputLabel>
                      </Box>
                      <TextField
                        id="company-key-select"
                        error={Boolean(
                          formik.touched.file && formik.errors.file
                        )}
                        fullWidth
                        helperText={formik.touched.file && formik.errors.file}
                        name="fileName"
                        value={formik.values.fileName}
                        variant="filled"
                        placeholder="Subir archivo"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <HtmlTooltip title={<b>Subir company key</b>}>
                                <IconButton
                                  aria-label="subir archivo"
                                  component="label"
                                >
                                  <input
                                    hidden
                                    id="subir-button"
                                    type="file"
                                    name="file"
                                    onChange={(e: any) =>
                                      handleUploadFile(e.currentTarget.files[0])
                                    }
                                    accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                  />
                                  <MaterialIcons.FileUploadIcon color="primary" />
                                </IconButton>
                              </HtmlTooltip>
                            </InputAdornment>
                          ),
                          readOnly: true,
                          disableUnderline: true,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      item
                      xs={4}
                      sx={{ pb: "26px", pt: "10px", pl: "18px" }}
                    >
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="sftp-user-input"
                        >
                          Usuario SFTP
                        </InputLabel>
                      </Box>
                      <TextField
                        id="sftp-user-input"
                        error={Boolean(
                          formik.touched.sftp_user && formik.errors.sftp_user
                        )}
                        fullWidth
                        helperText={
                          formik.touched.sftp_user && formik.errors.sftp_user
                        }
                        name="sftp_user"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.sftp_user}
                        variant="filled"
                        placeholder="Usuario SFTP"
                        InputProps={{
                          disableUnderline: true,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{ pb: "26px", pt: "10px", pl: "18px" }}
                    >
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="sftp-password-input"
                        >
                          Password SFTP
                        </InputLabel>
                      </Box>
                      <TextField
                        id="sftp-password-input"
                        error={Boolean(
                          formik.touched.sftp_password &&
                            formik.errors.sftp_password
                        )}
                        fullWidth
                        helperText={
                          formik.touched.sftp_password &&
                          formik.errors.sftp_password
                        }
                        name="sftp_password"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.sftp_password}
                        variant="filled"
                        placeholder="Password SFTP"
                        InputProps={{
                          disableUnderline: true,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container maxWidth="sm" paddingBottom="32px">
                    <Box sx={{ display: "flex" }}>
                      <Button
                        sx={{ marginLeft: "18px" }}
                        variant="contained"
                        color="primary"
                        onClick={handleClickGuardar}
                      >
                        Guardar
                      </Button>

                      {formik.dirty ? (
                        <Button
                          sx={{ marginLeft: "18px" }}
                          variant="contained"
                          color="error"
                          onClick={handleClickDirtyCancelar}
                        >
                          Cancelar
                        </Button>
                      ) : (
                        <Button
                          sx={{ marginLeft: "18px" }}
                          variant="contained"
                          color="error"
                          component={RouterLink}
                          to="/empresas"
                          relative="path"
                        >
                          Cancelar
                        </Button>
                      )}
                    </Box>
                  </Grid>
                </Box>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>

      {openErrorModal && (
        <InformationModal
          title="Ha ocurrido un error"
          message={errorMessage}
          onClose={handleCloseModal}
          openModal={openErrorModal}
          clickCancelar={false}
          clickEliminar={false}
          guardar={false}
          isDirty={false}
          errorMessage
        />
      )}

      {openInformationModal && (
        <InformationModal
          title={isDirty ? "Cancelar cambios" : "Crear empresa"}
          message={
            isDirty
              ? "¿Querés cancelar los cambios realizados?"
              : "¿Querés crear ésta empresa?"
          }
          openModal={openInformationModal}
          onClose={handleCloseModal}
          saveForm={handleSaveButton}
          isDirty={isDirty}
          clickCancelar={clickCancelar}
          handleDeshacerCancelar={handleDeshacerCancelar}
          guardar={clickGuardar}
          guardarMessage="¿Querés crear ésta empresa?"
          clickEliminar={false}
          disabledOnSubmit={disabledOnSubmit}
        />
      )}
    </Layout>
  );
};

export default CrearEmpresa;
