import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface ReintentarModalType {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ReintentarModal: React.FC<ReintentarModalType> = ({
  open,
  onClose,
  onConfirm,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      keepMounted
      aria-labelledby="alert-dialog-retry-title"
      aria-describedby="alert-dialog-retry-description"
      PaperProps={{
        sx: {
          height: "auto",
          width: "382px",
        },
      }}
    >
      <DialogTitle
        sx={{
          color: "#000000",
          fontSize: "24px",
          padding: "28px",
          paddingBottom: "21px",
        }}
      >
        Ha ocurrido un error
      </DialogTitle>
      <DialogContent sx={{ paddingX: "28px", paddingBottom: "0px" }}>
        <DialogContentText sx={{ color: "#333333", fontSize: "14px" }}>
          No se han podido cargar las novedades. ¿Querés intentar de nuevo?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: "18px", paddingTop: "27px" }}>
        <Button onClick={onClose} color="primary" variant="outlined">
          Cancelar
        </Button>
        <Button onClick={onConfirm} color="primary" variant="contained">
          Reintentar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReintentarModal;
