import React, { useEffect } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import * as Yup from "yup";
import { useFormik } from "formik";

import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { addCompanyPeriod } from "../../../services/CompaniesService";

const periodsBefore = [
  {
    id: 1,
    value: "1",
  },
  {
    id: 2,
    value: "2",
  },
  {
    id: 3,
    value: "3",
  },
  {
    id: 4,
    value: "4",
  },
  {
    id: 5,
    value: "5",
  },
];

const durationArray = [
  {
    id: 0,
    value: "Mensual",
  },
  {
    id: 1,
    value: "Quincenal",
  },
];

type PeriodoStepperDetailsType = {
  handleClose: (status: boolean) => void;
  handleReset: () => void;
  periodo: any;
  handleRefreshTable: (status: boolean) => void;
  setDisabledOnSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenErrorModal: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
};

const PeriodoStepperDetails: React.FC<PeriodoStepperDetailsType> = ({
  handleClose,
  handleRefreshTable,
  handleReset,
  periodo,
  setDisabledOnSubmit,
  setErrorMessage,
  setOpenErrorModal,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const params = useParams();

  const formik = useFormik({
    initialValues: {
      name: "",
      duration: 0,
      day_limit: 1,
      periods_before: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().max(55).required("El nombre es requerido"),
      duration: Yup.number().max(365).required("La duración es requerida"),
      periods_before: Yup.number()
        .max(365)
        .required("El anticipo es requerida"),
      day_limit: Yup.number()
        .lessThan(1, "Debe ser un número igual o menor a cero (0)")
        .required("El dia de límite es requerido"),
    }),
    onSubmit: async (data) => {
      setDisabledOnSubmit(true);
      const finalData = {
        ...data,
        day_limit: Number(data.day_limit),
        periods_before: Number(data.periods_before),
        company_id: params.empresaId,
      };
      const token = await getAccessTokenSilently();
      addCompanyPeriod(token, finalData)
        .then((result) => {
          if (result.status === 201 || result.status === 200) {
            handleReset();
            handleRefreshTable(true);
            handleClose(false);
            setDisabledOnSubmit(false);
          }
        })
        .catch((e) => {
          console.error("error", e.response.data.error);
          setErrorMessage(e.response.data.error);
          setDisabledOnSubmit(false);
          setOpenErrorModal(true);
        });
    },
  });

  useEffect(() => {
    formik.setValues({
      name: periodo?.name,
      duration: periodo?.duration,
      day_limit: periodo?.day_limit.toString(),
      periods_before: periodo?.periods_before.toString(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodo]);

  return (
    <Grid container>
      <Grid bgcolor="white" item xs={12} borderRadius="6px">
        <Box
          sx={{
            borderRadius: "6px",
            display: "flex",
            backgroundColor: "white",
            height: "100%",
            width: "100%",
            paddingRight: "18px",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Box
              id="periodo-stepper-form"
              component="form"
              autoComplete="off"
              borderRadius="6px"
              sx={{ backgroundColor: "white", height: "100%" }}
              onSubmit={formik.handleSubmit}
            >
              <Grid container justifyContent="space-between">
                <Grid item xs={6} sx={{ pt: "18px", pl: "18px" }}>
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    marginBottom="4px"
                  >
                    <InputLabel sx={{ color: "black" }} htmlFor="nombre-input">
                      Nombre
                    </InputLabel>
                  </Box>
                  <TextField
                    id="nombre-input"
                    error={Boolean(formik.touched.name && formik.errors.name)}
                    fullWidth
                    helperText={formik.touched.name && formik.errors.name}
                    name="name"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.name}
                    variant="filled"
                    placeholder="Nombre del periodo"
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        borderRadius: "6px",
                        ".MuiInputBase-input": {
                          paddingBottom: "16px",
                          paddingTop: "16px",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6} sx={{ pt: "18px", pl: "18px" }}>
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    marginBottom="4px"
                  >
                    <InputLabel
                      sx={{ color: "black" }}
                      htmlFor="duration-input"
                    >
                      Duración
                    </InputLabel>
                  </Box>
                  <TextField
                    id="duration-select"
                    select
                    error={Boolean(
                      formik.touched.duration && formik.errors.duration
                    )}
                    fullWidth
                    helperText={
                      formik.touched.duration && formik.errors.duration
                    }
                    name="duration"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="number"
                    value={formik.values.duration}
                    variant="filled"
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        borderRadius: "6px",
                        ".MuiInputBase-input": {
                          paddingBottom: "16px",
                          paddingTop: "16px",
                          display: "flex",
                          justifyContent: "flex-start",
                        },
                      },
                    }}
                  >
                    {durationArray.map((duration) => (
                      <MenuItem key={duration.id} value={duration.id}>
                        {duration.value}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                paddingBottom="26px"
              >
                <Grid item xs={6} sx={{ py: "10px", pl: "18px" }}>
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    marginBottom="4px"
                  >
                    <InputLabel
                      sx={{ color: "black" }}
                      htmlFor="day-limit-input"
                    >
                      Día limite
                    </InputLabel>
                  </Box>
                  <TextField
                    id="day-limit-input"
                    error={Boolean(
                      formik.touched.day_limit && formik.errors.day_limit
                    )}
                    fullWidth
                    helperText={
                      formik.touched.day_limit && formik.errors.day_limit
                    }
                    name="day_limit"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="number"
                    value={formik.values.day_limit}
                    variant="filled"
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        borderRadius: "6px",
                        ".MuiInputBase-input": {
                          paddingBottom: "16px",
                          paddingTop: "16px",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6} sx={{ py: "10px", pl: "18px" }}>
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    marginBottom="4px"
                  >
                    <InputLabel
                      sx={{ color: "black" }}
                      htmlFor="periods-before-select"
                    >
                      Anticipo
                    </InputLabel>
                  </Box>
                  <TextField
                    id="periods-before-select"
                    select
                    error={Boolean(
                      formik.touched.periods_before &&
                        formik.errors.periods_before
                    )}
                    fullWidth
                    helperText={
                      formik.touched.periods_before &&
                      formik.errors.periods_before
                    }
                    name="periods_before"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.periods_before}
                    variant="filled"
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        borderRadius: "6px",
                        ".MuiInputBase-input": {
                          paddingBottom: "16px",
                          paddingTop: "16px",
                          display: "flex",
                          justifyContent: "flex-start",
                        },
                      },
                    }}
                  >
                    {periodsBefore.map((period) => (
                      <MenuItem key={period.id} value={period.value}>
                        {period.value}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PeriodoStepperDetails;
