import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { getUserProfile } from "../services/UsersService";
import { NewUsuariosRowsType } from "../types";

type NewUsuariosRowsTypeWithAdminOGerente = NewUsuariosRowsType & {
  isAdminOGerente: boolean;
};

type UserState = {
  user: NewUsuariosRowsTypeWithAdminOGerente | null;
  hasFetchUserDataError: boolean;
  errorMessage: string;
};

type UserrAction = {
  getUserData: (token: string) => Promise<void>;
  setHasFetchUserDataError: (error: boolean) => void;
  setUserData: (user: any) => void;
  resetUserState: () => void;
  returnUserData: () => NewUsuariosRowsTypeWithAdminOGerente | null;
};

// define the initial state
const initialUserState: UserState = {
  user: null,
  hasFetchUserDataError: false,
  errorMessage: "",
};

export const useUserStore = create<UserState & UserrAction>()(
  persist(
    (set, get) => ({
      ...initialUserState,
      getUserData: async (token) => {
        await getUserProfile(token)
          .then((resUser) =>
            set((state) => ({
              ...state,
              hasFetchUserDataError: false,
              // user: resUser.data,
              user: {
                ...resUser.data,
                userTopLink:
                  resUser.data.type === 1 ? "/empresas" : "/gestiondeclientes",
                isAdminOGerente:
                  resUser.data.role === 2 || resUser.data.role === 5,
              },
              // user: { ...resUser.data, status: 1 },
            }))
          )
          .catch((e) => {
            console.error(e.response.data.error);
            set((state) => ({
              ...state,
              hasFetchUserDataError: true,
              user: null,
              errorMessage: e.response.data.error,
            }));
          });
      },
      setUserData: (user) => {
        set((state) => ({
          ...state,
          user,
        }));
      },
      setHasFetchUserDataError: (hasError: boolean) => {
        set((state) => ({
          ...state,
          hasFetchUserDataError: hasError,
        }));
      },
      returnUserData: () => {
        return get().user;
      },
      resetUserState: () => {
        set(initialUserState);
      },
    }),
    {
      name: "user-storage", // name of the item in the storage (must be unique)
      storage: createJSONStorage<UserState>(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);
