import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface LoginErrorModalType {
  open: boolean;
}

const LoginErrorModal: React.FC<LoginErrorModalType> = ({ open }) => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = () => {
    loginWithRedirect();
  };

  return (
    <Dialog
      open={open}
      keepMounted
      aria-labelledby="alert-dialog-retry-title"
      aria-describedby="alert-dialog-retry-description"
      PaperProps={{
        sx: {
          height: "auto",
          width: "382px",
        },
      }}
    >
      <DialogTitle
        sx={{
          color: "#000000",
          fontSize: "24px",
          padding: "28px",
          paddingBottom: "21px",
        }}
      >
        Iniciar sesión para acceder
      </DialogTitle>
      <DialogContent sx={{ paddingX: "28px", paddingBottom: "0px" }}>
        <DialogContentText sx={{ color: "#333333", fontSize: "14px" }}>
          Por favor, inicia sesión para ver ésta página.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: "18px", paddingTop: "27px" }}>
        <Button onClick={handleLogin} color="primary" variant="contained">
          Iniciar sesión
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoginErrorModal;
