import React from "react";
import { useParams } from "react-router-dom";

import UsuarioDetails from "../../components/Details/Usuarios/UsuarioDetails";

const UsusarioDetailsPage = () => {
  const params = useParams();
  return <UsuarioDetails params={params} />;
};

export default UsusarioDetailsPage;
