import React, { useEffect } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import * as Yup from "yup";
import { useFormik } from "formik";

import Layout from "../Layout/Layout";

// import { useNavigate } from "react-router-dom";
// import { useAuth0 } from "@auth0/auth0-react";
// import { addCompany } from "../../services/CompaniesService";
import SelectUserCompany from "./SelectUserCompany";

const types = [
  {
    id: 1,
    value: "1",
    label: "Básico",
  },
  {
    id: 2,
    value: "2",
    label: "Cliente Administrador",
  },
  {
    id: 3,
    value: "3",
    label: "BDO Administrador",
  },
  {
    id: 4,
    value: "4",
    label: "Cliente Básico",
  },
  {
    id: 5,
    value: "5",
    label: "BDO Básico",
  },
  {
    id: 6,
    value: "6",
    label: "BDO Gerente",
  },
  {
    id: 7,
    value: "7",
    label: "BDO Lectura",
  },
];

const CrearUsuario = () => {
  // const navigate = useNavigate();

  // const { getAccessTokenSilently } = useAuth0();

  const formik = useFormik({
    initialValues: {
      name: "",
      lastname: "",
      email: "",
      type: "",
      phone: "",
      username: "",
      companies: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().max(55).required("El nombre es requerido"),
      username: Yup.string()
        .max(55)
        .required("El nombre de usuario es requerido"),
      email: Yup.string().max(255).required("El email es requerido"),
      phone: Yup.string().max(15).required("El teléfono es requerido"),
      type: Yup.string().max(255).required("El estado es requerido"),
      companies: Yup.array()
        .min(1, "Debe seleccionar al menos una (1) empresa")
        .required("Debe seleccionar al menos una (1) empresa"),
    }),
    onSubmit: async (datax) => {
      console.log("data", datax);
      // const token = await getAccessTokenSilently();
      // const result = await addCompany(token, data);
      // formik.resetForm();
      // console.log("submit - add", result);
      // if (result.status === 201) {
      //   navigate("/empresas");
      // }
    },
  });

  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - Crear usuario`;
  }, []);

  return (
    <Layout icon="PersonIcon" title="Crear usuario">
      <Grid container marginTop="12px" borderRadius="6px">
        <Grid bgcolor="white" item xs={10} borderRadius="6px">
          <Box
            sx={{
              borderRadius: "6px",
              display: "flex",
              backgroundColor: "white",
              height: "100%",
              width: "100%",
              paddingRight: "18px",
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Box
                component="form"
                id="crear-usuario-form"
                autoComplete="off"
                borderRadius="6px"
                sx={{ backgroundColor: "white", height: "100%" }}
                onSubmit={formik.handleSubmit}
              >
                <Grid container>
                  <Grid bgcolor="white" item xs={6}>
                    <Grid container>
                      <Grid item xs={6} sx={{ py: "10px", pl: "18px" }}>
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          marginBottom="4px"
                        >
                          <InputLabel
                            sx={{ color: "black" }}
                            htmlFor="usuario-input"
                          >
                            Usuario
                          </InputLabel>
                        </Box>
                        <TextField
                          id="usuario-input"
                          error={Boolean(
                            formik.touched.username && formik.errors.username
                          )}
                          fullWidth
                          helperText={
                            formik.touched.username && formik.errors.username
                          }
                          name="username"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          type="text"
                          value={formik.values.username}
                          variant="filled"
                          placeholder="Nombre de usuario"
                          InputProps={{
                            disableUnderline: true,
                            sx: {
                              borderRadius: "6px",
                              ".MuiInputBase-input": {
                                paddingBottom: "16px",
                                paddingTop: "16px",
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} sx={{ py: "10px", pl: "18px" }}>
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          marginBottom="4px"
                        >
                          <InputLabel
                            sx={{ color: "black" }}
                            htmlFor="email-input"
                          >
                            Email
                          </InputLabel>
                        </Box>
                        <TextField
                          id="email-input"
                          error={Boolean(
                            formik.touched.email && formik.errors.email
                          )}
                          fullWidth
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                          name="email"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          type="email"
                          value={formik.values.email}
                          variant="filled"
                          placeholder="info@bglobalsolutions.com"
                          InputProps={{
                            disableUnderline: true,
                            sx: {
                              borderRadius: "6px",
                              ".MuiInputBase-input": {
                                paddingBottom: "16px",
                                paddingTop: "16px",
                              },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={6} sx={{ py: "10px", pl: "18px" }}>
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          marginBottom="4px"
                        >
                          <InputLabel
                            sx={{ color: "black" }}
                            htmlFor="nombre-input"
                          >
                            Nombre
                          </InputLabel>
                        </Box>
                        <TextField
                          id="nombre-input"
                          error={Boolean(
                            formik.touched.name && formik.errors.name
                          )}
                          fullWidth
                          helperText={formik.touched.name && formik.errors.name}
                          name="name"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          type="text"
                          value={formik.values.name}
                          variant="filled"
                          placeholder="Nombre"
                          InputProps={{
                            disableUnderline: true,
                            sx: {
                              borderRadius: "6px",
                              ".MuiInputBase-input": {
                                paddingBottom: "16px",
                                paddingTop: "16px",
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} sx={{ py: "10px", pl: "18px" }}>
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          marginBottom="4px"
                        >
                          <InputLabel
                            sx={{ color: "black" }}
                            htmlFor="apellido-input"
                          >
                            Apellido
                          </InputLabel>
                        </Box>
                        <TextField
                          id="apellido-input"
                          error={Boolean(
                            formik.touched.lastname && formik.errors.lastname
                          )}
                          fullWidth
                          helperText={
                            formik.touched.lastname && formik.errors.lastname
                          }
                          name="lastname"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          type="text"
                          value={formik.values.lastname}
                          variant="filled"
                          placeholder="Apellido"
                          InputProps={{
                            disableUnderline: true,
                            sx: {
                              borderRadius: "6px",
                              ".MuiInputBase-input": {
                                paddingBottom: "16px",
                                paddingTop: "16px",
                              },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={6} sx={{ py: "10px", pl: "18px" }}>
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          marginBottom="4px"
                        >
                          <InputLabel
                            sx={{ color: "black" }}
                            htmlFor="phone-input"
                          >
                            Teléfono
                          </InputLabel>
                        </Box>
                        <TextField
                          id="phone-input"
                          error={Boolean(
                            formik.touched.phone && formik.errors.phone
                          )}
                          fullWidth
                          helperText={
                            formik.touched.phone && formik.errors.phone
                          }
                          name="phone"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          type="text"
                          value={formik.values.phone}
                          variant="filled"
                          placeholder="+5412345678"
                          InputProps={{
                            disableUnderline: true,
                            sx: {
                              borderRadius: "6px",
                              ".MuiInputBase-input": {
                                paddingBottom: "16px",
                                paddingTop: "16px",
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} sx={{ py: "10px", pl: "18px" }}>
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          marginBottom="4px"
                        >
                          <InputLabel
                            sx={{ color: "black" }}
                            htmlFor="tipo-select"
                          >
                            Tipo
                          </InputLabel>
                        </Box>
                        <TextField
                          id="tipo-select"
                          select
                          error={Boolean(
                            formik.touched.type && formik.errors.type
                          )}
                          fullWidth
                          helperText={formik.touched.type && formik.errors.type}
                          name="type"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.type}
                          variant="filled"
                          InputProps={{
                            disableUnderline: true,
                            sx: {
                              borderRadius: "6px",
                              ".MuiInputBase-input": {
                                paddingBottom: "16px",
                                paddingTop: "16px",
                                display: "flex",
                                justifyContent: "flex-start",
                              },
                            },
                          }}
                        >
                          {types.map((type) => (
                            <MenuItem key={type.id} value={type.value}>
                              {type.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid bgcolor="white" item xs={6}>
                    <Grid container>
                      <Grid item xs={12} sx={{ py: "10px", pl: "18px" }}>
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          marginBottom="4px"
                        >
                          <InputLabel
                            sx={{ color: "black" }}
                            htmlFor="status-select"
                          >
                            Asignar a empresa
                          </InputLabel>
                        </Box>
                        <SelectUserCompany
                          actualState={formik.values}
                          onChange={formik.setValues}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  maxWidth="sm"
                  paddingBottom="32px"
                  paddingTop="32px"
                >
                  <Button
                    sx={{ marginLeft: "18px" }}
                    variant="contained"
                    color="primary"
                    form="crear-usuario-form"
                    type="submit"
                    disabled={
                      !formik.dirty ||
                      !formik.values.email ||
                      !formik.values.name ||
                      !formik.values.companies ||
                      !formik.values.type
                    }
                  >
                    Guardar
                  </Button>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default CrearUsuario;
