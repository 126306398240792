import React, { useEffect } from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { MaterialIcons } from "../../icons/Material/MaterialIcons";
import { ReactComponent as BDOIcon } from "../../icons/svg/bdo-icon.svg";
import NavBar from "../Layout/NavBar";
import { useUserStore } from "../../store/userStore";

const InactiveUser = () => {
  const user = useUserStore((state) => state.user);

  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - Usuario Inactivo`;
  }, []);

  return (
    <>
      {user && <NavBar drawerOpen={false} title="" />}
      <Grid
        container
        spacing={0}
        alignItems="center"
        justifyContent="center"
        sx={{ backgroundColor: "#EEF1FC", minHeight: "100vh" }}
      >
        <Grid item xs={8} sx={{ maxHeight: "634px" }}>
          <Box
            component="main"
            sx={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              minHeight: "100%",
              backgroundColor: "white",
            }}
          >
            <Container sx={{ height: "603px" }}>
              <Grid container justifyContent="center">
                <Box sx={{ paddingBottom: "72px", paddingTop: "148px" }}>
                  <MaterialIcons.AccountBoxOutlined width={96} height={96} />
                </Box>
              </Grid>
              <Grid container justifyContent="center">
                <Box sx={{ pb: 9 }} justifyContent="center" display="flex">
                  <Box
                    sx={{
                      display: "flex",
                      placeContent: "center",
                      width: "350px",
                    }}
                  >
                    <Typography
                      sx={{
                        height: "48px",
                        fontWeight: 500,
                        fontSize: "18px",
                        lineHeight: "27px",
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",

                        color: "#333333",
                      }}
                    >
                      Lo sentimos, tu cuenta esta inactiva. Si crees que esto es
                      un error, contacta a tu administrador.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid container justifyContent="center">
                <Box
                  sx={{ pl: 4, pb: 18 }}
                  justifyContent="center"
                  display="flex"
                >
                  <BDOIcon width={128} height={50} />
                </Box>
              </Grid>
            </Container>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default InactiveUser;
