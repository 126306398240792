import React, { useEffect } from "react";

import Parametros from "../components/Parametros/Parametros";

const ParametrosPage = () => {
  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - Parametros`;
  }, []);

  return <Parametros />;
};

export default ParametrosPage;
