import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export type PermissionsState = {
  permissions: string[] | any | null;
  roles: string[] | any | null;
};

export type PermissionAction = {
  resetPermissionsState: () => void;
  returnPermissions: () => string[] | any | null;
  returnRoles: () => string[] | any | null;
  setPermissions: (permissions: string[] | any | null) => void;
  setRoles: (roles: string[] | any | null) => void;
};

// define the initial state
const initialPermissionsState: PermissionsState = {
  permissions: [],
  roles: [],
};

export const usePermissionsStore = create<
  PermissionsState & PermissionAction
>()(
  persist(
    (set, get) => ({
      ...initialPermissionsState,
      setRoles: (roles) => {
        set((state) => ({
          ...state,
          roles,
        }));
      },
      setPermissions: (permissions) => {
        set((state) => ({
          ...state,
          permissions,
        }));
      },
      returnPermissions: () => {
        return get().permissions;
      },
      returnRoles: () => {
        return get().roles;
      },
      resetPermissionsState: () => {
        set(initialPermissionsState);
      },
    }),
    {
      name: "permissions-storage", // name of the item in the storage (must be unique)
      storage: createJSONStorage<PermissionsState>(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);
