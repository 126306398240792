import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { shallow } from "zustand/shallow";

import { getUsersCompanies } from "../../services/CompaniesService";

import { EmpresaContext } from "./EmpresaContext";
import { ParametroContext } from "../parametro/ParametroContext";
import { UserContext } from "../user/UserContext";

import { useEmpresaStore } from "../../store/empresaStore";

import { CompanyType, EmpresaType } from "../../types";

type EmpresaProviderType = {
  children: ReactNode;
};

export const EmpresaProvider = ({ children }: EmpresaProviderType) => {
  const [empresas, setEmpresas] = useState<EmpresaType[] | CompanyType[]>([]);

  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  const { handleActualEmpresa } = useContext(ParametroContext);

  const { user } = useContext(UserContext);

  const {
    empresaId,
    selectedEmpresa,
    allEmpresas,
    allEmpresaPeriodos,
    allTenants,
  } = useEmpresaStore(
    ({
      empresaId,
      selectedEmpresa,
      allEmpresas,
      allEmpresaPeriodos,
      allTenants,
    }) => ({
      empresaId,
      selectedEmpresa,
      allEmpresas,
      allEmpresaPeriodos,
      allTenants,
    }),
    shallow
  );

  const {
    setEmpresaId,
    setSelectedEmpresa,
    setAllEmpresas,
    getAllCompanyPeriods,
  } = useEmpresaStore(
    ({
      setEmpresaId,
      setSelectedEmpresa,
      setAllEmpresas,
      getAllCompanyPeriods,
    }) => ({
      setEmpresaId,
      setSelectedEmpresa,
      setAllEmpresas,
      getAllCompanyPeriods,
    })
  );

  const getCompanies = useCallback(async () => {
    const token = await getAccessTokenSilently();

    getUsersCompanies(token)
      .then((result) => {
        const userEmpresas: any[] = [];

        result.data.forEach((empresa) => {
          userEmpresas.push(empresa.company);
        });

        setEmpresas(userEmpresas);
      })
      .catch((e) => {
        console.error("users-companies", e.response.data.error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPeriodos = useCallback(async () => {
    const token = await getAccessTokenSilently();
    await getAllCompanyPeriods(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      getCompanies();
    }
  }, [getCompanies, user]);

  useEffect(() => {
    if (selectedEmpresa) {
      handleActualEmpresa(selectedEmpresa.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmpresa]);

  useEffect(() => {
    if (empresaId.length > 0) {
      getPeriodos();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresaId]);

  return (
    <EmpresaContext.Provider
      value={{
        allEmpresaPeriodos,
        allEmpresas,
        allTenants,
        empresaId,
        empresas,
        navigate,
        selectedEmpresa,
        setAllEmpresas,
        setEmpresaId,
        setEmpresas,
        setSelectedEmpresa,
      }}
    >
      {children}
    </EmpresaContext.Provider>
  );
};
