import React, { useEffect, useState } from "react";
import { isEqual } from "lodash";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useHasPermissions } from "react-simple-rbac";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import CustomBreadcrumbs from "../CustomBreadcrumbs/CustomBreadcrumbs";
import FetchErrorMessage from "../FetchErrorMessage/FetchErrorMessage";
import HtmlTooltip from "../HtmlTooltip/HtmlTooltip";
import InformationModal from "../Modals/ConfirmationModal/InformationModal";
import Layout from "../Layout/Layout";

import { MaterialIcons } from "../../icons/Material/MaterialIcons";

import {
  deleteCompanyNoveltyType,
  editCompanyNoveltyType,
  getCompanyNoveltyTypeById,
} from "../../services/CompaniesService";

import { NoveltyType } from "../../types";
import { getNoveltyType } from "../../services/ParametersService";
import { useUserStore } from "../../store/userStore";
import { shallow } from "zustand/shallow";

type EmpresaTipoNovedadDetailsType = {
  params: any;
};

const EmpresaTipoNovedadDetails: React.FC<EmpresaTipoNovedadDetailsType> = ({
  params,
}) => {
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [clickCancelar, setClickCancelar] = useState<boolean>(false);
  const [clickGuardar, setClickGuardar] = useState<boolean>(false);
  const [clickEliminar, setClickEliminar] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [modalMessage, setModalMessage] = useState<string>("");
  const [allNovelties, setAllNovelties] = useState<NoveltyType[]>([]);
  const [openInformationModal, setOpenInformationModal] =
    useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [fetchError, setFetchError] = useState<boolean>(false);
  const [disabledOnSubmit, setDisabledOnSubmit] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [fetchErrorMessage, setFetchErrorMessage] = useState<string>("");

  const [previusState, setPreviusState] = useState({
    name: "",
    file: "",
    fileName: "",
    novelty_type: "",
    automatic: false,
  });

  const navigate = useNavigate();

  //const userCanEdit = useHasPermissions(["create:novelty-type-company"]);
  const userCanDelete = useHasPermissions(["delete:novelty-type-company"]);

  const { getAccessTokenSilently } = useAuth0();

  const { empresaId, novedadId } = params;

  const userDataStore = useUserStore((state) => state.user?.role, shallow);

  const isUserReadOnly = userDataStore === 6;

  const handleOpenModal = () => {
    setOpenInformationModal(true);
  };

  const handleCloseModal = () => {
    setOpenInformationModal(false);
    openErrorModal && setOpenErrorModal(false);
  };

  const handleClickDirtyCancelar = () => {
    setIsDirty(true);
    setClickGuardar(false);
    setClickEliminar(false);
    setClickCancelar(true);
    handleOpenModal();
    setModalTitle("Cancelar cambios");
    setModalMessage(
      "¿Querés cancelar los cambios realizados a éste tipo de novedad?"
    );
  };

  const handleClickGuardar = () => {
    setIsDirty(false);
    setClickCancelar(false);
    setClickEliminar(false);
    setClickGuardar(true);
    setModalTitle("Editar tipo de novedad");
    setModalMessage(
      "¿Querés guardar los cambios realizados a éste tipo de novedad?"
    );
    handleOpenModal();
  };

  const handleClickEliminar = () => {
    setClickGuardar(false);
    setClickCancelar(false);
    setClickEliminar(true);
    setModalTitle("Eliminar tipo de novedad");
    setModalMessage("¿Querés eliminar éste tipo de novedad?");
    handleOpenModal();
  };

  const handleDeshacerCancelar = () => {
    formik.resetForm();
    navigate(-1);
  };

  const handleSaveButton = () => {
    formik.submitForm();
  };

  const handleRemove = async () => {
    const token = await getAccessTokenSilently();

    const result = await deleteCompanyNoveltyType(token, novedadId);

    if (result.status === 200 || 201) {
      handleCloseModal();
      navigate(-1);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      novelty_type: "",
      fileName: "",
      file: "",
      automatic: false,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(55)
        .required("El nombre de la novedad es requerido"),
      novelty_type: Yup.mixed().required("Seleccione un tipo de novedad"),
    }),
    onSubmit: async (data) => {
      setDisabledOnSubmit(true);
      const finalNovedad = {
        ...allNovelties.find((novelty) => novelty.id === data.novelty_type)!,
        name: data.name,
      };

      const finalData = {
        // file: data.file,
        automatic: data.automatic,
        company_id: empresaId,
        novelty_type: JSON.stringify(finalNovedad),
        ...(Object.keys(data.file).includes("size") && { file: data.file }),
      };

      const token = await getAccessTokenSilently();
      editCompanyNoveltyType(token, novedadId, finalData)
        .then((result) => {
          if (result.status === 200 || result.status === 201) {
            setOpenInformationModal(false);
            formik.resetForm();
            navigate(-1);
            setDisabledOnSubmit(false);
          }
        })
        .catch((e) => {
          setErrorMessage(e.response.data.error);
          setOpenErrorModal(true);
          setDisabledOnSubmit(false);
        });
    },
  });

  const handleUploadFile = (file: any) => {
    formik.setFieldValue("file", file);
    formik.setFieldValue("fileName", file.name);
  };

  const links: any[] = [
    { id: "1", value: "Empresas", isLink: true, to: "/empresas" },
    {
      id: "2",
      value: "Empresa",
      isLink: true,
      to: `/empresas/empresa/${empresaId}`,
    },
    {
      id: "3",
      value: "Parametros",
      isLink: true,
      to: `/empresas/empresa/${empresaId}/parametros`,
    },
    { id: "4", value: "Tipo de Novedad", isLink: false },
  ];

  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - Crear tipo de novedad`;
  }, []);

  useEffect(() => {
    const allPromises: Promise<void>[] = [];

    const getNovedadesParametros = async () => {
      const token = await getAccessTokenSilently();

      await getNoveltyType(token)
        .then((novedades) => {
          setAllNovelties(novedades.data);
        })
        .catch((e) => {
          console.error(e.response.data.error);
          setAllNovelties([]);
        });
    };

    const getNovelty = async () => {
      const token = await getAccessTokenSilently();

      await getCompanyNoveltyTypeById(token, empresaId, novedadId)
        .then((result) => {
          formik.setValues({
            name: result.data.novelty_type.name,
            file: result.data.file,
            fileName: result.data.file.name,
            novelty_type: result.data.novelty_type.id,
            automatic: result.data.automatic,
          });
          setPreviusState({
            name: result.data.novelty_type.name,
            file: result.data.file,
            fileName: result.data.file.name,
            novelty_type: result.data.novelty_type.id,
            automatic: result.data.automatic,
          });
        })
        .catch((e) => {
          console.error(e.response.data.error);
          setFetchError(true);
          setFetchErrorMessage(e.response.data.error);
        });
    };

    allPromises.push(getNovelty(), getNovedadesParametros());

    Promise.all(allPromises).then((res) => {
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [novedadId]);

  return (
    <Layout noIcon title="Tipo de novedad">
      <CustomBreadcrumbs icon="PollIcon" links={links} />
      <Grid container marginTop="12px" borderRadius="6px">
        <Grid bgcolor="white" item xs={8} borderRadius="6px">
          {isLoading ? (
            <Box
              sx={{
                borderRadius: "6px",
                display: "flex",
                backgroundColor: "white",
                height: "100%",
                width: "100%",
                paddingRight: "18px",
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Box
                  id="create-novelty-form"
                  borderRadius="6px"
                  sx={{
                    backgroundColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "350px",
                  }}
                >
                  <CircularProgress />
                </Box>
              </Box>
            </Box>
          ) : fetchError ? (
            <FetchErrorMessage
              errorMessage={fetchErrorMessage}
              height="350px"
            />
          ) : (
            <Box
              sx={{
                borderRadius: "6px",
                display: "flex",
                backgroundColor: "white",
                height: "100%",
                width: "100%",
                paddingRight: "18px",
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Box
                  component="form"
                  autoComplete="off"
                  borderRadius="6px"
                  sx={{ backgroundColor: "white", height: "100%" }}
                  onSubmit={formik.handleSubmit}
                >
                  <Grid container>
                    <Grid item xs={6} sx={{ pt: "18px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="nombre-input"
                        >
                          Nombre
                        </InputLabel>
                      </Box>
                      <TextField
                        id="nombre-input"
                        error={Boolean(
                          formik.touched.name && formik.errors.name
                        )}
                        fullWidth
                        helperText={formik.touched.name && formik.errors.name}
                        name="name"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.name}
                        variant="filled"
                        placeholder="Nombre de la novedad"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: isUserReadOnly,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ pt: "18px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="type-select"
                        >
                          Tipo
                        </InputLabel>
                      </Box>
                      <TextField
                        id="type-select"
                        select
                        error={Boolean(
                          formik.touched.novelty_type &&
                            formik.errors.novelty_type
                        )}
                        fullWidth
                        helperText={
                          formik.touched.novelty_type &&
                          formik.errors.novelty_type
                        }
                        name="novelty_type"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.novelty_type}
                        variant="filled"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: isUserReadOnly,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                              display: "flex",
                              justifyContent: "flex-start",
                            },
                          },
                        }}
                      >
                        {allNovelties.map((novelty) => (
                          <MenuItem key={novelty.id} value={novelty.id}>
                            {`${novelty.code} - ${novelty.name}`}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Grid item xs={6} sx={{ py: "10px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="archivo-input"
                        >
                          Archivo
                        </InputLabel>
                      </Box>
                      <TextField
                        id="archivo-input"
                        error={Boolean(
                          formik.touched.fileName && formik.errors.fileName
                        )}
                        fullWidth
                        helperText={
                          formik.touched.fileName && formik.errors.fileName
                        }
                        name="fileName"
                        value={formik.values.fileName}
                        variant="filled"
                        placeholder="Subir archivo"
                        InputProps={{
                          endAdornment: previusState.file && (
                            <InputAdornment position="end">
                              <HtmlTooltip title={<b>Subir archivo</b>}>
                                <IconButton
                                  aria-label="subir archivo"
                                  component="label"
                                  disabled={isUserReadOnly}
                                >
                                  <input
                                    hidden
                                    id="importar-plantilla-icon-button"
                                    type="file"
                                    name="file"
                                    onChange={(e: any) =>
                                      handleUploadFile(e.currentTarget.files[0])
                                    }
                                    accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                  />
                                  <MaterialIcons.FileUploadIcon
                                    color={
                                      isUserReadOnly ? "disabled" : "primary"
                                    }
                                  />
                                </IconButton>
                              </HtmlTooltip>
                            </InputAdornment>
                          ),
                          readOnly: true,
                          disableUnderline: true,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      item
                      xs={6}
                      sx={{ pb: "24px", pt: "10px", pl: "18px" }}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="automatic"
                              checked={formik.values.automatic}
                              onChange={formik.handleChange}
                              inputProps={{
                                "aria-label": "automatic-checkbox",
                              }}
                              disabled={isUserReadOnly}
                            />
                          }
                          label="Procesamiento automático"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid container maxWidth="sm" paddingBottom="32px">
                    <Box sx={{ display: "flex" }}>
                      {isUserReadOnly ? (
                        <HtmlTooltip
                          title={
                            <Typography variant="body1">
                              Unauthorized access.{" "}
                              <b>create:novelty-type-company</b> permission
                              needed
                            </Typography>
                          }
                        >
                          <span>
                            <Button
                              sx={{ marginLeft: "18px" }}
                              variant="contained"
                              color="primary"
                              onClick={handleClickGuardar}
                              disabled
                            >
                              Guardar
                            </Button>
                          </span>
                        </HtmlTooltip>
                      ) : (
                        <Button
                          sx={{ marginLeft: "18px" }}
                          variant="contained"
                          color="primary"
                          onClick={handleClickGuardar}
                          disabled={
                            !formik.dirty ||
                            !formik.values.name ||
                            !formik.values.novelty_type ||
                            !formik.values.fileName ||
                            isEqual(formik.values, previusState)
                          }
                        >
                          Guardar
                        </Button>
                      )}

                      {!isEqual(formik.values, previusState) ? (
                        <Button
                          sx={{ marginLeft: "18px" }}
                          variant="outlined"
                          color="primary"
                          onClick={handleClickDirtyCancelar}
                        >
                          Cancelar
                        </Button>
                      ) : (
                        <Button
                          sx={{ marginLeft: "18px" }}
                          variant="outlined"
                          color="primary"
                          onClick={() => navigate(-1)}
                        >
                          Cancelar
                        </Button>
                      )}
                      {userCanDelete ? (
                        <Button
                          sx={{ marginLeft: "18px" }}
                          variant="contained"
                          color="error"
                          onClick={handleClickEliminar}
                        >
                          Eliminar
                        </Button>
                      ) : (
                        <HtmlTooltip
                          title={
                            <Typography variant="body1">
                              Unauthorized access.{" "}
                              <b>delete:novelty-type-company</b> permission
                              needed
                            </Typography>
                          }
                        >
                          <span>
                            <Button
                              sx={{ marginLeft: "18px" }}
                              variant="contained"
                              color="error"
                              onClick={handleClickEliminar}
                              disabled
                            >
                              Eliminar
                            </Button>
                          </span>
                        </HtmlTooltip>
                      )}
                      {/*isUserReadOnly ? (
                        <HtmlTooltip
                          title={
                            <Typography variant="body1">
                              Unauthorized access.{" "}
                              <b>delete:novelty-type-company</b> permission
                              needed
                            </Typography>
                          }
                        >
                          <span>
                            <Button
                              sx={{ marginLeft: "18px" }}
                              variant="contained"
                              color="error"
                              onClick={handleClickEliminar}
                              disabled
                            >
                              Eliminar
                            </Button>
                          </span>
                        </HtmlTooltip>
                      ) : (
                        <Button
                          sx={{ marginLeft: "18px" }}
                          variant="contained"
                          color="error"
                          onClick={handleClickEliminar}
                        >
                          Eliminar
                        </Button>
                      )*/}
                    </Box>
                  </Grid>
                </Box>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>

      {openErrorModal && (
        <InformationModal
          title="Ha ocurrido un error"
          message={errorMessage}
          onClose={handleCloseModal}
          openModal={openErrorModal}
          clickCancelar={false}
          clickEliminar={false}
          guardar={false}
          isDirty={false}
          errorMessage
        />
      )}

      {openInformationModal && (
        <InformationModal
          openModal={openInformationModal}
          clickCancelar={clickCancelar}
          clickEliminar={clickEliminar}
          guardar={clickGuardar}
          title={modalTitle}
          message={modalMessage}
          isDirty={isDirty}
          onClose={() => handleCloseModal()}
          onEliminar={() => handleRemove()}
          handleDeshacerCancelar={handleDeshacerCancelar}
          saveForm={handleSaveButton}
          disabledOnSubmit={disabledOnSubmit}
        />
      )}
    </Layout>
  );
};

export default EmpresaTipoNovedadDetails;
