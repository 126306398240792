import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import SearchBar from "material-ui-search-bar";

import FetchErrorMessage from "../../FetchErrorMessage/FetchErrorMessage";

import { getPeriodType } from "../../../services/CompaniesService";

import { useParametrosStore } from "../../../store/parametrosStore";

const durationArray = [
  {
    id: 0,
    value: "Mensual",
  },
  {
    id: 1,
    value: "Quincenal",
  },
];

const BackGround = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

type PeriodosListType = {
  handleSelectPeriodo: (fieldName: string, userId: string) => void;
  handleSetPeriodo: (periodo: any) => void;
};

const PeriodosList: React.FC<PeriodosListType> = ({
  handleSelectPeriodo,
  handleSetPeriodo,
}) => {
  const [periodosList, setPeriodosList] = React.useState<any[]>([]);
  const [originalPeriodosList, setOriginalPeriodosList] = React.useState<any[]>(
    []
  );
  const [searched, setSearched] = React.useState<string>("");
  const [selectedId, setSelectedId] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [fetchPeriodTypeError, setFetchPeriodTypeError] =
    React.useState<boolean>(false);
  const [fetchPeriodTypeErrorMessage, setFetchPeriodTypeErrorMessage] =
    React.useState<string>("");

  const { getAccessTokenSilently } = useAuth0();

  const setAllPeriodos = useParametrosStore((state) => state.setAllPeriodos);

  const getPeriod = React.useCallback(async () => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    getPeriodType(token)
      .then((result) => {
        setPeriodosList(result.data);
        setOriginalPeriodosList(result.data);
        setAllPeriodos(result.data);
        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e.response.data.error);
        setFetchPeriodTypeError(true);
        setFetchPeriodTypeErrorMessage(e.response.data.error);
        setPeriodosList([]);
        setOriginalPeriodosList([]);
        setAllPeriodos([]);
        setIsLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const requestSearch = (searchedVal: string) => {
    const newPeriodosList = [...originalPeriodosList];
    if (searchedVal.length > 2) {
      const filteredByName = newPeriodosList.filter((user) => {
        return user?.name?.toLowerCase().includes(searchedVal.toLowerCase());
      });

      if (filteredByName.length > 0) {
        setPeriodosList(filteredByName);
      } else {
        setPeriodosList([]);
      }
    } else {
      setPeriodosList(originalPeriodosList);
    }
  };

  const handleListItemClick = (periodo: any) => {
    setSelectedId(periodo.id);
    handleSelectPeriodo("periodo_id", periodo.id);
    handleSetPeriodo(periodo);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  React.useLayoutEffect(() => {
    getPeriod();
  }, [getPeriod]);

  return (
    <>
      {!isLoading && periodosList.length > 0 && (
        <Box sx={{ paddingBottom: "24px" }}>
          <SearchBar
            value={searched}
            onChange={(searchVal: string) => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            placeholder="Buscar tipo de periodo"
            style={{
              boxShadow: "none",
              borderRadius: "6px",
              backgroundColor: "rgba(0, 0, 0, 0.06)",
              height: "54px",
            }}
          />
        </Box>
      )}
      <Box sx={{ flexGrow: 1, maxWidth: 752 }}>
        <BackGround
          sx={{
            "& .MuiList-root>div:nth-of-type(odd)": {
              backgroundColor: "#F2F2F2",
            },
          }}
        >
          {isLoading && (
            <Box display="flex" justifyContent="center" pt={4}>
              <CircularProgress />
            </Box>
          )}
          {!isLoading && periodosList.length > 0 ? (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                paddingX="24px"
                paddingY="18px"
                bgcolor="#EEF1FC"
                sx={{
                  placeItems: "center",
                }}
              >
                <Typography fontWeight="600">Nombre</Typography>
                <Typography fontWeight="600">Duración</Typography>
                <Typography fontWeight="600">Día limite novedades</Typography>
              </Box>
              <List>
                {periodosList.map((periodo) => {
                  return (
                    <Box key={periodo.id}>
                      <ListItem disablePadding>
                        <ListItemButton
                          selected={selectedId === periodo.id}
                          onClick={() => handleListItemClick(periodo)}
                          sx={{
                            "&.Mui-selected": {
                              backgroundColor: "#AAC1E6 !important",
                            },
                          }}
                        >
                          <Grid container>
                            <Grid item xs={4}>
                              <ListItemText
                                sx={{ paddingY: "16px" }}
                                primary={periodo.name}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <ListItemText
                                sx={{ paddingY: "16px" }}
                                primary={durationArray[periodo.duration].value}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <ListItemText
                                sx={{ paddingY: "16px" }}
                                primary={periodo.day_limit}
                              />
                            </Grid>
                          </Grid>
                        </ListItemButton>
                      </ListItem>
                      <Divider />
                    </Box>
                  );
                })}
              </List>
            </>
          ) : !isLoading && fetchPeriodTypeError ? (
            <FetchErrorMessage errorMessage={fetchPeriodTypeErrorMessage} />
          ) : (
            !isLoading && (
              <Box
                display="flex"
                justifyContent="space-between"
                paddingX="24px"
                paddingY="18px"
                bgcolor="#EEF1FC"
                sx={{
                  placeItems: "center",
                }}
              >
                <Typography fontWeight="600">
                  No se encuentraron periodos con ese nombre
                </Typography>
              </Box>
            )
          )}
        </BackGround>
      </Box>
    </>
  );
};

export default PeriodosList;
