import React, { useCallback, useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import { useAuth0 } from "@auth0/auth0-react";
import dayjs from "dayjs";

import Box from "@mui/material/Box";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import SearchBar from "material-ui-search-bar";

import { MaterialIcons } from "../../icons/Material/MaterialIcons";
import Filtros from "../Modals/Filtros/Filtros";
import Layout from "../Layout/Layout";
import DataTable from "../table/DataTable";

import { useEmpresaStore } from "../../store/empresaStore";
// import { useUsersStore } from "../../store/usersStore";

import {
  getHistoricCompanyNovelties,
  getHistoricCompanyNoveltiesByPeriodId,
} from "../../services/CompaniesService";
import { getPastPeriods } from "../../services/ParametersService";

import { CompanyPeriodsType } from "../../types";

const statuses = [
  { id: 1, value: 0, label: "Pendiente de Procesar" },
  { id: 2, value: 1, label: "Inactivo" },
  { id: 3, value: 2, label: "Cancelado" },
  { id: 4, value: 3, label: "Bloqueado" },
  { id: 5, value: 4, label: "Error en Proceso" },
  { id: 6, value: 5, label: "Procesado Exitosamente" },
];

const tableColumnsName = [
  "Fecha Alta",
  "Fecha Procesamiento",
  "Usuario",
  "Estado",
];

const tableFieldsName = ["created_at", "process_date", "user", "status"];

//1: success, 2:pending, 3:cancel
const getAction = (params: GridRenderCellParams) => {
  return (
    <Box
      sx={{
        display: "flex",
        placeItems: "center",
        justifyContent: "flex-end",
        width: "100%",
      }}
    >
      <MaterialIcons.VisibilityOutlined />
    </Box>
  );
};

//0: Active, 1:Inactive, 2:Cancel, 3:Block, 4:Error
const getStatus = (params: GridRenderCellParams) => {
  if (params.value === 0 || params.value) {
    return (
      <Box
        key={statuses[params.value]?.id}
        sx={{
          display: "flex",
          placeItems: "center",
          width: "100%",
        }}
      >
        {/* <MaterialIcons.BriefcaseCheck color="success" fontSize="small" /> */}
        <Typography marginLeft="8px">
          {statuses[params.value]?.label}
        </Typography>
      </Box>
    );
  } else {
    return (
      <Box
        key={statuses[0]?.id}
        sx={{
          display: "flex",
          placeItems: "center",
          width: "100%",
        }}
      >
        {/* <MaterialIcons.BriefcaseCheck color="success" fontSize="small" /> */}
        <Typography marginLeft="8px">Status undefined</Typography>
      </Box>
    );
  }
};

const Historial = () => {
  const [searched, setSearched] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [history, setHistory] = useState<any[]>([]);
  const [originalHistory, setOriginalHistory] = useState<any[]>([]);
  const [empresaPeriodos, setEmpresaPeriodos] = useState<CompanyPeriodsType[]>(
    []
  );
  const [empresaPeriodo, setEmpresaPeriodo] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [filterValues, setFilterValues] = useState<Record<string, string>>({});
  const [openFiltersModal, setOpenFilterModal] = useState<boolean>(false);
  const [isFiltering, setIsFiltering] = useState<boolean>(false);

  const { getAccessTokenSilently } = useAuth0();

  // const users = useUsersStore((state) => state.users, shallow);

  const handleOpenModal = (open: boolean) => {
    setOpenFilterModal(open);
  };

  const handleClearFilterButton = () => {
    setFilterValues({});
    setHistory(originalHistory);
    setIsFiltering(false);
    handleOpenModal(false);
  };

  const handleSetFilter = (newFilter: any) => {
    setHistory(newFilter);
  };

  const handleFilterWithButton = (value: string, name: string) => {
    setFilterValues({ ...filterValues, [name]: value });
  };

  const handleOnFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFilterValues({ ...filterValues, [name]: value });
  };

  const handleOnFilterDateChange = (date: string, name: string) => {
    if (date.toLowerCase() === "invalid date") {
      setFilterValues({ ...filterValues, [name]: "" });
    } else {
      setFilterValues({ ...filterValues, [name]: date });
    }
  };

  const applyFilters = () => {
    const dateFields = ["created_at", "process_date"];

    if (Object.keys(filterValues).length > 0) {
      const values = Object.values(filterValues);
      if (values.every((value) => !value)) {
        setIsFiltering(false);
      } else {
        const filteredRows: any = originalHistory.filter((row: any) => {
          for (const key in filterValues) {
            if (key === "user") {
              if (
                row[key]?.email
                  ?.toString()
                  .toLowerCase()
                  .includes(filterValues[key].toLowerCase())
              ) {
                continue;
              } else {
                return false;
              }
            }
            if (dateFields.indexOf(key) !== -1) {
              if (dayjs(row[key]).format("DD/MM/YYYY") === filterValues[key]) {
                continue;
              } else {
                return false;
              }
            }
            if (filterValues[key]) {
              if (
                row[key]
                  ?.toString()
                  .toLowerCase()
                  .includes(filterValues[key].toString().toLowerCase())
              ) {
                continue;
              } else {
                return false;
              }
            }
          }
          return true;
        });
        setIsFiltering(true);
        handleSetFilter(filteredRows);
      }
    } else {
      setIsFiltering(false);
    }
    handleOpenModal(false);
  };

  const getPeriodo = (params: GridRenderCellParams) => {
    const periodos = [...returnEmpresaPeriodos()];
    if (params.value) {
      const periodo = periodos.find((period) => period.id === params.value)!;
      return periodo ? periodo.name : "Period not found";
    }
  };

  /* const getCreatedByUser = (userId: string) => {
    const allUsers = [...users];
    if (userId) {
      const user = allUsers.find((user) => user.id === userId)!;
      return user ? user.email : "User not found";
    }
  }; */

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Novedad",
      width: 150,
      editable: true,
      headerClassName: "theme--header",
      flex: 1,
    },
    {
      field: "period_id",
      headerName: "Periodo",
      headerClassName: "theme--header",
      flex: 1,
      renderCell: getPeriodo,
    },
    {
      field: "created_at",
      headerName: "Fecha Alta",
      type: "dateTime",
      headerClassName: "theme--header",
      flex: 1,
      // valueGetter: ({ value }) => value && dayjs(value).format("DD/MM/YYYY"),
      valueFormatter: ({ value }) => value && dayjs(value).format("DD/MM/YYYY"),
    },
    {
      field: "process_date",
      headerName: "Fecha Procesamiento",
      type: "dateTime",
      headerClassName: "theme--header",
      flex: 1,
      // valueGetter: ({ value }) => value && dayjs(value).format("DD/MM/YYYY"),
      valueFormatter: ({ value }) => value && dayjs(value).format("DD/MM/YYYY"),
    },
    {
      field: "user",
      headerName: "Usuario",
      headerClassName: "theme--header",
      renderCell(params) {
        return params.value.email;
        // return getCreatedByUser(params.value);
      },
      flex: 1,
    },
    {
      field: "status",
      headerName: "Estado",
      type: "number",
      headerClassName: "theme--header",
      renderCell: getStatus,
      flex: 1,
      headerAlign: "left",
    },
    {
      field: "action",
      headerName: "Acción",
      type: "number",
      headerClassName: "theme--header",
      renderCell: getAction,
      flex: 1,
    },
  ];

  const { returnEmpresaPeriodos, empresaId } = useEmpresaStore(
    ({ returnEmpresaPeriodos, empresaId }) => ({
      returnEmpresaPeriodos,
      empresaId,
    }),
    shallow
  );

  const requestSearch = (searchedVal: string) => {
    const searchHistory = [...originalHistory];
    if (searchedVal.length > 2) {
      const filteredRows = searchHistory.filter((row) => {
        return row.name.toLowerCase().includes(searchedVal.toLowerCase());
      });
      setHistory(filteredRows);
    } else {
      setHistory(originalHistory);
    }
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const obtenerPastPeriods = useCallback(async () => {
    setIsLoading(true);
    setEmpresaPeriodos([]);
    setEmpresaPeriodo("");
    const token = await getAccessTokenSilently();
    getPastPeriods(token, empresaId)
      .then((result) => {
        setEmpresaPeriodos(result.data);
        setEmpresaPeriodo(result.data[0].id);
      })
      .catch((e) => {
        console.log("error", e.response.data.error);
        setEmpresaPeriodos([]);
        setEmpresaPeriodo("");
        setHistory([]);
        setOriginalHistory([]);
        setIsLoading(false);
      });

    // const result = await getCompanyNovelties(token, empresaId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getHistoryByPeriodId = useCallback(async (periodId: string) => {
    setIsLoading(true);
    setHistory([]);
    setOriginalHistory([]);
    const token = await getAccessTokenSilently();
    getHistoricCompanyNoveltiesByPeriodId(token, empresaId, periodId)
      .then((result) => {
        setHistory(result.data);
        setOriginalHistory(result.data);
        setErrorMessage("");
        setIsLoading(false);
      })
      .catch((e) => {
        console.log("error", e.response.data.error);
        setErrorMessage(e.response.data.error);
        setHistory([]);
        setOriginalHistory([]);
        setIsLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePeriod = (periodId: string) => {
    setEmpresaPeriodo(periodId);
    getHistoryByPeriodId(periodId);
  };

  const getHistory = useCallback(async () => {
    setIsLoading(true);
    setHistory([]);
    setOriginalHistory([]);
    const token = await getAccessTokenSilently();
    getHistoricCompanyNovelties(token, empresaId)
      .then((result) => {
        setHistory(result.data);
        setOriginalHistory(result.data);
        setErrorMessage("");
        setIsLoading(false);
      })
      .catch((e) => {
        console.error("getHistory - error", e.response.data.error);
        setErrorMessage(e.response.data.error);
        setHistory([]);
        setOriginalHistory([]);
        setIsLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    obtenerPastPeriods();
    getHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresaId]);

  return (
    <Layout icon="AccessTimeIcon" title="Historial">
      <Box
        sx={{
          height: "100%",
          "& .theme--header": {
            backgroundColor: "#EEF1FC",
          },
        }}
      >
        <Grid container spacing={2} sx={{ py: "24px", placeItems: "center" }}>
          <Grid display="flex" sx={{ placeItems: "center" }} item xs={6}>
            <Box sx={{ marginRight: 4, width: "459px" }}>
              <SearchBar
                value={searched}
                onChange={(searchVal: string) => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
                placeholder="Buscar Novedad"
                style={{ boxShadow: "none" }}
              />
            </Box>
            <Box sx={{ display: "flex", placeItems: "center" }}>
              <Typography
                sx={{
                  color: "black",
                  fontSize: "18px",
                  fontWeight: 500,
                  marginRight: "23px",
                }}
              >
                Periodo:
              </Typography>
              <TextField
                id="history-periodo-select"
                select
                fullWidth
                name="period_id"
                onChange={(
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >
                ) => handleChangePeriod(event.target.value)}
                value={empresaPeriodo}
                variant="filled"
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    backgroundColor: "white",
                    borderRadius: "6px",
                    ".MuiInputBase-input": {
                      paddingY: "12px",
                      display: "flex",
                      justifyContent: "flex-start",
                    },
                  },
                }}
              >
                {empresaPeriodos.map((periodo) => (
                  <MenuItem key={periodo.id} value={periodo.id}>
                    {periodo.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Grid container display="flex" justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  startIcon={<MaterialIcons.FilterAltIcon />}
                  onClick={() => setOpenFilterModal(true)}
                  sx={{
                    backgroundColor: isFiltering ? "#182F84" : "white",
                    color: isFiltering ? "white" : "black",
                    "&:hover": { color: "white" },
                  }}
                >
                  Filtro
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <DataTable
          columns={columns}
          rows={history}
          navigateRowLink="/historial/novedad"
          noFilterRowsMessage="No hay novedades con ese nombre"
          noRowsMessage={errorMessage || "No hay historial de novedades"}
          loading={isLoading}
          sortModel={[{ field: "created_at", sort: "desc" }]}
        />

        {openFiltersModal && (
          <Filtros
            filterValues={filterValues}
            openModal={openFiltersModal}
            handleOpenModal={handleOpenModal}
            handleFilterButton={() => applyFilters()}
            handleClearFilterButton={() => handleClearFilterButton()}
            handleFilters={handleOnFilterChange}
            handleFilterDate={handleOnFilterDateChange}
            handleFilterWithButton={handleFilterWithButton}
            tableColumnsName={tableColumnsName}
            tableFieldsName={tableFieldsName}
            historicStatuses={statuses}
            filtersTo="Historial"
          />
        )}
      </Box>
    </Layout>
  );
};

export default Historial;
