import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { shallow } from "zustand/shallow";

import { UserContext } from "./UserContext";

import { useUserStore } from "../../store/userStore";

type UserProviderType = {
  children: ReactNode;
};

export const UserProvider = ({ children }: UserProviderType) => {
  const [firstLogin, setFirstLogin] = useState<number>(0);
  const [refreshUserEmpresa, setRefreshUserEmpresas] = useState<boolean>(false);

  const { user, getAccessTokenSilently } = useAuth0();

  const userData = useUserStore((state) => state.user, shallow);

  const getUserData = useUserStore((state) => state.getUserData);

  const loadUserData = useCallback(async () => {
    const token = await getAccessTokenSilently();
    await getUserData(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user || refreshUserEmpresa) {
      setRefreshUserEmpresas(false);
      loadUserData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshUserEmpresa, user]);

  return (
    <UserContext.Provider
      value={{
        user: userData,
        firstLogin,
        setFirstLogin,
        setRefreshUserEmpresas,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
