import React from "react";
import { useParams } from "react-router-dom";

import PeriodoDetails from "../../components/Details/PeriodoDetails/PeriodoDetails";

const PeriodoDetailsPage = () => {
  const params = useParams();
  return <PeriodoDetails params={params} />;
};

export default PeriodoDetailsPage;
