import React, { useEffect } from "react";

import Usuarios from "../components/Users/Users";

const UsersPage = () => {
  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - Usuarios`;
  }, []);

  return <Usuarios />;
};

export default UsersPage;
