import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { shallow } from "zustand/shallow";
// import { useHasPermissions, useHasRoles } from "react-simple-rbac";

import SearchBar from "material-ui-search-bar";

import Box from "@mui/material/Box";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import Layout from "../Layout/Layout";
import DataTable from "../table/DataTable";

import { MaterialIcons } from "../../icons/Material/MaterialIcons";

import { useDrawerStore } from "../../store/drawerStore";
import { useEmpresaStore } from "../../store/empresaStore";
import { useUserStore } from "../../store/userStore";

import { EmpresasRowsType } from "../../types";
import HtmlTooltip from "../HtmlTooltip/HtmlTooltip";

//1: success, 2:pending, 3:cancel
const getAction = () => {
  return (
    <Box
      sx={{
        display: "flex",
        placeItems: "center",
        justifyContent: "flex-end",
        width: "100%",
      }}
    >
      <MaterialIcons.EditIcon />
    </Box>
  );
};

//0: active, 1:inactive
const getStatus = (params: GridRenderCellParams) => {
  if (params.value === 0) {
    return "Activo";
  }

  if (params.value === 1) {
    return "Inactivo";
  }
};

const columns: GridColDef[] = [
  // {
  //   field: "id",
  //   headerName: "ID",
  //   headerClassName: "theme--header",
  //   flex: 0.4,
  // },
  {
    field: "name",
    headerName: "Nombre",
    editable: true,
    headerClassName: "theme--header",
    flex: 1,
  },
  {
    field: "status",
    headerName: "Estado",
    editable: true,
    headerClassName: "theme--header",
    flex: 1,
    renderCell: getStatus,
  },
  {
    field: "action",
    headerName: "Acción",
    type: "number",
    editable: false,
    headerClassName: "theme--header",
    renderCell: getAction,
    flex: 1,
  },
];

const Empresas = () => {
  const [searched, setSearched] = useState<string>("");
  const [companies, setCompanies] = useState<EmpresasRowsType[]>([]);
  const [originalCompanies, setOriginalCompanies] = useState<
    EmpresasRowsType[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  const returnEmpresas = useEmpresaStore(
    (state) => state.returnEmpresas,
    shallow
  );

  const firstLogin = useDrawerStore((state) => state.firstLogin, shallow);

  const isAdmin = useUserStore((state) => state.user?.role, shallow);

  const setFirstLogin = useDrawerStore((state) => state.setFirstLogin);

  const getAllEmpresas = useEmpresaStore((state) => state.getAllEmpresas);

  const { hasFetchError, errorMessage } = useEmpresaStore(
    ({ hasFetchError, errorMessage }) => ({
      hasFetchError,
      errorMessage,
    }),
    shallow
  );

  // const isAdminOrManager = useHasRoles(["admin", "manager"], true);
  // const userCanCreate = useHasPermissions(["create_company"]);

  const canCreate = isAdmin === 2;

  const requestSearch = (searchedVal: string) => {
    const searchCompanies = [...originalCompanies];
    if (searchedVal.length > 2) {
      const filteredRows = searchCompanies.filter((company) => {
        return company.name.toLowerCase().includes(searchedVal.toLowerCase());
      });
      setCompanies(filteredRows);
    } else {
      setCompanies(originalCompanies);
    }
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const addCompany = () => {
    navigate("/empresas/empresa/crear empresa");
  };

  const getCompanies = useCallback(async () => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    await getAllEmpresas(token);
    setCompanies(returnEmpresas());
    setOriginalCompanies(returnEmpresas());
    if (firstLogin) {
      setFirstLogin(false);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCompanies();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout icon="HomeWorkOutlinedIcon" title="Empresas">
      <Box
        sx={{
          height: "100%",
          "& .theme--header": {
            backgroundColor: "#EEF1FC",
          },
        }}
      >
        <Grid container spacing={2} sx={{ py: "24px", placeItems: "center" }}>
          <Grid display="flex" sx={{ placeItems: "center" }} item xs={6}>
            <Box sx={{ marginRight: 4, width: "459px" }}>
              <SearchBar
                value={searched}
                onChange={(searchVal: string) => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
                placeholder="Buscar Empresa"
                style={{ boxShadow: "none" }}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Grid container display="flex" justifyContent="flex-end">
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  startIcon={<MaterialIcons.FilterAltIcon />}
                  sx={{
                    backgroundColor: "white",
                    color: "black",
                    "&:hover": { color: "white" },
                  }}
                >
                  Filtrar
                </Button>
                {canCreate ? (
                  <Button
                    id="add-company-button"
                    variant="contained"
                    startIcon={<MaterialIcons.HomeWorkOutlinedIcon />}
                    color="primary"
                    onClick={() => addCompany()}
                  >
                    Crear empresa
                  </Button>
                ) : (
                  <HtmlTooltip
                    title={<b>No tienes permisos para crear empresas</b>}
                  >
                    <span>
                      <Button
                        id="add-company-disabled-button"
                        variant="contained"
                        startIcon={<MaterialIcons.HomeWorkOutlinedIcon />}
                        color="primary"
                        disabled
                        onClick={() => addCompany()}
                      >
                        Crear empresa
                      </Button>
                    </span>
                  </HtmlTooltip>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <DataTable
          columns={columns}
          rows={companies}
          navigateRowLink="/empresas/empresa"
          loading={isLoading}
          noFilterRowsMessage="No existen empresas con ese nombre"
          noRowsMessage={hasFetchError ? errorMessage : "No hay empresas"}
        />
      </Box>
    </Layout>
  );
};

export default Empresas;
