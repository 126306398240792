import React, { useEffect } from "react";

import SelectCompany from "../components/Selectcompany/SelectCompany";

const SelectCompanyPage = () => {
  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - Elegir empresa`;
  }, []);

  return <SelectCompany />;
};

export default SelectCompanyPage;
