import React, { useContext, useEffect, useState } from "react";
import { isEqual } from "lodash";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { shallow } from "zustand/shallow";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import CustomBreadcrumbs from "../CustomBreadcrumbs/CustomBreadcrumbs";
import HtmlTooltip from "../HtmlTooltip/HtmlTooltip";
import InformationModal from "../Modals/ConfirmationModal/InformationModal";
import Layout from "../Layout/Layout";

import {
  deletePeriod,
  editPeriod,
  getPeriodById,
} from "../../services/ParametersService";

import { DrawerContext } from "../../context/drawer/DrawerContext";

import { useUserStore } from "../../store/userStore";

const periodsBefore = [
  {
    id: 1,
    value: "1",
  },
  {
    id: 2,
    value: "2",
  },
  {
    id: 3,
    value: "3",
  },
  {
    id: 4,
    value: "4",
  },
  {
    id: 5,
    value: "5",
  },
];

const durationArray = [
  {
    id: 0,
    value: "Mensual",
  },
  {
    id: 1,
    value: "Quincenal",
  },
];

type EmpresaTipoPeriodoDetailsType = {
  params: any;
};

const EmpresaTipoPeriodoDetails: React.FC<EmpresaTipoPeriodoDetailsType> = ({
  params,
}) => {
  const [previusState, setPreviusState] = useState({
    name: "",
    duration: 1,
    day_limit: "",
    periods_before: "",
  });
  const [openInformationModal, setOpenInformationModal] =
    useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [clickEliminar, setClickEliminar] = useState<boolean>(false);
  const [clickGuardar, setClickGuardar] = useState<boolean>(false);
  const [clickCancelar, setClickCancelar] = useState<boolean>(false);
  const [disabledOnSubmit, setDisabledOnSubmit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [modalMessage, setModalMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  const { changeLink } = useContext(DrawerContext);

  const { empresaId, periodoId } = params;

  const userDataStore = useUserStore((state) => state.user?.role, shallow);

  const isUserReadOnly = userDataStore === 6;

  const formik = useFormik({
    initialValues: {
      name: "",
      duration: 1,
      day_limit: "",
      periods_before: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().max(55).required("El nombre es requerido"),
      duration: Yup.number().max(365).required("La duración es requerida"),
      day_limit: Yup.number()
        .lessThan(1, "Debe ser un número igual o menor a cero (0)")
        .required("El dia de límite es requerido"),
    }),
    onSubmit: async (data) => {
      setDisabledOnSubmit(true);
      const token = await getAccessTokenSilently();
      editPeriod(token, data, periodoId)
        .then((result) => {
          if (result.status === 201 || result.status === 200) {
            handleCloseModal();
            setDisabledOnSubmit(false);
            navigate(-1);
          }
        })
        .catch((e) => {
          console.error(e.response.dara.error);
          setErrorMessage(e.response.data.error);
          setDisabledOnSubmit(false);
          setOpenErrorModal(true);
        });
    },
  });

  const deletePeriodByID = async () => {
    setDisabledOnSubmit(true);
    const token = await getAccessTokenSilently();
    deletePeriod(token, periodoId)
      .then((result) => {
        if (result.status === 201 || result.status === 200) {
          navigate(-1);
          handleCloseModal();
          setDisabledOnSubmit(false);
        }
      })
      .catch((e) => {
        console.error(e.response.dara.error);
        setErrorMessage(e.response.data.error);
        setDisabledOnSubmit(false);
        setOpenErrorModal(true);
      });
  };

  const handleClickGuardar = () => {
    setIsDirty(false);
    setClickCancelar(false);
    setClickGuardar(true);
    setModalTitle("Guardar tipo de periodo");
    setModalMessage(
      "¿Querés guardar los cambios realizados a éste tipo de periodo?"
    );
    handleOpenModal();
  };

  const handleClickDirtyEliminar = () => {
    setIsDirty(false);
    setClickGuardar(false);
    setClickCancelar(false);
    setClickEliminar(true);
    setModalTitle("Eliminar tipo de periodo");
    setModalMessage("¿Querés eliminar éste tipo de periodo?");
    handleOpenModal();
  };

  const handleOpenModal = () => {
    setOpenInformationModal(true);
  };

  const handleCloseModal = () => {
    setOpenInformationModal(false);
    openErrorModal && setOpenErrorModal(false);
  };

  const handleDeshacerCancelar = () => {
    formik.resetForm();
    navigate(-1);
  };

  const handleClickDirtyCancelar = () => {
    setIsDirty(true);
    setClickGuardar(false);
    setClickEliminar(false);
    setClickCancelar(true);
    setModalTitle("Cancelar cambios");
    setModalMessage(
      "¿Querés cancelar los cambios realizados a éste tipo de periodo?"
    );
    handleOpenModal();
  };

  const handleSaveButton = () => {
    formik.submitForm();
  };

  const links: any[] = [
    { id: "1", value: "Empresas", isLink: true, to: "/empresas" },
    {
      id: "2",
      value: "Empresa",
      isLink: true,
      to: `/empresas/empresa/${empresaId}`,
    },
    {
      id: "3",
      value: "Parametros",
      isLink: true,
      to: `/empresas/empresa/${empresaId}/parametros`,
    },
    { id: "4", value: "Tipo de Periodo", isLink: false },
  ];

  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - ${formik.values.name} - Detalles`;
  }, [formik.values.name]);

  useEffect(() => {
    const getPeriod = async () => {
      setIsLoading(true);
      const token = await getAccessTokenSilently();
      getPeriodById(token, periodoId)
        .then((result) => {
          formik.setValues({
            name: result.data.name,
            duration: result.data.duration,
            day_limit: result.data.day_limit.toString(),
            periods_before: result.data.periods_before.toString(),
          });
          setPreviusState({
            name: result.data.name,
            duration: result.data.duration,
            day_limit: result.data.day_limit.toString(),
            periods_before: result.data.periods_before.toString(),
          });
          setIsLoading(false);
        })
        .catch((e) => {
          console.error("error", e.response.data.error);
          setIsLoading(false);
        });
    };

    getPeriod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodoId]);

  return (
    <Layout noIcon title="Tipo de periodo">
      {isLoading ? (
        <Box
          sx={{
            borderRadius: "6px",
            display: "flex",
            backgroundColor: "white",
            height: "303px",
            width: "723px",
            paddingRight: "18px",
            marginTop: "36px",
            placeContent: "center",
            placeItems: "center",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Box
              borderRadius="6px"
              sx={{
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <CustomBreadcrumbs icon="PollIcon" links={links} />
          <Grid container>
            <Grid bgcolor="white" item xs={8} borderRadius="6px">
              <Box
                sx={{
                  borderRadius: "6px",
                  display: "flex",
                  backgroundColor: "white",
                  height: "100%",
                  width: "100%",
                  paddingRight: "18px",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Box
                    component="form"
                    autoComplete="off"
                    borderRadius="6px"
                    sx={{ backgroundColor: "white", height: "100%" }}
                    onSubmit={formik.handleSubmit}
                  >
                    <Grid container justifyContent="space-between">
                      <Grid item xs={6} sx={{ pt: "18px", pl: "18px" }}>
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          marginBottom="4px"
                        >
                          <InputLabel
                            sx={{ color: "black" }}
                            htmlFor="nombre-input"
                          >
                            Nombre
                          </InputLabel>
                        </Box>
                        <TextField
                          id="nombre-input"
                          error={Boolean(
                            formik.touched.name && formik.errors.name
                          )}
                          fullWidth
                          helperText={formik.touched.name && formik.errors.name}
                          name="name"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          type="text"
                          value={formik.values.name}
                          variant="filled"
                          placeholder="Nombre del periodo"
                          InputProps={{
                            disableUnderline: true,
                            readOnly: changeLink === 2 || isUserReadOnly,
                            sx: {
                              borderRadius: "6px",
                              ".MuiInputBase-input": {
                                paddingBottom: "16px",
                                paddingTop: "16px",
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} sx={{ pt: "18px", pl: "18px" }}>
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          marginBottom="4px"
                        >
                          <InputLabel
                            sx={{ color: "black" }}
                            htmlFor="duration'input"
                          >
                            Duración
                          </InputLabel>
                        </Box>
                        <TextField
                          id="duration-select"
                          select
                          error={Boolean(
                            formik.touched.duration && formik.errors.duration
                          )}
                          fullWidth
                          helperText={
                            formik.touched.duration && formik.errors.duration
                          }
                          name="duration"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          type="number"
                          value={formik.values.duration}
                          variant="filled"
                          InputProps={{
                            disableUnderline: true,
                            readOnly: changeLink === 2 || isUserReadOnly,
                            sx: {
                              borderRadius: "6px",
                              ".MuiInputBase-input": {
                                paddingBottom: "16px",
                                paddingTop: "16px",
                                display: "flex",
                                justifyContent: "flex-start",
                              },
                            },
                          }}
                        >
                          {durationArray.map((duration) => (
                            <MenuItem key={duration.id} value={duration.id}>
                              {duration.value}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent="space-between"
                      paddingBottom="26px"
                    >
                      <Grid item xs={6} sx={{ py: "10px", pl: "18px" }}>
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          marginBottom="4px"
                        >
                          <InputLabel
                            sx={{ color: "black" }}
                            htmlFor="day-limit-input"
                          >
                            Día limite
                          </InputLabel>
                        </Box>
                        <TextField
                          id="day-limit-input"
                          error={Boolean(
                            formik.touched.day_limit && formik.errors.day_limit
                          )}
                          fullWidth
                          helperText={
                            formik.touched.day_limit && formik.errors.day_limit
                          }
                          name="day_limit"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          type="number"
                          value={formik.values.day_limit}
                          variant="filled"
                          InputProps={{
                            disableUnderline: true,
                            readOnly: changeLink === 2 || isUserReadOnly,
                            sx: {
                              borderRadius: "6px",
                              ".MuiInputBase-input": {
                                paddingBottom: "16px",
                                paddingTop: "16px",
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} sx={{ py: "10px", pl: "18px" }}>
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          marginBottom="4px"
                        >
                          <InputLabel
                            sx={{ color: "black" }}
                            htmlFor="periods-before-select"
                          >
                            Anticipo
                          </InputLabel>
                        </Box>
                        <TextField
                          id="periods-before-select"
                          select
                          error={Boolean(
                            formik.touched.periods_before &&
                              formik.errors.periods_before
                          )}
                          fullWidth
                          helperText={
                            formik.touched.periods_before &&
                            formik.errors.periods_before
                          }
                          name="periods_before"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.periods_before}
                          variant="filled"
                          InputProps={{
                            disableUnderline: true,
                            readOnly: changeLink === 2 || isUserReadOnly,
                            sx: {
                              borderRadius: "6px",
                              ".MuiInputBase-input": {
                                paddingBottom: "16px",
                                paddingTop: "16px",
                                display: "flex",
                                justifyContent: "flex-start",
                              },
                            },
                          }}
                        >
                          {periodsBefore.map((period) => (
                            <MenuItem key={period.id} value={period.value}>
                              {period.value}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                    {changeLink === 1 && (
                      <Grid container maxWidth="sm" paddingBottom="32px">
                        <Box sx={{ display: "flex" }}>
                          {!isUserReadOnly && (
                            <Button
                              sx={{ marginLeft: "18px" }}
                              variant="contained"
                              color="primary"
                              onClick={handleClickGuardar}
                              disabled={
                                !formik.dirty ||
                                !formik.values.name ||
                                !formik.values.day_limit ||
                                isEqual(previusState, formik.values)
                              }
                            >
                              Guardar
                            </Button>
                          )}

                          {formik.dirty &&
                          !isEqual(previusState, formik.values) ? (
                            <Button
                              sx={{ marginLeft: "18px" }}
                              variant="outlined"
                              color="primary"
                              onClick={handleClickDirtyCancelar}
                            >
                              Cancelar
                            </Button>
                          ) : (
                            <Button
                              sx={{ marginLeft: "18px" }}
                              variant="outlined"
                              color="primary"
                              onClick={() => navigate(-1)}
                            >
                              Cancelar
                            </Button>
                          )}
                          {isUserReadOnly ? (
                            <HtmlTooltip
                              title={
                                <Typography variant="body1">
                                  Unauthorized access.{" "}
                                  <b>delete:period-type-company</b> permission
                                  needed
                                </Typography>
                              }
                            >
                              <span>
                                <Button
                                  sx={{ marginLeft: "18px" }}
                                  variant="contained"
                                  onClick={() => handleClickDirtyEliminar()}
                                  disabled
                                >
                                  Eliminar
                                </Button>
                              </span>
                            </HtmlTooltip>
                          ) : (
                            <Button
                              sx={{ marginLeft: "18px" }}
                              variant="contained"
                              color="error"
                              onClick={() => handleClickDirtyEliminar()}
                            >
                              Eliminar
                            </Button>
                          )}
                        </Box>
                      </Grid>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      )}

      {openErrorModal && (
        <InformationModal
          title="Ha ocurrido un error"
          message={errorMessage}
          onClose={handleCloseModal}
          openModal={openErrorModal}
          clickCancelar={false}
          clickEliminar={false}
          guardar={false}
          isDirty={false}
          errorMessage
        />
      )}

      {openInformationModal && (
        <InformationModal
          title={modalTitle}
          message={modalMessage}
          openModal={openInformationModal}
          onClose={handleCloseModal}
          saveForm={handleSaveButton}
          isDirty={isDirty}
          clickCancelar={clickCancelar}
          handleDeshacerCancelar={handleDeshacerCancelar}
          guardar={clickGuardar}
          guardarMessage={modalMessage}
          clickEliminar={clickEliminar}
          onEliminar={deletePeriodByID}
          disabledOnSubmit={disabledOnSubmit}
        />
      )}
    </Layout>
  );
};

export default EmpresaTipoPeriodoDetails;
