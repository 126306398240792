import * as React from "react";

import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Fade from "@mui/material/Fade";

import CustomCircularProgress from "../../CustomCircularProgress/CustomCircularProgress";

type InformationModalType = {
  clickCancelar: boolean;
  clickEliminar: boolean;
  disabledOnSubmit?: boolean;
  errorMessage?: boolean;
  guardar: boolean;
  guardarButtonMessage?: string;
  guardarMessage?: string;
  html?: boolean;
  isDirty: boolean;
  isProcessingMessage?: string;
  processingErrorMessage?: string;
  message: string;
  showIcon?: boolean;
  successIcon?: boolean;
  openModal: boolean;
  title: string;

  handleDeshacerCancelar?: () => void;
  onClose: () => void;
  onEliminar?: () => void;
  saveForm?: () => void;
};

const DialogButton = styled(Button)(({ theme }) => ({
  width: "90px",
}));

const InformationModal: React.FC<InformationModalType> = ({
  clickCancelar,
  clickEliminar,
  disabledOnSubmit,
  errorMessage,
  guardar,
  handleDeshacerCancelar,
  html,
  isDirty,
  isProcessingMessage = "Procesando...",
  message,
  onClose,
  onEliminar,
  openModal,
  processingErrorMessage = "",
  saveForm,
  showIcon,
  successIcon,
  title,
}) => {
  const handleClose = () => {
    isDirty ? handleDeshacerCancelar && handleDeshacerCancelar() : onClose();
  };

  const handleEliminar = () => {
    onEliminar && onEliminar();
  };

  const handleSaveForm = () => {
    saveForm && saveForm();
  };

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            height: disabledOnSubmit ? "214px" : "auto",
            width: "382px",
            ...(disabledOnSubmit && {
              alignItems: "center",
              justifyContent: "center",
            }),
          },
        }}
      >
        {disabledOnSubmit ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Fade in={disabledOnSubmit}>
              <div>
                {showIcon ? (
                  <CustomCircularProgress
                    successIcon={successIcon || false}
                    processingErrorMessage={processingErrorMessage}
                    handleCloseButton={onClose}
                    isProcessingMessage={isProcessingMessage}
                  />
                ) : (
                  <CircularProgress />
                )}
              </div>
            </Fade>
          </Box>
        ) : (
          <>
            <Fade in={!disabledOnSubmit}>
              <div>
                <DialogTitle
                  id="alert-dialog-title"
                  sx={{
                    color: "#000000",
                    fontSize: "24px",
                    padding: "28px",
                    paddingBottom: "21px",
                  }}
                >
                  {title}
                </DialogTitle>

                <DialogContent sx={{ paddingX: "28px", paddingBottom: "0px" }}>
                  {html ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: message }}
                      style={{ color: "#333333", fontSize: "14px" }}
                    />
                  ) : (
                    <DialogContentText
                      id="alert-dialog-description"
                      sx={{ color: "#333333", fontSize: "14px" }}
                    >
                      {message}
                    </DialogContentText>
                  )}
                </DialogContent>

                {clickCancelar && isDirty && (
                  <DialogActions sx={{ padding: "18px", paddingTop: "27px" }}>
                    <DialogButton
                      color="primary"
                      variant="outlined"
                      onClick={onClose}
                      autoFocus
                    >
                      Cancelar
                    </DialogButton>
                    <DialogButton
                      color="primary"
                      variant="contained"
                      onClick={handleDeshacerCancelar}
                    >
                      Aceptar
                    </DialogButton>
                  </DialogActions>
                )}

                {errorMessage && (
                  <DialogActions sx={{ padding: "18px", paddingTop: "27px" }}>
                    <DialogButton
                      color="primary"
                      variant="contained"
                      onClick={onClose}
                      autoFocus
                    >
                      Aceptar
                    </DialogButton>
                  </DialogActions>
                )}

                {clickEliminar && (
                  <DialogActions sx={{ padding: "18px", paddingTop: "27px" }}>
                    <DialogButton
                      color="primary"
                      variant="outlined"
                      onClick={onClose}
                      disabled={disabledOnSubmit}
                    >
                      Cancelar
                    </DialogButton>
                    <DialogButton
                      color="primary"
                      variant="contained"
                      onClick={handleEliminar}
                      disabled={disabledOnSubmit}
                      autoFocus
                    >
                      Aceptar
                    </DialogButton>
                  </DialogActions>
                )}

                {guardar && (
                  <DialogActions sx={{ padding: "18px", paddingTop: "27px" }}>
                    <DialogButton
                      color="primary"
                      variant="outlined"
                      onClick={onClose}
                      disabled={disabledOnSubmit}
                    >
                      Cancelar
                    </DialogButton>
                    <DialogButton
                      color="primary"
                      variant="contained"
                      onClick={handleSaveForm}
                      disabled={disabledOnSubmit}
                      autoFocus
                    >
                      Aceptar
                    </DialogButton>
                  </DialogActions>
                )}
              </div>
            </Fade>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default InformationModal;
