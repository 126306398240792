import React, { Dispatch, SetStateAction, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import CommonUsersTable from "./CommonUsersTable";

import { CompanyUsersRowsType } from "../../../types";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ height: "100%" }}
      {...other}
    >
      {/* {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )} */}
      {value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    sx: { color: "black" },
  };
}

type UsersTableType = {
  setRefresTable: Dispatch<SetStateAction<boolean>>;
  refreshTable: boolean;
  inviteUser: () => void;
  setErrorMessage?: Dispatch<SetStateAction<string>>;
  users: CompanyUsersRowsType[];
  originalUsers: CompanyUsersRowsType[];
  setUsers: Dispatch<SetStateAction<CompanyUsersRowsType[]>>;
  isLoading: boolean;
  fetchUsersError?: boolean;
  fetchUsersErrorMessage?: string;
};

const UsersTable: React.FC<UsersTableType> = ({
  inviteUser,
  refreshTable,
  setRefresTable,
  setErrorMessage,
  users,
  originalUsers,
  setUsers,
  isLoading,
  fetchUsersError,
  fetchUsersErrorMessage
}) => {
  const [tabValue, setTabValue] = useState<number>(0);

  const handleChangeTab = (newTabValue: number) => {
    setTabValue(newTabValue);
  };

  return (
    <Box
      sx={{
        height: "100%",
        "& .theme--header": {
          backgroundColor: "#EEF1FC",
        },
        marginTop: "16px",
      }}
    >
      <Box>
        <Tabs
          value={tabValue}
          onChange={(_, newTabValue: number) => handleChangeTab(newTabValue)}
          aria-label="company tabs"
          TabIndicatorProps={{ sx: { backgroundColor: "black" } }}
          sx={{ "& button.Mui-selected": { color: "black" } }}
        >
          <Tab label="Usuarios" {...a11yProps(0)} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <CommonUsersTable
          inviteUser={inviteUser}
          refreshTable={refreshTable}
          setRefreshTable={setRefresTable}
          setErrorMessage={setErrorMessage}
          users={users}
          originalUsers={originalUsers}
          setUsers={setUsers}
          isLoading={isLoading}
          fetchUsersError={fetchUsersError}
          fetchUsersErrorMessage={fetchUsersErrorMessage}
        />
      </TabPanel>
    </Box>
  );
};

export default UsersTable;
