import React from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";

//MUI V4
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";

//MUI V5
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { MaterialIcons } from "../../icons/Material/MaterialIcons";

import NavBar from "./NavBar";
import BreadcrumbsBar from "./Breadcrumbs";

import { LayoutPropsType } from "../../types";

import { EmpresaContext } from "../../context/empresa/EmpresaContext";
import { UserContext } from "../../context/user/UserContext";
import { DrawerContext } from "../../context/drawer/DrawerContext";

import { ReactComponent as BDOIcon } from "../../icons/svg/bdo-icon.svg";

import "./layout.css";

const drawerWidth = 259;

const styles = createStyles((theme: Theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#EEF1FC",
    position: "absolute",
    height: "100%",
    width: "100%",
    // overflow: "scroll",
  },
  appBar: {
    zIndex: theme.zIndex.drawer,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 12,
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: "rotate(180deg)",
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: "rotate(0deg)",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: theme.zIndex.drawer + 1,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
    justifyContent: "flex-end",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: "100%",
    flexBasis: "auto",
    display: "flex",
    flexDirection: "column",
    maxWidth: "320rem",
    overflow: "hidden",
  },
  grow: {
    flexGrow: 1,
  },
  routeText: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-start",
    paddingLeft: "14px",
  },
}));

type LayoutStateType = {
  open: boolean;
  openEmpresaList: boolean;
  anchorEl: any;
  userType: any;
};

type DrawerItem = {
  id: number;
  label: string;
  path: string;
  icon: keyof typeof MaterialIcons;
  linksType?: number;
};

//this come from backend

const topLinks: DrawerItem[] = [
  {
    id: 1,
    label: "Gestión de cliente",
    path: "/gestiondecliente",
    icon: "AccountBalanceIcon",
    linksType: 1,
  },
  {
    id: 2,
    label: "Ajustes de portal",
    path: "/empresas",
    icon: "SettingsIcon",
    linksType: 2,
  },
];

class Layout extends React.Component<LayoutPropsType, LayoutStateType> {
  state = {
    open: false,
    openEmpresaList: false,
    anchorEl: null,
    userType: null,
  };

  static contextType = EmpresaContext;
  context!: React.ContextType<typeof EmpresaContext>;

  handleChangeEmpresas = (evt: any) => {
    const empresa = this.context.empresas?.find(
      (empresa) => empresa.id === evt.target.value
    );
    this.context.setSelectedEmpresa(empresa);
    this.context.setEmpresaId(evt.target.value);
  };

  render() {
    const { breadcrumb, children, classes, icon, noIcon, title } = this.props;
    // const { anchorEl } = this.state;
    // const open = Boolean(anchorEl);

    const Icon = MaterialIcons[icon || "PollIcon"];
    const empresaContext = this.context;
    const empresas = empresaContext.empresas;
    const selectedEmpresa = empresaContext.selectedEmpresa;

    return (
      <UserContext.Consumer>
        {({ user }) => {
          return (
            <DrawerContext.Consumer>
              {({
                changeLink,
                handleDrawerOpen,
                isDrawerOpen,
                links,
                setChangeLink,
                topLink,
              }) => {
                return (
                  <div className={classes.root}>
                    <NavBar drawerOpen={isDrawerOpen} title={title} />
                    <Drawer
                      variant="permanent"
                      className={classNames(classes.drawer, {
                        [classes.drawerOpen]: isDrawerOpen,
                        [classes.drawerClose]: !isDrawerOpen,
                      })}
                      classes={{
                        paper: classNames({
                          [classes.drawerOpen]: isDrawerOpen,
                          [classes.drawerClose]: !isDrawerOpen,
                        }),
                      }}
                      PaperProps={{
                        sx: { overflow: "unset" },
                      }}
                      open={isDrawerOpen}
                    >
                      <Box className={classes.toolbar}>
                        <Box display="flex" sx={{ placeItems: "center" }}>
                          <BDOIcon />
                          <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={() => handleDrawerOpen()}
                            sx={{
                              marginLeft: "16px",
                              marginRight: isDrawerOpen ? "6px" : "16px",
                            }}
                          >
                            <MaterialIcons.BackBurguer
                              classes={{
                                root: isDrawerOpen
                                  ? classes.menuButtonIconOpen
                                  : classes.menuButtonIconClosed,
                              }}
                            />
                          </IconButton>
                        </Box>
                      </Box>
                      <List
                        sx={{
                          ".MuiListItemIcon-root": {
                            paddingLeft: isDrawerOpen ? "10px" : "0px",
                          },
                          ".MuiListItemButton-root:hover": {
                            backgroundColor: "rgba(238, 241, 251, 0.5)",
                            borderRadius: "6px",
                          },
                          paddingBottom: 0,
                        }}
                      >
                        {user && user?.type === 1 && (
                          <TextField
                            id="links-select"
                            select
                            SelectProps={{
                              onOpen: () => this.setState({ open: true }),
                              onClose: () => this.setState({ open: false }),
                            }}
                            onChange={(e: any) => {
                              setChangeLink(changeLink === 1 ? 2 : 1);
                              this.context.navigate(e.target.value);
                            }}
                            value={
                              this.state.open && !isDrawerOpen ? "" : topLink
                            }
                            sx={{
                              ...(changeLink === 1
                                ? {
                                    paddingTop: "20px",
                                    paddingBottom: "0px",
                                  }
                                : {
                                    paddingY: "20px",
                                  }),
                              paddingRight: "0px",
                              paddingLeft: changeLink === 1 ? "8px" : "0px",
                              width: changeLink === 1 ? "96%" : "94%",
                            }}
                            InputProps={{
                              sx: {
                                backgroundColor: "rgba(238, 241, 251, 0.5)",
                                borderRadius: "2px",
                                height: "40px",
                                ".MuiInputBase-input": {
                                  paddingBottom: "16px",
                                  paddingTop: "16px",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                },
                              },
                            }}
                          >
                            {topLinks.map((link) => {
                              const Icon = MaterialIcons[link.icon];
                              return (
                                <MenuItem key={link.id} value={link.path}>
                                  <Icon
                                    sx={{
                                      color: "black",
                                      marginRight: "20px",
                                      paddingLeft: 0,
                                    }}
                                  />
                                  {(this.state.open || isDrawerOpen) &&
                                    link.label}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        )}
                        {selectedEmpresa &&
                          changeLink === 2 &&
                          window.location.pathname !== "/gestiondecliente" &&
                          (empresas && empresas?.length > 1 ? (
                            <TextField
                              id="empresas-select"
                              select
                              SelectProps={{
                                onOpen: () =>
                                  this.setState({
                                    openEmpresaList: true,
                                  }),
                                onClose: () =>
                                  this.setState({
                                    openEmpresaList: false,
                                  }),
                              }}
                              onChange={this.handleChangeEmpresas}
                              value={
                                this.state.openEmpresaList && !isDrawerOpen
                                  ? ""
                                  : selectedEmpresa?.id
                              }
                              sx={{
                                paddingLeft: "0px",
                                width: "94%",
                              }}
                              InputProps={{
                                sx: {
                                  borderRadius: "6px",
                                  border: "1px solid grsy",
                                  height: "40px",
                                  ".MuiInputBase-input": {
                                    paddingBottom: "16px",
                                    paddingTop: "16px",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                  },
                                },
                              }}
                            >
                              {empresas.map((empresa) => (
                                <MenuItem key={empresa.id} value={empresa.id}>
                                  {this.state.openEmpresaList ||
                                  isDrawerOpen ? (
                                    empresa.name
                                  ) : (
                                    <MaterialIcons.BusinessIcon
                                      sx={{
                                        color: "black",
                                        marginRight: "20px",
                                        paddingLeft: 0,
                                      }}
                                    />
                                  )}
                                </MenuItem>
                              ))}
                            </TextField>
                          ) : isDrawerOpen ? (
                            <Typography
                              textAlign="start"
                              sx={{
                                marginLeft: "34px",
                                paddingTop: user?.type === 0 ? "24px" : "0px",
                              }}
                            >
                              {selectedEmpresa?.name}
                            </Typography>
                          ) : (
                            <Tooltip title={selectedEmpresa?.name}>
                              <MaterialIcons.BusinessIcon
                                sx={{
                                  color: "black",
                                  marginRight: "4px",
                                }}
                              />
                            </Tooltip>
                          ))}
                      </List>
                      <List
                        sx={{
                          ".MuiListItemIcon-root": {
                            paddingLeft: isDrawerOpen ? "10px" : "0px",
                          },
                          ".MuiListItemButton-root:hover": {
                            backgroundColor: "rgba(238, 241, 251, 0.5)",
                            borderRadius: "6px",
                          },
                          height: "100%",
                          paddingTop: 0,
                        }}
                      >
                        {window.location.pathname !== "/gestiondecliente" && (
                          <PerfectScrollbar
                            options={{ wheelPropagation: false }}
                          >
                            {links.map((drawerItem) => {
                              //if user.role==="tal" mostrar link especifico
                              const Icon = MaterialIcons[drawerItem.icon];
                              return (
                                <NavLink
                                  key={drawerItem.id}
                                  to={drawerItem.path}
                                  className={({ isActive }) =>
                                    isActive ? "NavLinkClass" : ""
                                  }
                                  style={{
                                    color: "black",
                                    padding: "10px",
                                    textDecoration: "none",
                                  }}
                                >
                                  <ListItem
                                    sx={{
                                      height: "40px",
                                      paddingLeft: "8px",
                                      paddingRight: "8px",
                                    }}
                                    disablePadding
                                  >
                                    <ListItemButton
                                      id={`ListItem-${drawerItem.id}`}
                                    >
                                      <ListItemIcon>
                                        <Icon sx={{ color: "black" }} />
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={
                                          drawerItem.id === 6 && !isDrawerOpen
                                            ? "AdP"
                                            : drawerItem.label
                                        }
                                        sx={{ color: "#333333" }}
                                      />
                                    </ListItemButton>
                                  </ListItem>
                                </NavLink>
                              );
                            })}
                          </PerfectScrollbar>
                        )}
                      </List>
                    </Drawer>
                    <main
                      className={classes.content}
                      style={{ overflow: "auto" }}
                    >
                      <Toolbar />
                      {!noIcon &&
                        (breadcrumb ? (
                          <BreadcrumbsBar
                            empresa={title === "Empresas "}
                            empresaparametro={title === "Parametros "}
                            icon={icon}
                          />
                        ) : (
                          <Icon />
                        ))}

                      {children}
                    </main>
                  </div>
                );
              }}
            </DrawerContext.Consumer>
          );
        }}
      </UserContext.Consumer>
    );
  }
}

export default withStyles(styles)(Layout);
