import React, { useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { shallow } from "zustand/shallow";
import dayjs from "dayjs";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

import { MaterialIcons } from "../../icons/Material/MaterialIcons";

import Filtros from "../Modals/Filtros/Filtros";
import Layout from "../Layout/Layout";
import DataTable from "../table/DataTable";

import {
  getCompanyPeriods,
  getCompanyPeriodsTypes,
} from "../../services/CompaniesService";

import { useEmpresaStore } from "../../store/empresaStore";

import { CompanyPeriodsType, TipoPeriodoRowsType } from "../../types";

const periodsStatuses = [
  { id: "1", value: 0, label: "Activo" },
  { id: "2", value: 1, label: "Inactivo" },
];

const tableColumnsName = [
  "Duración",
  "Fecha Inicio",
  "Fecha Fin",
  "Día Límite",
  "Tipo",
  "Estado",
];

const tableFieldsName = [
  "duration",
  "start_date",
  "finish_date",
  "day_limit",
  "period_type_id",
  "period_state",
];

const Periodos = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [periods, setPeriods] = useState<CompanyPeriodsType[]>([]);
  const [originalPeriods, setOriginalPeriods] = useState<CompanyPeriodsType[]>(
    []
  );
  const [companyPeriodsType, setCompanyPeriodsType] =
    useState<TipoPeriodoRowsType[]>();
  const [fetchErrorMessage, setFetchErrorMessage] = useState<string>("");
  const [filterValues, setFilterValues] = useState<Record<string, string>>({});
  const [openFiltersModal, setOpenFilterModal] = useState<boolean>(false);
  const [isFiltering, setIsFiltering] = useState<boolean>(false);

  const { getAccessTokenSilently } = useAuth0();

  const empresaId = useEmpresaStore((state) => state.empresaId, shallow);

  const handleOpenModal = (open: boolean) => {
    setOpenFilterModal(open);
  };

  const handleClearFilterButton = () => {
    setFilterValues({});
    setPeriods(originalPeriods);
    setIsFiltering(false);
    handleOpenModal(false);
  };

  const handleSetFilter = (newFilter: any) => {
    setPeriods(newFilter);
  };

  const handleFilterWithButton = (value: string, name: string) => {
    setFilterValues({ ...filterValues, [name]: value });
  };

  const handleOnFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFilterValues({ ...filterValues, [name]: value });
  };

  const handleOnFilterDateChange = (date: string, name: string) => {
    if (date.toLowerCase() === "invalid date") {
      setFilterValues({ ...filterValues, [name]: "" });
    } else {
      setFilterValues({ ...filterValues, [name]: date });
    }
  };

  const applyFilters = () => {
    const dateFields = ["start_date", "finish_date", "day_limit"];

    if (Object.keys(filterValues).length > 0) {
      const values = Object.values(filterValues);
      if (values.every((value) => !value)) {
        setIsFiltering(false);
      } else {
        const filteredRows: any = originalPeriods.filter((row: any) => {
          for (const key in filterValues) {
            if (dateFields.indexOf(key) !== -1) {
              if (dayjs(row[key]).format("DD/MM/YYYY") === filterValues[key]) {
                continue;
              } else {
                return false;
              }
            }
            if (filterValues[key]) {
              if (
                row[key]
                  ?.toString()
                  .toLowerCase()
                  .includes(filterValues[key].toString().toLowerCase())
              ) {
                continue;
              } else {
                return false;
              }
            }
            if (filterValues[key]) {
              if (
                row[key]
                  ?.toString()
                  .toLowerCase()
                  .includes(filterValues[key].toLowerCase())
              ) {
                continue;
              } else {
                return false;
              }
            }
          }
          return true;
        });
        setIsFiltering(true);
        handleSetFilter(filteredRows);
      }
    } else {
      setIsFiltering(false);
    }
    handleOpenModal(false);
  };

  const getPeriods = useCallback(async () => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    getCompanyPeriods(token, empresaId)
      .then((result) => {
        getCompanyPeriodsTypes(token, empresaId)
          .then((result2) => {
            setFetchErrorMessage("");
            setPeriods(result.data);
            setOriginalPeriods(result.data);
            setCompanyPeriodsType(result2.data);
            setIsLoading(false);
          })
          .catch((e) => {
            console.error(e.response.data.error);
            setFetchErrorMessage(e.response.data.error);
            setIsLoading(false);
          });
      })
      .catch((e) => {
        console.error(e.response.data.error);
        setFetchErrorMessage(e.response.data.error);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresaId]);

  useEffect(() => {
    getPeriods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //1: success, 2:pending, 3:cancel
  const getCompanyPeriod = (params: GridRenderCellParams) => {
    const periodType = companyPeriodsType?.find(
      (cmpyPT) => cmpyPT.company_id === empresaId && cmpyPT.id === params.value
    );
    return periodType?.name || "No asignado";
  };

  //1: success, 2:pending, 3:cancel
  const getAction = (params: GridRenderCellParams) => {
    return (
      <Box
        sx={{
          display: "flex",
          placeItems: "center",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <MaterialIcons.VisibilityOutlined />
      </Box>
    );
  };

  //0:active, 1:inactive
  const getStatus = (params: GridRenderCellParams) => {
    if (params.value === 0) {
      return <Typography marginLeft="8px">Activo</Typography>;
    }

    if (params.value === 1) {
      return <Typography marginLeft="8px">Inactivo</Typography>;
    }
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nombre",
      headerClassName: "theme--header",
      flex: 1,
    },
    {
      field: "duration",
      headerName: "Duración",
      headerClassName: "theme--header",
      flex: 1,
    },
    {
      field: "start_date",
      headerName: "Fecha Inicio",
      type: "dateTime",
      // valueGetter: ({ value }) => value && dayjs(value).format("DD/MM/YYYY"),
      valueFormatter: ({ value }) => value && dayjs(value).format("DD/MM/YYYY"),
      headerClassName: "theme--header",
      flex: 1,
    },
    {
      field: "finish_date",
      headerName: "Fecha Fin",
      type: "dateTime",
      // valueGetter: ({ value }) => value && dayjs(value).format("DD/MM/YYYY"),
      valueFormatter: ({ value }) => value && dayjs(value).format("DD/MM/YYYY"),
      headerClassName: "theme--header",
      flex: 1,
    },
    {
      field: "day_limit",
      headerName: "Día Límite",
      type: "dateTime",
      // valueGetter: ({ value }) => value && dayjs(value).format("DD/MM/YYYY"),
      valueFormatter: ({ value }) => value && dayjs(value).format("DD/MM/YYYY"),
      headerClassName: "theme--header",
      flex: 1,
    },
    {
      field: "period_type_id",
      headerName: "Tipo",
      sortable: false,
      headerClassName: "theme--header",
      align: "center",
      renderCell: getCompanyPeriod,
      flex: 1,
    },
    {
      field: "period_state",
      headerName: "Estado",
      type: "number",
      headerClassName: "theme--header",
      renderCell: getStatus,
      flex: 1,
      headerAlign: "left",
      align: "center",
    },
    {
      field: "action",
      headerName: "Acción",
      type: "number",
      headerClassName: "theme--header",
      renderCell: getAction,
      flex: 1,
    },
  ];

  return (
    <Layout icon="CalendarTodayIcon" title="Periodos">
      <Box
        sx={{
          height: "100%",
          "& .theme--header": {
            backgroundColor: "#EEF1FC",
          },
        }}
      >
        <Grid container sx={{ py: "24px", placeItems: "center" }}>
          <Grid item xs>
            <Grid container display="flex" justifyContent="flex-end">
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  startIcon={<MaterialIcons.FilterAltIcon />}
                  onClick={() => setOpenFilterModal(true)}
                  sx={{
                    backgroundColor: isFiltering ? "#182F84" : "white",
                    color: isFiltering ? "white" : "black",
                    "&:hover": { color: "white" },
                  }}
                >
                  Filtrar
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <DataTable
          columns={columns}
          rows={periods}
          noFilterRowsMessage="No exite un empleado con ese nombre"
          noRowsMessage={fetchErrorMessage || "No hay periodos actualmente"}
          loading={isLoading}
          navigateRowLink="/periodos/periodo"
        />

        {openFiltersModal && (
          <Filtros
            filterValues={filterValues}
            openModal={openFiltersModal}
            handleOpenModal={handleOpenModal}
            handleFilterButton={() => applyFilters()}
            handleClearFilterButton={() => handleClearFilterButton()}
            handleFilters={handleOnFilterChange}
            handleFilterDate={handleOnFilterDateChange}
            handleFilterWithButton={handleFilterWithButton}
            tableColumnsName={tableColumnsName}
            tableFieldsName={tableFieldsName}
            periodsStatuses={periodsStatuses}
            periodsTypes={companyPeriodsType || []}
            filtersTo="Periodos"
          />
        )}
      </Box>
    </Layout>
  );
};

export default Periodos;
