import React from "react";
import { useParams } from "react-router-dom";

import EmpresaNovedadDetails from "../../components/Details/EmpresaNovedadDetails/EmpresaNovedadDetails";

const EmpresaNovedadDetailsPage = () => {
  const params = useParams();
  return <EmpresaNovedadDetails params={params} />;
};

export default EmpresaNovedadDetailsPage;
