import React, { Dispatch, SetStateAction, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

import SearchBar from "material-ui-search-bar";

import DataTable from "../../table/DataTable";
import InformationModal from "../../Modals/ConfirmationModal/InformationModal";
import { MaterialIcons } from "../../../icons/Material/MaterialIcons";

import { deleteUserFromCompany } from "../../../services/CompaniesService";

import { CompanyType } from "../../../types";

type UserCompaniesTableType = {
  setRefreshTable: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  companies: CompanyType[];
  originalCompanies: CompanyType[];
  setCompanies: Dispatch<SetStateAction<CompanyType[]>>;
  usuarioEmail: string;
  fetchUserCompaniesError?: boolean;
  fetchUserCompaniesErrorMessage?: string;
  userHasRole: boolean;
};

const UserCompaniesTable: React.FC<UserCompaniesTableType> = ({
  isLoading,
  companies,
  setRefreshTable,
  originalCompanies,
  setCompanies,
  usuarioEmail,
  fetchUserCompaniesError,
  fetchUserCompaniesErrorMessage,
  userHasRole
}) => {
  const [searched, setSearched] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [openEliminarModal, setOpenEliminarModal] = useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [disabledOnSubmit, setDisabledOnSubmit] = useState<boolean>(false);
  const [empresaName, setEmpresaName] = useState<string>("");
  const [userData, setUserData] = useState<any>({
    user_id: "",
    company_id: "",
    role: "",
    status: "",
  });

  const { getAccessTokenSilently } = useAuth0();

  const requestSearch = (searchedVal: string) => {
    const seacrhCompanies = [...originalCompanies];
    if (searchedVal.length > 2) {
      const filteredRowsByName = seacrhCompanies.filter((company) => {
        return company.name.toLowerCase().includes(searchedVal.toLowerCase());
      });

      if (filteredRowsByName.length > 0) {
        setCompanies(filteredRowsByName);
      } else {
        setCompanies([]);
      }
    } else {
      setCompanies(originalCompanies);
    }
  };

  const handleClickEliminar = async (empresa: any) => {
    setEmpresaName(empresa.company.name);

    const userData = {
      user_id: empresa.id,
      company_id: empresa.company_id,
      role: empresa.role,
      status: empresa.status,
      email: usuarioEmail,
    };

    setUserData(userData);
    setOpenEliminarModal(true);
  };

  const removeUserFromCompany = async () => {
    setDisabledOnSubmit(true);
    const token = await getAccessTokenSilently();

    await deleteUserFromCompany(token, userData.user_id, userData)
      .then(() => {
        setRefreshTable(true);
        setOpenEliminarModal(false);
        setDisabledOnSubmit(false);
      })
      .catch((e) => {
        console.error("Desvincular empresa", e.response.data.error);
        setErrorMessage(
          `Ha ocurrido un error al desvincular la empresa <strong>${empresaName}</strong>. Intente de nuevo mas tarde.`
        );
        setOpenEliminarModal(false);
        setDisabledOnSubmit(false);
        setOpenErrorModal(true);
      });
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  //1: success, 2:pending, 3:cancel
  const getAction = (params: GridRenderCellParams) => {
    return userHasRole ? (
      <Box
        sx={{
          display: "flex",
          placeItems: "center",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <IconButton
          color="inherit"
          onClick={() => handleClickEliminar(params.row)}
          // disabled={params.row.user_id === user.id}
        >
          <MaterialIcons.DeleteIcon />
        </IconButton>
      </Box>
    ) : null;
  };

  //0: active, 1:inactive
  // const getStatus = (params: GridRenderCellParams) => {
  //   if (params.value === 0) {
  //     return "Activo";
  //   }

  //   if (params.value === 1) {
  //     return "Inactivo";
  //   }
  // };

  const columns: GridColDef[] = [
    // {
    //   field: "id",
    //   headerName: "ID",
    //   headerClassName: "theme--header",
    //   flex: 0.4,
    // },
    {
      field: "name",
      headerName: "Nombre",
      editable: true,
      headerClassName: "theme--header",
      flex: 1,
      valueGetter: (params) => params.row.company.name,
    },
    // {
    //   field: "status",
    //   headerName: "Estado",
    //   editable: true,
    //   headerClassName: "theme--header",
    //   flex: 1,
    //   renderCell: getStatus,
    // },
    {
      field: "action",
      headerName: "Acción",
      type: "number",
      editable: false,
      headerClassName: "theme--header",
      renderCell: getAction,
      flex: 1,
    },
  ];

  return (
    <Box
      sx={{
        height: "76%",
        "& .theme--header": {
          backgroundColor: "#EEF1FC",
        },
      }}
    >
      <Grid container spacing={2} sx={{ py: "24px" }}>
        <Grid display="flex" sx={{ placeItems: "center" }} item xs={6}>
          <Box sx={{ marginRight: 4, width: "459px" }}>
            <SearchBar
              value={searched}
              onChange={(searchVal: string) => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
              placeholder="Buscar Empresa"
              style={{ boxShadow: "none" }}
            />
          </Box>
        </Grid>
      </Grid>
      <DataTable
        columns={columns}
        rows={companies}
        noFilterRowsMessage="No hay empresas con ese nombre"
        noRowsMessage={
          fetchUserCompaniesError
            ? fetchUserCompaniesErrorMessage
            : "No hay empresas asignadas"
        }
        loading={isLoading}
        height="64%"
        noRowClick
      />

      {openEliminarModal && (
        <InformationModal
          title="Desvincular empresa"
          message={`¿Querés desvincular a la empresa <strong>${empresaName}</strong> del usuario <strong>${usuarioEmail}</strong>?`}
          openModal={openEliminarModal}
          onClose={() => setOpenEliminarModal(false)}
          isDirty={false}
          clickCancelar={false}
          guardar={false}
          clickEliminar={true}
          onEliminar={() => removeUserFromCompany()}
          disabledOnSubmit={disabledOnSubmit}
          html
        />
      )}

      {openErrorModal && (
        <InformationModal
          title="Ha ocurrido un error"
          message={errorMessage}
          onClose={() => setOpenErrorModal(false)}
          openModal={openErrorModal}
          clickCancelar={false}
          clickEliminar={false}
          guardar={false}
          isDirty={false}
          errorMessage
          html
        />
      )}
    </Box>
  );
};

export default UserCompaniesTable;
