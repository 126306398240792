import React, { useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import * as Yup from "yup";
import { useFormik } from "formik";

import Layout from "../Layout/Layout";
import { useAuth0 } from "@auth0/auth0-react";
import { addEmployee } from "../../services/CompaniesService";

const statuses = [
  {
    id: 1,
    value: 0,
    label: "Activo",
  },
  {
    id: 2,
    value: 1,
    label: "Inactivo",
  },
];

const cities = [
  {
    id: 1,
    value: "1",
    label: "Buenos Aires",
  },
  {
    id: 2,
    value: "2",
    label: "Córdoba",
  },
  {
    id: 3,
    value: "3",
    label: "Rosario",
  },
];

const AddUser = () => {
  const [city, setCity] = useState<string>("1");
  const [status, setStatus] = useState<number>(0);

  const { getAccessTokenSilently } = useAuth0();

  const handleChangeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(Number(event.target.value));
  };

  const handleChangeCity = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCity(event.target.value);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().max(55).required("El nombre es requerido"),
      email: Yup.string().max(55).required("La dirección es requerida"),
    }),
    onSubmit: async (data) => {
      const token = await getAccessTokenSilently();
      addEmployee(token, data)
        .then(() => {
          formik.resetForm();
        })
        .catch((e) => {
          console.error(e.response.data.error);
        });
    },
  });

  return (
    <Layout icon="HomeWorkOutlinedIcon" title="Empresas " breadcrumb>
      <Grid
        bgcolor="white"
        container
        marginTop="12px"
        borderRadius="6px"
        maxWidth="855px"
      >
        <Box
          sx={{
            display: "flex",
            backgroundColor: "white",
            height: "100%",
            width: "74%",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              borderRadius="6px"
              sx={{ backgroundColor: "white", height: "399px" }}
            >
              <Grid container maxWidth="md" justifyContent="space-between">
                <Grid item xs={8} sx={{ py: "26px", px: "32px" }}>
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    marginBottom="4px"
                  >
                    <InputLabel sx={{ color: "black" }} htmlFor="nombre-input">
                      Nombre
                    </InputLabel>
                  </Box>
                  <TextField
                    id="nombre-input"
                    error={Boolean(formik.touched.name && formik.errors.name)}
                    fullWidth
                    helperText={formik.touched.name && formik.errors.name}
                    name="name"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.name}
                    variant="filled"
                    placeholder="Nombre de la empresa"
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        borderRadius: "6px",
                        ".MuiInputBase-input": {
                          paddingBottom: "16px",
                          paddingTop: "16px",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={4} sx={{ py: "26px", pl: "32px" }}>
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    marginBottom="4px"
                  >
                    <InputLabel sx={{ color: "black" }} htmlFor="tipo-input">
                      Tipo
                    </InputLabel>
                  </Box>
                  <TextField
                    id="tipo-input"
                    select
                    // error={Boolean(formik.touched.email && formik.errors.email)}
                    fullWidth
                    // helperText={formik.touched.email && formik.errors.email}
                    name="status"
                    // onBlur={formik.handleBlur}
                    // onChange={formik.handleChange}
                    // value={formik.values.email}
                    variant="filled"
                    value={status}
                    onChange={handleChangeStatus}
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        borderRadius: "6px",
                        ".MuiInputBase-input": {
                          paddingBottom: "16px",
                          paddingTop: "16px",
                          display: "flex",
                          justifyContent: "flex-start",
                        },
                      },
                    }}
                  >
                    {statuses.map((status) => (
                      <MenuItem key={status.id} value={status.value}>
                        {status.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid container maxWidth="md" justifyContent="space-between">
                <Grid item xs={8} sx={{ pb: "26px", px: "32px" }}>
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    marginBottom="4px"
                  >
                    <InputLabel sx={{ color: "black" }} htmlFor="email-input">
                      Correo
                    </InputLabel>
                  </Box>
                  <TextField
                    id="email-input"
                    error={Boolean(formik.touched.email && formik.errors.email)}
                    fullWidth
                    helperText={formik.touched.email && formik.errors.email}
                    name="address"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.email}
                    variant="filled"
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        borderRadius: "6px",
                        ".MuiInputBase-input": {
                          paddingBottom: "16px",
                          paddingTop: "16px",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={4} sx={{ pb: "26px", pl: "32px" }}>
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    marginBottom="4px"
                  >
                    <InputLabel sx={{ color: "black" }} htmlFor="modelo-input">
                      Ciudad
                    </InputLabel>
                  </Box>
                  <TextField
                    id="tipo-input"
                    select
                    // error={Boolean(formik.touched.email && formik.errors.email)}
                    fullWidth
                    // helperText={formik.touched.email && formik.errors.email}
                    name="city"
                    // onBlur={formik.handleBlur}
                    // onChange={formik.handleChange}
                    // value={formik.values.email}
                    variant="filled"
                    value={city}
                    onChange={handleChangeCity}
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        borderRadius: "6px",
                        ".MuiInputBase-input": {
                          paddingBottom: "16px",
                          paddingTop: "16px",
                          display: "flex",
                          justifyContent: "flex-start",
                        },
                      },
                    }}
                  >
                    {cities.map((city) => (
                      <MenuItem key={city.id} value={city.value}>
                        {city.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid container maxWidth="sm" marginTop="52px">
                <Button
                  sx={{ marginLeft: "34px" }}
                  variant="contained"
                  color="primary"
                  disabled={
                    !formik.dirty || !formik.values.email || !formik.values.name
                  }
                >
                  Guardar
                </Button>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Layout>
  );
};

export default AddUser;
