import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

type FetchErrorMessageType = {
  height?: string;
  errorMessage: string;
};

const FetchErrorMessage: React.FC<FetchErrorMessageType> = ({
  errorMessage,
  height,
}) => {
  return (
    <Box
      sx={{
        borderRadius: "6px",
        display: "flex",
        backgroundColor: "white",
        height: "100%",
        width: "100%",
        paddingRight: "18px",
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Box
          id="create-novelty-form"
          borderRadius="6px"
          sx={{
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: height || "214px",
          }}
        >
          <Typography variant="h3">{errorMessage}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default FetchErrorMessage;
