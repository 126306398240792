import React, { useEffect } from "react";
import Nomina from "../components/Nomina/Nomina";

const NominaPage = () => {
  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - Nomina`;
  }, []);

  return <Nomina />;
};

export default NominaPage;
