import React, { useState } from "react";
import { shallow } from "zustand/shallow";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";

import { alpha, styled } from "@mui/material/styles";
import { DataGrid, gridClasses, GridEventListener } from "@mui/x-data-grid";

import { useEmpresaStore } from "../../store/empresaStore";

import { DataTableType } from "../../types";

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: "#F2F2F2",
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const DataTable: React.FC<DataTableType> = ({
  columns,
  editable,
  filter,
  height,
  labelRowsPerPage,
  loading,
  navigateRowLink,
  noFilterRowsMessage,
  noRowClick,
  noRowsMessage,

  onCellClick,
  rows,
  sortModel,
}) => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const setEmpresaId = useEmpresaStore((state) => state.setEmpresaId, shallow);

  const navigate = useNavigate();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    if (navigateRowLink === "/empresas/empresa") {
      setEmpresaId(params.id.toString());
    }
    navigate(`${navigateRowLink}/${params.id}`);
  };

  return (
    <Box
      sx={{
        height: "100%",
        "& .theme--header": {
          backgroundColor: "#EEF1FC",
        },
        "& .MuiTablePagination-toolbar": {
          paddingLeft: "18px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          height: height || "82%",
          borderRadius: "6px",
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Box display="flex" justifyContent="flex-start">
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={labelRowsPerPage || "Mostrar Registros"}
            />
          </Box>
          <StripedDataGrid
            initialState={{
              sorting: {
                sortModel: sortModel
                  ? sortModel
                  : [{ field: "name", sort: "asc" }],
              },
            }}
            sx={{
              backgroundColor: "white",
              border: "1px solid white",
              borderRadius: "6px",
              paddingLeft: "18px",
              paddingRight: "18px",
            }}
            rows={rows}
            columns={columns}
            pageSize={rowsPerPage}
            page={page}
            rowsPerPageOptions={[rowsPerPage]}
            {...(!editable && !noRowClick && { onRowClick: handleRowClick })}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            onCellClick={onCellClick}
            localeText={{
              columnMenuLabel: "Menu",
              columnMenuShowColumns: "Mostrar Columna",
              columnMenuFilter: "Filtrar",
              columnMenuHideColumn: "Ocultar",
              columnMenuUnsort: "Deshacer",
              columnHeaderSortIconLabel: "Ordenar",
              columnMenuSortAsc: "Ordenar por ASC",
              columnMenuSortDesc: "Ordenar por DESC",
              noResultsOverlayLabel: noFilterRowsMessage || "Sin Resultados",
              noRowsLabel: noRowsMessage || "Sin Resultados",
            }}
            loading={loading}
            experimentalFeatures={{ newEditingApi: editable }}
            {...(filter && { filterModel: { items: filter } })}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableSelectionOnClick
            hideFooterPagination
            hideFooter
            autoHeight
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DataTable;
