import React from "react";

import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { useNavigate } from "react-router-dom";
import { MaterialIcons } from "../../icons/Material/MaterialIcons";

type linkType = {
  id: string;
  value: string;
  isLink: boolean;
};

type CustomBreadcrumbsType = {
  links: linkType[];
  icon: keyof typeof MaterialIcons;
};

const CustomBreadcrumbs: React.FC<CustomBreadcrumbsType> = ({
  icon,
  links,
}) => {
  const navigate = useNavigate();

  const Icon = MaterialIcons[icon || "PollIcon"];

  const handleBreadcrumbClick = (e: React.SyntheticEvent, url: string) => {
    e.preventDefault();
    navigate(url);
  };

  const renderLinks = (link: any) => {
    if (link.isLink) {
      return (
        <Link
          key={link.id}
          underline="none"
          sx={{ color: "black", display: "flex", placeItems: "center" }}
          href={link.to}
          role="button"
          onClick={(e: React.SyntheticEvent) => handleBreadcrumbClick(e, link.to)}
        >
          {link.id === "1" && <Icon sx={{ marginRight: "20px" }} />}
          {link.value}
        </Link>
      );
    }

    return (
      <Typography key="3" sx={{ color: "black", textAlign: "center" }}>
        {link.value}
      </Typography>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        paddingBottom: "12px",
      }}
    >
      <div role="presentation">
        <Breadcrumbs
          separator={
            <MaterialIcons.ArrowForwardIcon
              fontSize="small"
              sx={{ color: "black" }}
            />
          }
          aria-label="breadcrumb"
        >
          {links.map((link) => renderLinks(link))}
        </Breadcrumbs>
      </div>
    </Box>
  );
};

export default CustomBreadcrumbs;
