import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

import { useFormik } from "formik";

import PeriodoStepper from "./PeriodoStepper";

import { MaterialIcons } from "../../../icons/Material/MaterialIcons";

type AgregarPeriodoModalType = {
  openModal: boolean;
  handleRefreshTable: React.Dispatch<React.SetStateAction<boolean>>;
  changeOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenErrorModal: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
};

const AgregarPeriodoModal: React.FC<AgregarPeriodoModalType> = ({
  changeOpenModal,
  handleRefreshTable,
  openModal,
  setErrorMessage,
  setOpenErrorModal,
}) => {
  const [activeStep, setActiveStep] = React.useState(1);
  const [periodo, setPeriodo] = React.useState<any>(null);
  const [disabledOnSubmit, setDisabledOnSubmit] =
    React.useState<boolean>(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    formik.resetForm();
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSetPeriodo = (periodo: any) => {
    setPeriodo(periodo);
  };

  const formik = useFormik({
    initialValues: {
      periodo_id: "",
    },
    onSubmit: () => {},
  });

  const handleClose = () => {
    formik.resetForm();
    changeOpenModal(false);
  };

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: "40%",
            height: "744px",
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          Crear tipo de periodo
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
          >
            <MaterialIcons.CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container borderRadius="6px">
            <Grid bgcolor="white" item xs borderRadius="6px">
              <Box
                sx={{
                  borderRadius: "6px",
                  display: "flex",
                  backgroundColor: "white",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Box
                    id="crear-usuario-form"
                    borderRadius="6px"
                    sx={{ backgroundColor: "white", height: "100%" }}
                  >
                    <Grid container>
                      <Grid bgcolor="white" item xs>
                        <Grid container>
                          <Grid item xs={12} sx={{ py: "10px" }}>
                            {/* <PeriodosList handleSelectPeriodo={formik.setFieldValue} /> */}
                            <PeriodoStepper
                              activeStep={activeStep}
                              handleReset={handleReset}
                              handleSelectPeriodo={formik.setFieldValue}
                              periodo={periodo}
                              handleSetPeriodo={handleSetPeriodo}
                              handleClose={handleClose}
                              handleRefreshTable={handleRefreshTable}
                              setDisabledOnSubmit={setDisabledOnSubmit}
                              setOpenErrorModal={setOpenErrorModal}
                              setErrorMessage={setErrorMessage}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ paddingBottom: "16px", paddingRight: "24px" }}>
          {activeStep === 1 && (
            <Button
              color="primary"
              variant="outlined"
              onClick={handleClose}
              sx={{ width: "168px" }}
            >
              Cancelar
            </Button>
          )}
          {activeStep === 2 && (
            <Button
              color="primary"
              variant="outlined"
              onClick={() => handleBack()}
              sx={{ width: "168px" }}
            >
              Cancelar
            </Button>
          )}
          {activeStep === 1 && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleNext()}
              disabled={!formik.values.periodo_id}
              sx={{ width: "168px" }}
            >
              Aceptar
            </Button>
          )}
          {activeStep === 2 && (
            <Button
              variant="contained"
              color="primary"
              form="periodo-stepper-form"
              type="submit"
              disabled={!formik.values.periodo_id || disabledOnSubmit}
              sx={{ width: "168px" }}
            >
              Aceptar
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AgregarPeriodoModal;
