import React, { useEffect } from "react";
import Empresa from "../components/Empresas/Empresa";

const EmpresaPage = () => {
  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - Empresa`;
  }, []);

  return <Empresa />;
};

export default EmpresaPage;
