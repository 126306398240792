import React, { useContext, useState } from "react";
import { shallow } from "zustand/shallow";
import classNames from "classnames";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

//MUI V5
import Avatar from "@mui/material/Avatar";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

import { MaterialIcons } from "../../icons/Material/MaterialIcons";

import { ParametroContext } from "../../context/parametro/ParametroContext";

import { useEmpresaStore } from "../../store/empresaStore";
import { useDrawerStore } from "../../store/drawerStore";
import { useUserStore } from "../../store/userStore";
import { useParametrosStore } from "../../store/parametrosStore";

const drawerWidth = 259;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#EEF1FC",
    position: "absolute",
    height: "100%",
  },
  appBar: {
    zIndex: theme.zIndex.drawer,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 12,
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: "rotate(180deg)",
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: "rotate(0deg)",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: theme.zIndex.drawer + 1,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
    justifyContent: "flex-end",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: "100%",
    flexBasis: "auto",
    display: "flex",
    flexDirection: "column",
    maxWidth: "120rem",
  },
  grow: {
    flexGrow: 1,
  },
  routeText: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-start",
    paddingLeft: "14px",
  },
}));

// using `interface` is also ok
type NavBarType = {
  drawerOpen: boolean;
  title: string;
};

const NavBar: React.FC<NavBarType> = ({ drawerOpen, title }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { logout, user } = useAuth0();
  const classes = useStyles();

  const navigate = useNavigate();

  const userData = useUserStore((state) => state.user, shallow);

  const setUserData = useUserStore((state) => state.setUserData);

  const setFirstLogin = useDrawerStore((state) => state.setFirstLogin);
  const setTopLink = useDrawerStore((state) => state.setTopLink);

  const setTabValue = useParametrosStore((state) => state.setTabValue);

  const resetEmpresasState = useEmpresaStore(
    (state) => state.resetEmpresasState
  );

  const { handleActualEmpresa } = useContext(ParametroContext);

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGoToProfile = () => {
    navigate("/perfil");
  };

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
    resetEmpresasState();
    handleActualEmpresa("");
    setTabValue(0);
    setFirstLogin(true);
    setUserData(null);
    setTopLink("");
  };

  const open = Boolean(anchorEl);

  return (
    <AppBar
      position="fixed"
      className={classes.appBar}
      sx={{ backgroundColor: "#EEF1FC" }}
      elevation={0}
      // foojoin={classNames(classes.appBar, {
      //   [classes.appBarShift]: open,
      // })}
    >
      <Toolbar disableGutters sx={{ padding: "0 8px" }}>
        <Box
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen,
          })}
        ></Box>
        <Typography
          variant="h6"
          color="primary"
          className={classes.routeText}
          sx={{ color: "black", fontWeight: 500, fontSize: 36 }}
          noWrap
        >
          {title}
        </Typography>
        <Box display="flex" justifyContent="flex-end">
          <IconButton
            onClick={(e) => handleMenu(e)}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            id="avatar-button"
          >
            <Avatar sx={{ width: 32, height: 32 }} src={`${user?.picture}`} />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={() => handleClose()}
          >
            {userData?.status === 1 && (
              <MenuItem
                id="profile-menuItem"
                onClick={() => handleGoToProfile()}
              >
                <ListItemIcon>
                  <MaterialIcons.PersonIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText>Mi perfil</ListItemText>
              </MenuItem>
            )}
            <MenuItem id="logout-menuItem" onClick={() => handleLogout()}>
              <ListItemIcon>
                <MaterialIcons.LogoutIcon
                  sx={{ color: "black" }}
                  fontSize="small"
                />
              </ListItemIcon>
              <ListItemText>Cerrar sesión</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
