import { createContext, Dispatch, SetStateAction } from "react";
import {
  CompanyPeriodsType,
  CompanyType,
  EmpresaType,
  TenantType,
} from "../../types";

type EmpresaContextInterface = {
  allEmpresaPeriodos: CompanyPeriodsType[];
  allEmpresas: EmpresaType[] | CompanyType[];
  allTenants: TenantType[];
  empresaId: string;
  empresas: any[];
  navigate: (to: string) => void;
  selectedEmpresa: EmpresaType | CompanyType | undefined;
  setAllEmpresas: (empresas: EmpresaType[]) => void;
  setEmpresaId: (empresaId: string) => void;
  setEmpresas: Dispatch<SetStateAction<EmpresaType[] | CompanyType[]>>;
  setSelectedEmpresa: (empresa: EmpresaType | CompanyType | undefined) => void;
};

const EmpresaContext = createContext({} as EmpresaContextInterface);
EmpresaContext.displayName = "EmpresaContext";

export { EmpresaContext };
