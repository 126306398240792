import React, {
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { shallow } from "zustand/shallow";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams } from "react-router-dom";

import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  MuiEvent,
} from "@mui/x-data-grid";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import SearchBar from "material-ui-search-bar";

import DataTable from "../table/DataTable";
import InformationModal from "../Modals/ConfirmationModal/InformationModal";

import { MaterialIcons } from "../../icons/Material/MaterialIcons";

import { TipoNovedadRowsType } from "../../types";

import {
  deleteCompanyNoveltyType,
  getCompanyNoveltiesTypes,
} from "../../services/CompaniesService";
import { downloadNoveltyFile } from "../../services/ParametersService";

import { useParametrosStore } from "../../store/parametrosStore";
import { ParametroContext } from "../../context/parametro/ParametroContext";
import { useUserStore } from "../../store/userStore";
import HtmlTooltip from "../HtmlTooltip/HtmlTooltip";

const getFile = (params: GridRenderCellParams) => {
  return (
    <Box sx={{ display: "flex", placeItems: "center", width: "100%" }}>
      <MaterialIcons.FileDownloadIcon
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
        }}
        color="primary"
        fontSize="small"
      />
      <Tooltip title={params.row.file.name}>
        <Typography marginLeft="8px">{params.row.file.name}</Typography>
      </Tooltip>
    </Box>
  );
};

const TiposNovedades = () => {
  const [searched, setSearched] = useState<string>("");
  const [deleteNoveltyId, setDeleteNoveltyId] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [novelties, setNovelties] = useState<TipoNovedadRowsType[]>([]);
  const [originalNovelties, setOriginalNovelties] = useState<
    TipoNovedadRowsType[]
  >([]);
  const [openInformationModal, setOpenInformationModal] =
    useState<boolean>(false);
  const [clickEliminar, setClickEliminar] = useState<boolean>(false);
  const [refreshTable, setRefreshTable] = useState<boolean>(false);

  const { getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();

  const params = useParams();
  const empresaId = params.empresaId!;

  const { handleActualEmpresa, refreshActualEmpresaNovedades } =
    useContext(ParametroContext);

  const empresaTabValue = useParametrosStore(
    (state) => state.empresaTabValue,
    shallow
  );

  const userDataStore = useUserStore((state) => state.user?.role, shallow);

  const isUserReadOnly = userDataStore === 6;

  const setAllCompanyNovedadesType = useParametrosStore(
    (state) => state.setAllCompanyNovedadesType
  );

  const columns: GridColDef[] = [
    // {
    //   field: "id",
    //   headerName: "ID",
    //   flex: 0.4,
    //   headerClassName: "theme--header",
    // },
    {
      field: "name",
      headerName: "Nombre",
      flex: 1,
      editable: true,
      headerClassName: "theme--header",
      valueGetter(params) {
        return params.row.novelty_type.name;
      },
    },
    {
      field: "code",
      headerName: "Código",
      flex: 1,
      editable: true,
      headerClassName: "theme--header",
      valueGetter(params) {
        return params.row.novelty_type.code;
      },
    },
    {
      field: "file",
      headerName: "Plantilla Modelo",
      type: "date",
      flex: 1,
      editable: true,
      headerClassName: "theme--header",
      renderCell: getFile,
    },
    {
      field: "action",
      headerName: "Acción",
      type: "number",
      flex: 1,
      editable: false,
      headerClassName: "theme--header",
      renderCell(params) {
        return getAction(params, "DeleteIcon");
      },
    },
  ];

  //1: success, 2:pending, 3:cancel
  const getAction = (
    params: GridRenderCellParams,
    icon: keyof typeof MaterialIcons
  ) => {
    const Icon = MaterialIcons[icon || "PollIcon"];

    return isUserReadOnly ? (
      <HtmlTooltip
        title={
          <Typography variant="body1">
            Unauthorized access. <b>delete:novelty-type-company</b> permission
            needed
          </Typography>
        }
      >
        <span>
          <Box
            sx={{
              display: "flex",
              placeItems: "center",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <IconButton
              color="inherit"
              onClick={(e: SyntheticEvent) => handleOnClick(e, params.row.id)}
              disabled
            >
              <Icon />
            </IconButton>
          </Box>
        </span>
      </HtmlTooltip>
    ) : (
      <Box
        sx={{
          display: "flex",
          placeItems: "center",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <IconButton
          color="inherit"
          onClick={(e: SyntheticEvent) => handleOnClick(e, params.row.id)}
        >
          <Icon />
        </IconButton>
      </Box>
    );
  };

  const requestSearch = (searchedVal: string) => {
    const searchNoveltyType = [...originalNovelties];
    if (searchedVal.length > 2) {
      const filteredRows = searchNoveltyType.filter((row) => {
        return row.name.toLowerCase().includes(searchedVal.toLowerCase());
      });
      setNovelties(filteredRows);
    } else {
      setNovelties(originalNovelties);
    }
  };

  const handleClickCrearNovedad = () => {
    navigate("creartipodenovedad");
    // setOpenModal(true);
  };

  const downloadArch = async (file: any) => {
    const token = await getAccessTokenSilently();
    const result = await downloadNoveltyFile(token, file.ref);

    // Create blob link to download
    const link = document.createElement("a");
    const url = window.URL.createObjectURL(new Blob([result.data]));
    link.href = url;
    link.setAttribute("download", file.name);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.dispatchEvent(new MouseEvent("click"));

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
  };

  const handleOnCellClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>
  ) => {
    if (params.field === "file") {
      downloadArch(params.value);
    } else if (params.field === "action") {
      return;
    } else {
      const novedadId = params.row.id;
      const url = `/empresas/empresa/${empresaId}/parametros/tipodenovedad/${novedadId}`;

      navigate(url);
    }
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const handleRemove = async (noveltyId: string) => {
    const token = await getAccessTokenSilently();

    deleteCompanyNoveltyType(token, noveltyId)
      .then(() => {
        setOpenInformationModal(false);
        setDeleteNoveltyId("");
        setRefreshTable(true);

        refreshActualEmpresaNovedades(true);
      })
      .catch((e) => {
        console.error(e.response.data.error);
        setOpenInformationModal(false);
        setDeleteNoveltyId("");
      });
  };

  const handleCloseModal = () => {
    setOpenInformationModal(false);
  };

  const handleOnClick = (e: SyntheticEvent, noveltyId: string) => {
    e.stopPropagation();
    setOpenInformationModal(true);
    setClickEliminar(true);
    setDeleteNoveltyId(noveltyId);
  };

  const handleClickAceptarModal = () => {
    handleRemove(deleteNoveltyId);
  };

  const getNovelties = useCallback(async () => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    getCompanyNoveltiesTypes(token, empresaId)
      .then((result) => {
        setNovelties(result.data);
        setOriginalNovelties(result.data);
        setAllCompanyNovedadesType(result.data);
        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e.response.data.error);
        setNovelties([]);
        setOriginalNovelties([]);
        setAllCompanyNovedadesType([]);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (empresaTabValue === 1) {
      getNovelties();
      handleActualEmpresa(empresaId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresaTabValue]);

  useEffect(() => {
    if (empresaTabValue === 1 && refreshTable) {
      getNovelties();
      setRefreshTable(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresaTabValue, refreshTable]);

  return (
    <Box
      sx={{
        height: "100%",
        "& .theme--header": {
          backgroundColor: "#EEF1FC",
        },
      }}
    >
      <Grid container spacing={2} sx={{ py: "24px" }}>
        <Grid display="flex" sx={{ placeItems: "center" }} item xs={6}>
          <Box sx={{ marginRight: 4, width: "459px" }}>
            <SearchBar
              value={searched}
              onChange={(searchVal: string) => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
              placeholder="Buscar tipos de novedades"
              style={{ boxShadow: "none" }}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Grid container display="flex" justifyContent="flex-end">
            <Stack direction="row" spacing={2}>
              {/* <Button
                variant="contained"
                startIcon={<MaterialIcons.FilterAltIcon />}
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  "&:hover": { color: "white" },
                }}
              >
                Filtrar
              </Button> */}
              {isUserReadOnly ? (
                <HtmlTooltip
                  title={
                    <Typography variant="body1">
                      Unauthorized access. <b>create:novelty-type-company</b>{" "}
                      permission needed
                    </Typography>
                  }
                  placement="top"
                >
                  <span>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleClickCrearNovedad()}
                      disabled
                    >
                      Crear tipo de novedad
                    </Button>
                  </span>
                </HtmlTooltip>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleClickCrearNovedad()}
                >
                  Crear tipo de novedad
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Grid>

      {/* <AgregarRemoverNovedadesModal
        openModal={openModal}
        handleOpenModal={setOpenModal}
      /> */}

      <DataTable
        columns={columns}
        rows={novelties}
        loading={isLoading}
        noFilterRowsMessage="No existen tipos de novedades con ese nombre"
        noRowsMessage="No hay tipos de novedades"
        navigateRowLink="/parametros/tipodenovedad/novedad"
        onCellClick={handleOnCellClick}
        noRowClick
      />

      <InformationModal
        title="Eliminar tipo de novedad"
        message="¿Querés eliminar éste tipo de novedad?"
        openModal={openInformationModal}
        onClose={handleCloseModal}
        onEliminar={() => handleClickAceptarModal()}
        clickEliminar={clickEliminar}
        isDirty={false}
        clickCancelar={false}
        guardar={false}
      />
    </Box>
  );
};

export default TiposNovedades;
