import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

type NovedadesState = {
  novedades: Record<string, boolean>;
};

type NovedadesAction = {
  eliminarNovedad: (id: string) => void;
  setNovedadProcesada: (id: string, isProcessed: boolean) => void;
  returnNovedadesProcesadas: () => Record<string, boolean>;
  resetNovedadesProcesadas: () => void;
};

// define the initial state
const initialNovedadesState: NovedadesState = {
  novedades: {},
};

export const useNoveltiesStore = create<NovedadesState & NovedadesAction>()(
  persist(
    (set, get) => ({
      ...initialNovedadesState,
      setNovedadProcesada: (id, isProcessed) => {
        set((state) => ({
          novedades: { ...state.novedades, [id]: isProcessed },
        }));
      },
      eliminarNovedad: (id: string) => {
        set((state) => {
          const { [id]: _, ...novedadesRestantes } = state.novedades;
          return { novedades: novedadesRestantes };
        });
      },
      returnNovedadesProcesadas: () => {
        return get().novedades;
      },
      resetNovedadesProcesadas: () => {
        set(initialNovedadesState);
      },
    }),
    {
      name: "novelties-storage", // name of the item in the storage (must be unique)
      storage: createJSONStorage<NovedadesState>(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);
