import React, { useContext, useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import Layout from "../../Layout/Layout";
import HtmlTooltip from "../../HtmlTooltip/HtmlTooltip";
import InformationModal from "../../Modals/ConfirmationModal/InformationModal";

//Icons
import { MaterialIcons } from "../../../icons/Material/MaterialIcons";

import {
  editCompanyNovelty,
  getCompanyNoveltyById,
  getNoveltyLogs,
} from "../../../services/CompaniesService";
import { downloadNoveltyFile } from "../../../services/ParametersService";

import { useEmpresaStore } from "../../../store/empresaStore";

import { ParametroContext } from "../../../context/parametro/ParametroContext";

const statuses = [
  { id: 1, value: 0, label: "Pendiente de Procesar" },
  { id: 2, value: 1, label: "Inactivo" },
  { id: 3, value: 2, label: "Cancelado" },
  { id: 4, value: 3, label: "Bloqueado" },
  { id: 5, value: 4, label: "Error en Proceso" },
  { id: 6, value: 5, label: "Procesado Exitosamente" },
];

type NovedadDetailsType = {
  params: any;
};

const NovedadDetails: React.FC<NovedadDetailsType> = ({ params }) => {
  const [disabledDownload, setDisabledDownload] = useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [disabledDownloadLogs, setDisabledDownloadLogs] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  const { actualEmpresaNovedades } = useContext(ParametroContext);

  const { allEmpresaPeriodos, empresaId } = useEmpresaStore(
    ({ allEmpresaPeriodos, empresaId }) => ({
      allEmpresaPeriodos,
      empresaId,
    }),
    shallow
  );

  const formik = useFormik({
    initialValues: {
      name: "",
      period_id: "",
      novelty_type_id: "",
      fileName: "",
      file: "",
      urgent: false,
      status: 0,
      priority: 0,
      created_by: "",
      process_date: "",
      error: "",
      result: "",
    },
    onSubmit: async (data) => {
      const token = await getAccessTokenSilently();

      editCompanyNovelty(token, empresaId, params.novedadId, data)
        .then((result) => {
          if (result.status === 201 || result.status === 200) {
            formik.resetForm();
            navigate(-1);
          }
        })
        .catch((e) => {
          console.error(e.response.data.error);
        });
    },
  });

  const handleChangeNoveltyType = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue("novelty_type_id", e.target.value);
  };

  const handleChangePriority = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(e.target.value) >= 0) {
      formik.setFieldValue("priority", Number(e.target.value));
    }
  };

  const getExtensionFromContentType = (contentType: string) => {
    // Define un mapeo de tipos de contenido a extensiones
    const contentTypeToExtension: Record<string, string> = {
      "application/zip": "zip",
      "application/pdf": "pdf",
      "image/jpeg": "jpg",
      "image/png": "png",
      "text/plain": "txt",
      "application/msword": "doc",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        "docx",
      "application/vnd.ms-excel": "xls",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        "xlsx",
    };

    // Obtén el tipo de contenido sin los parámetros adicionales (si los hay)
    const cleanContentType = contentType.split(";")[0].trim();

    // Busca la extensión correspondiente en el mapeo
    const extension = contentTypeToExtension[cleanContentType];

    return extension || ""; // Devuelve la extensión o cadena vacía si no se encuentra
  };

  const handleCloseModal = () => {
    setOpenErrorModal(false);
  };

  const getNovedadLogs = async () => {
    setDisabledDownloadLogs(true);
    const token = await getAccessTokenSilently();
    getNoveltyLogs(token, empresaId, params.novedadId)
      .then((result) => {
        const extension = getExtensionFromContentType(
          result.headers["content-type"] || "text/plain"
        );
        // Create blob link to download
        const link = document.createElement("a");
        const url = window.URL.createObjectURL(
          new Blob([result.data], {
            type: result.headers["content-type"] || "",
          })
        );
        link.href = url;
        link.setAttribute(
          "download",
          `${formik.values.name} - logs.${extension}`
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.dispatchEvent(new MouseEvent("click"));

        // Clean up and remove the link
        window.URL.revokeObjectURL(url);
        link.parentNode?.removeChild(link);
        setDisabledDownloadLogs(false);
      })
      .catch((e) => {
        console.error("error", e.response.data.error);
        setErrorMessage(e.response.data.error);
        setDisabledDownloadLogs(false);
        setOpenErrorModal(true);
      });
  };

  const downloadArch = async () => {
    setDisabledDownload(true);
    const token = await getAccessTokenSilently();
    const result = await downloadNoveltyFile(token, formik.values.file);

    // Create blob link to download
    const link = document.createElement("a");
    const url = window.URL.createObjectURL(new Blob([result.data]));
    link.href = url;
    link.setAttribute("download", formik.values.fileName);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.dispatchEvent(new MouseEvent("click"));

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
    setDisabledDownload(false);
  };

  useEffect(() => {
    const getEmpresaNovedadById = async () => {
      const token = await getAccessTokenSilently();
      const result = await getCompanyNoveltyById(
        token,
        empresaId,
        params.novedadId
      );
      console.table(result.data);
      formik.setValues({
        file: result.data.file.ref,
        fileName: result.data.file.name,
        name: result.data.name,
        novelty_type_id: result.data.novelty_type_id,
        period_id: result.data.period_id,
        priority: result.data.priority,
        urgent: result.data.urgent,
        status: result.data.status,
        created_by: result.data.created_by,
        process_date: result.data.process_date || "",
        error: result.data.error ? result.data.error : "",
        result: result.data.result ? result.data.result : "",
      });

      setIsLoading(false);
    };

    getEmpresaNovedadById();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = `${
      window._env_.REACT_APP_EMPRESA_NAME || "BDO"
    } - Historia`;
  }, []);

  return (
    <Layout icon="AccessTimeIcon" title="Historial" breadcrumb>
      <Grid container marginTop="12px" borderRadius="6px">
        <Grid bgcolor="white" item xs={8} borderRadius="6px">
          {isLoading ? (
            <Box
              sx={{
                borderRadius: "6px",
                display: "flex",
                backgroundColor: "white",
                height: "100%",
                width: "100%",
                paddingRight: "18px",
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Box
                  id="create-novelty-form"
                  borderRadius="6px"
                  sx={{
                    backgroundColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "461px",
                  }}
                >
                  <CircularProgress />
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                borderRadius: "6px",
                display: "flex",
                backgroundColor: "white",
                height: "100%",
                width: "100%",
                paddingRight: "18px",
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Box
                  id="create-novelty-form"
                  component="form"
                  autoComplete="off"
                  borderRadius="6px"
                  sx={{ backgroundColor: "white", height: "100%" }}
                  onSubmit={formik.handleSubmit}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={6}
                      sx={{ pt: "18px", pb: "10px", pl: "18px" }}
                    >
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="nombre-input"
                        >
                          Nombre
                        </InputLabel>
                      </Box>
                      <TextField
                        id="nombre-input"
                        error={Boolean(
                          formik.touched.name && formik.errors.name
                        )}
                        fullWidth
                        helperText={formik.touched.name && formik.errors.name}
                        name="name"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.name}
                        variant="filled"
                        placeholder="Nombre de la novedad"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: true,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{ pt: "18px", pb: "10px", pl: "18px" }}
                    >
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="type-select"
                        >
                          Tipo
                        </InputLabel>
                      </Box>
                      <TextField
                        id="type-select"
                        select
                        error={Boolean(
                          formik.touched.novelty_type_id &&
                            formik.errors.novelty_type_id
                        )}
                        fullWidth
                        helperText={
                          formik.touched.novelty_type_id &&
                          formik.errors.novelty_type_id
                        }
                        name="novelty_type_id"
                        onBlur={formik.handleBlur}
                        onChange={handleChangeNoveltyType}
                        value={formik.values.novelty_type_id}
                        variant="filled"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: true,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                              display: "flex",
                              justifyContent: "flex-start",
                            },
                          },
                        }}
                      >
                        {actualEmpresaNovedades.map((actualEmpresaNovedad) => (
                          <MenuItem
                            key={actualEmpresaNovedad.id}
                            value={actualEmpresaNovedad.id}
                          >
                            {`${actualEmpresaNovedad.novelty_type.code} - ${actualEmpresaNovedad.novelty_type.name}`}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6} sx={{ py: "10px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="periodo-select"
                        >
                          Periodo
                        </InputLabel>
                      </Box>
                      <TextField
                        id="periodo-select"
                        select
                        error={Boolean(
                          formik.touched.period_id && formik.errors.period_id
                        )}
                        fullWidth
                        helperText={
                          formik.touched.period_id && formik.errors.period_id
                        }
                        name="period_id"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.period_id}
                        variant="filled"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: true,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                              display: "flex",
                              justifyContent: "flex-start",
                            },
                          },
                        }}
                      >
                        {allEmpresaPeriodos.map((periodo) => (
                          <MenuItem key={periodo.id} value={periodo.id}>
                            {periodo.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={6} sx={{ py: "10px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="status-select"
                        >
                          Estado
                        </InputLabel>
                      </Box>
                      <TextField
                        id="status-select"
                        select
                        error={Boolean(
                          formik.touched.status && formik.errors.status
                        )}
                        fullWidth
                        helperText={
                          formik.touched.status && formik.errors.status
                        }
                        name="status"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.status}
                        variant="filled"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: true,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                              display: "flex",
                              justifyContent: "flex-start",
                            },
                          },
                        }}
                      >
                        {statuses.map((status) => (
                          <MenuItem key={status.id} value={status.value}>
                            {status.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6} sx={{ py: "10px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="prioridad-input"
                        >
                          Prioridad
                        </InputLabel>
                      </Box>
                      <TextField
                        id="prioridad-input"
                        error={Boolean(
                          formik.touched.priority && formik.errors.priority
                        )}
                        fullWidth
                        helperText={
                          formik.touched.priority && formik.errors.priority
                        }
                        name="priority"
                        onBlur={formik.handleBlur}
                        onChange={handleChangePriority}
                        type="number"
                        value={formik.values.priority}
                        variant="filled"
                        placeholder="Prioridad"
                        inputProps={{
                          min: 0,
                        }}
                        InputProps={{
                          disableUnderline: true,
                          readOnly: true,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ py: "10px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="novedad-input"
                        >
                          Novedad
                        </InputLabel>
                      </Box>
                      <TextField
                        id="novedad-input"
                        error={Boolean(
                          formik.touched.fileName && formik.errors.fileName
                        )}
                        fullWidth
                        helperText={
                          formik.touched.fileName && formik.errors.fileName
                        }
                        name="fileName"
                        value={formik.values.fileName}
                        variant="filled"
                        placeholder="Subir archivo"
                        InputProps={{
                          endAdornment: formik.values.file && (
                            <InputAdornment position="end">
                              <HtmlTooltip title={<b>Descargar</b>}>
                                <span>
                                  <IconButton
                                    aria-label="descargar plantillo modelo"
                                    onClick={() => downloadArch()}
                                    disabled={disabledDownload}
                                  >
                                    <MaterialIcons.FileDownloadIcon
                                      color={
                                        disabledDownload
                                          ? "disabled"
                                          : "primary"
                                      }
                                    />
                                  </IconButton>
                                </span>
                              </HtmlTooltip>
                            </InputAdornment>
                          ),
                          readOnly: true,
                          disableUnderline: true,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      item
                      xs={2}
                      sx={{ pb: "24px", pt: "10px", pl: "18px" }}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="urgent"
                              checked={formik.values.urgent}
                              onChange={formik.handleChange}
                              inputProps={{
                                "aria-label": "urgente-checkbox",
                              }}
                              disabled
                            />
                          }
                          sx={{
                            ".Mui-disabled.MuiTypography-root": {
                              color: "black",
                            },
                            ".Mui-disabled.MuiCheckbox-root": {
                              color: (theme) => theme.palette.primary.main,
                            },
                          }}
                          label="Urgente"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    maxWidth="sm"
                    paddingBottom="32px"
                    paddingLeft="18px"
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => navigate(-1)}
                      id="cancelar-edicion-button"
                    >
                      Atras
                    </Button>
                  </Grid>
                </Box>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>

      {!isLoading && (
        <>
          <Grid bgcolor="#EEF1FC" container>
            <Box
              sx={{
                display: "flex",
                placeItems: "center",
                justifyContent: "flex-start",
                py: "30px",
              }}
            >
              <Typography
                sx={{ fontSize: "24px", fontWeight: 500, lineHeight: "24px" }}
              >
                Resultado
              </Typography>
            </Box>
          </Grid>

          <Grid container borderRadius="6px">
            <Grid bgcolor="white" item xs={8} borderRadius="6px">
              <Box
                sx={{
                  borderRadius: "6px",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "white",
                  height: "100%",
                  width: "74%",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "6px",
                    flexGrow: 1,
                  }}
                >
                  <Grid container>
                    <Stack spacing={1} width="100%">
                      <Grid container paddingLeft="18px" paddingTop="18px">
                        <Grid item xs={6}>
                          <Typography textAlign="start">
                            Fecha de procesamiento:
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography textAlign="start">
                            {formik.values.process_date
                              ? new Date(
                                  formik.values.process_date
                                ).toLocaleString()
                              : "No procesado"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container paddingLeft="18px" paddingTop="10px">
                        <Grid item xs={6}>
                          <Typography textAlign="start">Resultado:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          {formik.values.error && (
                            <Box height="auto">
                              <Typography
                                overflow="hidden"
                                whiteSpace="nowrap"
                                textAlign="start"
                                textOverflow="ellipsis"
                              >
                                {formik.values.error}
                              </Typography>
                            </Box>
                          )}
                          {formik.values.result && (
                            <Box>
                              <Typography textAlign="start">
                                {formik.values.result}
                              </Typography>
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                      {(formik.values.error || formik.values.result) && (
                        <Grid
                          container
                          sx={{
                            paddingLeft: "18px",
                            paddingTop: "10px",
                            paddingBottom: "32px",
                          }}
                        >
                          <Button
                            color="primary"
                            variant="contained"
                            startIcon={<MaterialIcons.DescriptionIcon />}
                            onClick={() => getNovedadLogs()}
                            disabled={disabledDownloadLogs}
                          >
                            Descargar logs
                          </Button>
                        </Grid>
                      )}
                    </Stack>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      )}

      {openErrorModal && (
        <InformationModal
          title="Ha ocurrido un error"
          message={errorMessage}
          onClose={handleCloseModal}
          openModal={openErrorModal}
          clickCancelar={false}
          clickEliminar={false}
          guardar={false}
          isDirty={false}
          errorMessage
        />
      )}
    </Layout>
  );
};

export default NovedadDetails;
