import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

import {
  IdentificationType,
  NoveltyType,
  ParametersPeriodType,
} from "../types";

export type ParametrosState = {
  hasFetchError: boolean;
  errorMessage: string;
  tabValue: number;
  empresaTabValue: number;
  allPeriodos: ParametersPeriodType[];
  allNovedades: NoveltyType[];
  allCompanyNovedadesType: any[];
  actualEmpresaNovedades: any[];
  actualEmpresaPeriodos: any[];
  identifications: IdentificationType[];
  refreshPeriods: boolean;
  refreshNovedades: boolean;
  refreshActualNovedades: boolean;
  actualEmpresa: string;
};

export type ParametrosAction = {
  resetParametrosState: () => void;
  setIdentifications: (identifications: IdentificationType[]) => void;
  setAllPeriodos: (periods: ParametersPeriodType[]) => void;
  setAllCompanyNovedadesType: (allCompanyNovedadesType: any[]) => void;
  setTabValue: (tabValue: number) => void;
  setEmpresaTabValue: (empresaTabValue: number) => void;
  returnAllPeriodos: () => ParametersPeriodType[];
  returnAllNovedades: () => NoveltyType[];
  returnAllCompanyNovedadesType: () => any[];
  setRefreshPeriodos: (status: boolean) => void;
  setRefreshNovedades: (status: boolean) => void;
  setRefreshActualEmpresaNovedades: (status: boolean) => void;
  setActualEmpresa: (empresaId: string) => void;
};

// define the initial state

const initialParametrosState: ParametrosState = {
  hasFetchError: false,
  errorMessage: "",
  tabValue: 0,
  empresaTabValue: 0,
  allPeriodos: [],
  allNovedades: [],
  allCompanyNovedadesType: [],
  actualEmpresaNovedades: [],
  actualEmpresaPeriodos: [],
  identifications: [],
  refreshPeriods: false,
  refreshNovedades: false,
  refreshActualNovedades: false,
  actualEmpresa: "",
};

export const useParametrosStore = create<ParametrosState & ParametrosAction>()(
  persist(
    (set, get) => ({
      ...initialParametrosState,
      setAllPeriodos: (allPeriodos) => {
        set((state) => ({
          ...state,
          allPeriodos,
        }));
      },
      setIdentifications: (identifications) => {
        set((state) => ({
          ...state,
          identifications,
        }));
      },
      setAllCompanyNovedadesType: (allCompanyNovedadesType) => {
        const sortedNovelties = allCompanyNovedadesType.sort(function (
          a: { novelty_type: { name: string } },
          b: { novelty_type: { name: string } }
        ) {
          if (a.novelty_type.name < b.novelty_type.name) {
            return -1;
          }
          if (a.novelty_type.name > b.novelty_type.name) {
            return 1;
          }
          return 0;
        });
        set((state) => ({
          ...state,
          allCompanyNovedadesType:
            allCompanyNovedadesType.length > 0 ? sortedNovelties : [],
        }));
      },
      setTabValue: (tabValue) => {
        set((state) => ({
          ...state,
          tabValue,
        }));
      },
      setEmpresaTabValue: (empresaTabValue) => {
        set((state) => ({
          ...state,
          empresaTabValue,
        }));
      },
      returnAllPeriodos: () => {
        return get().allPeriodos;
      },
      returnAllNovedades: () => {
        return get().allNovedades;
      },
      returnAllCompanyNovedadesType: () => {
        return get().allCompanyNovedadesType;
      },
      setRefreshPeriodos: (status) => {
        set((state) => ({
          ...state,
          refreshPeriods: status,
        }));
      },
      setRefreshNovedades: (status) => {
        set((state) => ({
          ...state,
          refreshNovedades: status,
        }));
      },
      setRefreshActualEmpresaNovedades: (status) => {
        set((state) => ({
          ...state,
          refreshActualNovedades: status,
        }));
      },
      setActualEmpresa: (empresaId) => {
        set((state) => ({
          ...state,
          actualEmpresa: empresaId,
        }));
      },
      resetParametrosState: () => {
        set(initialParametrosState);
      },
    }),
    {
      name: "parametros-storage", // name of the item in the storage (must be unique)
      storage: createJSONStorage<ParametrosState>(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);
