import React, { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { isEqual } from "lodash";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";

import Layout from "../Layout/Layout";
import InformationModal from "../Modals/ConfirmationModal/InformationModal";

import { getUserProfile, updateUserProfile } from "../../services/UsersService";

const AccountProfile = () => {
  const [refreshUserData, setRefreshUserData] = useState<boolean>(false);
  const [clickCancelar, setClickCancelar] = useState<boolean>(false);
  const [clickGuardar, setClickGuardar] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [openInformationModal, setOpenInformationModal] =
    useState<boolean>(false);
  const [openProcessingModal, setOpenProcessingModal] =
    useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [disabledOnSubmit, setDisabledOnsubmit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [previusState, setPreviusState] = useState<{
    given_name: string;
    family_name: string;
    email: string;
    phone_number: string;
    nickname: string;
  }>({
    given_name: "",
    family_name: "",
    email: "",
    phone_number: "",
    nickname: "",
  });

  const { getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      given_name: "",
      family_name: "",
      email: "",
      phone_number: "",
      nickname: "",
    },
    validationSchema: Yup.object({
      given_name: Yup.string().max(55).required("El nombre es requerido"),
      email: Yup.string().max(55).required("El email es requerido"),
    }),
    onSubmit: async (data) => {
      setDisabledOnsubmit(true);
      const token = await getAccessTokenSilently();
      updateUserProfile(token, data)
        .then(() => {
          setOpenInformationModal(false);
          setRefreshUserData(true);
          setDisabledOnsubmit(false);
          setIsLoading(true);
        })
        .catch((e) => {
          console.error(e.response.data.error);
          setErrorMessage(
            `Ha ocurrido un error al editar el usuario. ${e.response?.data?.error}`
          );
          setDisabledOnsubmit(false);
          setOpenErrorModal(true);
        });
    },
  });

  const handleSaveButton = () => {
    formik.submitForm();
  };

  const handleChangePhoneNumber = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!/\D/.test(e.target.value)) {
      formik.setFieldValue("phone_number", e.target.value);
    }
  };

  const handleCloseModal = () => {
    setOpenInformationModal(false);
    openErrorModal && setOpenErrorModal(false);
    openProcessingModal && setOpenProcessingModal(false);
  };

  const handleDeshacerCancelar = () => {
    setClickCancelar(true);
    setClickGuardar(false);
    formik.resetForm();
    navigate(-1);
  };

  const handleClickDirtyCancelar = () => {
    setClickGuardar(false);
    setOpenInformationModal(true);
    setIsDirty(true);
    setClickCancelar(true);
  };

  const handleClickGuardar = () => {
    setIsDirty(false);
    setClickCancelar(false);
    setClickGuardar(true);
    setOpenInformationModal(true);
  };

  const getUserData = useCallback(async () => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    const userProfile = await getUserProfile(token);

    formik.setValues({
      given_name: userProfile.data.given_name,
      family_name: userProfile.data.family_name,
      email: userProfile.data.email,
      phone_number: userProfile.data.phone_number,
      nickname: userProfile.data.nickname,
    });

    setPreviusState({
      given_name: userProfile.data.given_name,
      family_name: userProfile.data.family_name,
      email: userProfile.data.email,
      phone_number: userProfile.data.phone_number,
      nickname: userProfile.data.nickname,
    });

    setIsLoading(false);

    if (refreshUserData) {
      setRefreshUserData(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (refreshUserData) {
      getUserData();
    }
  }, [getUserData, refreshUserData]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  return (
    <Layout icon="PersonIcon" title="Perfil">
      <Grid
        container
        sx={{
          backgroundColor: "white",
          borderRadius: "6px",
          marginTop: "12px",
          width: "588px",
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              borderRadius: "6px",
              display: "flex",
              backgroundColor: "white",
              height: "100%",
              width: "100%",
              paddingRight: "18px",
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Box
                id="create-novelty-form"
                borderRadius="6px"
                sx={{
                  backgroundColor: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "403px",
                }}
              >
                <CircularProgress />
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
              height: "100%",
              width: "100%",
              borderRadius: "6px",
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Box
                component="form"
                noValidate
                autoComplete="off"
                borderRadius="6px"
                sx={{ backgroundColor: "white" }}
                onSubmit={formik.handleSubmit}
              >
                <Grid container maxWidth="md" justifyContent="space-between">
                  <Grid item xs={6} sx={{ pt: "18px", pl: "18px" }}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      marginBottom="4px"
                    >
                      <InputLabel
                        sx={{ color: "black" }}
                        htmlFor="username-input"
                      >
                        Usuario
                      </InputLabel>
                    </Box>
                    <TextField
                      id="username-input"
                      error={Boolean(
                        formik.touched.nickname && formik.errors.nickname
                      )}
                      fullWidth
                      helperText={
                        formik.touched.nickname && formik.errors.nickname
                      }
                      name="nickname"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.nickname}
                      variant="filled"
                      placeholder="Nombre de ususario"
                      InputProps={{
                        disableUnderline: true,
                        sx: {
                          borderRadius: "6px",
                          ".MuiInputBase-input": {
                            paddingBottom: "16px",
                            paddingTop: "16px",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ pt: "18px", px: "18px" }}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      marginBottom="4px"
                    >
                      <InputLabel sx={{ color: "black" }} htmlFor="email-input">
                        Email
                      </InputLabel>
                    </Box>
                    <TextField
                      id="email-input"
                      error={Boolean(
                        formik.touched.email && formik.errors.email
                      )}
                      fullWidth
                      helperText={formik.touched.email && formik.errors.email}
                      name="email"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      variant="filled"
                      placeholder="E-mail"
                      InputProps={{
                        disableUnderline: true,
                        sx: {
                          borderRadius: "6px",
                          ".MuiInputBase-input": {
                            paddingBottom: "16px",
                            paddingTop: "16px",
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container maxWidth="md" justifyContent="space-between">
                  <Grid item xs={6} sx={{ pt: "18px", pl: "18px" }}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      marginBottom="4px"
                    >
                      <InputLabel
                        sx={{ color: "black" }}
                        htmlFor="nombre-input"
                      >
                        Nombre
                      </InputLabel>
                    </Box>
                    <TextField
                      id="nombre-input"
                      error={Boolean(
                        formik.touched.given_name && formik.errors.given_name
                      )}
                      fullWidth
                      helperText={
                        formik.touched.given_name && formik.errors.given_name
                      }
                      name="given_name"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      value={formik.values.given_name}
                      variant="filled"
                      placeholder="Nombre"
                      InputProps={{
                        disableUnderline: true,
                        sx: {
                          borderRadius: "6px",
                          ".MuiInputBase-input": {
                            paddingBottom: "16px",
                            paddingTop: "16px",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ pt: "18px", px: "18px" }}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      marginBottom="4px"
                    >
                      <InputLabel
                        sx={{ color: "black" }}
                        htmlFor="apellido-input"
                      >
                        Apellido
                      </InputLabel>
                    </Box>
                    <TextField
                      id="apellido-input"
                      error={Boolean(
                        formik.touched.family_name && formik.errors.family_name
                      )}
                      fullWidth
                      helperText={
                        formik.touched.family_name && formik.errors.family_name
                      }
                      name="family_name"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.family_name}
                      variant="filled"
                      placeholder="Apellido"
                      InputProps={{
                        disableUnderline: true,
                        sx: {
                          borderRadius: "6px",
                          ".MuiInputBase-input": {
                            paddingBottom: "16px",
                            paddingTop: "16px",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ pt: "18px", pl: "18px" }}>
                    <Box display="flex" justifyContent="flex-start">
                      <InputLabel sx={{ color: "black" }} htmlFor="phone-input">
                        Teléfono
                      </InputLabel>
                    </Box>
                    <TextField
                      id="phone-input"
                      error={Boolean(
                        formik.touched.phone_number &&
                          formik.errors.phone_number
                      )}
                      fullWidth
                      helperText={
                        formik.touched.phone_number &&
                        formik.errors.phone_number
                      }
                      name="phone_number"
                      onBlur={formik.handleBlur}
                      onChange={(e) => handleChangePhoneNumber(e)}
                      value={formik.values.phone_number}
                      variant="filled"
                      type="text"
                      placeholder="Teléfono"
                      InputProps={{
                        disableUnderline: true,
                        sx: {
                          borderRadius: "6px",
                          ".MuiInputBase-input": {
                            paddingBottom: "16px",
                            paddingTop: "16px",
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container maxWidth="sm" sx={{ py: "32px" }}>
                  <Box sx={{ display: "flex" }}>
                    <Button
                      sx={{ marginLeft: "18px" }}
                      variant="contained"
                      color="primary"
                      onClick={handleClickGuardar}
                      id="guardar-edicion-user-button"
                      disabled={
                        !formik.values.given_name ||
                        !formik.values.email ||
                        isEqual(formik.values, previusState)
                      }
                    >
                      Guardar
                    </Button>

                    {formik.dirty && !isEqual(formik.values, previusState) ? (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleClickDirtyCancelar}
                        id="cancelar-edicion-user-modal-button"
                      >
                        Cancelar Edición
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => navigate(-1)}
                        id="cancelar-edicion-user-button"
                      >
                        Cancelar Edición
                      </Button>
                    )}
                  </Box>
                </Grid>
              </Box>
            </Box>
          </Box>
        )}
      </Grid>

      {openInformationModal && (
        <InformationModal
          title={isDirty ? "Cancelar cambios" : "Editar usuario"}
          message={
            isDirty
              ? "¿Querés cancelar los cambios realizados?"
              : "¿Querés guardar los cambios realizados a éste usuario?"
          }
          openModal={openInformationModal}
          onClose={handleCloseModal}
          saveForm={handleSaveButton}
          isDirty={isDirty}
          clickCancelar={clickCancelar}
          handleDeshacerCancelar={handleDeshacerCancelar}
          guardar={clickGuardar}
          guardarMessage="¿Querés guardar los cambios realizados a éste usuario?"
          clickEliminar={false}
          disabledOnSubmit={disabledOnSubmit}
        />
      )}

      {openErrorModal && (
        <InformationModal
          title="Ha ocurrido un error"
          message={errorMessage}
          onClose={handleCloseModal}
          openModal={openErrorModal}
          clickCancelar={false}
          clickEliminar={false}
          guardar={false}
          isDirty={false}
          errorMessage
        />
      )}
    </Layout>
  );
};

export default AccountProfile;
