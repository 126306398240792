import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";

import Layout from "../Layout/Layout";
import InformationModal from "../Modals/ConfirmationModal/InformationModal";
import CustomBreadcrumbs from "../CustomBreadcrumbs/CustomBreadcrumbs";
import HtmlTooltip from "../HtmlTooltip/HtmlTooltip";

import { MaterialIcons } from "../../icons/Material/MaterialIcons";

import { addCompanyNoveltyType } from "../../services/CompaniesService";
import { getNoveltyType } from "../../services/ParametersService";

import { NoveltyType } from "../../types";

const AsignarNovedad = () => {
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [clickCancelar, setClickCancelar] = useState<boolean>(false);
  const [clickGuardar, setClickGuardar] = useState<boolean>(false);
  const [disabledOnSubmit, setDisabledOnSubmit] = useState<boolean>(false);
  const [allNovelties, setAllNovelties] = useState<NoveltyType[]>([]);
  const [openInformationModal, setOpenInformationModal] =
    useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const navigate = useNavigate();
  const params = useParams();

  const { getAccessTokenSilently } = useAuth0();

  const handleOpenModal = () => {
    setOpenInformationModal(true);
  };

  const handleCloseModal = () => {
    setOpenInformationModal(false);
    if (openErrorModal) {
      setErrorMessage("");
      setOpenErrorModal(false);
    }
  };

  const handleClickDirtyCancelar = () => {
    setClickGuardar(false);
    setIsDirty(true);
    setClickCancelar(true);
    handleOpenModal();
  };

  const handleClickGuardar = () => {
    setIsDirty(false);
    setClickCancelar(false);
    setClickGuardar(true);
    handleOpenModal();
  };

  const getNovedades = useCallback(async () => {
    const token = await getAccessTokenSilently();
    getNoveltyType(token)
      .then((novedades) => {
        setAllNovelties(novedades.data);
      })
      .catch((e) => {
        console.error(e.response.data.error);
        setAllNovelties([]);
      });
  }, [getAccessTokenSilently]);

  const handleDeshacerCancelar = () => {
    formik.resetForm();
    navigate(`/empresas/empresa/${params.empresaId}/parametros`);
  };

  const handleSaveButton = () => {
    formik.submitForm();
  };

  const links: any[] = [
    { id: "1", value: "Empresas", isLink: true, to: "/empresas" },
    {
      id: "2",
      value: "Empresa",
      isLink: true,
      to: `/empresas/empresa/${params.empresaId}`,
    },
    {
      id: "3",
      value: "Parametros",
      isLink: true,
      to: `/empresas/empresa/${params.empresaId}/parametros`,
    },
    { id: "4", value: "Tipo de Novedad", isLink: false },
  ];

  const formik = useFormik({
    initialValues: {
      name: "",
      novelty_type: "",
      fileName: "",
      file: "",
      automatic: false,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(55)
        .required("El nombre de la novedad es requerido"),
      novelty_type: Yup.mixed().required("Seleccione un tipo de novedad"),
    }),
    onSubmit: async (data) => {
      setDisabledOnSubmit(true);
      const finalNovedad = {
        ...allNovelties.find((novelty) => novelty.id === data.novelty_type)!,
        name: data.name,
      };

      const finalData = {
        company_id: params.empresaId,
        novelty_type: JSON.stringify(finalNovedad),
        file: data.file,
        automatic: data.automatic,
      };

      const token = await getAccessTokenSilently();
      addCompanyNoveltyType(token, finalData)
        .then((result) => {
          formik.resetForm();
          if (result.status === 200 || result.status === 201) {
            navigate(-1);
            setOpenInformationModal(false);
            setDisabledOnSubmit(false);
          }
        })
        .catch((err) => {
          console.error("ERROR ", err.response.data.error);
          setErrorMessage(err.response.data.error);
          setDisabledOnSubmit(false);
          setOpenErrorModal(true);
        });
    },
  });

  const handleUploadFile = (file: any) => {
    formik.setFieldValue("file", file);
    formik.setFieldValue("fileName", file.name);
  };

  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - Crear tipo de novedad`;
  }, []);

  useEffect(() => {
    getNovedades();
  }, [getNovedades]);

  return (
    <Layout noIcon title="Crear tipo de novedad">
      <CustomBreadcrumbs icon="PollIcon" links={links} />
      <Grid container marginTop="12px" borderRadius="6px">
        <Grid bgcolor="white" item xs={8} borderRadius="6px">
          <Box
            sx={{
              borderRadius: "6px",
              display: "flex",
              backgroundColor: "white",
              height: "100%",
              width: "100%",
              paddingRight: "18px",
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Box
                component="form"
                autoComplete="off"
                borderRadius="6px"
                sx={{ backgroundColor: "white", height: "100%" }}
                onSubmit={formik.handleSubmit}
              >
                <Grid container alignItems="center">
                  <Grid item xs={6} sx={{ pt: "18px", pl: "18px" }}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      marginBottom="4px"
                    >
                      <InputLabel
                        sx={{ color: "black" }}
                        htmlFor="nombre-input"
                      >
                        Nombre
                      </InputLabel>
                    </Box>
                    <TextField
                      id="nombre-input"
                      error={Boolean(formik.touched.name && formik.errors.name)}
                      fullWidth
                      helperText={formik.touched.name && formik.errors.name}
                      name="name"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      value={formik.values.name}
                      variant="filled"
                      placeholder="Nombre de la novedad"
                      InputProps={{
                        disableUnderline: true,
                        sx: {
                          borderRadius: "6px",
                          ".MuiInputBase-input": {
                            paddingBottom: "16px",
                            paddingTop: "16px",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} sx={{ pt: "36px", pl: "18px" }}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="automatic"
                            checked={formik.values.automatic}
                            onChange={formik.handleChange}
                            inputProps={{
                              "aria-label": "procesamiento-automatico-checkbox",
                            }}
                          />
                        }
                        label="Procesamiento automático"
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6} sx={{ pt: "18px", pl: "18px" }}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      marginBottom="4px"
                    >
                      <InputLabel sx={{ color: "black" }} htmlFor="type-select">
                        Tipo
                      </InputLabel>
                    </Box>
                    <TextField
                      id="type-select"
                      select
                      error={Boolean(
                        formik.touched.novelty_type &&
                          formik.errors.novelty_type
                      )}
                      fullWidth
                      helperText={
                        formik.touched.novelty_type &&
                        formik.errors.novelty_type
                      }
                      name="novelty_type"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.novelty_type}
                      variant="filled"
                      InputProps={{
                        disableUnderline: true,
                        sx: {
                          borderRadius: "6px",
                          ".MuiInputBase-input": {
                            paddingBottom: "16px",
                            paddingTop: "16px",
                            display: "flex",
                            justifyContent: "flex-start",
                          },
                        },
                      }}
                    >
                      {allNovelties.map((novelty) => (
                        <MenuItem key={novelty.id} value={novelty.id}>
                          {`${novelty.code} - ${novelty.name}`}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container justifyContent="space-between">
                  <Grid item xs={6} sx={{ py: "10px", pl: "18px" }}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      marginBottom="4px"
                    >
                      <InputLabel
                        sx={{ color: "black" }}
                        htmlFor="archivo-input"
                      >
                        Archivo
                      </InputLabel>
                    </Box>
                    <Stack direction="row" spacing={2} marginBottom="4px">
                      <TextField
                        id="archivo-input"
                        error={Boolean(
                          formik.touched.fileName && formik.errors.fileName
                        )}
                        fullWidth
                        helperText={
                          formik.touched.fileName && formik.errors.fileName
                        }
                        name="fileName"
                        value={formik.values.fileName}
                        variant="filled"
                        placeholder="Subir archivo"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <HtmlTooltip title={<b>Subir archivo</b>}>
                                <IconButton
                                  aria-label="subir archivo"
                                  component="label"
                                >
                                  <input
                                    hidden
                                    id="importar-plantilla-icon-button"
                                    type="file"
                                    name="file"
                                    onChange={(e: any) =>
                                      handleUploadFile(e.currentTarget.files[0])
                                    }
                                    accept=".csv, .xlsm, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                  />
                                  <MaterialIcons.FileUploadIcon color="primary" />
                                </IconButton>
                              </HtmlTooltip>
                            </InputAdornment>
                          ),
                          readOnly: true,
                          disableUnderline: true,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Stack>
                  </Grid>
                </Grid>
                <Grid container maxWidth="sm" paddingBottom="32px">
                  <Box sx={{ display: "flex" }}>
                    <Button
                      sx={{ marginLeft: "18px" }}
                      variant="contained"
                      color="primary"
                      onClick={handleClickGuardar}
                      disabled={
                        !formik.dirty ||
                        !formik.values.name ||
                        !formik.values.novelty_type ||
                        !formik.values.fileName
                      }
                    >
                      Guardar
                    </Button>

                    {formik.dirty ? (
                      <Button
                        sx={{ marginLeft: "18px" }}
                        variant="contained"
                        color="error"
                        onClick={handleClickDirtyCancelar}
                      >
                        Cancelar
                      </Button>
                    ) : (
                      <Button
                        sx={{ marginLeft: "18px" }}
                        variant="contained"
                        color="error"
                        onClick={() => navigate(-1)}
                      >
                        Cancelar
                      </Button>
                    )}
                  </Box>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {openErrorModal && (
        <InformationModal
          title="Ha ocurrido un error"
          message={errorMessage}
          onClose={handleCloseModal}
          openModal={openErrorModal}
          clickCancelar={false}
          clickEliminar={false}
          guardar={false}
          isDirty={false}
          errorMessage
        />
      )}

      {openInformationModal && (
        <InformationModal
          title={isDirty ? "Cancelar cambios" : "Crear tipo de novedad"}
          message={
            isDirty
              ? "¿Querés cancelar los cambios realizados?"
              : "¿Querés crear éste tipo de novedad?"
          }
          openModal={openInformationModal}
          onClose={handleCloseModal}
          saveForm={handleSaveButton}
          isDirty={isDirty}
          clickCancelar={clickCancelar}
          handleDeshacerCancelar={handleDeshacerCancelar}
          guardar={clickGuardar}
          guardarMessage="¿Querés guardar ésta novedad?"
          clickEliminar={false}
          disabledOnSubmit={disabledOnSubmit}
        />
      )}
    </Layout>
  );
};

export default AsignarNovedad;
