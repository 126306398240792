import * as React from "react";
import { useFormik } from "formik";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";

import UsersList from "./UsersList";

import { inviteCompanyUser } from "../../../services/CompaniesService";
import { getAllUsers } from "../../../services/UsersService";

type InvitarUsuarioModalType = {
  openModal: boolean;
  handleRefreshTable: React.Dispatch<React.SetStateAction<boolean>>;
  changeOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleOpenErrorModal: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
};

const InvitarUsuarioModal: React.FC<InvitarUsuarioModalType> = ({
  changeOpenModal,
  handleRefreshTable,
  handleOpenErrorModal,
  setErrorMessage,
  openModal,
}) => {
  const [disabledOnSubmit, setDisabledOnSubmit] =
    React.useState<boolean>(false);
  const [userList, setUsersList] = React.useState<any[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { getAccessTokenSilently } = useAuth0();

  const params = useParams();

  const empresaId = params.empresaId;

  const formik = useFormik({
    initialValues: {
      company_id: empresaId,
      role: 1,
      status: 1,
      user_id: "",
    },
    onSubmit: async (data) => {
      setDisabledOnSubmit(true);
      const token = await getAccessTokenSilently();
      inviteCompanyUser(token, data)
        .then((res) => {
          setDisabledOnSubmit(false);
          handleRefreshTable(true);
          changeOpenModal(false);
          formik.resetForm();
        })
        .catch((e) => {
          setDisabledOnSubmit(false);
          changeOpenModal(false);
          handleOpenErrorModal(true);
          setErrorMessage("El usuario que intenta asignar, ya está asignado");
        });
    },
  });

  const getUsers = React.useCallback(async () => {
    const token = await getAccessTokenSilently();
    setIsLoading(true);
    getAllUsers(token)
      .then((res) => {
        setUsersList(res.data);
        setIsLoading(false);
      })
      .catch((e) => {
        console.error("Error obteniendo la lista de ususarios", e);
        setUsersList([]);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    changeOpenModal(false);
  };

  React.useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: "40%",
            height: "744px",
          },
        }}
      >
        <DialogTitle>Invitar Usuario</DialogTitle>
        <DialogContent>
          <Grid container borderRadius="6px">
            <Grid bgcolor="white" item xs borderRadius="6px">
              <Box
                sx={{
                  borderRadius: "6px",
                  display: "flex",
                  backgroundColor: "white",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Box
                    component="form"
                    id="crear-usuario-form"
                    autoComplete="off"
                    borderRadius="6px"
                    sx={{ backgroundColor: "white", height: "100%" }}
                    onSubmit={formik.handleSubmit}
                  >
                    <Grid container>
                      <Grid bgcolor="white" item xs>
                        <Grid container>
                          <Grid item xs={12} sx={{ py: "10px" }}>
                            <UsersList
                              handleSelectUserId={formik.setFieldValue}
                              userList={userList}
                              isLoading={isLoading}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ paddingBottom: "16px", paddingRight: "24px" }}>
          <Button color="primary" variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            form="crear-usuario-form"
            type="submit"
            disabled={!formik.values.user_id || disabledOnSubmit}
          >
            Invitar Usuario
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InvitarUsuarioModal;
