import React from "react";
import { useParams } from "react-router-dom";

import ParametroNovedadDetails from "../../components/Details/Parametros/ParametroNovedadDetails";

const ParametroNovedadDetailsPage = () => {
  const params = useParams();
  return <ParametroNovedadDetails params={params} />;
};

export default ParametroNovedadDetailsPage;
