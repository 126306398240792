import React from "react";
import { useParams } from "react-router-dom";

import EmpresaTipoNovedadDetails from "../../components/EmpresaParametros/EmpresaTipoNovedadDetails";

const EmpresaTipoNovedadDetailsPage = () => {
  const params = useParams();
  return <EmpresaTipoNovedadDetails params={params} />;
};

export default EmpresaTipoNovedadDetailsPage;
