import React from "react";
import { useParams } from "react-router-dom";

import EmpresaTipoPeriodoDetails from "../../components/EmpresaParametros/EmpresaTipoPeriodoDetails";

const EmpresaTipoPeriodoDetailsPage = () => {
  const params = useParams();
  return <EmpresaTipoPeriodoDetails params={params} />;
};

export default EmpresaTipoPeriodoDetailsPage;
