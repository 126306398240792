import React from "react";
import { useParams } from "react-router-dom";
import ParametroIdentificacionDetails from "../../components/Details/Parametros/ParametroIdentificacionDetails";

const ParametroIdentificacionDetailsPage = () => {
  const params = useParams();
  return <ParametroIdentificacionDetails params={params} />;
};

export default ParametroIdentificacionDetailsPage;
