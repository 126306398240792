import React from "react";
import ReactDOM from "react-dom/client";

import { DrawerProvider } from "./context/drawer/DrawerProvider";
import { UserProvider } from "./context/user/UserProvider";

import reportWebVitals from "./reportWebVitals";
import Auth0ProviderConfig from "./auth0Provider/Auth0";
import App from "./App";

import "react-perfect-scrollbar/dist/css/styles.css"; //Layout scrollbar styles
import "react-date-range/dist/styles.css"; // react date range main css file
import "react-date-range/dist/theme/default.css"; // react date range theme css file

import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  <Auth0ProviderConfig>
    <UserProvider>
      <DrawerProvider>
        <App />
      </DrawerProvider>
    </UserProvider>
  </Auth0ProviderConfig>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
