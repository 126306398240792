import React, { useEffect } from "react";
import CompaniesParametros from "../components/Parametros/CompaniesParametros";

const EmpresasParametrosPage = () => {
  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - Parametros`;
  }, []);

  return <CompaniesParametros />;
};

export default EmpresasParametrosPage;
