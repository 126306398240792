import React, { useCallback, useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams } from "react-router-dom";
// import { useHasPermissions } from "react-simple-rbac";
import { shallow } from "zustand/shallow";

import Box from "@mui/material/Box";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  MuiEvent,
} from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import SearchBar from "material-ui-search-bar";

import AgregarPeriodoModal from "../Modals/AgregarPeriodoModal/AgregarPeriodosModal";
import DataTable from "../table/DataTable";
import HtmlTooltip from "../HtmlTooltip/HtmlTooltip";
import InformationModal from "../Modals/ConfirmationModal/InformationModal";

import { MaterialIcons } from "../../icons/Material/MaterialIcons";

import { getCompanyPeriodsTypes } from "../../services/CompaniesService";

import { ParametroContext } from "../../context/parametro/ParametroContext";
import { DrawerContext } from "../../context/drawer/DrawerContext";

import { useUserStore } from "../../store/userStore";

import { TipoPeriodoRowsType } from "../../types";

const durationArray = [
  {
    id: 0,
    value: "Mensual",
  },
  {
    id: 1,
    value: "Quincenal",
  },
];

//1: success, 2:pending, 3:cancel
const getAction = (
  params: GridRenderCellParams,
  icon: keyof typeof MaterialIcons
) => {
  const Icon = MaterialIcons[icon || "PollIcon"];
  if (params.value === "2") {
    return (
      <Box
        sx={{
          display: "flex",
          placeItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Button variant="contained" color="primary">
          Procesar ahora
        </Button>
        <Icon />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        placeItems: "center",
        justifyContent: "flex-end",
        width: "100%",
      }}
    >
      <Icon />
    </Box>
  );
};
const getDuration = (params: GridValueFormatterParams) => {
  return durationArray.find((duration) => duration.id === params.value)?.value;
};

const TiposPeriodos = () => {
  const [searched, setSearched] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [periods, setPeriods] = useState<TipoPeriodoRowsType[]>([]);
  const [orignialPeriods, setOriginalPeriods] = useState<TipoPeriodoRowsType[]>(
    []
  );
  const [openAgregarPeriodoModal, setOpenAgregarPeriodoModal] =
    useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [refreshTable, setRefreshTable] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  /*const hasRequiredPermission = useHasPermissions([
    "create:period-type-company",
  ]);*/

  const navigate = useNavigate();

  const params = useParams();
  const empresaId = params.empresaId!;

  const { getAccessTokenSilently } = useAuth0();

  const { empresaTabValue } = useContext(ParametroContext);

  const { changeLink } = useContext(DrawerContext);

  const userDataStore = useUserStore((state) => state.user?.role, shallow);

  const isUserReadOnly = userDataStore === 6

  const columns: GridColDef[] = [
    // {
    //   field: "id",
    //   headerName: "ID",
    //   flex: 0.4,
    //   headerClassName: "theme--header",
    //   hideable: true,
    // },
    {
      field: "name",
      headerName: "Nombre",
      flex: 1,
      editable: true,
      headerClassName: "theme--header",
    },
    {
      field: "duration",
      headerName: "Duración",
      flex: 1,
      editable: true,
      headerClassName: "theme--header",
      valueFormatter: getDuration,
    },
    {
      field: "day_limit",
      headerName: "Día limite novedades",
      headerClassName: "theme--header",
      flex: 1,
    },
    {
      field: "periods_before",
      headerName: "Anticipo",
      headerClassName: "theme--header",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Acción",
      type: "number",
      flex: 1,
      editable: false,
      headerClassName: "theme--header",
      renderCell(params) {
        return getAction(
          params,
          changeLink === 1 ? "EditIcon" : "VisibilityOutlined"
        );
      },
    },
  ];

  const requestSearch = (searchedVal: string) => {
    const searchPeriodType = [...orignialPeriods];
    if (searchedVal.length > 2) {
      const filteredRows = searchPeriodType.filter((row) => {
        return row.name.toLowerCase().includes(searchedVal.toLowerCase());
      });
      setPeriods(filteredRows);
    } else {
      setPeriods(orignialPeriods);
    }
  };

  const handleClickCrearPeriodo = () => {
    setOpenAgregarPeriodoModal(true);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const handleOnCellClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>
  ) => {
    if (changeLink === 1) {
      navigate(`tipodeperiodo/periodo/${params.id}`);
    } else {
      navigate(`tipodeperiodo/periodo/${params.row.id}`);
    }
  };

  const getPeriod = useCallback(async () => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    getCompanyPeriodsTypes(token, empresaId)
      .then((result) => {
        setPeriods(result.data);
        setOriginalPeriods(result.data);
        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e.response.data.error);
        setPeriods([]);
        setOriginalPeriods([]);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresaId]);

  useEffect(() => {
    if (empresaTabValue === 0) {
      getPeriod();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresaTabValue]);

  useEffect(() => {
    if (refreshTable) {
      getPeriod();
      setRefreshTable(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);

  return (
    <Box
      sx={{
        height: "100%",
        "& .theme--header": {
          backgroundColor: "#EEF1FC",
        },
      }}
    >
      <Grid container spacing={2} sx={{ py: "24px" }}>
        <Grid display="flex" sx={{ placeItems: "center" }} item xs={6}>
          <Box sx={{ marginRight: 4, width: "459px" }}>
            <SearchBar
              value={searched}
              onChange={(searchVal: string) => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
              placeholder="Buscar tipos de periodos"
              style={{ boxShadow: "none" }}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Grid container display="flex" justifyContent="flex-end">
            <Stack direction="row" spacing={2}>
              {/* <Button
                variant="contained"
                startIcon={<MaterialIcons.FilterAltIcon />}
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  "&:hover": { color: "white" },
                }}
              >
                Filtrar
              </Button> */}
              {isUserReadOnly ? (
                <HtmlTooltip
                  title={
                    <Typography variant="body1">
                      Unauthorized access. <b>create:period-type-company</b>{" "}
                      permission needed
                    </Typography>
                  }
                  placement="top"
                >
                  <span>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleClickCrearPeriodo()}
                      disabled
                    >
                      Crear
                    </Button>
                  </span>
                </HtmlTooltip>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleClickCrearPeriodo()}
                >
                  Crear
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <DataTable
        columns={columns}
        rows={periods}
        loading={isLoading}
        noFilterRowsMessage="No existen tipos de periodos con ese nombre"
        noRowsMessage="No hay tipos de periodos"
        noRowClick
        onCellClick={handleOnCellClick}
      />

      {openErrorModal && (
        <InformationModal
          title="Ha ocurrido un error"
          message={errorMessage}
          onClose={() => setOpenErrorModal(false)}
          openModal={openErrorModal}
          clickCancelar={false}
          clickEliminar={false}
          guardar={false}
          isDirty={false}
          errorMessage
        />
      )}

      {openAgregarPeriodoModal && (
        <AgregarPeriodoModal
          changeOpenModal={setOpenAgregarPeriodoModal}
          openModal={openAgregarPeriodoModal}
          handleRefreshTable={setRefreshTable}
          setOpenErrorModal={setOpenErrorModal}
          setErrorMessage={setErrorMessage}
        />
      )}
    </Box>
  );
};

export default TiposPeriodos;
