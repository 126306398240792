import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { shallow } from "zustand/shallow";
import dayjs from "dayjs";

import Box from "@mui/material/Box";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import SearchBar from "material-ui-search-bar";

import { MaterialIcons } from "../../icons/Material/MaterialIcons";

import Layout from "../Layout/Layout";
import DataTable from "../table/DataTable";
import ReintentarModal from "../Modals/ReintentarModal/ReintentarModal";

import { getCompanyNoveltiesByPeriodId } from "../../services/CompaniesService";
import { getCloserPeriods } from "../../services/ParametersService";

import { useEmpresaStore } from "../../store/empresaStore";
// import { useUsersStore } from "../../store/usersStore";

import { reorderPeriods } from "../../utils/reorderPeriods";

import { CompanyNoveltiesType, CompanyPeriodsType } from "../../types";

const statuses = [
  { id: 1, value: 0, label: "Pendiente de Procesar" },
  { id: 2, value: 1, label: "Inactivo" },
  { id: 3, value: 2, label: "Cancelado" },
  { id: 4, value: 3, label: "Bloqueado" },
  { id: 5, value: 4, label: "Error en Proceso" },
  { id: 6, value: 5, label: "Procesado Exitosamente" },
];

//1: success, 2:pending, 3:cancel
const getUrgentIcon = (params: GridRenderCellParams) => {
  if (params.value) {
    return (
      <Box
        sx={{
          display: "flex",
          placeItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <MaterialIcons.LightningBolt color="error" />
      </Box>
    );
  }
  return <></>;
};

//1: success, 2:pending, 3:cancel
const getAction = (params: GridRenderCellParams) => {
  if (params.value === "2") {
    return (
      <Box
        sx={{
          display: "flex",
          placeItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Button variant="contained" color="primary">
          Procesar ahora
        </Button>
        <MaterialIcons.EditIcon />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        placeItems: "center",
        justifyContent: "flex-end",
        width: "100%",
      }}
    >
      <MaterialIcons.EditIcon />
    </Box>
  );
};

//0: Active, 1:Inactive, 2:Cancel, 3:Block, 4:Error
const getStatus = (params: GridRenderCellParams) => {
  return (
    <Box
      key={statuses[params.value]?.id}
      sx={{
        display: "flex",
        placeItems: "center",
        width: "100%",
      }}
    >
      {/* <MaterialIcons.BriefcaseCheck color="success" fontSize="small" /> */}
      <Typography marginLeft="8px">{statuses[params.value]?.label}</Typography>
    </Box>
  );
};

const Novedades = () => {
  const [searched, setSearched] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [openReintentarModal, setOpenReintentarModal] =
    useState<boolean>(false);
  const [retryGetPeriodos, setRetryGetPeriodos] = useState<boolean>(false);
  const [novelties, setNovelties] = useState<CompanyNoveltiesType[]>([]);
  const [originalNovelties, setOriginalNovelties] = useState<
    CompanyNoveltiesType[]
  >([]);
  const [empresaPeriodos, setEmpresaPeriodos] = useState<CompanyPeriodsType[]>(
    []
  );
  const [empresaPeriodo, setEmpresaPeriodo] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  const { empresaId, returnEmpresaPeriodos } = useEmpresaStore(
    ({ empresaId, returnEmpresaPeriodos }) => ({
      empresaId,
      returnEmpresaPeriodos,
    }),
    shallow
  );

  // const users = useUsersStore((state) => state.users, shallow);

  const requestSearch = (searchedVal: string) => {
    const newNovelties = [...originalNovelties];
    if (searchedVal.length > 2) {
      const filteredRows = newNovelties.filter((row) => {
        return row.name.toLowerCase().includes(searchedVal.toLowerCase());
      });
      setNovelties(filteredRows);
    } else {
      setNovelties(originalNovelties);
    }
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const crearNovedad = () => {
    navigate("crearnovedad");
  };

  const getPeriodo = (params: GridRenderCellParams) => {
    const periodos = [...returnEmpresaPeriodos()];
    if (params.value) {
      const periodo = periodos.find((period) => period.id === params.value)!;
      return periodo ? periodo.name : "no";
    }
  };

  const handleChangePeriod = (periodId: string) => {
    setEmpresaPeriodo(periodId);
    getNoveltiesByPeriodId(periodId);
  };

  /*
  const getCreatedByUser = (userId: string) => {
    const allUsers = [...users];
    if (userId) {
      const user = allUsers.find((user) => user.id === userId)!;
      return user ? user.email : "no";
    }
  };
  */

  const handleOpenDialog = () => {
    setOpenReintentarModal(true);
  };

  const handleCloseDialog = () => {
    setOpenReintentarModal(false);
  };

  const handleConfirmAction = () => {
    // Realiza la acción
    // ...
    setRetryGetPeriodos(true);

    // Cierra el diálogo
    setOpenReintentarModal(false);
  };

  const getCloserPeriod = (unOrderedPeriods: any[]) => {
    if (unOrderedPeriods.length > 0) {
      const periodos = [...unOrderedPeriods];
      const sortedPeriods = [...reorderPeriods(periodos)];
      setEmpresaPeriodos(sortedPeriods);
      setEmpresaPeriodo(sortedPeriods[0].id);
      getNoveltiesByPeriodId(sortedPeriods[0].id);
    } else {
      setIsLoading(false);
      setEmpresaPeriodos([]);
      setNovelties([]);
      setOriginalNovelties([]);
      setEmpresaPeriodo("");
    }
  };

  const columns: GridColDef[] = [
    // {
    //   field: "id",
    //   headerName: "ID",
    //   width: 90,
    //   headerClassName: "theme--header",
    //   flex: 0.4,
    // },
    {
      field: "name",
      headerName: "Novedad",
      headerClassName: "theme--header",
      flex: 1,
    },
    {
      field: "period_id",
      headerName: "Periodo",
      headerClassName: "theme--header",
      flex: 1,
      renderCell: getPeriodo,
    },
    {
      field: "created_at",
      headerName: "Fecha Alta",
      type: "dateTime",
      headerClassName: "theme--header",
      flex: 1,
      // valueGetter: ({ value }) => value && dayjs(value).format("DD/MM/YYYY"),
      valueFormatter: ({ value }) => value && dayjs(value).format("DD/MM/YYYY"),
    },
    {
      field: "process_date",
      headerName: "Fecha Procesamiento",
      type: "dateTime",
      headerClassName: "theme--header",
      flex: 1,
      // valueGetter: ({ value }) => value && dayjs(value).format("DD/MM/YYYY"),
      valueFormatter: ({ value }) => value && dayjs(value).format("DD/MM/YYYY"),
    },
    {
      field: "user",
      headerName: "Usuario",
      headerClassName: "theme--header",
      renderCell(params) {
        return params.value.email;
      },
      flex: 1,
    },
    {
      field: "urgent",
      headerName: "Urgente",
      headerClassName: "theme--header",
      renderCell: getUrgentIcon,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Estado",
      type: "number",
      headerClassName: "theme--header",
      renderCell: getStatus,
      flex: 1,
      headerAlign: "left",
    },
    {
      field: "action",
      headerName: "Acción",
      type: "number",
      headerClassName: "theme--header",
      renderCell: getAction,
      flex: 1,
    },
  ];

  const getNoveltiesByPeriodId = useCallback(
    async (periodId: string) => {
      setIsLoading(true);
      const token = await getAccessTokenSilently();
      getCompanyNoveltiesByPeriodId(token, empresaId, periodId)
        .then((result) => {
          setErrorMessage("");
          setNovelties(result.data);
          setOriginalNovelties(result.data);
          setIsLoading(false);
        })
        .catch((e) => {
          console.error("error", e.response.data.error);
          setErrorMessage(e.response.data.error);
          setNovelties([]);
          setOriginalNovelties([]);
          setIsLoading(false);
        });
    },
    [empresaId, getAccessTokenSilently]
  );

  /*
  const obtenerAvailablePeriods = useCallback(async () => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    getAvailablePeriods(token, empresaId)
      .then((result) => {
        setErrorMessage("");
        getCloserPeriod(result.data);
      })
      .catch((e) => {
        console.error("error", e.response.data.error);
        setErrorMessage(e.response.data.error);
        setEmpresaPeriodos([]);
        setEmpresaPeriodo("");
        setNovelties([]);
        setOriginalNovelties([]);
        setIsLoading(false);
      });

    // const result = await getCompanyNovelties(token, empresaId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  */

  const getPeridosCercanos = useCallback(async () => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    await getCloserPeriods(token, empresaId)
      .then((result) => {
        setErrorMessage("");
        getCloserPeriod(result.data);
      })
      .catch((e) => {
        setIsLoading(false);
        if (e.request.status === 0) {
          setErrorMessage("Ha ocurrido un error de CORS");
          handleOpenDialog();
        } else {
          setErrorMessage("Ha ocurrido un error");
        }
        console.error("ERROR", e.request.status);
        console.error("error", e.response);
        setEmpresaPeriodos([]);
        setEmpresaPeriodo("");
        setNovelties([]);
        setOriginalNovelties([]);
      });

    // const result = await getCompanyNovelties(token, empresaId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*  const getNovelties = useCallback(async () => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    getCompanyNovelties(token, empresaId)
      .then((result) => {
        setNovelties(result.data);
        setOriginalNovelties(result.data);
        setErrorMessage("");
      })
      .catch((e) => {
        console.error("error", e.response.data.error);
        setErrorMessage(e.response.data.error);
        setNovelties([]);
        setOriginalNovelties([]);
      });

    // const result = await getCompanyNovelties(token, empresaId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); */

  useEffect(() => {
    getPeridosCercanos();
  }, [getPeridosCercanos]);

  useEffect(() => {
    if (retryGetPeriodos) {
      getPeridosCercanos();
      setRetryGetPeriodos(false);
    }
  }, [getPeridosCercanos, retryGetPeriodos]);

  // useEffect(() => {
  //   if (empresaPeriodo) {
  //     getNoveltiesByPeriodId(empresaPeriodo);
  //   }
  // }, [empresaPeriodo, getNoveltiesByPeriodId, getPeridosCercanos]);

  return (
    <Layout icon="PollIcon" title="Novedades">
      <Box
        sx={{
          height: "100%",
          "& .theme--header": {
            backgroundColor: "#EEF1FC",
          },
        }}
      >
        <Grid container spacing={2} sx={{ py: "24px", placeItems: "center" }}>
          <Grid display="flex" sx={{ placeItems: "center" }} item xs={6}>
            <Box sx={{ marginRight: 4, width: "459px" }}>
              <SearchBar
                value={searched}
                onChange={(searchVal: string) => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
                placeholder="Buscar Novedad"
                style={{ boxShadow: "none" }}
              />
            </Box>
            <Box sx={{ display: "flex", placeItems: "center" }}>
              <Typography
                sx={{
                  color: "black",
                  fontSize: "18px",
                  fontWeight: 500,
                  marginRight: "23px",
                }}
              >
                Periodo:
              </Typography>
              <TextField
                id="periodo-select"
                select
                fullWidth
                name="period_id"
                onChange={(
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >
                ) => handleChangePeriod(event.target.value)}
                value={empresaPeriodo}
                variant="filled"
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    backgroundColor: "white",
                    borderRadius: "6px",
                    ".MuiInputBase-input": {
                      paddingY: "12px",
                      display: "flex",
                      justifyContent: "flex-start",
                    },
                  },
                }}
              >
                {empresaPeriodos.map((periodo) => (
                  <MenuItem key={periodo.id} value={periodo.id}>
                    {periodo.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Grid container display="flex" justifyContent="flex-end">
              <Stack direction="row" spacing={2}>
                {/* <Button
                  variant="contained"
                  startIcon={<MaterialIcons.FilterAltIcon />}
                  sx={{
                    backgroundColor: "white",
                    color: "black",
                    "&:hover": { color: "white" },
                  }}
                >
                  Filtro
                </Button> */}
                <Button
                  variant="contained"
                  startIcon={<MaterialIcons.AddchartIcon />}
                  color="primary"
                  onClick={() => crearNovedad()}
                >
                  Crear Novedad
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <DataTable
          columns={columns}
          rows={novelties}
          navigateRowLink="/novedades/novedad"
          noFilterRowsMessage="No existen novedades con ese nombre"
          noRowsMessage={errorMessage || "No hay novedades"}
          loading={isLoading}
          sortModel={[{ field: "created_at", sort: "desc" }]}
        />
      </Box>
      {openReintentarModal && (
        <ReintentarModal
          open={openReintentarModal}
          onClose={handleCloseDialog}
          onConfirm={handleConfirmAction}
        />
      )}
    </Layout>
  );
};

export default Novedades;
