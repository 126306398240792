import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import SearchBar from "material-ui-search-bar";

import { getAllCompanies } from "../../../services/CompaniesService";

const userRoles = [
  { id: 1, name: "Básico" },
  { id: 2, name: "Administrador" },
];

type EmpresasCheckboxListType = {
  actualState: any;
  onChange: any;
};

type CompaniesUserType = {
  name?: string;
  company_id: string;
  role: number;
};

const EmpresasCheckboxList: React.FC<EmpresasCheckboxListType> = ({
  actualState,
  onChange,
}) => {
  const [checked, setChecked] = React.useState<number[]>([]);
  const [empresasChecked, setEmpresasChecked] = React.useState<any[]>([]);
  const [userEmpresasChecked, setUserEmpresasChecked] = React.useState<
    CompaniesUserType[]
  >([]);
  const [companiesState, setCompaniesState] = React.useState<
    CompaniesUserType[]
  >([]);
  const [originalCompaniesState, setOriginalCompaniesState] = React.useState<
    CompaniesUserType[]
  >([]);
  const [searched, setSearched] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [allEmpresas, setAllEmpresas] = React.useState<any[]>([]);

  const { getAccessTokenSilently } = useAuth0();

  const getCompanies = React.useCallback(async () => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    getAllCompanies(token)
      .then((res) => {
        setAllEmpresas(res.data);
        if (res.data.length > 0) {
          for (let index = 0; index < res.data.length; index++) {
            const empresa = res.data[index];
            setCompaniesState((pS) => [
              ...pS,
              { company_id: empresa.id, name: empresa.name, role: 1 },
            ]);
            setOriginalCompaniesState((pS) => [
              ...pS,
              { company_id: empresa.id, name: empresa.name, role: 1 },
            ]);
          }
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e.response.data.error);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggle = (value: number, empresa: CompaniesUserType) => () => {
    const isChecked = checked.includes(value);
    const newChecked = isChecked
      ? checked.filter((val) => val !== value)
      : [...checked, value];

    const newEmpresasChecked = isChecked
      ? empresasChecked.filter((emp) => emp !== allEmpresas[value])
      : [...empresasChecked, allEmpresas[value]];

    const newUserEmpresasChecked = isChecked
      ? userEmpresasChecked.filter(
          (userEmpresa) => userEmpresa.company_id !== empresa.company_id
        )
      : [
          ...userEmpresasChecked,
          { company_id: empresa.company_id, role: empresa.role },
        ];

    setChecked(newChecked);
    setEmpresasChecked(newEmpresasChecked);
    setUserEmpresasChecked(newUserEmpresasChecked);
  };

  /*
  const handleToggle = (value: number, empresa: CompaniesUserType) => () => {
    const currentIndex = checked.indexOf(value);
    const empresaIndex = empresasChecked?.indexOf(allEmpresas[value]);
    const newChecked = [...checked];
    const newEmpresasChecked = [...empresasChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
      setUserEmpresasChecked([
        ...userEmpresasChecked,
        { company_id: empresa.company_id, role: empresa.role },
      ]);
    } else {
      newChecked.splice(currentIndex, 1);
      setUserEmpresasChecked(
        userEmpresasChecked.filter(
          (userEmpresa) => userEmpresa.company_id !== empresa.company_id
        )
      );
    }

    if (empresaIndex === -1) {
      newEmpresasChecked.push(allEmpresas[value]);
    } else {
      newEmpresasChecked.splice(empresaIndex, 1);
    }

    setEmpresasChecked(newEmpresasChecked);
    setChecked(newChecked);
  };
  */

  const requestSearch = (searchedVal: string) => {
    const newEmpresasState = [...companiesState];
    if (searchedVal.length > 2) {
      const filteredEmpresas = newEmpresasState.filter((empresa) => {
        return empresa?.name?.toLowerCase().includes(searchedVal.toLowerCase());
      });
      if (filteredEmpresas.length > 0) {
        setCompaniesState(filteredEmpresas);
      } else {
        setCompaniesState([]);
      }
    } else {
      setCompaniesState(originalCompaniesState);
    }
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const handleOnChangeUserType = (value: string, empresaId: string) => {
    setCompaniesState(
      companiesState.map((company) => {
        if (company.company_id === empresaId) {
          return { ...company, role: Number(value) };
        } else {
          return company;
        }
      })
    );
    setUserEmpresasChecked(
      userEmpresasChecked.map((company) => {
        if (company.company_id === empresaId) {
          return { ...company, role: Number(value) };
        } else {
          return company;
        }
      })
    );
  };

  React.useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  React.useEffect(() => {
    if (userEmpresasChecked) {
      onChange({ ...actualState, companies: userEmpresasChecked });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEmpresasChecked]);

  return (
    <>
      <Box sx={{ paddingBottom: "24px" }}>
        <SearchBar
          value={searched}
          onChange={(searchVal: string) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
          placeholder="Buscar empresa"
          style={{
            boxShadow: "none",
            borderRadius: "6px",
            backgroundColor: "rgba(0, 0, 0, 0.06)",
            height: "54px",
          }}
        />
      </Box>
      {companiesState.length > 0 ? (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            paddingX="24px"
            paddingY="18px"
            bgcolor="#EEF1FC"
            sx={{
              placeItems: "center",
            }}
          >
            <Typography fontWeight="600">Empresa</Typography>
            <Typography fontWeight="600">Rol</Typography>
          </Box>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {companiesState.map((empresa, i) => {
              const labelId = `checkbox-list-label-${empresa.company_id}`;

              return (
                <ListItem
                  key={empresa.company_id}
                  secondaryAction={
                    <TextField
                      id="user-type-select"
                      select
                      fullWidth
                      name="type"
                      onChange={(e) =>
                        handleOnChangeUserType(
                          e.target.value,
                          empresa.company_id
                        )
                      }
                      value={empresa.role}
                      variant="filled"
                      InputProps={{
                        disableUnderline: true,
                        sx: {
                          borderRadius: "6px",
                          backgroundColor: "transparent",
                          ".MuiInputBase-input": {
                            paddingBottom: "16px",
                            paddingTop: "16px",
                            display: "flex",
                            justifyContent: "flex-start",
                          },
                        },
                      }}
                    >
                      {userRoles.map((role) => (
                        <MenuItem key={role.id} value={role.id}>
                          {role.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  }
                  disablePadding
                >
                  <ListItemButton
                    role={undefined}
                    onClick={handleToggle(i, empresa)}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        id={`checkbox-${empresa.company_id}`}
                        edge="start"
                        checked={checked.indexOf(i) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={empresa.name} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </>
      ) : isLoading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          paddingX="24px"
          paddingY="18px"
          bgcolor="#EEF1FC"
          sx={{
            placeItems: "center",
          }}
        >
          <Typography fontWeight="600">
            No hay empresas con ese nombre
          </Typography>
        </Box>
      )}
    </>
  );
};

export default EmpresasCheckboxList;
