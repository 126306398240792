import React, { useCallback, useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { shallow } from "zustand/shallow";
// import { useHasRoles } from "react-simple-rbac";

import Box from "@mui/material/Box";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  MuiEvent,
} from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import SearchBar from "material-ui-search-bar";

import DataTable from "../table/DataTable";

import { MaterialIcons } from "../../icons/Material/MaterialIcons";

import { getCompanyNoveltiesTypes } from "../../services/CompaniesService";
import {
  downloadNoveltyFile,
  getNoveltyType,
} from "../../services/ParametersService";

import { DrawerContext } from "../../context/drawer/DrawerContext";

import { useParametrosStore } from "../../store/parametrosStore";
import { useEmpresaStore } from "../../store/empresaStore";

import { NoveltyType } from "../../types";

//1: success, 2:pending, 3:cancel
const getAction = (
  params: GridRenderCellParams,
  icon: keyof typeof MaterialIcons
) => {
  const Icon = MaterialIcons[icon || "PollIcon"];
  if (params.value === "2") {
    return (
      <Box
        sx={{
          display: "flex",
          placeItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Button variant="contained" color="primary">
          Procesar ahora
        </Button>
        <Icon />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        placeItems: "center",
        justifyContent: "flex-end",
        width: "100%",
      }}
    >
      <Icon />
    </Box>
  );
};

type TiposNovedadesType = {
  fromPortal?: boolean;
};

const TiposNovedades: React.FC<TiposNovedadesType> = ({ fromPortal }) => {
  const [searched, setSearched] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fetchError, setFetchError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [novelties, setNovelties] = useState<NoveltyType[]>([]);
  const [originalNovelties, setOriginalNovelties] = useState<NoveltyType[]>([]);

  const { getAccessTokenSilently } = useAuth0();

  const { changeLink } = useContext(DrawerContext);

  const setAllCompanyNovedadesType = useParametrosStore(
    (state) => state.setAllCompanyNovedadesType
  );

  const empresaId = useEmpresaStore((state) => state.empresaId, shallow);

  const navigate = useNavigate();

  // const canCreate = useHasRoles(["admin", "basic", "manager"], true);

  const canCreate = true;

  const getFile = (params: GridRenderCellParams) => {
    const title = changeLink === 2 ? params.row.file.name : params.row.name;
    return (
      <Box sx={{ display: "flex", placeItems: "center", width: "100%" }}>
        <MaterialIcons.FileDownloadIcon
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            console.log("click clip");
          }}
          color="primary"
          fontSize="small"
        />
        <Tooltip title={title}>
          <Typography marginLeft="8px">{title}</Typography>
        </Tooltip>
      </Box>
    );
  };

  const columns: GridColDef[] =
    changeLink === 1
      ? [
          {
            field: "name",
            headerName: "Nombre",
            flex: 1,
            headerClassName: "theme--header",
            valueGetter(params) {
              return params.value;
            },
          },
          {
            field: "code",
            headerName: "Código",
            flex: 1,
            headerClassName: "theme--header",
            valueGetter(params) {
              return params.value;
            },
          },
          {
            field: "action",
            headerName: "Acción",
            type: "number",
            flex: 1,
            headerClassName: "theme--header",
            renderCell(params) {
              return getAction(params, "EditIcon");
            },
          },
        ]
      : [
          {
            field: "name",
            headerName: "Nombre",
            flex: 1,
            headerClassName: "theme--header",
            valueGetter(params) {
              return params.row.novelty_type.name;
            },
          },
          {
            field: "code",
            headerName: "Código",
            flex: 1,
            headerClassName: "theme--header",
            valueGetter(params) {
              return params.row.novelty_type.code;
            },
          },
          {
            field: "file",
            headerName: "Plantilla Modelo",
            flex: 1,
            headerClassName: "theme--header",
            renderCell: getFile,
          },
          {
            field: "action",
            headerName: "Acción",
            type: "number",
            flex: 1,
            headerClassName: "theme--header",
            renderCell(params) {
              return getAction(params, "VisibilityOutlined");
            },
          },
        ];

  const requestSearch = (searchedVal: string) => {
    const searchNoveltyType = [...originalNovelties];
    if (searchedVal.length > 2) {
      const filteredRows = searchNoveltyType.filter((row) => {
        return row.name.toLowerCase().includes(searchedVal.toLowerCase());
      });
      setNovelties(filteredRows);
    } else {
      setNovelties(originalNovelties);
    }
  };

  const addNovelty = () => {
    navigate("/parametrosportal/creartipodenovedad");
  };

  const downloadArch = async (file: any) => {
    const token = await getAccessTokenSilently();
    const result = await downloadNoveltyFile(token, file.ref);

    // Create blob link to download
    const link = document.createElement("a");
    const url = window.URL.createObjectURL(new Blob([result.data]));
    link.href = url;
    link.setAttribute("download", file.name);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.dispatchEvent(new MouseEvent("click"));

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
  };

  const handleOnCellClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>
  ) => {
    if (params.field === "file") {
      downloadArch(
        changeLink === 2 ? params.value : params.row.novelty_type.file
      );
    } else if (changeLink === 1) {
      navigate(`/parametrosportal/tipodenovedad/novedad/${params.id}`);
    } else {
      navigate(`/parametros/tipodenovedad/novedad/${params.row.id}`);
    }
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const getNovelties = useCallback(async () => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    changeLink === 1
      ? getNoveltyType(token)
          .then((result) => {
            setNovelties(result.data);
            setOriginalNovelties(result.data);
            setIsLoading(false);
          })
          .catch((e) => {
            console.log(e.response.data.error);
            setFetchError(true);
            setErrorMessage(e.response.data.error);
            setNovelties([]);
            setOriginalNovelties([]);
            setIsLoading(false);
          })
      : getCompanyNoveltiesTypes(token, empresaId)
          .then((result) => {
            setNovelties(result.data);
            setOriginalNovelties(result.data);
            setAllCompanyNovedadesType(result.data);
            setIsLoading(false);
          })
          .catch((e) => {
            console.log(e.response.data.error);
            setFetchError(true);
            setErrorMessage(e.response.data.error);
            setNovelties([]);
            setOriginalNovelties([]);
            setAllCompanyNovedadesType([]);
            setIsLoading(false);
          });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeLink]);

  useEffect(() => {
    getNovelties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        height: "100%",
        "& .theme--header": {
          backgroundColor: "#EEF1FC",
        },
      }}
    >
      <Grid container spacing={2} sx={{ py: "24px" }}>
        <Grid display="flex" sx={{ placeItems: "center" }} item xs={6}>
          <Box sx={{ marginRight: 4, width: "459px" }}>
            <SearchBar
              value={searched}
              onChange={(searchVal: string) => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
              placeholder="Buscar tipos de novedades"
              style={{ boxShadow: "none" }}
            />
          </Box>
        </Grid>
        {changeLink === 1 && (
          <Grid item xs={6}>
            <Grid container display="flex" justifyContent="flex-end">
              <Stack direction="row" spacing={2}>
                {/* <Button
                variant="contained"
                startIcon={<MaterialIcons.FilterAltIcon />}
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  "&:hover": { color: "white" },
                }}
              >
                Filtrar
              </Button> */}
                {canCreate && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => addNovelty()}
                  >
                    Crear tipo de novedad
                  </Button>
                )}
              </Stack>
            </Grid>
          </Grid>
        )}
      </Grid>
      <DataTable
        columns={columns}
        rows={novelties}
        loading={isLoading}
        noFilterRowsMessage="No existen tipos de novedades con ese nombre"
        noRowsMessage={fetchError ? errorMessage : "No hay tipos de novedades"}
        onCellClick={handleOnCellClick}
        noRowClick
      />
    </Box>
  );
};

export default TiposNovedades;
