import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import Layout from "../../Layout/Layout";

const statuses = [
  {
    id: 1,
    value: "0",
    label: "Activo",
  },
  {
    id: 2,
    value: "1",
    label: "Inactivo",
  },
];

type NominaUsuarioDetailsType = {
  params: any;
};

const NominaUsuarioDetails: React.FC<NominaUsuarioDetailsType> = ({
  params,
}) => {
  const [status, setStatus] = useState<string>("0");

  const handleChangeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(event.target.value);
  };

  const navigate = useNavigate();

  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - Empleado`;
  }, []);

  return (
    <Layout icon="PeopleAltOutlinedIcon" title="Empleado" breadcrumb>
      <Grid container>
        <Grid bgcolor="white" item xs={8} borderRadius="6px">
          <Box
            sx={{
              borderRadius: "6px",
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
              height: "100%",
              width: "74%",
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Box
                component="form"
                noValidate
                autoComplete="off"
                borderRadius="6px"
                sx={{ backgroundColor: "white", height: "100%" }}
              >
                <Grid container maxWidth="md" justifyContent="space-between">
                  <Grid item xs={6} sx={{ pt: "18px", pl: "18px" }}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      marginBottom="4px"
                    >
                      <InputLabel
                        sx={{ color: "black" }}
                        htmlFor="nombre-input"
                      >
                        Nombre
                      </InputLabel>
                    </Box>
                    <TextField
                      id="nombre-input"
                      // error={Boolean(formik.touched.name && formik.errors.name)}
                      fullWidth
                      // helperText={formik.touched.name && formik.errors.name}
                      name="name"
                      // onBlur={formik.handleBlur}
                      // onChange={formik.handleChange}
                      type="text"
                      // value={formik.values.name}
                      variant="filled"
                      placeholder="Nombre"
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                        sx: {
                          borderRadius: "6px",
                          ".MuiInputBase-input": {
                            paddingBottom: "16px",
                            paddingTop: "16px",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ pt: "18px", pl: "18px" }}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      marginBottom="4px"
                    >
                      <InputLabel sx={{ color: "black" }} htmlFor="email-input">
                        Email
                      </InputLabel>
                    </Box>
                    <TextField
                      id="email-input"
                      // error={Boolean(formik.touched.email && formik.errors.email)}
                      fullWidth
                      // helperText={formik.touched.email && formik.errors.email}
                      name="email"
                      // onBlur={formik.handleBlur}
                      // onChange={formik.handleChange}
                      type="email"
                      // value={formik.values.email}
                      variant="filled"
                      placeholder="Email"
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                        sx: {
                          borderRadius: "6px",
                          ".MuiInputBase-input": {
                            paddingBottom: "16px",
                            paddingTop: "16px",
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  maxWidth="md"
                  justifyContent="space-between"
                  paddingBottom="26px"
                >
                  <Grid item xs={6} sx={{ pt: "18px", pl: "18px" }}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      marginBottom="4px"
                    >
                      <InputLabel sx={{ color: "black" }} htmlFor="cuil-input">
                        CUIL
                      </InputLabel>
                    </Box>
                    <TextField
                      id="cuil-input"
                      // error={Boolean(formik.touched.cuil && formik.errors.cuil)}
                      fullWidth
                      // helperText={formik.touched.cuil && formik.errors.cuil}
                      name="cuil"
                      // onBlur={formik.handleBlur}
                      // onChange={formik.handleChange}
                      type="text"
                      // value={formik.values.cuil}
                      variant="filled"
                      placeholder="Nro. de CUIL"
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                        sx: {
                          borderRadius: "6px",
                          ".MuiInputBase-input": {
                            paddingBottom: "16px",
                            paddingTop: "16px",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ pt: "18px", pl: "18px" }}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      marginBottom="4px"
                    >
                      <InputLabel
                        sx={{ color: "black" }}
                        htmlFor="status-input"
                      >
                        Estado
                      </InputLabel>
                    </Box>
                    <TextField
                      id="status-input"
                      select
                      // error={Boolean(formik.touched.status && formik.errors.status)}
                      fullWidth
                      // helperText={formik.touched.status && formik.errors.status}
                      name="status"
                      // onBlur={formik.handleBlur}
                      // onChange={formik.handleChange}
                      // value={formik.values.status}
                      variant="filled"
                      value={status}
                      onChange={handleChangeStatus}
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                        sx: {
                          borderRadius: "6px",
                          ".MuiInputBase-input": {
                            paddingBottom: "16px",
                            paddingTop: "16px",
                            display: "flex",
                            justifyContent: "flex-start",
                          },
                        },
                      }}
                    >
                      {statuses.map((status) => (
                        <MenuItem key={status.value} value={status.value}>
                          {status.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container maxWidth="sm" paddingBottom="32px">
                  <Button
                    sx={{ marginLeft: "18px" }}
                    variant="outlined"
                    color="primary"
                    onClick={() => navigate(-1)}
                  >
                    Volver
                  </Button>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default NominaUsuarioDetails;
