import { createContext } from "react";
import {
  IdentificationType,
  NoveltyType,
  ParametersPeriodType,
} from "../../types";

type ParametroContextInterface = {
  tabValue: number;
  setTabValue: (tabValue: number) => void;
  empresaTabValue: number;
  setEmpresaTabValue: (empresaTabValue: number) => void;
  allPeriodos: ParametersPeriodType[];
  allNovedades: NoveltyType[];
  refreshPeriodos: (status: boolean) => void;
  refreshNovedades: (status: boolean) => void;
  refreshActualEmpresaNovedades: (status: boolean) => void;
  handleActualEmpresa: (empresaId: string) => void;
  actualEmpresaNovedades: any[];
  actualEmpresaPeriodos: any[];
  identifications: IdentificationType[];
};

const ParametroContext = createContext({} as ParametroContextInterface);
ParametroContext.displayName = "ParametroContext";

export { ParametroContext };
