import React from "react";
import dayjs from "dayjs";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

type FiltroHistorialType = {
  filterValues: Record<string, string>;
  historicStatuses: any[];
  tableColumnsName: string[];
  tableFieldsName: string[];

  handleFilterDate: (date: string, name: string) => void;
  handleFilters: (event: any) => void;
  handleFilterWithButton: (value: string, name: string) => void;
};

const FiltroHistorial: React.FC<FiltroHistorialType> = ({
  filterValues,
  historicStatuses,
  tableColumnsName,
  tableFieldsName,

  handleFilterDate,
  handleFilters,
  handleFilterWithButton,
}) => {
  const renderField = (
    fieldName: string,
    columnName: string,
    index: number
  ) => {
    //Switch para determinar cual componente renderizar
    switch (fieldName) {
      case "created_at":
      case "process_date":
        return (
          <DesktopDatePicker
            value={dayjs(filterValues[fieldName], "DD/MM/YYYY")}
            onChange={(newDate) => {
              handleFilterDate(dayjs(newDate).format("DD/MM/YYYY"), fieldName);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                margin="normal"
                error={false}
              />
            )}
          />
        );

      case "status":
        return (
          <Box
            sx={{
              display: "flex",
              marginTop: "16px",
              marginBottom: "8px",
            }}
          >
            <Grid container>
              {historicStatuses.map((option, i) => (
                <Grid item mr={2} mb={2} key={i}>
                  <Button
                    variant={
                      filterValues[fieldName] === option.value.toString()
                        ? "contained"
                        : "text"
                    }
                    sx={{
                      backgroundColor: (theme) =>
                        filterValues[fieldName] === option.value.toString()
                          ? theme.palette.primary.main
                          : theme.palette.grey[300],
                      color:
                        filterValues[fieldName] === option.value.toString()
                          ? "white"
                          : "#4F4F4F",
                      padding: "9px 16px",
                    }}
                    onClick={() =>
                      handleFilterWithButton(option.value.toString(), fieldName)
                    }
                  >
                    {option.label}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
        );

      default:
        return (
          <TextField
            id={`${columnName}-${index}-id-name`}
            name={fieldName}
            fullWidth
            variant="outlined"
            margin="normal"
            value={filterValues[fieldName]}
            onChange={handleFilters}
          />
        );
    }
  };

  return (
    <DialogContent>
      {tableColumnsName.length > 0 &&
        tableColumnsName.map((columnName, i) => {
          if (tableFieldsName[i] === "status") {
            return (
              <Box key={i} sx={{ py: 1 }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "18px",
                    lineHeight: "27px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  {columnName}
                </Typography>
                {renderField(tableFieldsName[i], columnName, i)}
              </Box>
            );
          } else {
            return (
              <FormControl key={i} fullWidth sx={{ py: 1 }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "18px",
                    lineHeight: "27px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  {columnName}
                </Typography>
                {renderField(tableFieldsName[i], columnName, i)}
              </FormControl>
            );
          }
        })}
    </DialogContent>
  );
};

export default FiltroHistorial;
