import React, { useEffect } from "react";
import { shallow } from "zustand/shallow";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import TiposPeriodos from "./TiposPeriodos";
import TiposNovedades from "./TiposNovedades";

import Layout from "../Layout/Layout";

import { useParametrosStore } from "../../store/parametrosStore";
import { useDrawerStore } from "../../store/drawerStore";

type TabLabelType = {
  id: number;
  label: string;
};

const tabsLabels: TabLabelType[] = [
  { id: 1, label: "Tipos de Periodos" },
  { id: 2, label: "Tipos de Novedad" },
];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`parametro-tabpanel-${index}`}
      aria-labelledby={`parametro-tab-${index}`}
      style={{ height: "100%" }}
      {...other}
    >
      {/* {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )} */}
      {value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `parametro-tab-${index}`,
    "aria-controls": `parametro-tabpanel-${index}`,
    sx: { color: "black" },
  };
}

const Parametros = () => {
  const tabValue = useParametrosStore((state) => state.tabValue, shallow);

  const setTabValue = useParametrosStore((state) => state.setTabValue);

  const changeLink = useDrawerStore((state) => state.changeLink, shallow);

  const handleChangeTab = (newTabValue: number) => {
    setTabValue(newTabValue);
  };

  const renderTabs = () => {
    return tabsLabels.map((tabLabel, index) => {
      return <Tab key={index} label={tabLabel.label} {...a11yProps(index)} />;
    });
  };

  useEffect(() => {
    if (changeLink === 2 && tabValue === 2) {
      setTabValue(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeLink]);

  return (
    <Layout icon="SettingsIcon" title="Parametros" noIcon>
      <Box
        sx={{
          height: "100%",
          "& .theme--header": {
            backgroundColor: "#EEF1FC",
          },
        }}
      >
        <Box>
          <Tabs
            value={tabValue}
            onChange={(_, newTabValue: number) => handleChangeTab(newTabValue)}
            aria-label="parametros tabs"
            TabIndicatorProps={{ sx: { backgroundColor: "black" } }}
            sx={{ "& button.Mui-selected": { color: "black" } }}
          >
            {renderTabs()}
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <TiposPeriodos />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <TiposNovedades />
        </TabPanel>
      </Box>
    </Layout>
  );
};

export default Parametros;
