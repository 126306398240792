import React, { useCallback, useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import { useAuth0 } from "@auth0/auth0-react";
// import { useHasPermissions, useHasRoles } from "react-simple-rbac";

import Box from "@mui/material/Box";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import SearchBar from "material-ui-search-bar";
// import { useNavigate } from "react-router-dom";

import Layout from "../Layout/Layout";
import DataTable from "../table/DataTable";

import { MaterialIcons } from "../../icons/Material/MaterialIcons";

import NewUserModal from "../Modals/NewUserModal/NewUserModal";
import InformationModal from "../Modals/ConfirmationModal/InformationModal";

import { useUsersStore } from "../../store/usersStore";

import { NewUsuariosRowsType } from "../../types";
import { useUserStore } from "../../store/userStore";
import HtmlTooltip from "../HtmlTooltip/HtmlTooltip";

//1: success, 2:pending, 3:cancel
const getAction = (params: GridRenderCellParams) => {
  if (params.value === "2") {
    return (
      <Box
        sx={{
          display: "flex",
          placeItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Button variant="contained" color="primary">
          Procesar ahora
        </Button>
        <MaterialIcons.EditIcon />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        placeItems: "center",
        justifyContent: "flex-end",
        width: "100%",
      }}
    >
      <MaterialIcons.EditIcon />
    </Box>
  );
};

//0 inactive, 1 active, 2 pending
const getStatus = (params: GridRenderCellParams) => {
  if (params.value === 0) {
    return <Typography marginLeft="8px">Inactivo</Typography>;
  }
  if (params.value === 1) {
    return <Typography marginLeft="8px">Activo</Typography>;
  } else {
    return <Typography marginLeft="8px">Pendiente</Typography>;
  }
};

const columns: GridColDef[] = [
  // {
  //   field: "id",
  //   headerName: "ID",
  //   flex: 0.4,
  //   headerClassName: "theme--header",
  // },
  {
    field: "given_name",
    headerName: "Nombre",
    flex: 1,
    headerClassName: "theme--header",
  },
  {
    field: "family_name",
    headerName: "Apellido",
    flex: 1,
    headerClassName: "theme--header",
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    headerClassName: "theme--header",
  },
  {
    field: "status",
    headerName: "Estado",
    headerClassName: "theme--header",
    flex: 1,
    renderCell: getStatus,
  },
  {
    field: "action",
    headerName: "Acción",
    type: "number",
    flex: 1,
    headerClassName: "theme--header",
    renderCell: getAction,
  },
];

const Usuarios = () => {
  const [searched, setSearched] = useState<string>("");
  const [users, setUsers] = useState<NewUsuariosRowsType[]>([]);
  const [originalUsers, setOriginalUsers] = useState<NewUsuariosRowsType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openInfoModal, setOpenInfoModal] = useState<boolean>(false);
  const [refreshTable, setRefreshTable] = useState<boolean>(false);
  const [filter, setFilter] = useState<any[]>([]);
  const [filterStatus, setFilterStatus] = useState<number>(0);
  const [modalErrorMessage, setModalErrorMessage] = useState<string>("");

  // const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  const returnUsers = useUsersStore((state) => state.returnUsers);

  const { refreshUsersTable, hasFetchError, errorMessage } = useUsersStore(
    ({ refreshUsersTable, hasFetchError, errorMessage }) => ({
      refreshUsersTable,
      errorMessage,
      hasFetchError,
    }),
    shallow
  );

  const { getTodosUsers, setRefreshUsersTable } = useUsersStore(
    ({ getTodosUsers, setRefreshUsersTable }) => ({
      getTodosUsers,
      setRefreshUsersTable,
    })
  );

  const isAdminoGerente = useUserStore((state) => state.user?.isAdminOGerente);

  // const userRoles = useHasRoles(["read_onlyp"]);

  // const userCanCreateUser = useHasPermissions(["create_user"]);

  // const userCanCreate = !userRoles && userCanCreateUser;

  const userCanCreateOrFilter = isAdminoGerente;

  const requestSearch = (searchedVal: string) => {
    if (searchedVal.length > 2) {
      const searchLower = searchedVal.toLowerCase();
      const filteredRowsByNameOrEmail = originalUsers.filter((user) => {
        return (
          user.given_name.toLowerCase().includes(searchLower) ||
          user.email.toLowerCase().includes(searchLower)
        );
      });
      setUsers(
        filteredRowsByNameOrEmail.length > 0 ? filteredRowsByNameOrEmail : []
      );
    } else {
      setUsers(originalUsers);
    }
  };

  const handleClickCrearUsuario = () => {
    setOpenModal(true);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const handleSetFilter = () => {
    switch (filterStatus) {
      case 0:
        setFilterStatus(1);
        setFilter([
          {
            columnField: "status",
            operatorValue: "equals",
            value: "0",
          },
        ]);
        break;
      case 1:
        setFilterStatus(2);
        setFilter([
          {
            columnField: "status",
            operatorValue: "equals",
            value: "1",
          },
        ]);

        break;
      case 2:
        setFilterStatus(3);
        setFilter([
          {
            columnField: "status",
            operatorValue: "equals",
            value: "2",
          },
        ]);

        break;

      default:
        setFilter([]);
        setFilterStatus(0);
        break;
    }
  };

  const getUsers = useCallback(async () => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    getTodosUsers(token).then(() => {
      if (!hasFetchError) {
        setUsers(returnUsers());
        setOriginalUsers(returnUsers());
        setIsLoading(false);
      } else {
        console.error(errorMessage);
        setUsers([]);
        setOriginalUsers([]);
        setIsLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (refreshTable || refreshUsersTable) {
      setRefreshTable(false);
      setRefreshUsersTable(false);
      getUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable, refreshUsersTable]);

  return (
    <Layout icon="PeopleAltOutlinedIcon" title="Usuarios">
      <Box
        sx={{
          height: "100%",
          "& .theme--header": {
            backgroundColor: "#EEF1FC",
          },
        }}
      >
        <Grid container spacing={2} sx={{ py: "24px", placeItems: "center" }}>
          <Grid display="flex" sx={{ placeItems: "center" }} item xs={6}>
            <Box sx={{ marginRight: 4, width: "459px" }}>
              <SearchBar
                value={searched}
                onChange={(searchVal: string) => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
                placeholder="Buscar usuario"
                style={{ boxShadow: "none" }}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Grid container display="flex" justifyContent="flex-end">
              <Stack direction="row" spacing={2}>
                {userCanCreateOrFilter ? (
                  <Button
                    variant="contained"
                    startIcon={<MaterialIcons.FilterAltIcon />}
                    onClick={() => handleSetFilter()}
                    sx={{
                      backgroundColor: "white",
                      color: "black",
                      "&:hover": { color: "white" },
                    }}
                  >
                    {filterStatus
                      ? `Estado: ${
                          filterStatus === 1
                            ? "Inactivo"
                            : filterStatus === 2
                            ? "Activo"
                            : "Pendiente"
                        }`
                      : "Filtrar"}
                  </Button>
                ) : (
                  <HtmlTooltip
                    title={<b>No tienes permisos para filtrar usuarios</b>}
                  >
                    <span>
                      <Button
                        variant="contained"
                        startIcon={<MaterialIcons.FilterAltIcon />}
                        onClick={() => handleSetFilter()}
                        disabled
                      >
                        Filtrar
                      </Button>
                    </span>
                  </HtmlTooltip>
                )}

                {userCanCreateOrFilter ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleClickCrearUsuario()}
                  >
                    Crear usuario
                  </Button>
                ) : (
                  <HtmlTooltip
                    title={<b>No tienes permisos para crear usuarios</b>}
                  >
                    <span>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleClickCrearUsuario()}
                        disabled
                      >
                        Crear usuario
                      </Button>
                    </span>
                  </HtmlTooltip>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <DataTable
          columns={columns}
          rows={users}
          navigateRowLink="/usuarios/usuario"
          noRowsMessage={hasFetchError ? errorMessage : "No hay usuarios"}
          noFilterRowsMessage={
            filterStatus
              ? `${
                  filterStatus === 1
                    ? "No hay usuarios en estado Inactivo"
                    : filterStatus === 2
                    ? "No hay usuarios en estado Activo"
                    : "No hay usuarios en estado Pendiente"
                }`
              : "No hay usuarios con ese nombre"
          }
          loading={isLoading}
          filter={filter}
          sortModel={[
            {
              field: "given_name",
              sort: "asc",
            },
          ]}
        />
      </Box>

      {openModal && (
        <NewUserModal
          openModal={openModal}
          changeOpenModal={setOpenModal}
          changeOpenInfoModal={setOpenInfoModal}
          handleRefreshTable={setRefreshTable}
          setErrorMessage={setModalErrorMessage}
        />
      )}

      {openInfoModal && (
        <InformationModal
          openModal={openInfoModal}
          message={modalErrorMessage}
          title="Ha ocurrido un error"
          clickCancelar={false}
          clickEliminar={false}
          guardar={false}
          isDirty={false}
          onClose={() => setOpenInfoModal(false)}
          errorMessage
        />
      )}
    </Layout>
  );
};

export default Usuarios;
