import React, { useContext, useEffect, useState } from "react";
import { isEqual } from "lodash";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";

import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import Layout from "../../Layout/Layout";
import InformationModal from "../../Modals/ConfirmationModal/InformationModal";

import {
  editIdentificationType,
  getIdentificationTypeById,
} from "../../../services/ParametersService";

import { DrawerContext } from "../../../context/drawer/DrawerContext";
import { useParametrosStore } from "../../../store/parametrosStore";

type ParametroIdentificacionDetailsType = {
  params: any;
};

const ParametroIdentificacionDetails: React.FC<
  ParametroIdentificacionDetailsType
> = ({ params }) => {
  const [previusState, setPreviusState] = useState({
    label: "",
  });
  const [openInformationModal, setOpenInformationModal] =
    useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [clickCancelar, setClickCancelar] = useState<boolean>(false);
  const [clickGuardar, setClickGuardar] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [modalMessage, setModalMessage] = useState<string>("");

  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  const { identificacionId } = params;

  const setTabValue = useParametrosStore((state) => state.setTabValue);

  const { changeLink } = useContext(DrawerContext);

  const formik = useFormik({
    initialValues: {
      label: "",
    },
    validationSchema: Yup.object({
      label: Yup.string().max(55).required("El label es requerido"),
    }),
    onSubmit: async (data) => {
      const token = await getAccessTokenSilently();
      const result = await editIdentificationType(
        token,
        identificacionId,
        data
      );
      formik.resetForm();

      if (result.status === 201 || result.status === 200) {
        setTabValue(2);
        navigate(-1);
      }
    },
  });

  const handleOpenModal = () => {
    setOpenInformationModal(true);
  };

  const handleCloseModal = () => {
    setOpenInformationModal(false);
  };

  const handleSaveButton = () => {
    formik.submitForm();
  };

  const handleDeshacerCancelar = () => {
    formik.resetForm();
    navigate(-1);
  };

  const handleClickDirtyCancelar = () => {
    setIsDirty(true);
    setClickGuardar(false);
    setClickCancelar(true);
    setModalTitle("Cancelar cambios");
    setModalMessage(
      "¿Querés cancelar los cambios realizados a éste tipo de identificación?"
    );
    handleOpenModal();
  };

  const handleClickGuardar = () => {
    setIsDirty(false);
    setClickCancelar(false);
    setClickGuardar(true);
    setModalTitle("Guardar cambios");
    setModalMessage(
      "¿Querés guardar los cambios realizados a éste tipo de identificación?"
    );
    handleOpenModal();
  };

  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - Tipo de indentificación - Detalles`;
  }, [formik.values.label]);

  useEffect(() => {
    const getPeriod = async () => {
      const token = await getAccessTokenSilently();
      const result = await getIdentificationTypeById(token, identificacionId);
      formik.setValues({
        label: result.data.label,
      });
      setPreviusState({ label: result.data.label });
    };

    getPeriod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identificacionId]);

  return (
    <Layout title="Tipo de Identificación" noIcon>
      <Grid container marginTop="12px" borderRadius="6px">
        <Grid bgcolor="white" item xs={4} borderRadius="6px">
          <Box
            sx={{
              borderRadius: "6px",
              display: "flex",
              backgroundColor: "white",
              height: "100%",
              width: "100%",
              paddingRight: "18px",
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Box
                component="form"
                autoComplete="off"
                borderRadius="6px"
                sx={{ backgroundColor: "white", height: "100%" }}
                onSubmit={formik.handleSubmit}
              >
                <Grid container justifyContent="space-between">
                  <Grid item xs={8} sx={{ py: "18px", pl: "18px" }}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      marginBottom="4px"
                    >
                      <InputLabel sx={{ color: "black" }} htmlFor="label-input">
                        Nombre
                      </InputLabel>
                    </Box>
                    <TextField
                      id="label-input"
                      error={Boolean(
                        formik.touched.label && formik.errors.label
                      )}
                      fullWidth
                      helperText={formik.touched.label && formik.errors.label}
                      name="label"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      value={formik.values.label}
                      variant="filled"
                      placeholder="Nombre de la identificación"
                      InputProps={{
                        disableUnderline: true,
                        readOnly: changeLink === 2,
                        sx: {
                          borderRadius: "6px",
                          ".MuiInputBase-input": {
                            paddingBottom: "16px",
                            paddingTop: "16px",
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                {changeLink === 1 && (
                  <Grid container maxWidth="sm" paddingBottom="32px">
                    <Box sx={{ display: "flex" }}>
                      <Button
                        sx={{ marginLeft: "18px" }}
                        variant="contained"
                        color="primary"
                        onClick={handleClickGuardar}
                        disabled={
                          !formik.dirty ||
                          !formik.values.label ||
                          isEqual(previusState, formik.values)
                        }
                      >
                        Guardar
                      </Button>

                      {formik.dirty && !isEqual(previusState, formik.values) ? (
                        <Button
                          sx={{ marginLeft: "18px" }}
                          variant="contained"
                          color="error"
                          onClick={handleClickDirtyCancelar}
                        >
                          Cancelar
                        </Button>
                      ) : (
                        <Button
                          sx={{ marginLeft: "18px" }}
                          variant="contained"
                          color="error"
                          onClick={() => navigate(-1)}
                        >
                          Cancelar
                        </Button>
                      )}
                    </Box>
                  </Grid>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <InformationModal
        title={modalTitle}
        message={modalMessage}
        openModal={openInformationModal}
        onClose={handleCloseModal}
        saveForm={handleSaveButton}
        isDirty={isDirty}
        clickCancelar={clickCancelar}
        handleDeshacerCancelar={handleDeshacerCancelar}
        guardar={clickGuardar}
        guardarMessage={modalMessage}
        clickEliminar={false}
      />
    </Layout>
  );
};

export default ParametroIdentificacionDetails;
