import React, { useEffect } from "react";
import Periodos from "../components/Periodos/Periodos";

const PeriodosPage = () => {
  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - Periodos`;
  }, []);

  return <Periodos />;
};

export default PeriodosPage;
