import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import Layout from "../Layout/Layout";
import InformationModal from "../Modals/ConfirmationModal/InformationModal";

import { addPeriodType } from "../../services/ParametersService";

import { ParametroContext } from "../../context/parametro/ParametroContext";

const periodsBefore = [
  {
    id: 1,
    value: "1",
  },
  {
    id: 2,
    value: "2",
  },
  {
    id: 3,
    value: "3",
  },
  {
    id: 4,
    value: "4",
  },
  {
    id: 5,
    value: "5",
  },
];

const durationArray = [
  {
    id: 0,
    value: "Mensual",
  },
  {
    id: 1,
    value: "Quincenal",
  },
];

const CrearPeriodo = () => {
  const [openInformationModal, setOpenInformationModal] =
    useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [clickCancelar, setClickCancelar] = useState<boolean>(false);
  const [clickGuardar, setClickGuardar] = useState<boolean>(false);
  const [disabledOnSubmit, setDisabledOnSubmit] = useState<boolean>(false);

  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  const { setTabValue, tabValue } = useContext(ParametroContext);

  const formik = useFormik({
    initialValues: {
      name: "",
      duration: 1,
      day_limit: "",
      periods_before: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().max(55).required("El nombre es requerido"),
      duration: Yup.number().max(365).required("La duración es requerida"),
      day_limit: Yup.number()
        .lessThan(1, "Debe ser un número igual o menor a cero (0)")
        .required("El dia de límite es requerido"),
    }),
    onSubmit: async (data) => {
      setDisabledOnSubmit(true);
      const token = await getAccessTokenSilently();
      const result = await addPeriodType(token, data);

      if (result.status === 201 || result.status === 200) {
        formik.resetForm();
        setTabValue(0);
        navigate(-1);
        handleCloseModal();
      }
    },
  });

  const handleSetDiaLimite = (e: any) => {
    if (Number(e.target.value) <= 0) {
      formik.setFieldValue("day_limit", e.target.value);
    }
  };

  const handleClickGuardar = () => {
    setIsDirty(false);
    setClickCancelar(false);
    setClickGuardar(true);
    handleOpenModal();
  };

  const handleOpenModal = () => {
    setOpenInformationModal(true);
  };

  const handleCloseModal = () => {
    setOpenInformationModal(false);
  };

  const handleClickDirtyCancelar = () => {
    setIsDirty(true);
    setClickGuardar(false);
    setClickCancelar(true);
    handleOpenModal();
  };

  const handleDeshacerCancelar = () => {
    formik.resetForm();
    navigate(-1);
  };

  const handleSaveButton = () => {
    formik.submitForm();
  };

  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - Agregar periodo`;

    if (tabValue !== 0) {
      setTabValue(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue]);

  return (
    <Layout title="Agregar Tipo de periodo" noIcon>
      <Grid container marginTop="12px" borderRadius="6px">
        <Grid bgcolor="white" item xs={8} borderRadius="6px">
          <Box
            sx={{
              borderRadius: "6px",
              display: "flex",
              backgroundColor: "white",
              height: "100%",
              width: "100%",
              paddingRight: "18px",
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Box
                component="form"
                autoComplete="off"
                borderRadius="6px"
                sx={{ backgroundColor: "white", height: "100%" }}
                onSubmit={formik.handleSubmit}
              >
                <Grid container justifyContent="space-between">
                  <Grid item xs={6} sx={{ pt: "18px", pl: "18px" }}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      marginBottom="4px"
                    >
                      <InputLabel
                        sx={{ color: "black" }}
                        htmlFor="nombre-input"
                      >
                        Nombre
                      </InputLabel>
                    </Box>
                    <TextField
                      id="nombre-input"
                      error={Boolean(formik.touched.name && formik.errors.name)}
                      fullWidth
                      helperText={formik.touched.name && formik.errors.name}
                      name="name"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      value={formik.values.name}
                      variant="filled"
                      placeholder="Nombre del periodo"
                      InputProps={{
                        disableUnderline: true,
                        sx: {
                          borderRadius: "6px",
                          ".MuiInputBase-input": {
                            paddingBottom: "16px",
                            paddingTop: "16px",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ pt: "18px", pl: "18px" }}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      marginBottom="4px"
                    >
                      <InputLabel
                        sx={{ color: "black" }}
                        htmlFor="duration-input"
                      >
                        Duración
                      </InputLabel>
                    </Box>
                    <TextField
                      id="duration-select"
                      select
                      error={Boolean(
                        formik.touched.duration && formik.errors.duration
                      )}
                      fullWidth
                      helperText={
                        formik.touched.duration && formik.errors.duration
                      }
                      name="duration"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="number"
                      value={formik.values.duration}
                      variant="filled"
                      InputProps={{
                        disableUnderline: true,
                        sx: {
                          borderRadius: "6px",
                          ".MuiInputBase-input": {
                            paddingBottom: "16px",
                            paddingTop: "16px",
                            display: "flex",
                            justifyContent: "flex-start",
                          },
                        },
                      }}
                    >
                      {durationArray.map((duration) => (
                        <MenuItem key={duration.id} value={duration.id}>
                          {duration.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  paddingBottom="26px"
                >
                  <Grid item xs={6} sx={{ py: "10px", pl: "18px" }}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      marginBottom="4px"
                    >
                      <InputLabel
                        sx={{ color: "black" }}
                        htmlFor="day-limit-input"
                      >
                        Día limite
                      </InputLabel>
                    </Box>
                    <TextField
                      id="day-limit-input"
                      error={Boolean(
                        formik.touched.day_limit && formik.errors.day_limit
                      )}
                      fullWidth
                      helperText={
                        formik.touched.day_limit && formik.errors.day_limit
                      }
                      name="day_limit"
                      onBlur={formik.handleBlur}
                      onChange={handleSetDiaLimite}
                      type="number"
                      value={formik.values.day_limit}
                      variant="filled"
                      InputProps={{
                        disableUnderline: true,
                        sx: {
                          borderRadius: "6px",
                          ".MuiInputBase-input": {
                            paddingBottom: "16px",
                            paddingTop: "16px",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ py: "10px", pl: "18px" }}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      marginBottom="4px"
                    >
                      <InputLabel
                        sx={{ color: "black" }}
                        htmlFor="periods-before-select"
                      >
                        Anticipo
                      </InputLabel>
                    </Box>
                    <TextField
                      id="periods-before-select"
                      select
                      error={Boolean(
                        formik.touched.periods_before &&
                          formik.errors.periods_before
                      )}
                      fullWidth
                      helperText={
                        formik.touched.periods_before &&
                        formik.errors.periods_before
                      }
                      name="periods_before"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.periods_before}
                      variant="filled"
                      InputProps={{
                        disableUnderline: true,
                        sx: {
                          borderRadius: "6px",
                          ".MuiInputBase-input": {
                            paddingBottom: "16px",
                            paddingTop: "16px",
                            display: "flex",
                            justifyContent: "flex-start",
                          },
                        },
                      }}
                    >
                      {periodsBefore.map((period) => (
                        <MenuItem key={period.id} value={period.value}>
                          {period.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container maxWidth="sm" paddingBottom="32px">
                  <Box sx={{ display: "flex" }}>
                    <Button
                      sx={{ marginLeft: "18px" }}
                      variant="contained"
                      color="primary"
                      onClick={handleClickGuardar}
                      disabled={
                        !formik.dirty ||
                        !formik.values.name ||
                        !formik.values.day_limit ||
                        !formik.values.periods_before ||
                        !(
                          formik.values.duration >= 0 &&
                          formik.values.duration <= 1
                        ) ||
                        Number(formik.values.day_limit) > 0
                      }
                    >
                      Guardar
                    </Button>

                    {formik.dirty ? (
                      <Button
                        sx={{ marginLeft: "18px" }}
                        variant="contained"
                        color="error"
                        onClick={handleClickDirtyCancelar}
                      >
                        Cancelar
                      </Button>
                    ) : (
                      <Button
                        sx={{ marginLeft: "18px" }}
                        variant="contained"
                        color="error"
                        onClick={() => navigate(-1)}
                      >
                        Cancelar
                      </Button>
                    )}
                  </Box>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {openInformationModal && (
        <InformationModal
          title={isDirty ? "Cancelar" : "Crear tipo de periodo"}
          message={
            isDirty
              ? "¿Querés cancelar los cambios realizados?"
              : "¿Querés crear éste tipo de periodo?"
          }
          openModal={openInformationModal}
          onClose={handleCloseModal}
          saveForm={handleSaveButton}
          isDirty={isDirty}
          clickCancelar={clickCancelar}
          handleDeshacerCancelar={handleDeshacerCancelar}
          guardar={clickGuardar}
          guardarMessage="¿Querés crear éste tipo de periodo?"
          clickEliminar={false}
          disabledOnSubmit={disabledOnSubmit}
        />
      )}
    </Layout>
  );
};

export default CrearPeriodo;
