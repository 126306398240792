import React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";

//Icons
import CloseIcon from "@mui/icons-material/Close";
import FiltroHistorial from "./FiltroHistorial";
import FiltroPeriodo from "./FiltroPeriodo";

type FiltrosType = {
  filterValues: Record<string, string>;
  historicStatuses?: any[];
  openModal: boolean;
  periodsStatuses?: any[];
  periodsTypes?: any[];
  tableColumnsName: string[];
  tableFieldsName: string[];
  filtersTo: string;

  handleClearFilterButton: () => void;
  handleFilterButton: () => void;

  handleFilterDate?: (date: string, name: string) => void;
  handleFilters: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFilterWithButton?: (value: string, name: string) => void;
  handleOpenModal: (open: boolean) => void;
};

const Filtros: React.FC<FiltrosType> = ({
  filterValues,
  historicStatuses,
  openModal,
  periodsStatuses,
  periodsTypes,
  tableColumnsName,
  tableFieldsName,
  filtersTo,

  handleClearFilterButton,
  handleFilterButton,
  handleFilterDate,
  handleFilters,
  handleFilterWithButton,
  handleOpenModal,
}) => {
  return (
    <Dialog open={openModal} onClose={() => handleOpenModal(false)}>
      <DialogTitle>
        <IconButton
          aria-label="filter-modal-close-icon-button"
          onClick={() => handleOpenModal(false)}
          sx={{
            mr: "20px",
            color: (theme) => theme.palette.grey[900],
          }}
        >
          <CloseIcon />
        </IconButton>
        Filtrar
      </DialogTitle>
      {filtersTo === "Historial" && (
        <FiltroHistorial
          filterValues={filterValues}
          historicStatuses={historicStatuses || []}
          tableColumnsName={tableColumnsName}
          tableFieldsName={tableFieldsName}
          handleFilterDate={handleFilterDate!}
          handleFilters={handleFilters}
          handleFilterWithButton={handleFilterWithButton!}
        />
      )}
      {filtersTo === "Periodos" && (
        <FiltroPeriodo
          filterValues={filterValues}
          periodsStatuses={periodsStatuses || []}
          periodsTypes={periodsTypes || []}
          tableColumnsName={tableColumnsName}
          tableFieldsName={tableFieldsName}
          handleFilterDate={handleFilterDate!}
          handleFilters={handleFilters}
          handleFilterWithButton={handleFilterWithButton!}
        />
      )}
      <DialogContent>
        {filtersTo === "Novedades" && (
          <>
            <h1>{filtersTo}</h1>
            {tableColumnsName.length > 0 &&
              tableColumnsName.map((columnName, i) => {
                return (
                  <TextField
                    key={i}
                    name={tableFieldsName[i]}
                    label={columnName}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    value={filterValues[tableFieldsName[i]]}
                    onChange={handleFilters}
                  />
                );
              })}
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ pb: "20px" }}>
        <Button
          variant="outlined"
          color="primary"
          sx={{ width: "167px" }}
          onClick={() => handleClearFilterButton()}
        >
          Limpiar filtros
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{ width: "167px" }}
          onClick={() => handleFilterButton()}
        >
          Filtrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Filtros;
