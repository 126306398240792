import React, { useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
// import { useHasRoles } from "react-simple-rbac";

import Box from "@mui/material/Box";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import SearchBar from "material-ui-search-bar";

import DataTable from "../table/DataTable";

import { MaterialIcons } from "../../icons/Material/MaterialIcons";

import { getIdentificationsTypes } from "../../services/ParametersService";
import { DrawerContext } from "../../context/drawer/DrawerContext";

import { useParametrosStore } from "../../store/parametrosStore";

import { IdentificationType } from "../../types";

//1: success, 2:pending, 3:cancel
const getAction = (
  params: GridRenderCellParams,
  icon: keyof typeof MaterialIcons
) => {
  const Icon = MaterialIcons[icon || "PollIcon"];
  if (params.value === "2") {
    return (
      <Box
        sx={{
          display: "flex",
          placeItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Button variant="contained" color="primary">
          Procesar ahora
        </Button>
        <Icon />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        placeItems: "center",
        justifyContent: "flex-end",
        width: "100%",
      }}
    >
      <Icon />
    </Box>
  );
};

const TiposIdentificacion = () => {
  const [searched, setSearched] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fetchError, setFetchError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [identifications, setIdentifications] = useState<IdentificationType[]>(
    []
  );
  const [originalIdentifications, setOriginalIdentifications] = useState<
    IdentificationType[]
  >([]);

  const { getAccessTokenSilently } = useAuth0();

  const { changeLink } = useContext(DrawerContext);

  const setIdentificationsStore = useParametrosStore(
    (state) => state.setIdentifications
  );

  const navigate = useNavigate();

  // const canCreate = useHasRoles(["admin", "basic", "manager"], true);

  const canCreate = true;

  const columns: GridColDef[] = [
    // {
    //   field: "id",
    //   headerName: "ID",
    //   flex: 1,
    //   headerClassName: "theme--header",
    // },
    {
      field: "label",
      headerName: "Nombre",
      flex: 1,
      editable: true,
      headerClassName: "theme--header",
    },
    {
      field: "action",
      headerName: "Acción",
      type: "number",
      flex: 1,
      editable: false,
      headerClassName: "theme--header",
      renderCell(params) {
        return getAction(
          params,
          changeLink === 1 ? "EditIcon" : "VisibilityOutlined"
        );
      },
    },
  ];

  const requestSearch = (searchedVal: string) => {
    const searchIdType = [...originalIdentifications];
    if (searchedVal.length > 2) {
      const filteredRows = searchIdType.filter((id) => {
        return id.label.toLowerCase().includes(searchedVal.toLowerCase());
      });
      setIdentifications(filteredRows);
    } else {
      setIdentifications(originalIdentifications);
    }
  };

  const addIdentification = () => {
    navigate("/parametrosportal/creartipodeidentificacion");
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  useEffect(() => {
    const getIdsTypes = async () => {
      setIsLoading(true);
      const token = await getAccessTokenSilently();
      getIdentificationsTypes(token)
        .then((result) => {
          setIdentifications(result.data);
          setOriginalIdentifications(result.data);
          setIdentificationsStore(result.data);
          setIsLoading(false);
        })
        .catch((e) => {
          console.error(e.response.data.error);
          setFetchError(true);
          setErrorMessage(e.response.data.error);
          setIdentifications([]);
          setOriginalIdentifications([]);
          setIdentificationsStore([]);
          setIsLoading(false);
        });
    };

    getIdsTypes();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        height: "100%",
        "& .theme--header": {
          backgroundColor: "#EEF1FC",
        },
      }}
    >
      <Grid container spacing={2} sx={{ py: "24px" }}>
        <Grid display="flex" sx={{ placeItems: "center" }} item xs={6}>
          <Box sx={{ marginRight: 4, width: "459px" }}>
            <SearchBar
              value={searched}
              onChange={(searchVal: string) => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
              placeholder="Buscar tipo de identificación"
              style={{ boxShadow: "none" }}
            />
          </Box>
        </Grid>
        {changeLink === 1 && (
          <Grid item xs={6}>
            <Grid container display="flex" justifyContent="flex-end">
              <Stack direction="row" spacing={2}>
                {/* <Button
                variant="contained"
                startIcon={<MaterialIcons.FilterAltIcon />}
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  "&:hover": { color: "white" },
                }}
              >
                Filtrar
              </Button> */}
                {canCreate && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => addIdentification()}
                  >
                    Crear tipo de identificación
                  </Button>
                )}
              </Stack>
            </Grid>
          </Grid>
        )}
      </Grid>
      <DataTable
        columns={columns}
        rows={identifications}
        loading={isLoading}
        noFilterRowsMessage="No existe un tipo de identificacion con ese nombre"
        noRowsMessage={
          fetchError ? errorMessage : "No hay tipos de identificacion"
        }
        navigateRowLink={
          changeLink === 1
            ? "/parametrosportal/identificaciones/identificacion"
            : "/parametros/identificaciones/identificacion"
        }
        sortModel={[{ field: "label", sort: "asc" }]}
      />
    </Box>
  );
};

export default TiposIdentificacion;
