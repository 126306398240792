import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { shallow } from "zustand/shallow";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Layout from "../Layout/Layout";

import { getUsersCompanies } from "../../services/CompaniesService";

import { DrawerContext } from "../../context/drawer/DrawerContext";
import { EmpresaContext } from "../../context/empresa/EmpresaContext";

import { useEmpresaStore } from "../../store/empresaStore";
import { useUserStore } from "../../store/userStore";

import { EmpresaType } from "../../types";

const SelectCompany = () => {
  const [companies, setCompanies] = useState<EmpresaType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [error, setError] = useState<boolean>(false);

  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  const user = useUserStore((state) => state.user, shallow);

  const { setChangeLink } = useContext(DrawerContext);

  const { setEmpresas } = useContext(EmpresaContext);

  const { setEmpresaId, setSelectedEmpresa } = useEmpresaStore(
    ({ setEmpresaId, setSelectedEmpresa }) => ({
      setEmpresaId,
      setSelectedEmpresa,
    })
  );

  const handleClickEmpresa = (company: any, empresaId: string) => {
    setSelectedEmpresa(company);
    setEmpresaId(empresaId);
    if (companies.length > 1) {
      setChangeLink(2);
      navigate("/novedades");
    } else {
      setChangeLink(2);
      navigate("/novedades");
    }
  };

  const getCompanies = useCallback(async () => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    // getAllCompanies(token);
    await getUsersCompanies(token)
      .then((result) => {
        const companies: any[] = [];
        if (result.data.length === 0) {
          setError(true);
          setErrorMessage(
            "Tu cuenta no tiene empresas asignadas. Contacta a tu administrador"
          );
        }
        if (result.data.length === 1) {
          handleClickEmpresa(result.data[0].company, result.data[0].company.id);
        }

        result.data.forEach((company) => {
          companies.push(company.company);
        });

        setEmpresas(companies);
        setCompanies(result.data);

        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e.response.data.error);
        setError(true);

        setErrorMessage(
          e.response?.data?.error ??
            "Tu cuenta no tiene empresas asignadas. Contacta a tu administrador"
        );

        setCompanies([]);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - Seleccionar empresa`;
  }, []);

  useLayoutEffect(() => {
    if (user) {
      getCompanies();
    }
  }, [getCompanies, user]);

  return (
    <Layout title="Seleccionar empresa" noIcon>
      <Grid container sx={{ backgroundColor: "#EEF1FC" }}>
        <Grid item sx={{ width: "460px" }}>
          {isLoading && !error ? (
            <Typography>Cargando empresas...</Typography>
          ) : companies.length > 0 && !error ? (
            <Stack spacing={2}>
              {companies.map(({ company }) => {
                return (
                  <Button
                    key={company.id}
                    variant="contained"
                    color="primary"
                    sx={{ justifyContent: "flex-start" }}
                    onClick={() => handleClickEmpresa(company, company.id)}
                  >
                    {company.name}
                  </Button>
                );
              })}
            </Stack>
          ) : (
            companies.length === 0 &&
            !error && (
              <Typography>
                Tu cuenta no tiene empresas asignadas. Contacta a tu
                administrador.
              </Typography>
            )
          )}

          {error && <Typography>{errorMessage}</Typography>}
        </Grid>
      </Grid>
    </Layout>
  );
};

export default SelectCompany;
