import React from "react";

import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { useLocation, useNavigate } from "react-router-dom";
import { MaterialIcons } from "../../icons/Material/MaterialIcons";

import { BreadcrumbsBarType } from "../../types";

const BreadcrumbsBar: React.FC<BreadcrumbsBarType> = ({
  empresa,
  empresaparametro,
  icon,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const firstCrumb = location.pathname.split("/")[1];
  const secondCrumb = location.pathname.split("/")[2];
  const thirdCrumb = empresaparametro
    ? location.pathname.split("/")[4]
    : location.pathname.split("/")[3];

  const Icon = MaterialIcons[icon || "PollIcon"];

  const firstCrumbCap =
    firstCrumb.charAt(0).toUpperCase() + firstCrumb.slice(1);
  const secondCrumbB =
    secondCrumb.charAt(0).toUpperCase() + secondCrumb.slice(1);
  const thirdCrumbCap =
    thirdCrumb.charAt(0).toUpperCase() + thirdCrumb.slice(1);

  const handleSecondCrumb = () => {
    if (secondCrumbB === "Tipodeperiodo") {
      return "Tipo de Periodo";
    } else if (secondCrumbB === "Tipodenovedad") {
      return "Tipo de Novedad";
    } else {
      return secondCrumbB;
    }
  };

  const handleBreadcrumbClick = (e: any) => {
    e.preventDefault();
    navigate(`/${firstCrumb}`);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        paddingBottom: "12px",
      }}
    >
      <div role="presentation">
        <Breadcrumbs
          separator={<MaterialIcons.ArrowForwardIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            underline="none"
            sx={{ color: "black", display: "flex", placeItems: "center" }}
            href={`/${firstCrumb}`}
            role="button"
            onClick={(e: any) => handleBreadcrumbClick(e)}
          >
            <Icon sx={{ marginRight: "20px" }} />
            {firstCrumbCap}
          </Link>

          {!empresa && (
            <Typography key="3" sx={{ color: "black", textAlign: "center" }}>
              {handleSecondCrumb()}
            </Typography>
          )}

          {empresa && (
            <Link
              underline="none"
              sx={{ color: "black", display: "flex", placeItems: "center" }}
              href={`/${firstCrumb}`}
            >
              {handleSecondCrumb()}
            </Link>
          )}

          {(empresa || empresaparametro) && (
            <Typography key="3" sx={{ color: "black", textAlign: "center" }}>
              {thirdCrumbCap}
            </Typography>
          )}
        </Breadcrumbs>
      </div>
    </Box>
  );
};

export default BreadcrumbsBar;
