import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { shallow } from "zustand/shallow";

import { ParametroContext } from "./ParametroContext";
import { useParametrosStore } from "../../store/parametrosStore";

import {
  getCompanyNoveltiesTypes,
  getCompanyPeriodsTypes,
  getPeriodType,
} from "../../services/CompaniesService";
import {
  // getIdentificationsTypes,
  getNoveltyType,
} from "../../services/ParametersService";

import { NoveltyType } from "../../types";

type ParametroProviderType = {
  children: ReactNode;
};

export const ParametroProvider = ({ children }: ParametroProviderType) => {
  const [refreshNovedades, setRefreshNovedades] = useState<boolean>(false);
  const [refreshPeriodos, setRefreshPeriodos] = useState<boolean>(false);
  const [refreshActualEmpresaNovedades, setRefreshActualEmpresaNovedades] =
    useState<boolean>(false);
  const [allNovedades, setAllNovedades] = useState<NoveltyType[]>([]);
  const [actualEmpresaPeriodos, setActualEmpresaPeriodos] = useState<any[]>([]);
  const [actualEmpresaId, setActualEmpresaId] = useState<string>("");

  const { getAccessTokenSilently } = useAuth0();

  const {
    setEmpresaTabValue,
    // setIdentifications,
    setTabValue,
    setAllPeriodos,
    setActualEmpresa,
    setAllCompanyNovedadesType,
    returnAllCompanyNovedadesType,
  } = useParametrosStore(
    ({
      setEmpresaTabValue,
      setIdentifications,
      setTabValue,
      setAllPeriodos,
      setActualEmpresa,
      setAllCompanyNovedadesType,
      returnAllCompanyNovedadesType,
    }) => ({
      setEmpresaTabValue,
      setIdentifications,
      setTabValue,
      setActualEmpresa,
      setAllPeriodos,
      setAllCompanyNovedadesType,
      returnAllCompanyNovedadesType,
    })
  );

  const { empresaTabValue, identifications, tabValue, allPeriodos } =
    useParametrosStore(
      ({ empresaTabValue, identifications, tabValue, allPeriodos }) => ({
        empresaTabValue,
        identifications,
        tabValue,
        allPeriodos,
      }),
      shallow
    );

  const handleActualEmpresa = (empresaId: string) => {
    setActualEmpresaId(empresaId);
    setActualEmpresa(empresaId);
  };

  const getActualEmpresaNovedades = useCallback(
    async (actEmpresaId: string) => {
      const token = await getAccessTokenSilently();
      const novedades = await getCompanyNoveltiesTypes(token, actEmpresaId);
      if (novedades.data) {
        if (novedades.data.length > 0) {
          setAllCompanyNovedadesType(novedades.data);
        } else {
          setAllCompanyNovedadesType([]);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getActualEmpresaPeriodos = useCallback(
    async (actEmpresaId: string) => {
      const token = await getAccessTokenSilently();
      getCompanyPeriodsTypes(token, actEmpresaId)
        .then((periodos) => {
          setActualEmpresaPeriodos(periodos.data);
        })
        .catch((e) => {
          console.error(e.response.data.error);
          setActualEmpresaPeriodos([]);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getNovedadesParametros = useCallback(async () => {
    const token = await getAccessTokenSilently();
    getNoveltyType(token)
      .then((novedades) => {
        setAllNovedades(novedades.data);
      })
      .catch((e) => {
        console.error(e.response.data.error);
        setAllNovedades([]);
      });
    getPeriodType(token)
      .then((periodos) => {
        setAllPeriodos(periodos.data);
      })
      .catch((e) => {
        console.error(e.response.data.error);
        setAllPeriodos([]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (refreshPeriodos || refreshNovedades) {
      getNovedadesParametros();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshNovedades, refreshPeriodos]);

  useEffect(() => {
    if (actualEmpresaId.length > 0) {
      getActualEmpresaNovedades(actualEmpresaId);
      getActualEmpresaPeriodos(actualEmpresaId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualEmpresaId]);

  useEffect(() => {
    if (refreshActualEmpresaNovedades) {
      setRefreshActualEmpresaNovedades(false);
      getActualEmpresaNovedades(actualEmpresaId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshActualEmpresaNovedades]);

  /*
  const getIdsTypes = useCallback(async () => {
    const token = await getAccessTokenSilently();
    getIdentificationsTypes(token)
      .then((result) => {
        setIdentifications(result.data);
      })
      .catch((e) => {
        console.error(e.response.data.error);
        setIdentifications([]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      getNovedadesParametros();
      getIdsTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  */

  return (
    <ParametroContext.Provider
      value={{
        actualEmpresaNovedades: returnAllCompanyNovedadesType(),
        actualEmpresaPeriodos,
        allNovedades,
        allPeriodos,
        empresaTabValue,
        handleActualEmpresa,
        identifications,
        refreshActualEmpresaNovedades: setRefreshActualEmpresaNovedades,
        refreshNovedades: setRefreshNovedades,
        refreshPeriodos: setRefreshPeriodos,
        setEmpresaTabValue,
        setTabValue,
        tabValue,
      }}
    >
      {children}
    </ParametroContext.Provider>
  );
};
