import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import Grid from "@mui/material/Grid";

const RedirectLogin = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - Iniciar sesión`;
  }, []);

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/gestiondecliente",
      },
    });
  };

  useEffect(() => {
    handleLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ backgroundColor: "#EEF1FC", minHeight: "100vh" }}
    ></Grid>
  );
};

export default RedirectLogin;
