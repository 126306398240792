const isInPeriod = (date: Date, start: Date, finish: Date) => {
  return (
    date.getTime() >= start.getTime() && date.getTime() <= finish.getTime()
  );
};

const reorderPeriods = (periods: any[]) => {
  const sortedPeriodos = periods.sort((a, b) => {
    if (new Date(a.start_date).getTime() === new Date(b.start_date).getTime()) {
      return (
        new Date(a.finish_date).getTime() - new Date(b.finish_date).getTime()
      );
    } else
      return (
        new Date(a.start_date).getTime() - new Date(b.start_date).getTime()
      );
  });

  const todayDate = new Date();

  const intervalDateIndex = sortedPeriodos.findIndex((sortedPeriod) => {
    return isInPeriod(
      todayDate,
      new Date(sortedPeriod.start_date),
      new Date(sortedPeriod.finish_date)
    );
  });

  return [...sortedPeriodos.splice(intervalDateIndex), ...sortedPeriodos];
};

export { isInPeriod, reorderPeriods };
