import axios from "axios";

const instance = axios.create({
  headers: {
    "Content-type": "application/json",
  },
});

instance.interceptors.request.use(function (config: any) {
  // const url = "https://visma-back.unacorbatanegra.dev"; DIGITAL OCEAN
  // const url = "https://back-dev.azurewebsites.net/"; AZURE
  const url = "https://api.payroll.bdoargentina.com"
  config.baseURL = url;
  return config;
});

export default instance;
