import { createContext, Dispatch, SetStateAction } from "react";
import { EmpresaType, UserType } from "../../types";

type UserCompanyType = {
  empresas: EmpresaType[];
  firstLogin: number;
  setFirstLogin: Dispatch<SetStateAction<number>>;
  setRefreshUserEmpresas: Dispatch<SetStateAction<boolean>>;
} & UserType;

const UserContext = createContext({} as UserCompanyType | any);
UserContext.displayName = "UserContext";

export { UserContext };
