const getTokenFromLocalStorage = (clientId: string): string => {
  const objectToMap: Record<string, any> = {};

  const keys: any[] = Object.keys(localStorage);

  keys.forEach((key, index) => {
    if (
      key.includes(`@@auth0spajs@@::${clientId}::`) &&
      !key.includes(`@@auth0spajs@@::${clientId}::@@user@@`)
    ) {
      return (objectToMap[key] = localStorage.getItem(key));
    }
  });

  const values: string[] = Object.values(objectToMap);

  return JSON.parse(values[0]).body.access_token;
};

export { getTokenFromLocalStorage };
