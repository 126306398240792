import React from "react";
import { useParams } from "react-router-dom";

import CompanyParametroIdentificacionDetails from "../../../components/Details/Parametros/CompaniesParametroIdentificacionDetails";

const CompaniesParametroIdentificacionDetailsPage = () => {
  const params = useParams();
  return <CompanyParametroIdentificacionDetails params={params} />;
};

export default CompaniesParametroIdentificacionDetailsPage;
