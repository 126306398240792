import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

type LoginErrorMessageType = {
  cause?: any;
  message: string;
  name: string;
};

const LoginErrorMessage: React.FC<LoginErrorMessageType> = ({
  cause,
  message,
  name,
}) => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ backgroundColor: "#EEF1FC", minHeight: "100vh" }}
    >
      <Grid item xs={6}>
        <Box
          component="main"
          sx={{
            alignItems: "center",
            display: "flex",
            flexGrow: 1,
            minHeight: "100%",
            backgroundColor: "white",
          }}
        >
          <Container sx={{ backgroundColor: "#EEF1FC" }} maxWidth="md">
            <Typography variant="h1">Ha ocurrido un error</Typography>
            {cause && <Typography variant="h2">{cause}</Typography>}

            <Typography variant="h2">{message}</Typography>
            <Typography variant="h2">{name}</Typography>
          </Container>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LoginErrorMessage;
