import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

import { DrawerItemType } from "../types";

const drawerListBDOShort: DrawerItemType[] = [
  // {
  //   id: 1,
  //   label: "Ajustes de Portal",
  //   icon: "SettingsIcon",
  //   path: "/ajustes",
  // },
  // {
  //   id: 2,
  //   label: "Periodos",
  //   icon: "CalendarTodayIcon",
  //   path: "/periodos",
  // },
  {
    id: 3,
    label: "Empresas",
    icon: "HomeWorkOutlinedIcon",
    path: "/empresas",
  },
  {
    id: 4,
    label: "Usuarios",
    icon: "PeopleAltOutlinedIcon",
    path: "/usuarios",
  },
  {
    id: 5,
    label: "Parametros",
    icon: "SettingsIcon",
    path: "/parametrosportal",
  },
];

const drawerListBasic: DrawerItemType[] = [
  { id: 1, label: "Novedades", icon: "PollIcon", path: "/novedades" },
  { id: 2, label: "Historial", icon: "AccessTimeIcon", path: "/historial" },
  {
    id: 3,
    label: "Periodos",
    icon: "CalendarTodayIcon",
    path: "/periodos",
  },
  {
    id: 4,
    label: "Nomina",
    icon: "PeopleAltOutlinedIcon",
    path: "/nomina",
  },
  // {
  //   id: 5,
  //   label: "Usuarios",
  //   icon: "PeopleAltOutlinedIcon",
  //   path: "/usuarios",
  // },
  { id: 5, label: "Parametros", icon: "SettingsIcon", path: "/parametros" },
  {
    id: 6,
    label: "Empresa",
    icon: "HomeWorkOutlinedIcon",
    path: "/empresa",
  },
  // {
  //   id: 7,
  //   label: "Ajustes de Portal",
  //   icon: "SettingsIcon",
  //   path: "/ajustes",
  // },
];

type DrawerState = {
  links: DrawerItemType[];
  changeLink: number;
  topLink: string | null;
  location: string;
  firstLogin: boolean;
  isDrawerOpen: boolean;
};

type DrawerAction = {
  setTopLink: (topLink: string) => void;
  setChangeLink: (changeLink: number) => void;
  setLinks: (links: DrawerItemType[]) => void;
  setLocation: (location: string) => void;
  returnLinks: () => void;
  returnFirstLogin: () => boolean;
  resetDrawerState: () => void;
  setFirstLogin: (firstLogin: boolean) => void;
  handleDrawerOpen: () => void;
};

// define the initial state

const initialDrawerState: DrawerState = {
  links: drawerListBasic,
  changeLink: 1,
  topLink: "/gestiondecliente",
  location: window.location.pathname,
  firstLogin: true,
  isDrawerOpen: true,
};

export const useDrawerStore = create<DrawerState & DrawerAction>()(
  persist(
    (set, get) => ({
      ...initialDrawerState,
      setChangeLink: (changeLink) => {
        set((state) => ({
          ...state,
          changeLink,
          links: changeLink === 1 ? drawerListBDOShort : drawerListBasic,
          topLink: changeLink === 1 ? "/empresas" : "/gestiondecliente",
        }));
      },
      setTopLink: (topLink) => {
        set((state) => ({
          ...state,
          topLink,
        }));
      },
      setLinks: (links) => {
        set((state) => ({
          ...state,
          links,
        }));
      },
      setLocation: (location) => {
        set((state) => ({
          ...state,
          location,
        }));
      },
      setFirstLogin: (firstLogin) => {
        set((state) => ({
          ...state,
          firstLogin,
        }));
      },
      returnFirstLogin: () => {
        return get().firstLogin;
      },
      returnLinks: () => {
        return get().links;
      },
      handleDrawerOpen: () => {
        set((state) => ({
          ...state,
          isDrawerOpen: !get().isDrawerOpen,
        }));
      },
      resetDrawerState: () => {
        set(initialDrawerState);
      },
    }),
    {
      name: "drawer-storage", // name of the item in the storage (must be unique)
      storage: createJSONStorage<DrawerState>(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);
