import React from "react";
import { useParams } from "react-router-dom";

import CompanyParametroPeriodoDetails from "../../../components/Details/Parametros/CompaniesParametroPeriodoDetails";

const CompaniesPeriodosDetailsPage = () => {
  const params = useParams();
  return <CompanyParametroPeriodoDetails params={params} />;
};

export default CompaniesPeriodosDetailsPage;
