import React from "react";
import dayjs from "dayjs";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

type FiltroPeriodoType = {
  filterValues: Record<string, string>;
  periodsStatuses: any[];
  periodsTypes: any[];
  tableColumnsName: string[];
  tableFieldsName: string[];

  handleFilterDate: (date: string, name: string) => void;
  handleFilters: (event: any) => void;
  handleFilterWithButton: (value: string, name: string) => void;
};

const FiltroPeriodo: React.FC<FiltroPeriodoType> = ({
  filterValues,
  periodsStatuses,
  periodsTypes,
  tableColumnsName,
  tableFieldsName,

  handleFilterDate,
  handleFilters,
  handleFilterWithButton,
}) => {
  const renderField = (
    fieldName: string,
    columnName: string,
    index: number
  ) => {
    //Switch para determinar cual componente renderizar
    switch (fieldName) {
      case "start_date":
      case "finish_date":
      case "day_limit":
        return (
          <DesktopDatePicker
            value={dayjs(filterValues[fieldName], "DD/MM/YYYY")}
            onChange={(newDate) => {
              handleFilterDate(dayjs(newDate).format("DD/MM/YYYY"), fieldName);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                margin="normal"
                error={false}
              />
            )}
          />
        );

      case "period_type_id":
        return (
          <Stack
            direction="row"
            spacing={1}
            sx={{ marginTop: "16px", marginBottom: "8px" }}
          >
            {periodsTypes.map((option) => (
              <Button
                key={option.id}
                variant={
                  filterValues[fieldName] === option.id ? "contained" : "text"
                }
                sx={{
                  backgroundColor: (theme) =>
                    filterValues[fieldName] === option.id
                      ? theme.palette.primary.main
                      : theme.palette.grey[300],
                  color:
                    filterValues[fieldName] === option.id ? "white" : "#4F4F4F",
                  padding: "9px 16px",
                }}
                onClick={() => handleFilterWithButton(option.id, fieldName)}
              >
                {option.name}
              </Button>
            ))}
          </Stack>
        );

      case "period_state":
        return (
          <Stack
            direction="row"
            spacing={1}
            sx={{ marginTop: "16px", marginBottom: "8px" }}
          >
            {periodsStatuses.map((option, i) => (
              <Button
                key={i}
                variant={
                  filterValues[fieldName] === option.value
                    ? "contained"
                    : "text"
                }
                sx={{
                  backgroundColor: (theme) =>
                    filterValues[fieldName] === option.value
                      ? theme.palette.primary.main
                      : theme.palette.grey[300],
                  color:
                    filterValues[fieldName] === option.value
                      ? "white"
                      : "#4F4F4F",
                  padding: "9px 16px",
                }}
                onClick={() => handleFilterWithButton(option.value, fieldName)}
              >
                {option.label}
              </Button>
            ))}
          </Stack>
        );

      case "duration":
        return (
          <Stack
            direction="row"
            spacing={1}
            sx={{ marginTop: "16px", marginBottom: "8px" }}
          >
            {[
              { id: "1", value: "0", label: "0" },
              { id: "2", value: "1", label: "1" },
            ].map((option) => (
              <Button
                key={option.id}
                variant={
                  filterValues[fieldName] === option.value
                    ? "contained"
                    : "text"
                }
                sx={{
                  backgroundColor: (theme) =>
                    filterValues[fieldName] === option.value
                      ? theme.palette.primary.main
                      : theme.palette.grey[300],
                  color:
                    filterValues[fieldName] === option.value
                      ? "white"
                      : "#4F4F4F",
                  padding: "9px 16px",
                }}
                onClick={() => handleFilterWithButton(option.value, fieldName)}
              >
                {option.label}
              </Button>
            ))}
          </Stack>
        );

      default:
        return (
          <TextField
            id={`${columnName}-${index}-id-name`}
            name={fieldName}
            fullWidth
            variant="outlined"
            margin="normal"
            value={filterValues[fieldName]}
            onChange={handleFilters}
          />
        );
    }
  };

  return (
    <DialogContent>
      {tableColumnsName.length > 0 &&
        tableColumnsName.map((columnName, i) => {
          if (
            tableFieldsName[i] === "period_type_id" ||
            tableFieldsName[i] === "period_state" ||
            tableFieldsName[i] === "duration"
          ) {
            return (
              <Box key={i} sx={{ py: 1 }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "18px",
                    lineHeight: "27px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  {columnName}
                </Typography>
                {renderField(tableFieldsName[i], columnName, i)}
              </Box>
            );
          } else {
            return (
              <FormControl key={i} fullWidth sx={{ py: 1 }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "18px",
                    lineHeight: "27px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  {columnName}
                </Typography>
                {renderField(tableFieldsName[i], columnName, i)}
              </FormControl>
            );
          }
        })}
    </DialogContent>
  );
};

export default FiltroPeriodo;
