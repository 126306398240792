import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { isEqual } from "lodash";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { shallow } from "zustand/shallow";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

// import InviteUserModal from "./InviteUserModal";
import FetchErrorMessage from "../../FetchErrorMessage/FetchErrorMessage";
import HtmlTooltip from "../../HtmlTooltip/HtmlTooltip";
import InformationModal from "../../Modals/ConfirmationModal/InformationModal";
import InvitarUsuarioModal from "../../Modals/InvitarUsuarioModal/InvitarUsuarioModal";
import Layout from "../../Layout/Layout";
import UsersTable from "./UsersTable";

import { MaterialIcons } from "../../../icons/Material/MaterialIcons";

import {
  editCompany,
  getCompany,
  getCompanyTenants,
  getCompanyUsers,
} from "../../../services/CompaniesService";

import {
  CompanyUsersRowsType,
  IdentificationType,
  TenantType,
} from "../../../types";
import { getIdentificationsTypes } from "../../../services/ParametersService";

import { useUserStore } from "../../../store/userStore";

const statuses = [
  {
    id: 1,
    value: "0",
    label: "Activo",
  },
  {
    id: 2,
    value: "1",
    label: "Inactivo",
  },
];

type EmpresaNovedadDetailsType = {
  params: any;
};

const EmpresaNovedadDetails: React.FC<EmpresaNovedadDetailsType> = ({
  params,
}) => {
  const [inviteUserModal, setInviteUserModal] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [clickCancelar, setClickCancelar] = useState<boolean>(false);
  const [clickGuardar, setClickGuardar] = useState<boolean>(false);
  const [refreshTable, setRefreshTable] = useState<boolean>(false);
  const [openInformationModal, setOpenInformationModal] =
    useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [users, setUsers] = useState<CompanyUsersRowsType[]>([]);
  const [originalUsers, setOriginalUsers] = useState<CompanyUsersRowsType[]>(
    []
  );
  const [identifications, setIdentifications] = useState<IdentificationType[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [disabledOnSubmit, setDisabledOnSubmit] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [fetchIdsError, setFetchIdsError] = useState<boolean>(false);
  const [fetchUsersError, setFetchUsersError] = useState<boolean>(false);
  const [fetchCompanyError, setFetchCompanyError] = useState<boolean>(false);
  const [fetchErrorMessage, setFetchErrorMessage] = useState<string>("");
  const [fetchUsersErrorMessage, setFetchUsersErrorMessage] =
    useState<string>("");
  const [isIdsLoading, setIsIdsLoading] = useState<boolean>(true);
  const [isTenantsLoading, setIsTenantsLoading] = useState<boolean>(true);
  const [tenants, setTenants] = useState<TenantType[]>([]);
  const [previusState, setPreviusState] = useState({
    name: "",
    email: "",
    base: "",
    phone: "",
    address: "",
    status: "",
    identification_id: "",
    idNumber: "",
    tenant: "",
    sftp_user: "",
    sftp_password: "",
    file: "",
    fileName: "",
  });

  const idsTypes = [...identifications];

  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  const { empresaId } = params;

  const userDataStore = useUserStore((state) => state.user, shallow);

  const isUserBasicoOGerenteOLectura =
    userDataStore?.role === 4 ||
    userDataStore?.role === 5 ||
    userDataStore?.role === 6;

  const inviteUser = () => {
    setInviteUserModal(true);
  };

  const handleOpenModal = () => {
    setOpenInformationModal(true);
  };

  const handleChangePassword = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setShowPassword(true);
    formik.setFieldValue("sftp_password", e.target.value);
  };

  const handleCloseModal = () => {
    setOpenInformationModal(false);
    openErrorModal && setOpenErrorModal(false);
  };

  const handleSaveButton = () => {
    formik.submitForm();
  };

  const handleDeshacerCancelar = () => {
    formik.resetForm();
    navigate(-1);
  };

  const handleClickDirtyCancelar = () => {
    setIsDirty(true);
    setClickCancelar(true);
    setClickGuardar(false);
    handleOpenModal();
  };

  const handleClickGuardar = () => {
    setIsDirty(false);
    setClickCancelar(false);
    setClickGuardar(true);
    handleOpenModal();
  };

  const handleNavigate = (route: string) => {
    navigate(route);
  };

  const handleUploadFile = (file: any) => {
    formik.setFieldValue("file", file);
    formik.setFieldValue("fileName", file.name);
  };

  const getIdsTypes = useCallback(async () => {
    const token = await getAccessTokenSilently();
    getIdentificationsTypes(token)
      .then((result) => {
        setIdentifications(result.data);
        setIsIdsLoading(false);
      })
      .catch((e) => {
        console.error("idsType", e.response.data.error);
        setFetchIdsError(true);
        setFetchErrorMessage(e.response.data.error);
        setIdentifications([]);
        setIsIdsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUsersCompany = useCallback(async () => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    getCompanyUsers(token, empresaId)
      .then((result) => {
        setUsers(result.data);
        setOriginalUsers(result.data);
        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e.response.data.error);
        setFetchUsersError(true);
        setFetchUsersErrorMessage(e.response.data.error);
        setUsers([]);
        setOriginalUsers([]);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEmpresaTenants = useCallback(async () => {
    const token = await getAccessTokenSilently();
    getCompanyTenants(token)
      .then((tenantsRes) => {
        const tenants = tenantsRes.data.sort(function (
          a: { TenantName: string },
          b: { TenantName: string }
        ) {
          if (a.TenantName < b.TenantName) {
            return -1;
          }
          if (a.TenantName > b.TenantName) {
            return 1;
          }
          return 0;
        });
        setTenants(tenants);
        setIsTenantsLoading(false);
      })
      .catch((e) => {
        console.error("tenants", e.response.data.error);
        setTenants([]);
        setIsTenantsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      base: "",
      phone: "",
      address: "",
      status: "",
      identification_id: "",
      idNumber: "",
      tenant: "",
      sftp_user: "",
      sftp_password: "",
      file: "",
      fileName: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("El nombre es requerido"),
      tenant: Yup.string().max(55).required("Seleccione un tenant"),
      idNumber: Yup.string()
        .max(55)
        .required("El nro de identificación es requerido"),
    }),
    onSubmit: async (data) => {
      setDisabledOnSubmit(true);
      const token = await getAccessTokenSilently();
      const finalTenant = tenants.find((tenant) => tenant.Id === data.tenant);

      const finalData = {
        identification_id: data.idNumber,
        identification_type: data.identification_id,
        tenant: JSON.stringify(finalTenant),
        name: data.name,
        email: data.email,
        phone: data.phone,
        status: Number(data.status),
        address: data.address,
        sftp_user: data.sftp_user,
        sftp_password: data.sftp_password,
        ...(data.file && { file: data.file }),
      };

      editCompany(token, finalData, empresaId)
        .then((result) => {
          formik.resetForm();
          navigate(-1);
        })
        .catch((e) => {
          console.log(e.response.data.error);
          setDisabledOnSubmit(false);
          setOpenErrorModal(true);
          setErrorMessage(
            "Ha ocurrido un error al editar la empresa. Intente de nuevo mas tarde."
          );
        });
    },
  });

  useEffect(() => {
    let passwordTimer = setTimeout(() => setShowPassword(false), 1000);

    // this will clear Timeout
    // when component unmount like in willComponentUnmount
    // and show will not change to true
    return () => {
      clearTimeout(passwordTimer);
    };
  }, [formik.values.sftp_password]);

  useLayoutEffect(() => {
    getUsersCompany();

    const getCompanyById = async () => {
      const token = await getAccessTokenSilently();

      getCompany(token, empresaId)
        .then(async (result) => {
          await getEmpresaTenants();
          await getIdsTypes();

          formik.setValues({
            name: result.data.name,
            email: result.data.email,
            base: result.data.base,
            phone: result.data.phone,
            address: result.data.address,
            status: result.data.status,
            identification_id: result.data.identification_type,
            idNumber: result.data.identification_id,
            tenant: result.data.tenant.Id,
            sftp_user: result.data.sftp_user,
            sftp_password: result.data.sftp_password,
            file: "",
            fileName: "",
          });

          setPreviusState({
            name: result.data.name,
            email: result.data.email,
            base: result.data.base,
            phone: result.data.phone,
            address: result.data.address,
            status: result.data.status,
            identification_id: result.data.identification_type,
            idNumber: result.data.identification_id,
            tenant: result.data.tenant.Id,
            sftp_user: result.data.sftp_user,
            sftp_password: result.data.sftp_password,
            file: "",
            fileName: "",
          });
        })
        .catch((e) => {
          console.error(e.response.data.error);
          setFetchCompanyError(true);
          setFetchErrorMessage(e.response.data.error);
          setIsLoading(false);
        });
    };

    getCompanyById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresaId]);

  useEffect(() => {
    if (refreshTable) {
      setRefreshTable(false);
      getUsersCompany();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);

  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - Empresa`;
  }, []);

  return (
    <Layout icon="PollIcon" title="Empresa" breadcrumb>
      <Grid container>
        <Grid bgcolor="white" item xs={8} borderRadius="6px">
          {isIdsLoading || isTenantsLoading ? (
            <Box
              sx={{
                borderRadius: "6px",
                display: "flex",
                backgroundColor: "white",
                height: "100%",
                width: "100%",
                paddingRight: "18px",
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Box
                  borderRadius="6px"
                  sx={{
                    backgroundColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "400px",
                  }}
                >
                  <CircularProgress />
                </Box>
              </Box>
            </Box>
          ) : fetchCompanyError || fetchIdsError ? (
            <FetchErrorMessage
              errorMessage={fetchErrorMessage}
              height="400px"
            />
          ) : (
            <Box
              sx={{
                borderRadius: "6px",
                display: "flex",
                backgroundColor: "white",
                height: "100%",
                width: "100%",
                paddingRight: "18px",
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Box
                  component="form"
                  autoComplete="off"
                  borderRadius="6px"
                  sx={{ backgroundColor: "white", height: "100%" }}
                  onSubmit={formik.handleSubmit}
                >
                  <Grid container justifyContent="space-between">
                    <Grid item xs={3} sx={{ py: "18px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="nombre-input"
                        >
                          Nombre
                        </InputLabel>
                      </Box>
                      <TextField
                        id="nombre-input"
                        error={Boolean(
                          formik.touched.name && formik.errors.name
                        )}
                        fullWidth
                        helperText={formik.touched.name && formik.errors.name}
                        name="name"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.name}
                        variant="filled"
                        placeholder="Nombre de la empresa"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: isUserBasicoOGerenteOLectura,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={3} sx={{ py: "18px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="email-input"
                        >
                          Email
                        </InputLabel>
                      </Box>
                      <TextField
                        id="email-input"
                        error={Boolean(
                          formik.touched.email && formik.errors.email
                        )}
                        fullWidth
                        helperText={formik.touched.email && formik.errors.email}
                        name="email"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.email}
                        variant="filled"
                        placeholder="info@bglobalsolutions.com"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: isUserBasicoOGerenteOLectura,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={3} sx={{ py: "18px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="tenant-input"
                        >
                          Tenant
                        </InputLabel>
                      </Box>
                      <TextField
                        id="tenant-input"
                        error={Boolean(
                          formik.touched.tenant && formik.errors.tenant
                        )}
                        select
                        fullWidth
                        helperText={
                          formik.touched.tenant && formik.errors.tenant
                        }
                        name="tenant"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.tenant}
                        variant="filled"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: isUserBasicoOGerenteOLectura,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                              display: "flex",
                              justifyContent: "flex-start",
                            },
                          },
                        }}
                      >
                        {tenants.map((tenant) => (
                          <MenuItem key={tenant.Id} value={tenant.Id}>
                            {tenant.TenantName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={3} sx={{ py: "18px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="phone-input"
                        >
                          Teléfono
                        </InputLabel>
                      </Box>
                      <TextField
                        id="phone-input"
                        error={Boolean(
                          formik.touched.phone && formik.errors.phone
                        )}
                        fullWidth
                        helperText={formik.touched.phone && formik.errors.phone}
                        name="phone"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.phone}
                        variant="filled"
                        placeholder="+542351235454"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: isUserBasicoOGerenteOLectura,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Grid item xs={3} sx={{ pb: "18px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="address-input"
                        >
                          Dirección
                        </InputLabel>
                      </Box>
                      <TextField
                        id="address-input"
                        error={Boolean(
                          formik.touched.address && formik.errors.address
                        )}
                        fullWidth
                        helperText={
                          formik.touched.address && formik.errors.address
                        }
                        name="address"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.address}
                        variant="filled"
                        placeholder="Av. Santander"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: isUserBasicoOGerenteOLectura,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={3} sx={{ pb: "18px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="id-tipo-input"
                        >
                          Tipo de Identificación
                        </InputLabel>
                      </Box>
                      <TextField
                        id="id-tipo-input"
                        select
                        error={Boolean(
                          formik.touched.identification_id &&
                            formik.errors.identification_id
                        )}
                        fullWidth
                        helperText={
                          formik.touched.identification_id &&
                          formik.errors.identification_id
                        }
                        name="identification_id"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.identification_id}
                        variant="filled"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: isUserBasicoOGerenteOLectura,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                              display: "flex",
                              justifyContent: "flex-start",
                            },
                          },
                        }}
                      >
                        {idsTypes.map((idType) => (
                          <MenuItem key={idType.id} value={idType.id}>
                            {idType.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={3} sx={{ pb: "18px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="id-number-input"
                        >
                          Nro. de Identificación
                        </InputLabel>
                      </Box>
                      <TextField
                        id="id-number-input"
                        error={Boolean(
                          formik.touched.idNumber && formik.errors.idNumber
                        )}
                        fullWidth
                        helperText={
                          formik.touched.idNumber && formik.errors.idNumber
                        }
                        name="idNumber"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.idNumber}
                        variant="filled"
                        placeholder="Nro. de Identificación"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: isUserBasicoOGerenteOLectura,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={3} sx={{ pb: "18px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="status-select"
                        >
                          Estado
                        </InputLabel>
                      </Box>
                      <TextField
                        id="status-select"
                        select
                        error={Boolean(
                          formik.touched.status && formik.errors.status
                        )}
                        fullWidth
                        helperText={
                          formik.touched.status && formik.errors.status
                        }
                        name="status"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.status}
                        variant="filled"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: isUserBasicoOGerenteOLectura,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                              display: "flex",
                              justifyContent: "flex-start",
                            },
                          },
                        }}
                      >
                        {statuses.map((status) => (
                          <MenuItem key={status.id} value={status.value}>
                            {status.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={3} sx={{ pb: "26px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="sftp-user-input"
                        >
                          Usuario SFTP
                        </InputLabel>
                      </Box>
                      <TextField
                        id="sftp-user-input"
                        error={Boolean(
                          formik.touched.sftp_user && formik.errors.sftp_user
                        )}
                        fullWidth
                        helperText={
                          formik.touched.sftp_user && formik.errors.sftp_user
                        }
                        name="sftp_user"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.sftp_user}
                        variant="filled"
                        placeholder="Usuario SFTP"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: isUserBasicoOGerenteOLectura,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={3} sx={{ pb: "26px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="sftp-password-input"
                        >
                          Password SFTP
                        </InputLabel>
                      </Box>
                      <TextField
                        id="sftp-password-input"
                        error={Boolean(
                          formik.touched.sftp_password &&
                            formik.errors.sftp_password
                        )}
                        fullWidth
                        helperText={
                          formik.touched.sftp_password &&
                          formik.errors.sftp_password
                        }
                        name="sftp_password"
                        onBlur={formik.handleBlur}
                        onChange={(e) => handleChangePassword(e)}
                        type={showPassword ? "text" : "password"}
                        value={formik.values.sftp_password}
                        variant="filled"
                        placeholder="Password SFTP"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: isUserBasicoOGerenteOLectura,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={3} sx={{ pb: "26px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="company-key-select"
                        >
                          Company Key
                        </InputLabel>
                      </Box>
                      <TextField
                        id="company-key-select"
                        error={Boolean(
                          formik.touched.fileName && formik.errors.fileName
                        )}
                        fullWidth
                        helperText={
                          formik.touched.fileName && formik.errors.fileName
                        }
                        name="fileName"
                        value={formik.values.fileName}
                        variant="filled"
                        placeholder="Subir archivo"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {isUserBasicoOGerenteOLectura ? (
                                <HtmlTooltip
                                  title={<b>No tienes permiso para editar</b>}
                                >
                                  <span>
                                    <IconButton
                                      aria-label="subir archivo"
                                      component="label"
                                      disabled
                                    >
                                      <input
                                        hidden
                                        id="subir-button"
                                        type="file"
                                        name="file"
                                        onChange={(e: any) =>
                                          handleUploadFile(
                                            e.currentTarget.files[0]
                                          )
                                        }
                                        accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                      />
                                      <MaterialIcons.FileUploadIcon color="disabled" />
                                    </IconButton>
                                  </span>
                                </HtmlTooltip>
                              ) : (
                                <HtmlTooltip title={<b>Subir company key</b>}>
                                  <IconButton
                                    aria-label="subir archivo"
                                    component="label"
                                  >
                                    <input
                                      hidden
                                      id="subir-button"
                                      type="file"
                                      name="file"
                                      onChange={(e: any) =>
                                        handleUploadFile(
                                          e.currentTarget.files[0]
                                        )
                                      }
                                      accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    />
                                    <MaterialIcons.FileUploadIcon color="primary" />
                                  </IconButton>
                                </HtmlTooltip>
                              )}
                            </InputAdornment>
                          ),
                          readOnly: true,
                          disableUnderline: true,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container maxWidth="sm" paddingBottom="32px">
                    <Box sx={{ display: "flex" }}>
                      {!isUserBasicoOGerenteOLectura && (
                        <Button
                          sx={{ marginLeft: "18px" }}
                          variant="contained"
                          color="primary"
                          onClick={handleClickGuardar}
                          disabled={
                            !formik.dirty ||
                            !formik.values.name ||
                            !formik.values.tenant ||
                            !formik.values.idNumber ||
                            isEqual(previusState, formik.values)
                          }
                        >
                          Guardar
                        </Button>
                      )}

                      {formik.dirty && !isEqual(previusState, formik.values) ? (
                        <Button
                          sx={{ marginLeft: "18px" }}
                          variant="contained"
                          color="error"
                          onClick={handleClickDirtyCancelar}
                        >
                          Cancelar
                        </Button>
                      ) : (
                        <Button
                          sx={{ marginLeft: "18px" }}
                          variant="contained"
                          color="error"
                          onClick={() => navigate(-1)}
                        >
                          Cancelar
                        </Button>
                      )}
                    </Box>
                  </Grid>
                </Box>
              </Box>
            </Box>
          )}
        </Grid>
        {!isIdsLoading && !isTenantsLoading && (
          <Grid bgcolor="#EEF1FC" item xs={2} sx={{ marginLeft: "67px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                flexGrow: 1,
                backgroundColor: "#EEF1FC",
              }}
            >
              <Stack spacing={2}>
                <Button
                  variant="contained"
                  startIcon={<MaterialIcons.SettingsIcon />}
                  sx={{
                    backgroundColor: "white",
                    color: "black",
                    "&:hover": { color: "white" },
                  }}
                  onClick={() =>
                    handleNavigate(`/empresas/empresa/${empresaId}/parametros`)
                  }
                >
                  Parametros
                </Button>
              </Stack>
            </Box>
          </Grid>
        )}
      </Grid>

      <UsersTable
        setRefresTable={setRefreshTable}
        refreshTable={refreshTable}
        inviteUser={() => inviteUser()}
        setErrorMessage={setErrorMessage}
        users={users}
        originalUsers={originalUsers}
        setUsers={setUsers}
        isLoading={isLoading}
        fetchUsersError={fetchUsersError}
        fetchUsersErrorMessage={fetchUsersErrorMessage}
      />

      {openInformationModal && (
        <InformationModal
          title={isDirty ? "Cancelar cambios" : "Editar empresa"}
          message={
            isDirty
              ? "¿Querés cancelar los cambios realizados?"
              : "¿Querés guardar los cambios realizados a ésta empresa?"
          }
          openModal={openInformationModal}
          onClose={handleCloseModal}
          saveForm={handleSaveButton}
          isDirty={isDirty}
          clickCancelar={clickCancelar}
          handleDeshacerCancelar={handleDeshacerCancelar}
          guardar={clickGuardar}
          guardarMessage="¿Querés guardar ésta novedad?"
          clickEliminar={false}
          disabledOnSubmit={disabledOnSubmit}
        />
      )}

      {inviteUserModal && (
        <InvitarUsuarioModal
          changeOpenModal={setInviteUserModal}
          openModal={inviteUserModal}
          handleRefreshTable={setRefreshTable}
          handleOpenErrorModal={setOpenErrorModal}
          setErrorMessage={setErrorMessage}
        />
      )}

      {openErrorModal && (
        <InformationModal
          title="Ha ocurrido un error"
          message={errorMessage}
          onClose={handleCloseModal}
          openModal={openErrorModal}
          clickCancelar={false}
          clickEliminar={false}
          guardar={false}
          isDirty={false}
          errorMessage
        />
      )}
    </Layout>
  );
};

export default EmpresaNovedadDetails;
