const linksMenuGestion: string[] = [
  "/novedades",
  "/novedades/novedad/",
  "/historial",
  "/historial/novedad/",
  "/periodos",
  "/periodos/periodo/",
  "/nomina",
  "/parametros",
  "/parametros/tipodeperiodo/periodo/",
  "/parametros/tipodenovedad/novedad/",
  "/empresa",
];

const linksMenuPortal: string[] = [
  "/empresas",
  "/empresas/empresa/",
  "/empresas/empresa/crear%20empresa",
  "/empresas/empresa/parametros",
  "/empresas/empresa/parametros/creartipodenovedad",
  "/empresas/empresa/parametros/tipodeperiodo/periodo/",
  "/empresas/empresa/parametros/tipodenovedad/",
  "/parametrosportal",
  "/parametrosportal/creartipodeidentificacion",
  "/parametrosportal/creartipodenovedad",
  "/parametrosportal/creartipodeperiodo",
  "/parametrosportal/identificaciones/identificacion/",
  "/parametrosportal/tipodenovedad/novedad/",
  "/parametrosportal/tipodeperiodo/periodo/",
  "/usuarios",
  "/usuarios/usuario/",
];

export { linksMenuGestion, linksMenuPortal };
