import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { isEqual } from "lodash";
import { useAuth0 } from "@auth0/auth0-react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useHasPermissions } from "react-simple-rbac";
import { shallow } from "zustand/shallow";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import FetchErrorMessage from "../../FetchErrorMessage/FetchErrorMessage";
import InformationModal from "../../Modals/ConfirmationModal/InformationModal";
import Layout from "../../Layout/Layout";
import UserCompaniesTable from "./UserCompaniesTable";

import { EmpresaContext } from "../../../context/empresa/EmpresaContext";

import { getUserById, updateUser } from "../../../services/UsersService";
import { getCompaniesByUser } from "../../../services/CompaniesService";

import { useUserStore } from "../../../store/userStore";
import { useUsersStore } from "../../../store/usersStore";

import { CompanyType } from "../../../types";

const usersRoles = [
  {
    id: 3,
    value: "3",
    label: "Cliente Básico",
  },
  {
    id: 1,
    value: "1",
    label: "Cliente Administrador",
  },
  {
    id: 6,
    value: "6",
    label: "BDO Lectura",
  },
  {
    id: 4,
    value: "4",
    label: "BDO Básico",
  },
  {
    id: 5,
    value: "5",
    label: "BDO Gerente",
  },
  {
    id: 2,
    value: "2",
    label: "BDO Administrador",
  },
];

const userTypes = [
  {
    id: 1,
    value: 0,
    label: "Cliente",
  },
  {
    id: 2,
    value: 1,
    label: "BDO",
  },
];

const statuses = [
  { id: 1, name: "Inactivo", value: 0 },
  { id: 2, name: "Activo", value: 1 },
  { id: 3, name: "Pendiente", value: 2 },
];

type UsuarioDetailsType = {
  params: any;
};

type UserType = {
  given_name: string;
  family_name: string;
  email: string;
  phone_number: string;
  role: number;
  status: number;
  companies: [];
  type: number;
};

const UsuarioDetails: React.FC<UsuarioDetailsType> = ({ params }) => {
  const [previusState, setPreviusState] = useState<UserType>({
    given_name: "",
    family_name: "",
    email: "",
    phone_number: "",
    role: 0,
    status: 0,
    companies: [],
    type: 0,
  });
  const [assignedCompanies, setAssignedCompanies] = useState<CompanyType[]>([]);
  const [originalAssignedCompanies, setOriginalAssignedCompanies] = useState<
    CompanyType[]
  >([]);
  const [openInformationModal, setOpenInformationModal] =
    useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [clickCancelar, setClickCancelar] = useState<boolean>(false);
  const [clickGuardar, setClickGuardar] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [refreshTable, setRefreshTable] = useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [disabledOnSubmit, setDisabledOnSubmit] = useState<boolean>(false);
  const [fetchUserDataError, setFetchUserDataError] = useState<boolean>(false);
  const [fetchuserDataErrorMessage, setFetchUserDataErrorMessage] =
    useState<string>("");
  const [fetchUserCompaniesError, setFetchUserCompaniesError] =
    useState<boolean>(false);
  const [fetchUserCompaniesErrorMessage, setFetchUserCompaniesErrorMessage] =
    useState<string>("");
  const [userHasRole, setUserHasRole] = useState<boolean>(false);
  const [isUserReadOnly, setIsUserReadOnly] = useState<boolean>(false);

  const { usuarioId } = params;
  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  const { setEmpresas } = useContext(EmpresaContext);

  const setRefreshUsersTable = useUsersStore(
    (state) => state.setRefreshUsersTable
  );

  const canUpdateUser = useHasPermissions(["update:user"]);

  const userDataStore = useUserStore((state) => state.user, shallow);

  const setUserData = useUserStore((state) => state.setUserData);

  // const isUserReadOnly = useHasRoles(["read_only"]);

  // const isAuthorized = useHasRoles(["admin", "manager"], true);

  // const canInactiveUser = userPermissions && isAuthorized;

  // const isUserReadOnly = false;

  // const canInactiveUser = true;

  const formik = useFormik({
    initialValues: {
      given_name: "",
      family_name: "",
      email: "",
      phone_number: "",
      role: 0,
      status: 0,
      companies: [],
      type: 0,
    },
    validationSchema: Yup.object({
      given_name: Yup.string().max(55).required("El nombre es requerido"),
      // family_name: Yup.string().max(55).required("El apellido es requerido"),
      email: Yup.string().max(55).required("El email es requerido"),
      // phone_number: Yup.string().max(55).required("El teléfono es requerida"),
      // role: Yup.string().max(12).required("El role es requerido"),
      status: Yup.string().required("El estado es requerido"),
    }),
    onSubmit: async (data) => {
      setDisabledOnSubmit(true);
      const finalData = {
        phone_number: data.phone_number,
        family_name: data.family_name,
        given_name: data.given_name,
        nickname: "",
        status: data.status,
        type: data.type,
        role: Number(data.role),
      };
      const token = await getAccessTokenSilently();
      updateUser(token, usuarioId, finalData)
        .then(() => {
          if (usuarioId === userDataStore?.id) {
            setUserData({ ...userDataStore, ...finalData });
          }
          setRefreshUsersTable(true);
          handleCloseModal();
          setDisabledOnSubmit(false);
          navigate(-1);
        })
        .catch((e) => {
          console.error(e.response.data.error);
          setErrorMessage(e.response.data.error);
          setDisabledOnSubmit(false);
          setOpenErrorModal(true);
        });
    },
  });

  const handleOpenModal = () => {
    setOpenInformationModal(true);
  };

  const handleCloseModal = () => {
    setOpenInformationModal(false);
    openErrorModal && setOpenErrorModal(false);
  };

  const handleSaveButton = () => {
    formik.submitForm();
  };

  const handleDeshacerCancelar = () => {
    formik.resetForm();
    navigate(-1);
  };

  const handleClickDirtyCancelar = () => {
    setIsDirty(true);
    setClickCancelar(true);
    setClickGuardar(false);
    handleOpenModal();
  };

  const handleClickGuardar = () => {
    setIsDirty(false);
    setClickCancelar(false);
    setClickGuardar(true);
    handleOpenModal();
  };

  const getUserCompanies = useCallback(async () => {
    setIsLoading(true);
    setFetchUserCompaniesError(false);
    setFetchUserCompaniesErrorMessage("");
    const token = await getAccessTokenSilently();

    getCompaniesByUser(token, usuarioId)
      .then((result) => {
        setEmpresas(result.data);

        setAssignedCompanies(result.data);
        setOriginalAssignedCompanies(result.data);

        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e.response.data.error);
        setFetchUserCompaniesError(true);
        setFetchUserCompaniesErrorMessage(e.response.data.error);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserData = useCallback(async () => {
    setIsLoading(true);
    setFetchUserDataError(false);
    const token = await getAccessTokenSilently();
    getUserById(token, usuarioId)
      .then((result) => {
        formik.setValues(result.data);

        if (userDataStore) {
          setUserHasRole(userDataStore.isAdminOGerente);
          setIsUserReadOnly(!userDataStore.isAdminOGerente);
        }

        setPreviusState(result.data);
      })
      .catch((e) => {
        console.error(e.response.data.error);
        setFetchUserDataError(true);
        setFetchUserDataErrorMessage(e.response.data.error);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - Usuario`;
  }, []);

  useEffect(() => {
    getUserData();
    getUserCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usuarioId]);

  useEffect(() => {
    if (refreshTable) {
      getUserCompanies();
      setRefreshTable(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);

  return (
    <Layout icon="PersonIcon" title="Usuario" breadcrumb>
      <Grid container>
        <Grid bgcolor="white" item xs={8} borderRadius="6px">
          {isLoading ? (
            <Box
              sx={{
                borderRadius: "6px",
                display: "flex",
                backgroundColor: "white",
                height: "100%",
                width: "100%",
                paddingRight: "18px",
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Box
                  id="create-novelty-form"
                  borderRadius="6px"
                  sx={{
                    backgroundColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "422px",
                  }}
                >
                  <CircularProgress />
                </Box>
              </Box>
            </Box>
          ) : fetchUserDataError ? (
            <FetchErrorMessage
              errorMessage={fetchuserDataErrorMessage}
              height="422px"
            />
          ) : (
            <Box
              sx={{
                borderRadius: "6px",
                display: "flex",
                backgroundColor: "white",
                height: "100%",
                width: "100%",
                paddingRight: "18px",
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Box
                  component="form"
                  autoComplete="off"
                  borderRadius="6px"
                  sx={{ backgroundColor: "white", height: "100%" }}
                  onSubmit={formik.handleSubmit}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        pt: "18px",
                        pl: "18px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#333333",
                        }}
                      >
                        Edición
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Grid item xs={4} sx={{ pt: "18px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="nombre-input"
                        >
                          Nombre
                        </InputLabel>
                      </Box>
                      <TextField
                        id="nombre-input"
                        error={Boolean(
                          formik.touched.given_name && formik.errors.given_name
                        )}
                        fullWidth
                        helperText={
                          formik.touched.given_name && formik.errors.given_name
                        }
                        name="given_name"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.given_name}
                        variant="filled"
                        placeholder="Nombre"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: isUserReadOnly,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} sx={{ pt: "18px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="apellido-input"
                        >
                          Apellido
                        </InputLabel>
                      </Box>
                      <TextField
                        id="apellido-input"
                        error={Boolean(
                          formik.touched.family_name &&
                            formik.errors.family_name
                        )}
                        fullWidth
                        helperText={
                          formik.touched.family_name &&
                          formik.errors.family_name
                        }
                        name="family_name"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.family_name}
                        variant="filled"
                        placeholder="Apellido"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: isUserReadOnly,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} sx={{ pt: "18px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="email-input"
                        >
                          Email
                        </InputLabel>
                      </Box>
                      <TextField
                        id="email-input"
                        error={Boolean(
                          formik.touched.email && formik.errors.email
                        )}
                        fullWidth
                        helperText={formik.touched.email && formik.errors.email}
                        name="email"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        variant="filled"
                        placeholder="Email"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: isUserReadOnly,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Grid item xs={4} sx={{ pt: "10px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="phone-input"
                        >
                          Teléfono
                        </InputLabel>
                      </Box>
                      <TextField
                        id="phone-input"
                        error={Boolean(
                          formik.touched.phone_number &&
                            formik.errors.phone_number
                        )}
                        fullWidth
                        helperText={
                          formik.touched.phone_number &&
                          formik.errors.phone_number
                        }
                        name="phone_number"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.phone_number}
                        variant="filled"
                        placeholder="Teléfono"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: isUserReadOnly,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} sx={{ pt: "10px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="user-role-select"
                        >
                          Rol
                        </InputLabel>
                      </Box>
                      <TextField
                        id="user-role-select"
                        select
                        error={Boolean(
                          formik.touched.role && formik.errors.role
                        )}
                        fullWidth
                        helperText={formik.touched.role && formik.errors.role}
                        name="role"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.role.toString()}
                        variant="filled"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: isUserReadOnly,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                              display: "flex",
                              justifyContent: "flex-start",
                            },
                          },
                        }}
                      >
                        {usersRoles.map((role) => (
                          <MenuItem key={role.id} value={role.value}>
                            {role.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={4} sx={{ pt: "10px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="status-input"
                        >
                          Estado
                        </InputLabel>
                      </Box>
                      <TextField
                        id="status-input"
                        select
                        error={Boolean(
                          formik.touched.status && formik.errors.status
                        )}
                        fullWidth
                        helperText={
                          formik.touched.status && formik.errors.status
                        }
                        name="status"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.status}
                        variant="filled"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: isUserReadOnly,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                              display: "flex",
                              justifyContent: "flex-start",
                            },
                          },
                        }}
                      >
                        {statuses.map((status) => (
                          <MenuItem
                            disabled={!userHasRole && status.id === 1}
                            key={status.id}
                            value={status.value}
                          >
                            {status.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Grid item xs={4} sx={{ pt: "10px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="tipo-input"
                        >
                          Tipo
                        </InputLabel>
                      </Box>
                      <TextField
                        id="tipo-input"
                        error={Boolean(
                          formik.touched.type && formik.errors.type
                        )}
                        fullWidth
                        helperText={formik.touched.type && formik.errors.type}
                        name="type"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={userTypes[formik.values.type].label}
                        variant="filled"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: true,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                              display: "flex",
                              justifyContent: "flex-start",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    maxWidth="sm"
                    paddingBottom="32px"
                    paddingTop="24px"
                  >
                    <Box sx={{ display: "flex" }}>
                      {!isUserReadOnly && canUpdateUser && (
                        <Button
                          sx={{ marginLeft: "18px" }}
                          variant="contained"
                          color="primary"
                          onClick={handleClickGuardar}
                          disabled={
                            !formik.dirty ||
                            !formik.values.given_name ||
                            !formik.values.email ||
                            formik.values.status < 0 ||
                            formik.values.type < 0 ||
                            formik.values.role < 0 ||
                            isEqual(previusState, formik.values)
                          }
                        >
                          Guardar
                        </Button>
                      )}

                      {formik.dirty && !isEqual(previusState, formik.values) ? (
                        <Button
                          sx={{ marginLeft: "18px" }}
                          variant="contained"
                          color="error"
                          onClick={handleClickDirtyCancelar}
                        >
                          Cancelar
                        </Button>
                      ) : (
                        <Button
                          sx={{ marginLeft: "18px" }}
                          variant="contained"
                          color="error"
                          component={RouterLink}
                          to="/usuarios"
                          relative="path"
                        >
                          Cancelar
                        </Button>
                      )}
                    </Box>
                  </Grid>
                </Box>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>

      <UserCompaniesTable
        companies={assignedCompanies}
        originalCompanies={originalAssignedCompanies}
        isLoading={isLoading}
        setRefreshTable={setRefreshTable}
        setCompanies={setAssignedCompanies}
        usuarioEmail={formik.values.email}
        fetchUserCompaniesError={fetchUserCompaniesError}
        fetchUserCompaniesErrorMessage={fetchUserCompaniesErrorMessage}
        userHasRole={userHasRole}
      />

      {openInformationModal && (
        <InformationModal
          title={isDirty ? "Cancelar cambios" : "Editar usuario"}
          message={
            isDirty
              ? "¿Querés cancelar los cambios realizados?"
              : "¿Querés guardar los cambios realizados a éste usuario?"
          }
          openModal={openInformationModal}
          onClose={handleCloseModal}
          saveForm={handleSaveButton}
          isDirty={isDirty}
          clickCancelar={clickCancelar}
          handleDeshacerCancelar={handleDeshacerCancelar}
          guardar={clickGuardar}
          guardarMessage="¿Querés guardar los cambios realizados a éste usuario?"
          clickEliminar={false}
          disabledOnSubmit={disabledOnSubmit}
        />
      )}

      {openErrorModal && (
        <InformationModal
          title="Ha ocurrido un error"
          message={errorMessage}
          onClose={handleCloseModal}
          openModal={openErrorModal}
          clickCancelar={false}
          clickEliminar={false}
          guardar={false}
          isDirty={false}
          errorMessage
        />
      )}
    </Layout>
  );
};

export default UsuarioDetails;
