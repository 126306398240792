import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { useAuth0 } from "@auth0/auth0-react";

const CallbackPage = () => {
  const { error } = useAuth0();

  // const loader = async () => {
  //   console.log("user", user)
  //   if (user) {
  //     return redirect("/novedades");
  //   }
  // };

  // useEffect(() => {
  //   if (!isLoading) {
  //     loader();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isLoading]);

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          padding: "8px",
          width: "100%",
          flexBasis: "auto",
          flexDirection: "column",
          maxWidth: "120rem",
        }}
      >
        <Typography>{error.message}</Typography>
      </Box>
    );
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ backgroundColor: "#EEF1FC", minHeight: "100vh" }}
    >
      <Grid item xs={3}>
        <Box
          component="main"
          sx={{
            alignItems: "center",
            display: "flex",
            flexGrow: 1,
            minHeight: "100%",
            backgroundColor: "white",
          }}
        >
          <Container sx={{ backgroundColor: "#EEF1FC" }} maxWidth="sm">
            <CircularProgress />
          </Container>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CallbackPage;
