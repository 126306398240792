import http from "../api";
import {
  IdentificationType,
  NoveltyType,
  ParametersPeriodType,
} from "../types";

// const randomNumber = () => {
//   return Math.floor(Math.random() * 1000000000);
// };

const addPeriodType = (token: string, periodData: any) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  const periodoData = {
    ...periodData,
    periods_before: Number(periodData.periods_before),
    day_limit: Number(periodData.day_limit),
  };

  return http.post("/api/v1/parameters/periodType", periodoData);
};

const editPeriod = (token: string, periodData: any, periodId: string) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  const periodoData = {
    ...periodData,
    day_limit: Number(periodData.day_limit),
    duration: Number(periodData.duration),
    periods_before: Number(periodData.periods_before),
  };

  return http.patch(`/api/v1/parameters/periodType/${periodId}`, periodoData);
};

const deletePeriod = (token: string, periodId: string) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.delete(`/api/v1/parameters/periodType/${periodId}`);
};

const deleteNoveltyType = (token: string, noveltyId: string) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.delete(`/api/v1/parameters/noveltyType/${noveltyId}`);
};

const getPeriodById = (token: string, periodId: string) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.get<ParametersPeriodType>(
    `/api/v1/parameters/periodType/${periodId}`
  );
};

const addIdentificationType = (token: string, data: { label: string }) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.post("/api/v1/parameters/identificationType", data);
};

const editIdentificationType = (
  token: string,
  identificationTypeId: string,
  data: { label: string }
) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.patch(
    `/api/v1/parameters/identificationType/${identificationTypeId}`,
    data
  );
};

const getIdentificationsTypes = (token: string) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.get<IdentificationType[]>(
    "/api/v1/parameters/identificationType"
  );
};

const getIdentificationTypeById = (
  token: string,
  identificationTypeId: string
) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.get<IdentificationType>(
    `/api/v1/parameters/identificationType/${identificationTypeId}`
  );
};

const getNoveltyType = (token: string) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.get<NoveltyType[]>("/api/v1/parameters/noveltyType");
};

const addNoveltyType = (token: string, noveltyData: any) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.post("/api/v1/parameters/noveltyType", noveltyData);
};

const editNoveltyType = (token: string, noveltyTypeId: string, data: any) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.patch(`/api/v1/parameters/noveltyType/${noveltyTypeId}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getNoveltyTypeById = (token: string, noveltyTypeId: string) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.get(`/api/v1/parameters/noveltyType/${noveltyTypeId}`);
};

const downloadNoveltyFile = (token: string, noveltyRef: string) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http({
    url: `/api/v1/parameters/file/${noveltyRef}`,
    method: "GET",
    responseType: "blob",
  });
};

const getCloserPeriods = (token: string, companyId: string) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.get(`/api/v1/companies/${companyId}/periods/closer`);
};

const getAvailablePeriods = (token: string, companyId: string) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.get(`/api/v1/companies/${companyId}/periods/available`);
};

const getPastPeriods = (token: string, companyId: string) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.get(`/api/v1/companies/${companyId}/periods/past`);
};

const getPastPeriodsById = (
  token: string,
  companyId: string,
  periodId: string
) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.get(
    `/api/v1/companies/${companyId}/periods/past?period_id=${periodId}`
  );
};

export {
  addIdentificationType,
  addNoveltyType,
  addPeriodType,
  deleteNoveltyType,
  deletePeriod,
  downloadNoveltyFile,
  editIdentificationType,
  editNoveltyType,
  editPeriod,
  getAvailablePeriods,
  getCloserPeriods,
  getIdentificationsTypes,
  getIdentificationTypeById,
  getNoveltyType,
  getNoveltyTypeById,
  getPastPeriods,
  getPastPeriodsById,
  getPeriodById,
};
