import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import {
  getAllCompanies,
  getCompanyPeriods,
  getCompanyTenants,
  getUsersCompanies,
} from "../services/CompaniesService";
import {
  CompanyPeriodsType,
  CompanyType,
  EmpresaType,
  TenantType,
} from "../types";

type EmpresaState = {
  allEmpresaPeriodos: CompanyPeriodsType[];
  allEmpresas: EmpresaType[] | CompanyType[];
  allTenants: TenantType[];
  empresaId: string;
  empresas: EmpresaType[];
  selectedEmpresa: EmpresaType | CompanyType | undefined;
  userEmpresas: EmpresaType[];
  hasFetchError: boolean;
  hasUserCompaniesFetchError: boolean;
  hasEmpresaPeriodsFetchError: boolean;
  errorMessage: string;
};

type EmpresaAction = {
  setEmpresaId: (empresaId: string) => void;
  setEmpresas: (empresas: EmpresaType[]) => void;
  setAllEmpresas: (empresas: EmpresaType[] | CompanyType[]) => void;
  setSelectedEmpresa: (empresa?: EmpresaType | CompanyType) => void;
  getAllCompanyPeriods: (token: string) => Promise<void>;
  getAllEmpresas: (token: string) => Promise<void>;
  getUserEmpresas: (token: string) => Promise<void>;
  getEmpresaTenants: (token: string) => Promise<void>;
  returnEmpresas: () => EmpresaType[];
  returnUserEmpresas: () => EmpresaType[];
  returnEmpresaPeriodos: () => CompanyPeriodsType[];
  returnTenants: () => TenantType[];
  resetEmpresasState: () => void;
};

// define the initial state
const initialEmpresasState: EmpresaState = {
  allEmpresaPeriodos: [],
  allEmpresas: [],
  allTenants: [],
  empresaId: "",
  empresas: [],
  selectedEmpresa: undefined,
  userEmpresas: [],
  hasFetchError: false,
  hasUserCompaniesFetchError: false,
  hasEmpresaPeriodsFetchError: false,
  errorMessage: "",
};

//En caso de que la novedad no se hasha procesado, que se pueda editar.

export const useEmpresaStore = create<EmpresaState & EmpresaAction>()(
  persist(
    (set, get) => ({
      ...initialEmpresasState,
      getAllEmpresas: async (token) => {
        await getAllCompanies(token)
          .then((res) =>
            set((state) => ({
              ...state,
              hasFetchError: false,
              empresas: res.data,
              allEmpresas: res.data,
              errorMessage: "",
            }))
          )
          .catch((e) => {
            console.error(e.response.data.error);
            set((state) => ({
              ...state,
              hasFetchError: true,
              empresas: [],
              allEmpresas: [],
              errorMessage: e.response.data.error,
            }));
          });
      },
      getAllCompanyPeriods: async (token) => {
        getCompanyPeriods(token, get().empresaId)
          .then((companyPeriodResult) => {
            set((state) => ({
              ...state,
              hasEmpresaPeriodsFetchError: false,
              allEmpresaPeriodos: companyPeriodResult.data,
            }));
          })
          .catch((e) => {
            console.error(e.response.data.error);
            set((state) => ({
              ...state,
              hasEmpresaPeriodsFetchError: true,
              allEmpresaPeriodos: [],
              errorMessage: e.response.data.error,
            }));
          });
      },
      getUserEmpresas: async (token) => {
        getUsersCompanies(token)
          .then((usersResult) => {
            set({ hasUserCompaniesFetchError: false });
            set({ userEmpresas: [] });
          })
          .catch((e) => {
            console.error(e.response.data.error);
            set({ hasUserCompaniesFetchError: true });
            set({ userEmpresas: [] });
            set({
              errorMessage: e.response.data.error,
            });
          });
      },
      getEmpresaTenants: async (token) => {
        getCompanyTenants(token)
          .then((tenantsRes) => {
            const tenants = tenantsRes.data.sort(function (
              a: { TenantName: string },
              b: { TenantName: string }
            ) {
              if (a.TenantName < b.TenantName) {
                return -1;
              }
              if (a.TenantName > b.TenantName) {
                return 1;
              }
              return 0;
            });

            set((state) => ({
              ...state,
              allTenants: tenants,
            }));
          })
          .catch((e) => {
            console.error("tenants", e.response.data.error);
            set((state) => ({
              ...state,
              allTenants: [],
              errorMessage: e.response.data.error,
            }));
          });
      },
      setAllEmpresas: (allEmpresas) => {
        set((state) => ({
          ...state,
          allEmpresas,
        }));
      },
      setEmpresaId: (empresaId) => {
        set({ empresaId });
      },
      setEmpresas: (empresas) => {
        set({ empresas });
      },
      setSelectedEmpresa: (empresa) => {
        set({ selectedEmpresa: empresa });
      },
      returnEmpresas: () => {
        return get().empresas;
      },
      returnUserEmpresas: () => {
        return get().userEmpresas;
      },
      returnEmpresaPeriodos: () => {
        return get().allEmpresaPeriodos;
      },
      returnTenants: () => {
        return get().allTenants;
      },
      resetEmpresasState: () => {
        set(initialEmpresasState);
      },
    }),
    {
      name: "empresas-storage", // name of the item in the storage (must be unique)
      storage: createJSONStorage<EmpresaState>(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);
