import React, { ReactNode } from "react";
import { shallow } from "zustand/shallow";
import { useDrawerStore } from "../../store/drawerStore";

import { DrawerContext } from "./DrawerContext";

type DrawerProviderType = {
  children: ReactNode;
};

export const DrawerProvider = ({ children }: DrawerProviderType) => {
  const { changeLink, isDrawerOpen, links, location, topLink } = useDrawerStore(
    ({ changeLink, isDrawerOpen, links, location, topLink }) => ({
      changeLink,
      isDrawerOpen,
      links,
      location,
      topLink,
    }),
    shallow
  );

  const { handleDrawerOpen, setChangeLink, setLocation, setTopLink } =
    useDrawerStore(
      ({ handleDrawerOpen, setChangeLink, setLocation, setTopLink }) => ({
        handleDrawerOpen,
        setChangeLink,
        setLocation,
        setTopLink,
      })
    );

  return (
    <DrawerContext.Provider
      value={{
        changeLink,
        handleDrawerOpen,
        isDrawerOpen,
        links,
        location,
        setChangeLink,
        setLocation,
        setTopLink,
        topLink,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};
