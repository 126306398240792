import React, { useContext, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";

import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import Layout from "../Layout/Layout";
import InformationModal from "../Modals/ConfirmationModal/InformationModal";

import { addNoveltyType } from "../../services/ParametersService";

import { ParametroContext } from "../../context/parametro/ParametroContext";

const CrearTipoNovedad = () => {
  const [openInformationModal, setOpenInformationModal] =
    useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [clickCancelar, setClickCancelar] = useState<boolean>(false);
  const [clickGuardar, setClickGuardar] = useState<boolean>(false);
  const [disabledOnSubmit, setDisabledOnSubmit] = useState<boolean>(false);

  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  const { setTabValue, tabValue } = useContext(ParametroContext);

  const handleOpenModal = () => {
    setOpenInformationModal(true);
  };

  const handleCloseModal = () => {
    setOpenInformationModal(false);
  };

  const handleClickDirtyCancelar = () => {
    setIsDirty(true);
    setClickCancelar(true);
    setClickGuardar(false);
    handleOpenModal();
  };

  const handleDeshacerCancelar = () => {
    formik.resetForm();
    navigate(-1);
  };

  const handleSaveButton = () => {
    formik.submitForm();
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      code: "",
      file: null,
      fileName: "",
      sftp: false,
    },
    validationSchema: Yup.object({
      name: Yup.string().max(55).required("El nombre es requerido"),
      code: Yup.number().required("El código es requerido"),
    }),
    onSubmit: async (data) => {
      setDisabledOnSubmit(true);
      const token = await getAccessTokenSilently();

      const finalData = {
        name: data.name,
        code: Number(data.code),
        sftp: data.sftp,
      };

      const result = await addNoveltyType(token, finalData);
      formik.resetForm();

      if (result.status === 201) {
        setTabValue(1);
        handleCloseModal();
        navigate(-1);
      }
    },
  });

  const handleChangeCodigo = (e: any) => {
    if (Number(e.target.value) >= 0) {
      formik.setFieldValue("code", e.target.value);
    }
  };

  const handleClickGuardar = () => {
    setIsDirty(false);
    setClickCancelar(false);
    setClickGuardar(true);
    handleOpenModal();
  };

  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - Agregar novedad`;

    if (tabValue !== 1) {
      setTabValue(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue]);

  return (
    <Layout title="Agregar Tipo de novedad" noIcon>
      <Grid container marginTop="12px" borderRadius="6px">
        <Grid bgcolor="white" item xs={6} borderRadius="6px">
          <Box
            sx={{
              borderRadius: "6px",
              display: "flex",
              backgroundColor: "white",
              height: "100%",
              width: "100%",
              paddingRight: "18px",
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Box
                component="form"
                autoComplete="off"
                borderRadius="6px"
                sx={{ backgroundColor: "white", height: "100%" }}
                onSubmit={formik.handleSubmit}
              >
                <Grid container>
                  <Grid item xs={6} sx={{ pt: "18px", pl: "18px" }}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      marginBottom="4px"
                    >
                      <InputLabel
                        sx={{ color: "black" }}
                        htmlFor="nombre-input"
                      >
                        Nombre
                      </InputLabel>
                    </Box>
                    <TextField
                      id="nombre-input"
                      error={Boolean(formik.touched.name && formik.errors.name)}
                      fullWidth
                      helperText={formik.touched.name && formik.errors.name}
                      name="name"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      value={formik.values.name}
                      variant="filled"
                      placeholder="Nombre de la novedad"
                      InputProps={{
                        disableUnderline: true,
                        sx: {
                          borderRadius: "6px",
                          ".MuiInputBase-input": {
                            paddingBottom: "16px",
                            paddingTop: "16px",
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6} sx={{ pt: "18px", pl: "18px" }}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      marginBottom="4px"
                    >
                      <InputLabel sx={{ color: "black" }} htmlFor="code-input">
                        Codigo
                      </InputLabel>
                    </Box>
                    <TextField
                      id="code-input"
                      error={Boolean(formik.touched.code && formik.errors.code)}
                      fullWidth
                      helperText={formik.touched.code && formik.errors.code}
                      name="code"
                      onBlur={formik.handleBlur}
                      onChange={(e: any) => handleChangeCodigo(e)}
                      value={formik.values.code}
                      variant="filled"
                      placeholder="Codigo"
                      type="number"
                      InputProps={{
                        disableUnderline: true,
                        sx: {
                          borderRadius: "6px",
                          ".MuiInputBase-input": {
                            paddingBottom: "16px",
                            paddingTop: "16px",
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6} sx={{ pb: "8px", pt: "18px", pl: "18px" }}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="sftp"
                            checked={formik.values.sftp}
                            onChange={formik.handleChange}
                            inputProps={{
                              "aria-label": "sftp-checkbox",
                            }}
                          />
                        }
                        label="SFTP"
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid
                  container
                  maxWidth="sm"
                  paddingTop="16px"
                  paddingBottom="32px"
                >
                  <Box sx={{ display: "flex" }}>
                    <Button
                      sx={{ marginLeft: "18px" }}
                      variant="contained"
                      color="primary"
                      onClick={handleClickGuardar}
                      disabled={
                        !formik.dirty ||
                        !formik.values.name ||
                        !formik.values.code
                      }
                    >
                      Guardar
                    </Button>

                    {formik.dirty ? (
                      <Button
                        sx={{ marginLeft: "18px" }}
                        variant="contained"
                        color="error"
                        onClick={handleClickDirtyCancelar}
                      >
                        Cancelar
                      </Button>
                    ) : (
                      <Button
                        sx={{ marginLeft: "18px" }}
                        variant="contained"
                        color="error"
                        onClick={() => navigate(-1)}
                      >
                        Cancelar
                      </Button>
                    )}
                  </Box>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {openInformationModal && (
        <InformationModal
          title={isDirty ? "Cancelar cambios" : "Crear tipo de novedad"}
          message={
            isDirty
              ? "¿Querés cancelar los cambios realizados?"
              : "¿Querés crear éste tipo de novedad?"
          }
          openModal={openInformationModal}
          onClose={handleCloseModal}
          saveForm={handleSaveButton}
          isDirty={isDirty}
          clickCancelar={clickCancelar}
          handleDeshacerCancelar={handleDeshacerCancelar}
          guardar={clickGuardar}
          guardarMessage="¿Querés crear éste tipo de novedad?"
          clickEliminar={false}
          disabledOnSubmit={disabledOnSubmit}
        />
      )}
    </Layout>
  );
};

export default CrearTipoNovedad;
