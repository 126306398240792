import React from "react";
import { useParams } from "react-router-dom";

import ParametroPeriodoDetails from "../../components/Details/Parametros/ParametroPeriodoDetails";

const ParametroPeriodosDetailsPage = () => {
  const params = useParams();
  return <ParametroPeriodoDetails params={params} />;
};

export default ParametroPeriodosDetailsPage;
