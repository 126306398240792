import React, { useEffect } from "react";

import AccountProfile from "../components/AccountProfile/AccountProfile";

const PerfilPage = () => {
  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - Perfil`;
  }, []);

  return <AccountProfile />;
};

export default PerfilPage;
