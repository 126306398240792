import http from "../api";
import { NewUsuariosRowsType } from "../types";

const getAllUsers = (token: string) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.get<NewUsuariosRowsType[]>("/api/v1/users");
};

const getUserById = (token: string, id: string) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.get(`api/v1/users/${id}`);
};

const getUserProfile = (token: string) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.get<any>("/api/v1/users/profile");
};

const signUpUser = (token: string, userData: any) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.post("/api/v1/users/signup", userData);
};

const sendInviteToUser = (token: string, userData: any) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.post("/api/v1/users/invite", userData);
};

const updateUser = (token: string, userId: string, userData: any) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.patch(`/api/v1/users/${userId}`, userData);
};

const updateUserProfile = (token: string, userData: any) => {
  http.interceptors.request.use(function (config: any) {
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  return http.patch(`/api/v1/users/profile`, userData);
};

export {
  getAllUsers,
  getUserById,
  getUserProfile,
  sendInviteToUser,
  signUpUser,
  updateUser,
  updateUserProfile,
};
