import React from "react";
import { useParams } from "react-router-dom";

import NominaUsuarioDetails from "../../components/Details/NominaUsuarioDetails/NominaUsuarioDetails";

const NominaUsusarioDetailsPage = () => {
  const params = useParams();
  return <NominaUsuarioDetails params={params} />;
};

export default NominaUsusarioDetailsPage;
