import { createContext } from "react";
import { DrawerItemType } from "../../types";

type DrawerContextInterface = {
  links: DrawerItemType[];
  changeLink: number;
  setChangeLink: (changeLink: number) => void;
  topLink: string | null;
  setTopLink: (topLink: string) => void;
  setLocation: (location: string) => void;
  location: string;
  isDrawerOpen: boolean;
  handleDrawerOpen: () => void;
};

const DrawerContext = createContext({} as DrawerContextInterface);
DrawerContext.displayName = "DrawerContext";

export { DrawerContext };
