import React, { useEffect } from "react";

import EmpresaParametros from "../components/EmpresaParametros/EmpresaParametros";

const EmpresaSelectParametrosPage = () => {
  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - Parametros`;
  }, []);

  return <EmpresaParametros />;
};

export default EmpresaSelectParametrosPage;
