import React, { ReactNode } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { decodeJwt } from "jose";

import { useUserStore } from "../store/userStore";
import { useDrawerStore } from "../store/drawerStore";
import { getTokenFromLocalStorage } from "../utils/getTokenFromLocalStorage";
import { usePermissionsStore } from "../store/permissionsStore";

const roles = ["admin", "basic"];

declare global {
  interface Window {
    _env_: {
      REACT_APP_AUTH0_DOMAIN: string;
      REACT_APP_AUTH0_CLIENT_ID: string;
      REACT_APP_AUTH0_CALLBACK_URL: string;
      REACT_APP_AUTH0_AUDIENCE: string;
      REACT_APP_EMPRESA_NAME: string;
    };
  }
}

type Auth0ProviderType = {
  children: ReactNode;
};

const Auth0ProviderConfig: React.FC<Auth0ProviderType> = ({ children }) => {
  const domain = window._env_.REACT_APP_AUTH0_DOMAIN;
  const clientId = window._env_?.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = window._env_?.REACT_APP_AUTH0_CALLBACK_URL;
  const audience = window._env_?.REACT_APP_AUTH0_AUDIENCE;

  const { getUserData, returnUserData } = useUserStore(
    ({ getUserData, returnUserData }) => ({
      getUserData,
      returnUserData,
    })
  );

  const { setChangeLink, setTopLink } = useDrawerStore(
    ({ setChangeLink, setTopLink }) => ({
      setChangeLink,
      setTopLink,
    })
  );

  const { setPermissions, setRoles } = usePermissionsStore(
    ({ setPermissions, setRoles }) => ({ setPermissions, setRoles })
  );

  const handleOnRedirectCallback = async (appState: any) => {
    const token = getTokenFromLocalStorage(clientId);

    const permissions = decodeJwt(token).permissions;

    setPermissions(permissions);
    setRoles(roles);

    await getUserData(token).then(() => {
      const userData = returnUserData();
      if (userData) {
        if (userData.status === 0) {
          window.history.replaceState({}, "", "/");
          document.location.href = "/";
        } else if (userData.type === 1) {
          setChangeLink(1);
          setTopLink(userData.userTopLink);
          window.history.replaceState({}, "", "/empresas");
          document.location.href = "/empresas";
        } else {
          setChangeLink(2);
          setTopLink(userData.userTopLink);
          window.history.replaceState({}, "", appState.returnTo);
          document.location.href = appState.returnTo;
        }
      }
    });
  };

  if (!domain) {
    return <h1>No existe la variable de entorno "domain"</h1>;
  }

  if (!clientId) {
    return <h1>No existe la variable de entorno "clientId"</h1>;
  }

  if (!redirectUri) {
    return <h1>No existe la variable de entorno "redirectUri"</h1>;
  }

  if (!audience) {
    return <h1>No existe la variable de entorno "audience"</h1>;
  }

  const providerConfig = {
    domain,
    clientId,
    onRedirectCallback: handleOnRedirectCallback,
    authorizationParams: {
      redirect_uri: redirectUri,
      audience: audience,
    },
  };

  return (
    <Auth0Provider {...providerConfig} cacheLocation="localstorage">
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderConfig;
