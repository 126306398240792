import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

import { getAllUsers } from "../services/UsersService";

import { NewUsuariosRowsType } from "../types";

export type UsersState = {
  users: NewUsuariosRowsType[];
  refreshUsersTable: boolean;
  hasFetchError: boolean;
  errorMessage: string;
};

export type UsersAction = {
  getTodosUsers: (token: string) => Promise<void>;
  setRefreshUsersTable: (refresh: boolean) => void;
  setUsers: (users: any[]) => void;
  resetUsersState: () => void;
  returnUsers: () => NewUsuariosRowsType[];
};

// define the initial state

const initialUsersState: UsersState = {
  users: [],
  refreshUsersTable: false,
  hasFetchError: false,
  errorMessage: "",
};

export const useUsersStore = create<UsersState & UsersAction>()(
  persist(
    (set, get) => ({
      ...initialUsersState,
      getTodosUsers: async (token) => {
        await getAllUsers(token)
          .then((usersResult) => {
            set((state) => ({
              ...state,
              hasFetchError: false,
              users: usersResult.data,
            }));
          })
          .catch((e) => {
            console.error(e.response.data.error);
            set((state) => ({
              ...state,
              hasFetchError: true,
              users: [],
              errorMessage: e.response.data.error,
            }));
          });
      },
      setUsers: (users) => {
        set((state) => ({
          ...state,
          users,
        }));
      },
      setRefreshUsersTable: (refresh) => {
        set((state) => ({
          ...state,
          refreshUsersTable: refresh,
        }));
      },
      returnUsers: () => {
        return get().users;
      },
      resetUsersState: () => {
        set(initialUsersState);
      },
    }),
    {
      name: "users-storage", // name of the item in the storage (must be unique)
      storage: createJSONStorage<UsersState>(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);
