import React, { useEffect } from "react";
import Empresas from "../components/Empresas/Empresas";

const EmpresasPage = () => {
  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - Empresas`;
  }, []);

  return <Empresas />;
};

export default EmpresasPage;
