import React from "react";
import { useParams } from "react-router-dom";

import HistorialDetails from "../../components/Details/HistorialDetails/HistorialDetails";

const NovedadDetailsPage = () => {
  const params = useParams();
  return <HistorialDetails params={params} />;
};

export default NovedadDetailsPage;
