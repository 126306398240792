import React, { useEffect } from "react";
import CrearEmpresa from "../components/Empresas/CrearEmpresa";

const AddCompanyPage = () => {
  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - Crear empresa`;
  }, []);

  return <CrearEmpresa />;
};

export default AddCompanyPage;
