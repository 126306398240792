import React from "react";
import { useParams } from "react-router-dom";

import NovedadDetails from "../../components/Details/NovedadDetails/NovedadDetails";

const NovedadDetailsPage = () => {
  const params = useParams();
  return <NovedadDetails params={params} />;
};

export default NovedadDetailsPage;
