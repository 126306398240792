import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddchartIcon from "@mui/icons-material/Addchart";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import BackBurguer from "mdi-material-ui/Backburger";
import BusinessIcon from "@mui/icons-material/Business";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/EditOutlined";
import ErrorIcon from "@mui/icons-material/Error";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FilterAltIcon from "@mui/icons-material/FilterAltOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import PersonIcon from "@mui/icons-material/Person";
import PollIcon from "@mui/icons-material/PollOutlined";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import SouthIcon from "@mui/icons-material/South";
import VisibilityOffOutlined from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";

//MDI
import BriefcaseCheck from "mdi-material-ui/BriefcaseCheck";
import LightningBoltOutline from "mdi-material-ui/LightningBoltOutline";

//Local
import { ReactComponent as LightningBolt } from "../svg/lightning-bolt.svg";
import { ReactComponent as AccountBoxOutlined } from "../svg/account-box-outlined.svg";

export const MaterialIcons = {
  AccessTimeIcon,
  AccountBalanceIcon,
  AccountBoxOutlined,
  AccountCircleIcon,
  AddchartIcon,
  ArrowForwardIcon,
  ArrowUpwardIcon,
  AttachFileIcon,
  BackBurguer,
  BusinessIcon,
  BriefcaseCheck,
  CalendarTodayIcon,
  CheckBoxIcon,
  CheckIcon,
  ClearIcon,
  CloseIcon,
  DeleteIcon,
  DescriptionIcon,
  EditIcon,
  ErrorIcon,
  FileDownloadIcon,
  FileUploadIcon,
  FilterAltIcon,
  HelpOutlineIcon,
  HighlightOffIcon,
  HomeWorkOutlinedIcon,
  LightningBolt,
  LightningBoltOutline,
  LogoutIcon,
  PeopleAltOutlinedIcon,
  PersonIcon,
  PollIcon,
  SearchIcon,
  SettingsIcon,
  SouthIcon,
  VisibilityOffOutlined,
  VisibilityOutlined,
};
