import React from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { shallow } from "zustand/shallow";
import { RBACWrapper } from "react-simple-rbac";

import CallbackPage from "../../pages/callback";
import InactiveUser from "../InactiveUser/InactiveUser";
import Layout from "../Layout/Layout";
import LoginErrorModal from "../Modals/LoginErrorModal/LoginErrorModal";
import SelectCompanyPage from "../../pages/selectcompany";

import { useEmpresaStore } from "../../store/empresaStore";
import { useUserStore } from "../../store/userStore";

type ProtectedRouteType = {
  component: React.ComponentType<any>;
  redirectTo?: string;
  roleNeeded?: number;
  noEmpresa?: boolean;
  requiredPermissions?: string[];
};

const ProtectedRoute: React.FC<ProtectedRouteType> = ({
  component,
  noEmpresa,
  redirectTo,
  roleNeeded,
  requiredPermissions,
}) => {
  const selectedEmpresa = useEmpresaStore(
    (state) => state.selectedEmpresa,
    shallow
  );

  const user = useUserStore((state) => state.user);

  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated) {
    return <LoginErrorModal open={!isAuthenticated} />;
  }

  if (user && user.status === 0) {
    window.history.replaceState({}, "", "/");
    return <InactiveUser />;
  }

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <CallbackPage />,
  });

  if (noEmpresa) {
    return (
      <RBACWrapper requiredPermissions={requiredPermissions}>
        {({ hasRequiredPermissions }) => {
          if (!hasRequiredPermissions) {
            return (
              <Layout title="" noIcon>
                <div>
                  <h1>You dont have the required permissions :[</h1>
                </div>
              </Layout>
            );
          }
          return <Component />;
        }}
      </RBACWrapper>
    );
  }

  if (!selectedEmpresa) {
    return <SelectCompanyPage />;
  } else
    return (
      <RBACWrapper requiredPermissions={requiredPermissions}>
        {({ hasRequiredPermissions, hasRequiredRoles }) => {
          if (!hasRequiredRoles) {
            return (
              <Layout title="" noIcon>
                <div>
                  <h1>You dont have the required roles :[</h1>
                </div>
              </Layout>
            );
          }
          if (!hasRequiredPermissions) {
            return (
              <Layout title="" noIcon>
                <div>
                  <h1>You dont have the required permissions :[</h1>
                </div>
              </Layout>
            );
          }
          return <Component />;
        }}
      </RBACWrapper>
    );

  // if (noEmpresa) {
  //   return <Component />;
  // }

  // if (!selectedEmpresa) {
  //   return <SelectCompanyPage />;
  // } else return <Component />;
};

export default ProtectedRoute;
