import React, { useContext, useEffect, useState } from "react";
import { isEqual } from "lodash";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { shallow } from "zustand/shallow";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";

import Layout from "../../Layout/Layout";
import CustomBreadcrumbs from "../../CustomBreadcrumbs/CustomBreadcrumbs";
import InformationModal from "../../Modals/ConfirmationModal/InformationModal";

import { UserContext } from "../../../context/user/UserContext";
import { ParametroContext } from "../../../context/parametro/ParametroContext";
import { DrawerContext } from "../../../context/drawer/DrawerContext";

import { getCompanyNoveltyTypeById } from "../../../services/CompaniesService";
import {
  deleteNoveltyType,
  //downloadNoveltyFile,
  editNoveltyType,
  getNoveltyTypeById,
} from "../../../services/ParametersService";

import { useEmpresaStore } from "../../../store/empresaStore";

type CompanyParametroNovedadDetailsType = {
  params: any;
};

const CompanyParametroNovedadDetails: React.FC<
  CompanyParametroNovedadDetailsType
> = ({ params }) => {
  const [previusState, setPreviusState] = useState({
    name: "",
    code: "",
    sftp: false,
  });
  const [openInformationModal, setOpenInformationModal] =
    useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [clickCancelar, setClickCancelar] = useState<boolean>(false);
  const [clickGuardar, setClickGuardar] = useState<boolean>(false);
  const [clickEliminar, setClickEliminar] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [modalMessage, setModalMessage] = useState<string>("");
  const [disabledOnSubmit, setDisabledOnSubmit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [fetchError, setFetchError] = useState<boolean>(false);
  const [fetchErrorMessage, setFetchErrorMessage] = useState<string>("");

  const links: any[] = [
    { id: "1", value: "Parametros", isLink: true, to: "/parametrosportal" },
    { id: "2", value: "Tipo de Novedad", isLink: false },
  ];

  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  const { user } = useContext(UserContext);

  const { changeLink } = useContext(DrawerContext);

  const { setTabValue } = useContext(ParametroContext);

  const empresaId = useEmpresaStore((state) => state.empresaId, shallow);

  const { novedadId } = params;

  const formik = useFormik({
    initialValues: {
      name: "",
      code: "",
      sftp: false,
    },
    validationSchema: Yup.object({
      name: Yup.string().max(55).required("El nombre es requerido"),
      code: Yup.string().max(255).required("El código es requerido"),
    }),
    onSubmit: async (data) => {
      setDisabledOnSubmit(true);
      const token = await getAccessTokenSilently();
      const finalData = {
        name: data.name,
        code: data.code,
        sftp: data.sftp,
      };

      const result = await editNoveltyType(token, novedadId, finalData);

      if (result.status === 201 || result.status === 200) {
        handleCloseModal();
        formik.resetForm();
        setTabValue(1);
        navigate(-1);
      }
    },
  });

  const handleOpenModal = () => {
    setOpenInformationModal(true);
  };

  const handleCloseModal = () => {
    setOpenInformationModal(false);
  };

  const handleSaveButton = () => {
    formik.submitForm();
  };

  const handleDeshacerCancelar = () => {
    formik.resetForm();
    navigate(-1);
  };

  const handleClickDirtyCancelar = () => {
    setIsDirty(true);
    setClickCancelar(true);
    setClickGuardar(false);
    setClickEliminar(false);
    setModalTitle("Cancelar cambios");
    setModalMessage(
      "¿Querés cancelar los cambios realizados a éste tipo de novedad?"
    );
    handleOpenModal();
  };

  const handleClickGuardar = () => {
    setClickGuardar(true);
    setIsDirty(false);
    setClickCancelar(false);
    setClickEliminar(false);
    setModalTitle("Editar cambios");
    setModalMessage(
      "¿Querés guardar los cambios realizados a éste tipo de novedad?"
    );
    handleOpenModal();
  };

  const handleRemove = async () => {
    setDisabledOnSubmit(true);
    const token = await getAccessTokenSilently();

    const result = await deleteNoveltyType(token, novedadId);
    if (result.status === 200 || 201) {
      navigate(-1);
      handleCloseModal();
    }
  };

  const handleClickEliminar = () => {
    setClickGuardar(false);
    setClickCancelar(false);
    setClickEliminar(true);
    setModalTitle("Eliminar tipo de novedad");
    setModalMessage("¿Querés eliminar éste tipo de novedad?");
    handleOpenModal();
  };

  useEffect(() => {
    const getNovelty = async () => {
      setIsLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const result =
          changeLink === 1
            ? await getNoveltyTypeById(token, novedadId)
            : await getCompanyNoveltyTypeById(token, empresaId, novedadId);

        const commonValues = {
          name:
            changeLink === 2 ? result.data.novelty_type.name : result.data.name,
          code:
            changeLink === 2 ? result.data.novelty_type.code : result.data.code,
          sftp:
            changeLink === 2 ? result.data.novelty_type.sftp : result.data.sftp,
        };
        formik.setValues(commonValues);
        setPreviusState(commonValues);
        setFetchError(false);
      } catch (e: any) {
        console.error(e.response.data.error);
        setFetchErrorMessage(e.response.data.error);
        setFetchError(true);
      } finally {
        setIsLoading(false);
      }
    };

    getNovelty();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [novedadId]);

  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - Tipo de novedad`;
  }, []);

  return (
    <Layout title="Tipo de Novedad" noIcon>
      <CustomBreadcrumbs icon="PollIcon" links={links} />
      <Grid container marginTop="12px" borderRadius="6px">
        <Grid bgcolor="white" item xs={6} borderRadius="6px">
          <Box
            sx={{
              borderRadius: "6px",
              display: "flex",
              backgroundColor: "white",
              height: "100%",
              width: "100%",
              paddingRight: fetchError ? "0px" : "18px",
            }}
          >
            {isLoading ? (
              <Box
                sx={{
                  borderRadius: "6px",
                  display: "flex",
                  backgroundColor: "white",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Box
                    id="create-novelty-form"
                    borderRadius="6px"
                    sx={{
                      backgroundColor: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "371px",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                </Box>
              </Box>
            ) : fetchError ? (
              <Box
                sx={{
                  borderRadius: "6px",
                  display: "flex",
                  backgroundColor: "white",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Box
                    id="create-novelty-form"
                    borderRadius="6px"
                    sx={{
                      backgroundColor: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "371px",
                    }}
                  >
                    <h1>{fetchErrorMessage}</h1>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box sx={{ flexGrow: 1 }}>
                <Box
                  component="form"
                  autoComplete="off"
                  borderRadius="6px"
                  sx={{ backgroundColor: "white", height: "100%" }}
                  onSubmit={formik.handleSubmit}
                >
                  <Grid container justifyContent="space-between">
                    <Grid item xs={8} sx={{ pt: "18px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="nombre-input"
                        >
                          Nombre
                        </InputLabel>
                      </Box>
                      <TextField
                        id="nombre-input"
                        error={Boolean(
                          formik.touched.name && formik.errors.name
                        )}
                        fullWidth
                        helperText={formik.touched.name && formik.errors.name}
                        name="name"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.name}
                        variant="filled"
                        placeholder="Nombre de la novedad"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: user.type === 0 || changeLink === 2,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Grid
                      item
                      xs={8}
                      {...(changeLink === 1
                        ? { sx: { pt: "18px", pl: "18px" } }
                        : { sx: { pt: "10px", pb: "16px", pl: "18px" } })}
                    >
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="code-input"
                        >
                          Codigo
                        </InputLabel>
                      </Box>
                      <TextField
                        id="code-input"
                        error={Boolean(
                          formik.touched.code && formik.errors.code
                        )}
                        fullWidth
                        helperText={formik.touched.code && formik.errors.code}
                        name="code"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.code}
                        variant="filled"
                        placeholder="Codigo"
                        type="number"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: user.type === 0 || changeLink === 2,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      item
                      xs={6}
                      sx={{ pb: "8px", pt: "18px", pl: "18px" }}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="sftp"
                              checked={formik.values.sftp}
                              onChange={formik.handleChange}
                              inputProps={{
                                "aria-label": "sftp-checkbox",
                              }}
                            />
                          }
                          sx={{
                            ".Mui-disabled.MuiTypography-root": {
                              color: "black",
                            },
                            ".Mui-disabled.MuiCheckbox-root": {
                              color: (theme) => theme.palette.primary.main,
                            },
                          }}
                          label="SFTP"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                  {changeLink === 1 && (
                    <Grid
                      container
                      maxWidth="sm"
                      paddingBottom="32px"
                      paddingTop="32px"
                    >
                      <Box sx={{ display: "flex" }}>
                        <Button
                          sx={{ marginLeft: "18px" }}
                          variant="contained"
                          color="primary"
                          onClick={handleClickGuardar}
                          disabled={
                            !formik.dirty ||
                            !formik.values.name ||
                            !formik.values.code ||
                            isEqual(previusState, formik.values)
                          }
                        >
                          Guardar
                        </Button>

                        {formik.dirty &&
                        !isEqual(previusState, formik.values) ? (
                          <Button
                            sx={{ marginLeft: "18px" }}
                            variant="outlined"
                            color="primary"
                            onClick={handleClickDirtyCancelar}
                          >
                            Cancelar
                          </Button>
                        ) : (
                          <Button
                            sx={{ marginLeft: "18px" }}
                            variant="outlined"
                            color="primary"
                            onClick={() => navigate(-1)}
                          >
                            Cancelar
                          </Button>
                        )}
                        <Button
                          sx={{ marginLeft: "18px" }}
                          variant="contained"
                          color="error"
                          onClick={handleClickEliminar}
                        >
                          Eliminar
                        </Button>
                      </Box>
                    </Grid>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      {openInformationModal && (
        <InformationModal
          openModal={openInformationModal}
          clickCancelar={clickCancelar}
          clickEliminar={clickEliminar}
          guardar={clickGuardar}
          title={modalTitle}
          message={modalMessage}
          isDirty={isDirty}
          onClose={() => handleCloseModal()}
          onEliminar={() => handleRemove()}
          handleDeshacerCancelar={handleDeshacerCancelar}
          saveForm={handleSaveButton}
          disabledOnSubmit={disabledOnSubmit}
        />
      )}
    </Layout>
  );
};

export default CompanyParametroNovedadDetails;
