import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { shallow } from "zustand/shallow";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import Layout from "../Layout/Layout";
import UsersTable from "../Details/EmpresaNovedadDetails/UsersTable";

import NewUserModal from "../Modals/NewUserModal/NewUserModal";
import InformationModal from "../Modals/ConfirmationModal/InformationModal";

import {
  editCompany,
  getCompanyUsers,
  getUsersCompanies,
} from "../../services/CompaniesService";
import { useEmpresaStore } from "../../store/empresaStore";

import { DrawerContext } from "../../context/drawer/DrawerContext";
import { ParametroContext } from "../../context/parametro/ParametroContext";

import { CompanyUsersRowsType } from "../../types";

const statuses = [
  {
    id: 1,
    value: "0",
    label: "Activo",
  },
  {
    id: 2,
    value: "1",
    label: "Inactivo",
  },
];

const Empresa = () => {
  const [openNewUserModal, setOpenNewUserModal] = useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [refreshTable, setRefreshTable] = useState<boolean>(false);
  const [users, setUsers] = useState<CompanyUsersRowsType[]>([]);
  const [originalUsers, setOriginalUsers] = useState<CompanyUsersRowsType[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(true);
  const [fetchError, setFetchError] = useState<boolean>(false);
  const [fetchErrorMessage, setFetchErrorMessage] = useState<string>("");
  const [idTextType, setIdTextType] = useState<string>("");
  const [tenantName, setTenantName] = useState<string>("");
  const [fetchUsersErrorMessage, setFecthUsersErrorMessage] =
    useState<string>("");
  const [fetchUsersError, setFecthUsersError] = useState<boolean>(true);

  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth0();

  const { empresaId } = useEmpresaStore(
    ({ empresaId }) => ({ empresaId }),
    shallow
  );

  // const { allTenants, empresaId } = useEmpresaStore(
  //   ({ allTenants, empresaId }) => ({ allTenants, empresaId }),
  //   shallow
  // );

  const { changeLink } = useContext(DrawerContext);

  const { identifications } = useContext(ParametroContext);

  // const tenants = [...allTenants];

  const inviteUser = () => {
    setOpenNewUserModal(true);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      tenant: "",
      phone: "",
      address: "",
      status: 0,
      idType: "",
      idNumber: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("El nombre es requerido"),
      email: Yup.string().max(55).required("El email es requerido"),
      idNumber: Yup.string()
        .max(55)
        .required("El nro de identificación es requerido"),
    }),
    onSubmit: async (data) => {
      const token = await getAccessTokenSilently();
      const result = await editCompany(token, data, empresaId);
      formik.resetForm();
      if (result.status === 200) {
        navigate("/empresas");
      }
    },
  });

  const getTableUsersCompany = useCallback(async (token: string) => {
    setIsLoadingUsers(true);
    getCompanyUsers(token, empresaId)
      .then((result) => {
        setUsers(result.data);
        setOriginalUsers(result.data);
        setFecthUsersError(false);
        setFecthUsersErrorMessage("");
        setIsLoadingUsers(false);
      })
      .catch((e) => {
        console.error(e.response.data.error);
        setFecthUsersError(true);
        setFecthUsersErrorMessage(e.response.data.error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCompanyById = useCallback(async (token: string) => {
    setIsLoading(true);
    getUsersCompanies(token)
      .then((result) => {
        const companyResult: any = result.data.find(
          (empresa) => empresa.company.id === empresaId
        );

        if (companyResult) {
          formik.setValues({
            name: companyResult.company.name,
            email: companyResult.company.email,
            phone: companyResult.company.phone,
            address: companyResult.company.address,
            tenant: companyResult.company.tenant?.Id,
            status: companyResult.company.status,
            idType: companyResult.company.identification_type,
            idNumber: companyResult.company.identification_id,
          });
          setTenantName(companyResult.company.tenant?.TenantName);
          setFetchError(false);
          setFetchErrorMessage("");
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.error(e.response.data.error);
        setFetchError(true);
        setFetchErrorMessage(e.response.data.error);
        setIsLoading(false);
      });

    // getCompany(token, empresaId)
    //   .then((result) => {
    //     formik.setValues({
    //       name: result.data.name,
    //       email: result.data.email,
    //       phone: result.data.phone,
    //       address: result.data.address,
    //       tenant: result.data.tenant.Id,
    //       status: result.data.status,
    //       idType: result.data.identification_type,
    //       idNumber: result.data.identification_id,
    //     });
    //     setTenantName(result.data.tenant.TenantName);
    //     setFetchError(false);
    //     setFetchErrorMessage("");
    //     setIsLoading(false);
    //   })
    //   .catch((e) => {
    //     console.error(e.response.data.error);
    //     setFetchError(true);
    //     setFetchErrorMessage(e.response.data.error);
    //     setIsLoading(false);
    //   });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = useCallback(async () => {
    const token = await getAccessTokenSilently();
    getTableUsersCompany(token);
    getCompanyById(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUsersData = useCallback(async () => {
    const token = await getAccessTokenSilently();
    getTableUsersCompany(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresaId]);

  useEffect(() => {
    if (refreshTable) {
      getUsersData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);

  useLayoutEffect(() => {
    const idsTypes = [...identifications];
    if (idsTypes.length > 0) {
      const findedID = idsTypes.find(
        (idType) => idType.id === formik.values.idType
      );
      setIdTextType(findedID ? findedID.label : "");
    }
  }, [formik.values.idType, identifications]);

  useEffect(() => {
    document.title = `${window._env_.REACT_APP_EMPRESA_NAME} - Empresa`;
  }, []);

  return (
    <Layout icon="HomeWorkOutlinedIcon" title="Empresa">
      <Grid container paddingTop="24px">
        <Grid bgcolor="white" item xs={10} borderRadius="6px">
          {isLoading ? (
            <Box
              sx={{
                borderRadius: "6px",
                display: "flex",
                backgroundColor: "white",
                height: "100%",
                width: "100%",
                paddingRight: "18px",
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Box
                  id="create-novelty-form"
                  borderRadius="6px"
                  sx={{
                    backgroundColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "214px",
                  }}
                >
                  <CircularProgress />
                </Box>
              </Box>
            </Box>
          ) : fetchError ? (
            <Box
              sx={{
                borderRadius: "6px",
                display: "flex",
                backgroundColor: "white",
                height: "100%",
                width: "100%",
                paddingRight: "18px",
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Box
                  id="create-novelty-form"
                  borderRadius="6px"
                  sx={{
                    backgroundColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "214px",
                  }}
                >
                  <h1>{fetchErrorMessage}</h1>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                borderRadius: "6px",
                display: "flex",
                backgroundColor: "white",
                height: "100%",
                width: "100%",
                paddingRight: "18px",
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Box
                  component="form"
                  autoComplete="off"
                  borderRadius="6px"
                  sx={{
                    backgroundColor: "white",
                    height: "100%",
                    ".MuiFormLabel-root": { fontSize: "14px" },
                    ".MuiInputBase-input": { fontSize: "14px" },
                  }}
                  onSubmit={formik.handleSubmit}
                >
                  <Grid container justifyContent="space-between">
                    <Grid item xs={3} sx={{ py: "18px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="nombre-input"
                        >
                          Nombre
                        </InputLabel>
                      </Box>
                      <TextField
                        id="nombre-input"
                        error={Boolean(
                          formik.touched.name && formik.errors.name
                        )}
                        fullWidth
                        helperText={formik.touched.name && formik.errors.name}
                        name="name"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.name}
                        variant="filled"
                        placeholder="Nombre de la empresa"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: changeLink === 2,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={3} sx={{ py: "18px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="email-input"
                        >
                          Email
                        </InputLabel>
                      </Box>
                      <TextField
                        id="email-input"
                        error={Boolean(
                          formik.touched.email && formik.errors.email
                        )}
                        fullWidth
                        helperText={formik.touched.email && formik.errors.email}
                        name="email"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.email}
                        variant="filled"
                        placeholder="info@bglobalsolutions.com"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: changeLink === 2,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={3} sx={{ py: "18px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="tenant-input"
                        >
                          Tenant
                        </InputLabel>
                      </Box>
                      <TextField
                        id="tenant-input"
                        error={Boolean(
                          formik.touched.name && formik.errors.name
                        )}
                        fullWidth
                        helperText={formik.touched.name && formik.errors.name}
                        name="tenant"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={tenantName}
                        variant="filled"
                        placeholder="Tenant"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: changeLink === 2,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={3} sx={{ py: "18px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="phone-input"
                        >
                          Teléfono
                        </InputLabel>
                      </Box>
                      <TextField
                        id="phone-input"
                        error={Boolean(
                          formik.touched.phone && formik.errors.phone
                        )}
                        fullWidth
                        helperText={formik.touched.phone && formik.errors.phone}
                        name="phone"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.phone}
                        variant="filled"
                        placeholder="+542351235454"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: changeLink === 2,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Grid item xs={3} sx={{ pb: "26px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="address-input"
                        >
                          Dirección
                        </InputLabel>
                      </Box>
                      <TextField
                        id="address-input"
                        error={Boolean(
                          formik.touched.address && formik.errors.address
                        )}
                        fullWidth
                        helperText={
                          formik.touched.address && formik.errors.address
                        }
                        name="address"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.address}
                        variant="filled"
                        placeholder="Av. Santander"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: changeLink === 2,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={3} sx={{ pb: "26px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="id-number-input"
                        >
                          Nro. de Identificación
                        </InputLabel>
                      </Box>
                      <TextField
                        id="id-number-input"
                        error={Boolean(
                          formik.touched.idNumber && formik.errors.idNumber
                        )}
                        fullWidth
                        helperText={
                          formik.touched.idNumber && formik.errors.idNumber
                        }
                        name="idNumber"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.idNumber}
                        variant="filled"
                        placeholder="Nro. de Identificación"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: changeLink === 2,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={3} sx={{ pb: "26px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="status-select"
                        >
                          Estado
                        </InputLabel>
                      </Box>
                      <TextField
                        id="status-select"
                        error={Boolean(
                          formik.touched.status && formik.errors.status
                        )}
                        fullWidth
                        helperText={
                          formik.touched.status && formik.errors.status
                        }
                        name="status"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={statuses[formik.values.status].label}
                        variant="filled"
                        placeholder="Estado"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: changeLink === 2,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={3} sx={{ pb: "26px", pl: "18px" }}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        marginBottom="4px"
                      >
                        <InputLabel
                          sx={{ color: "black" }}
                          htmlFor="id-tipo-input"
                        >
                          Tipo de Identificación
                        </InputLabel>
                      </Box>
                      <TextField
                        id="id-tipo-input"
                        error={Boolean(
                          formik.touched.idType && formik.errors.idType
                        )}
                        fullWidth
                        helperText={
                          formik.touched.idType && formik.errors.idType
                        }
                        name="idType"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={idTextType}
                        variant="filled"
                        placeholder="Tipo de indentificación"
                        InputProps={{
                          disableUnderline: true,
                          readOnly: changeLink === 2,
                          sx: {
                            borderRadius: "6px",
                            ".MuiInputBase-input": {
                              paddingBottom: "16px",
                              paddingTop: "16px",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>

      {openNewUserModal && (
        <NewUserModal
          openModal={openNewUserModal}
          changeOpenModal={setOpenNewUserModal}
          changeOpenInfoModal={setOpenErrorModal}
          handleRefreshTable={setRefreshTable}
          setErrorMessage={setErrorMessage}
        />
      )}

      {openErrorModal && (
        <InformationModal
          title="Ha ocurrido un error"
          message={errorMessage}
          onClose={() => setOpenErrorModal(false)}
          openModal={openErrorModal}
          clickCancelar={false}
          clickEliminar={false}
          guardar={false}
          isDirty={false}
          errorMessage
        />
      )}

      <UsersTable
        inviteUser={() => inviteUser()}
        refreshTable={refreshTable}
        setRefresTable={setRefreshTable}
        users={users}
        originalUsers={originalUsers}
        setUsers={setUsers}
        isLoading={isLoadingUsers}
        fetchUsersError={fetchUsersError}
        fetchUsersErrorMessage={fetchUsersErrorMessage}
      />
    </Layout>
  );
};

export default Empresa;
