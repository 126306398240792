import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import PeriodosList from "./PeriodosList";
import Stack from "@mui/material/Stack";
import PeriodoStepperDetails from "./PeriodoStepperDetails";

const BoxStepper = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "12px",
  borderTopWidth: 8,
  borderRadius: 6,
}));

type PeriodoStepperType = {
  activeStep: number;
  handleSelectPeriodo: (fieldName: string, periodoId: string) => void;
  handleReset: () => void;
  periodo: any;
  handleSetPeriodo: (periodo: any) => void;
  handleClose: () => void;
  handleRefreshTable: React.Dispatch<React.SetStateAction<boolean>>;
  setDisabledOnSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenErrorModal: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
};

const PeriodoStepper: React.FC<PeriodoStepperType> = ({
  activeStep,
  handleClose,
  handleSelectPeriodo,
  handleSetPeriodo,
  handleReset,
  handleRefreshTable,
  periodo,
  setDisabledOnSubmit,
  setErrorMessage,
  setOpenErrorModal,
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Stack direction="row" spacing={1} sx={{ paddingBottom: "18px" }}>
        <BoxStepper
          sx={{
            backgroundColor: (theme) =>
              activeStep === 1 ? theme.palette.primary.main : "#eaeaf0",
          }}
        />
        <BoxStepper
          sx={{
            backgroundColor: (theme) =>
              activeStep === 2 ? theme.palette.primary.main : "#eaeaf0",
          }}
        />
      </Stack>
      <React.Fragment>
        {activeStep === 1 && (
          <PeriodosList
            handleSelectPeriodo={handleSelectPeriodo}
            handleSetPeriodo={handleSetPeriodo}
          />
        )}
        {activeStep === 2 && (
          <PeriodoStepperDetails
            handleClose={handleClose}
            handleRefreshTable={handleRefreshTable}
            periodo={periodo}
            handleReset={handleReset}
            setDisabledOnSubmit={setDisabledOnSubmit}
            setErrorMessage={setErrorMessage}
            setOpenErrorModal={setOpenErrorModal}
          />
        )}
      </React.Fragment>
    </Box>
  );
};

export default PeriodoStepper;
